/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useMemo, useContext } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { notification, Divider, Button, Popover, Segmented, Dropdown, Spin, Checkbox } from 'antd'
import { useFormik } from 'formik'
import qs from 'qs'

import {
  Container,
  NotiStyle,
  BoxTitle,
  CustomDrawer,
  CustomDivider,
  CustomInputDrawer,
  BtSubmit,
  CustomheadTime,
  StyleDropdown,
  CustomPopover,
  LabelText,
  CustomUploadfile,
  ButtonOpenMap,
  LabelTexthead,
  ClearableTextArea,
  BoxContentDetail,
  BoxSeeDetail,
  WrapBoxComplaint,
  WrapMap,
  Menucustom,
  CustomCheckBox,
  BtNewComplaint,
  WrapContent,
  WrapContentResponsive,
} from './HomePage.style'
import { postData, putData, getData, getList } from 'core/action/collection'

import DropdownEditProfile from 'components/Inputs/DropdownEditProfile/DropdownEditProfile'
import InputComponent from 'components/input/Input'
import { mockUserData, defaultValues, Schema } from './container'
import iconAdd from 'assets/images/svg/icon-add-org.svg'
import iconTime from 'assets/images/svg/icon-time-head.svg'
import iconInfo from 'assets/images/svg/icon-info.svg'
import iconpinLocation from 'assets/images/svg/icon-pin-location.svg'
import iconarrowright from 'assets/images/svg/icon-arrow-right-org.svg'
// import iconclose from 'assets/images/svg/icon-close.svg'
import { ReactComponent as IconClear } from 'assets/images/svg/icon-clear-complain.svg'
// import IconAllowClear from 'assets/images/svg/icon-add-org.svg'
import iconHome from 'assets/images/png/icon-homepage.png'
import support from 'assets/images/png/logo-support.png'
import lineqrcode from 'assets/images/png/lineqrcode.png'
// import { ReactComponent as IconClose } from 'assets/images/svg/icon-close.svg'
import IconClose from 'assets/images/svg/icon-close.svg'
import { ReactComponent as IconSurprised } from 'assets/images/svg/icon-logout-surprised.svg'
import { authentication, masterdata, complaint } from 'core/schemas'
import { httpClient } from 'core/action/httpClient'
import axios from 'axios'
import UploadFile from 'components/Inputs/UploadFile'
import { ReactComponent as IconClearinput } from 'assets/images/svg/icon-clear-orange.svg'
import { ReactComponent as ChatBotButton } from 'assets/images/svg/Chatbot-Button.svg'
import IconArrowDown from 'assets/images/svg/icon-arrow-down.svg'
import MapButton from './GoogleMap/Map'
import MapHome from './GoogleMap/MapHome'
import { disable } from 'ol/rotationconstraint'
import BoxComplaintMe from 'components/BoxComplaintMe/BoxComplaintMe'
import styled from 'styled-components'
import IconNoti from 'assets/images/svg/icon-noti.svg'

import BoxSeeMoreDetail from 'components/BoxSeeMoreDetail/BoxSeeMoreDetail'
import UserContext from 'core/contexts/userContext'
import { useSearchContext } from 'core/contexts/useSearch'
import testIconMap from './GoogleMap/image/png/test-icon-in-map.png'
import DrawerSeemoreDetail from './DrawerSeemoreDeatil'

const HomePageGuest = props => {
  const navigate = useNavigate()

  const token = localStorage.getItem('access_token')
  const { meData } = useContext(UserContext)
  const { sharedValue } = useSearchContext()
  const [api, contextHolder] = notification.useNotification()
  const [openDrawer, setOpenDrawer] = React.useState(false)
  const [defaultValueFromList, setDefaultValueFromList] = React.useState({
    first_name: '',
    last_name: '',
    mobile_number: '',
    groupuser: '',
    address: '',
    province: '',
    district: '',
    sub_districte: '',
    postcode: '',
    categories: '',
    name_complain: '',
    description_complain: '',
  })

  const [edit, setEdit] = React.useState(true)
  const [submit, setsubmit] = React.useState({})
  const [formattedDate, setFormattedDate] = useState('')

  const [provinceId, setProvinceId] = useState('')
  const [provinceData, setProvinceData] = useState([])
  const [districtData, setDistrictData] = useState([])
  const [subDistrictData, setSubDistrictData] = useState([])
  const [districtId, setDistrictId] = useState('')
  const [subDistrictId, setSubDistrictId] = useState('')
  const [categories, setCategories] = useState([])
  const [loading, setLoading] = useState(true)
  const [mapOpen, setMapOpen] = useState()
  const [cover, setCover] = React.useState([])
  const [locationme, setlocationme] = React.useState({})
  const [arrow, setArrow] = useState('Show')
  const [showDetail, setShowDetail] = useState(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [eserviceData, setEserviceData] = useState([])
  const [dataMap, setDataMap] = useState([])

  const [dataOnClickCard, setDataOnClickCard] = useState('')
  const [loadingComplaintMe, setLoadingComplaintMe] = useState(true)

  const [checkBoxComplaint, setCheckBoxComplaint] = useState(true)
  const [checkBoxEservice, setCheckBoxEservice] = useState(true)
  const [isResponsive, setIsResponsive] = useState(false)
  const [openDrawerDetailComplaint, setOpenDrawerDetailComplaint] = React.useState(false)
  const [openDrawerSuccess, setOpenDrawerSuccess] = React.useState(false)

  const formik = useFormik({
    initialValues: defaultValueFromList,
    validationSchema: Schema,
    enableReinitialize: true,
    onSubmit: values => {},
  })

  const checkimg = cover.length > 0 ? true : false
  const checkmap = Object.keys(locationme).length === 0 || locationme == {} ? false : true
  const isValidForm = formik.isValid && formik.dirty && checkimg && checkmap
  const [checkchange, setcheckchange] = React.useState(false)

  const notiEdit = localStorage.getItem('notiEdit')

  useEffect(() => {
    getMasterData()
    localStorage.removeItem('search')
    const checkNoti = () => {
      if (notiEdit) {
        openNotificationEditSuccess()
        localStorage.removeItem('notiEdit')
      }
    }
    checkNoti()
  }, [])

  useEffect(() => {
    // setLoadingComplaintMe(true)
    getComplantGuest()
    getEserviceGuest()
    setTimeout(() => {
      // setLoadingComplaintMe(false)
    }, 1000)
  }, [sharedValue])

  useEffect(() => {
    const handleResize = () => {
      setIsResponsive(window.innerWidth <= 820) // ตัวอย่างเงื่อนไขขนาดหน้าจอ responsive
    }

    handleResize() // เรียกใช้งานเมื่อโหลดครั้งแรก
    window.addEventListener('resize', handleResize) // เพิ่ม event listener สำหรับ resize

    return () => {
      window.removeEventListener('resize', handleResize) // ตรวจจับเหตุการณ์การ resize
    }
  }, [])

  const onclearinput = async () => {
    formik.resetForm()
    // setcheckchange(true)
    setDefaultValueFromList({
      first_name: mockUserData ? '' : '',
      last_name: mockUserData ? '' : '',
      mobile_number: mockUserData ? '' : '',
      groupuser: mockUserData ? '' : '',
      address: mockUserData ? '' : '',
      province: mockUserData ? '' : '',
      district: mockUserData ? '' : '',
      sub_districte: mockUserData ? '' : '',
      postcode: mockUserData ? '' : '',
      categories: mockUserData ? '' : '',
      name_complain: mockUserData ? '' : '',
      description_complain: mockUserData ? '' : '',
    })
  }

  const getMasterData = async () => {
    // handleGetLocation()
    // handleGetCurrentPosition()
    getComplantGuest()
    getEserviceGuest()
    // setTimeout(() => {
    setLoadingComplaintMe(false)
    // }, 1000)

    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }

  const getComplantGuest = async () => {
    if (sharedValue === '') {
      setDataMap([])
    } else {
      await axios
        .get(`${process.env.REACT_APP_BASEURL}complaint/api/v1/guest/all/complaint`, {
          params: {
            filters: [],
            search: [
              sharedValue ? `mobile_number:${sharedValue}` : undefined,
              sharedValue ? `complaint_no:${sharedValue}` : undefined,
            ],
          },
          paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
        })
        .then(res => {
          // setTimeout(() => {
          if (res?.status === 200) {
            if (sharedValue === '') {
              setDataMap([])
            } else {
              if (res?.data?.data?.length > 0) {
                setDataMap(res?.data?.data)
              }
            }
          } else if (res?.status === 204) {
            setDataMap([])
          } else {
            setDataMap([])
          }
          // }, 500)
        })
        .catch(err => {
          console.log('err', err)
        })
    }
  }

  const getEserviceGuest = async () => {
    if (sharedValue === '') {
      setEserviceData([])
    } else {
      await axios
        .get(`${process.env.REACT_APP_BASEURL}complaint/api/v1/guest/all/eservice`, {
          params: {
            filters: [],
            search: [
              sharedValue ? `mobile_number:${sharedValue}` : undefined,
              sharedValue ? `complaint_no:${sharedValue}` : undefined,
            ],
          },
          paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
        })
        .then(res => {
          // setTimeout(() => {
          if (res?.status === 200) {
            if (sharedValue === '') {
              setEserviceData([])
            } else {
              if (res?.data?.data?.length > 0) {
                setEserviceData(res?.data?.data)
              }
            }
          } else if (res?.status === 204) {
            setEserviceData([])
          } else {
            setEserviceData([])
          }
          // }, 500)
        })
        .catch(err => {
          console.log('err', err)
        })
    }
  }

  const covertTothai = async position => {
    try {
      const apiKey = 'AIzaSyBOrbrRCU9ZyF1FxHyBkJwuBMGA7vtZa14'
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=${apiKey}&language=th`
      )
      const data = await response.json()

      if (data.status === 'OK' && data.results.length > 0) {
        // setLocationName(data.results[0].formatted_address)
      }
    } catch (error) {
      console.error('Error fetching location name:', error)
    }
  }

  const getProvince = async () => {
    const res = await getData({
      schema: `${masterdata}/all/province`,
      params: {},
      url: '',
      baseURL: '',
    })

    if (res) {
      const newData = res.data.slice()
      newData.sort((a, b) => (a.id === '19' ? -1 : b.id === '19' ? 1 : 0))

      setProvinceData(newData)
    }
  }
  const getDistrict = async () => {
    const res = await getData({
      schema: `${masterdata}/all/district/` + provinceId,
      params: {},
      url: '',
      baseURL: '',
    })

    if (res) {
      const newData = res.data.slice()
      newData.sort((a, b) => (a.id === '215' ? -1 : b.id === '215' ? 1 : 0))

      setDistrictData(newData)
    }
  }

  const handlerSubmit = async () => {
    const {
      first_name,
      last_name,
      mobile_number,
      group_id,
      address,
      province_id,
      district_id,
      sub_district_id,
      postcode,
      category_id,
      name_complain,
      description_complain,
    } = formik.values

    const dataurl = []

    cover.map(item => {
      dataurl.push('https://' + item.urlkey)
    })

    const tmpValues = {
      name: {
        first_name: first_name,
        last_name: last_name,
      },
      line_id: 's',
      channel: 'website',
      mobile_number: mobile_number,
      org_id: '19118133238380',
      category_id: category_id,
      complaint_name: name_complain,
      description: description_complain,
      media_url: dataurl,
      address: address,
      province_id: province_id,
      district_id: district_id,
      sub_district_id: sub_district_id,
      coordinates: {
        latitude: locationme.lat,
        longitude: locationme.lng,
      },
    }
    setOpenDrawer(false)
    setOpenDrawerSuccess(true)
    const res = await postData(`${complaint}/guest/complaint`, tmpValues)
    if (res.code === 201) {
      setsubmit({ status: 1 })
      formik.setFieldValue('first_name', '')
      formik.setFieldValue('last_name', '')
      formik.setFieldValue('mobile_number', '')
      formik.setFieldValue('category_id', '')
      formik.setFieldValue('name_complain', '')
      formik.setFieldValue('description_complain', '')
      formik.setFieldValue('address', '')
      formik.setFieldValue('province_id', '')
      formik.setFieldValue('district_id', '')
      formik.setFieldValue('sub_district_id', '')
      formik.setFieldValue('coordinates', '')
      formik.setFieldValue('postcode', '')
      setlocationme({
        lat: '',
        lng: '',
      })
      setCover([])
    }
  }

  const getSubDistrict = async () => {
    const res = await getData({
      schema: `${masterdata}/all/sub-district/` + districtId,
      params: {},
      url: '',
      baseURL: '',
    })
    // setSubDistrictData(res?.data)

    if (res) {
      const newData = res.data.slice()
      newData.sort((a, b) => (a.id === '300101' ? -1 : b.id === '300101' ? 1 : 0))

      setSubDistrictData(newData)
    }
  }

  const getCategories = async () => {
    const res = await getData({
      schema: `${masterdata}/all/categories`,
      params: {},
      url: '',
      baseURL: '',
    })
    // setSubDistrictData(res?.data)
    if (res) {
      const newData = res.data
      setCategories(newData)
    }
  }
  const phoneNumberAutoFormat = phoneNumber => {
    const number = phoneNumber.trim().replace(/[^0-9]/g, '')

    if (number.length < 4) return number
    if (number.length < 7) return number.replace(/(\d{3})(\d{1})/, '$1-$2')
    if (number.length < 11) return number.replace(/(\d{3})(\d{3})(\d{1})/, '$1-$2-$3')
    return number.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
  }

  const onPhoneNumberChange = e => {
    const targetValue = phoneNumberAutoFormat(e.target.value)
    formik.setFieldValue('mobile_number', targetValue)
  }

  // useEffect(() => {
  //   provinceId && getDistrict()
  // }, [provinceId])

  // useEffect(() => {
  //   districtId && getSubDistrict()
  // }, [districtId])

  const DatetimeNowThai = () => {
    const currentDate = new Date()

    const options = {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      eraDisplay: 'narrow',
    }

    const thaiLocale = 'th-TH'

    // Format the date using Intl.DateTimeFormat
    const formattedDate = new Intl.DateTimeFormat(thaiLocale, options).format(currentDate)
    setFormattedDate(formattedDate)
  }

  useEffect(() => {
    DatetimeNowThai()
  }, [])

  React.useEffect(() => {
    formik.setFieldValue('cover', cover)
  }, [cover])

  const onclickComplain = () => {
    // setsubmit({ status: 1 })
    // setOpenDrawer(true)
    navigate('/create-complaint')
  }
  const oncloseComplain = () => {
    setsubmit({ status: 1 })
    setOpenDrawer(false)
  }

  const openNotificationEditSuccess = typeNoti => {
    notification.info({
      message: <div style={{ color: '#13AFA6', fontFamily: 'Prompt', fontWeight: '400' }}>แก้ไขรายละเอียดสำเร็จ</div>,
      placement: 'top',
      icon: <img src={IconNoti} alt="icon" />,
      closeIcon: <img src={IconClose} alt="icon" />,
      duration: 3,
    })
  }

  const showDetailIfClickNoti = notiValue => {
    !isResponsive ? onClickShowDetail(notiValue) : onResponsiveClickShowDetail(notiValue)
  }

  const onClickShowDetail = data => {
    setShowDetail(true)
    setDataOnClickCard(data)
  }

  const onResponsiveClickShowDetail = data => {
    onOpenDrawerDetailComplaint()
    setDataOnClickCard(data)
  }

  const handleMenuImportClick = e => {
    setIsDropdownOpen(false)
    if (e.key === 'เรื่องร้องเรียน') {
      if (token) {
        navigate('/create-complaint')
      } else {
        navigate('/create-complaint-guest')
      }
    } else if (e.key === 'บริการสาธารณะ') {
      if (token) {
        navigate('/create-e-service')
      } else {
        navigate('/create-e-service-guest')
      }
    }
  }

  const menuImport = value => {
    // setrecord(value)
    return (
      <Menucustom onClick={handleMenuImportClick}>
        <Menucustom.Item key={`เรื่องร้องเรียน`}>
          {/* {<img src={IconInputForm} alt="see" style={{ marginRight: '5px' }} />} */}
          เรื่องร้องเรียน
        </Menucustom.Item>
        <Menucustom.Item key={`บริการสาธารณะ`}>
          {/* {<img src={IconImportFile} alt="edit" style={{ marginRight: '5px' }} />} */}
          บริการสาธารณะ
        </Menucustom.Item>
      </Menucustom>
    )
  }

  const handleDropdownVisibleChange = visible => {
    setIsDropdownOpen(visible)
  }

  const onCloseSeeMoreDetail = () => {
    setShowDetail(false)
  }

  const mockData = {
    id: '1',
    complaint_no: '0502256700011',
    title_complaint: 'ดูดโคลนหน้าบ้าน1',
    description: 'ดูดโคลนหน้าบ้าน1',
    coordinates: {
      latitude: 14.98233703299874,
      longitude: 102.10832893748167,
    },
    type_of_complaint: {
      id: '1746938558460981249',
      name: 'บริการรถดูดโคลนเลน',
      icon_url: 'testIconMap',
      bg_icon_url: '',
      icon_line: 'testIconMap',
      bg_icon_line: 'testIconMap',
    },
    status_data: [
      {
        name: 'received',
        description: 'รับเรื่อง',
        staff_data: null,
        created_at: 1707125321,
        created_by: '1753353277405130752',
      },
    ],
    department_data: {
      id: '1730459515045801985',
      name: 'สำนักช่าง',
      officer_data: null,
    },
    address: '269 ถ. สุรนารายณ์ ตำบลในเมือง อำเภอเมืองนครราชสีมา นครราชสีมา 30000 ประเทศไทย',
    media_url: ['testIconMap'],
    created_at: 1707125321,
    created_by: '1753353277405130752',
    updated_at: 1707125321,
    updated_by: '',
  }

  const generateMockData = () => {
    const mockDataList = []
    for (let i = 0; i < 500; i++) {
      const mockData = {
        id: i.toString(),
        complaint_no: `05022567000${i}`,
        title_complaint: 'ดูดโคลนหน้าบ้าน1',
        description: 'ดูดโคลนหน้าบ้าน1',
        coordinates: {
          latitude: 14.98233703299874,
          longitude: 102.10832893748167,
        },
        type_of_complaint: {
          id: '1746938558460981249',
          name: 'บริการรถดูดโคลนเลน',
          icon_url: 'testIconMap',
          bg_icon_url: '',
          icon_line: 'testIconMap',
          bg_icon_line: 'testIconMap',
        },
        status_data: [
          {
            name: 'received',
            description: 'รับเรื่อง',
            staff_data: null,
            created_at: 1707125321,
            created_by: '1753353277405130752',
          },
        ],
        department_data: {
          id: '1730459515045801985',
          name: 'สำนักช่าง',
          officer_data: null,
        },
        address: '269 ถ. สุรนารายณ์ ตำบลในเมือง อำเภอเมืองนครราชสีมา นครราชสีมา 30000 ประเทศไทย',
        media_url: ['testIconMap'],
        created_at: 1707125321,
        created_by: '1753353277405130752',
        updated_at: 1707125321,
        updated_by: '',
      }
      mockDataList.push(mockData)
    }
    return mockDataList
  }

  const onCheckComplaint = e => {
    setCheckBoxComplaint(e.target.checked)
  }

  const onCheckEservice = e => {
    setCheckBoxEservice(e.target.checked)
  }

  const onOpenDrawerDetailComplaint = () => {
    setOpenDrawerDetailComplaint(true)
  }

  const onCloseDerwerDeaitlComplaint = () => {
    setOpenDrawerDetailComplaint(false)
  }

  return (
    <>
      <NotiStyle />
      {contextHolder}
      {loading ? (
        <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', paddingTop: '15%' }}>
          <Spin />
        </div>
      ) : (
        <Container>
          <BoxTitle>
            <Dropdown
              overlay={() => menuImport()}
              trigger={['click']}
              getPopupContainer={triggerNode => triggerNode.parentElement}
              onVisibleChange={handleDropdownVisibleChange}
            >
              <BtNewComplaint>
                <span className="text">แจ้งเรื่องใหม่</span>
                <img
                  src={IconArrowDown}
                  alt="add"
                  style={{
                    display: 'inline',
                    transform: isDropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                    transition: 'transform 0.3s ease',
                  }}
                />
              </BtNewComplaint>
            </Dropdown>
          </BoxTitle>

          <WrapContent>
            <div
              style={{
                width: '100%',
              }}
            >
              <div style={{ backgroundColor: 'blue', height: '100%' }}>
                <BoxContentDetail showDetail={showDetail}>
                  <WrapMap>
                    <MapHome
                      responsive={isResponsive}
                      onClickShowDetail={onClickShowDetail}
                      onResponsiveClickShowDetail={onResponsiveClickShowDetail}
                      eserviceData={eserviceData}
                      dataMap={dataMap}
                      checkBoxComplaint={checkBoxComplaint}
                      checkBoxEservice={checkBoxEservice}
                    />
                  </WrapMap>
                  {showDetail && (
                    <BoxSeeMoreDetail
                      onCloseSeeMoreDetail={onCloseSeeMoreDetail}
                      responsive={false}
                      data={dataOnClickCard}
                    />
                  )}
                </BoxContentDetail>
              </div>
            </div>
          </WrapContent>

          <WrapContentResponsive>
            <div className="div-wrap-map">
              <WrapMap>
                <MapHome
                  responsive={isResponsive}
                  onClickShowDetail={onClickShowDetail}
                  onResponsiveClickShowDetail={onResponsiveClickShowDetail}
                  eserviceData={eserviceData}
                  dataMap={dataMap}
                  checkBoxComplaint={checkBoxComplaint}
                  checkBoxEservice={checkBoxEservice}
                />
              </WrapMap>
            </div>
          </WrapContentResponsive>

          <DrawerSeemoreDetail
            onCloseDerwerDeaitlComplaint={onCloseDerwerDeaitlComplaint}
            openDrawerDetailComplaint={openDrawerDetailComplaint}
            data={dataOnClickCard}
            isResponsive={isResponsive}
          />
        </Container>
      )}
    </>
  )
}

export default HomePageGuest
