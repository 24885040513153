import React, { useEffect, useContext, useCallback, useState } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { Tooltip, Form, Input, notification, Button, Divider } from 'antd'
import moment from 'moment'
import debounce from 'lodash.debounce'
import version from 'core/constants/version'
import UserContext from 'core/contexts/userContext'
import { getData, loginAuthen, postData } from 'core/action/collection'
import { authentication, hospcode, mohpic, sso } from 'core/schemas'
import AutoComplete from 'components/Inputs/AutoComplete'
import BgLogin from 'assets/images/png/bg-login.png'
import BgLeft from 'assets/images/png/bg-login-ddc.png'
import ErrorFoglogOut from 'assets/images/png/errorFoglogOut.png'
import LogoFull2 from 'assets/images/png/logo-full-ddc2.png'
import { ReactComponent as IconAlert } from 'assets/images/svg/alert.svg'
import { ReactComponent as EyeIcon } from 'assets/images/svg/icon-eye.svg'
import { ReactComponent as IconClose } from 'assets/images/svg/icon-close.svg'
import { ReactComponent as EyeActiveIcon } from 'assets/images/svg/icon-eye-active.svg'
import { ReactComponent as IconSurprised } from 'assets/images/svg/icon-logout-surprised.svg'
import { ReactComponent as IconSurprisedForce } from 'assets/images/svg/Icon-Notification-force-logout.svg'
import { ReactComponent as IconActive } from 'assets/images/svg/IconActive.svg'
import { ReactComponent as LogoKorat } from 'assets/images/svg/logo-koratcity.svg'
import { ReactComponent as LogoLine } from 'assets/images/svg/logo-line.svg'
import BG from 'assets/images/png/bg-login-group.png'
import Loader from 'components/Layout/Loader'
import * as Styled from './Login.style'
// import io from 'socket.io-client'
// const socket = io.connect('192.168.110.113:5050')
import liff from '@line/liff'

let isValid = false

function LoginPage() {
  const navigate = useNavigate()

  const [form] = Form.useForm()
  const [api, contextHolder] = notification.useNotification()
  const [notiStyle, setNotiStyle] = useState(false)
  const [options, setOptions] = useState([])
  const [exactCurrentPage, setExactCurrentPage] = useState(1)
  const [disableBtn, setDisableBtn] = useState(true)
  const [alertModalOpen, setAlertModalOpen] = useState(false)
  const [modelForLogout, setModelForLogout] = useState(false)
  const [detailFogLogin, setDetailFogLogin] = useState([])
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [loading, setLoading] = useState(false)

  //line data login
  const [pictureUrl, setPictureUrl] = useState(LogoLine)
  const [idToken, setIdToken] = useState('')
  const [displayName, setDisplayName] = useState('')
  const [urlProfile, setUrlProfile] = useState('')
  const [statusMessage, setStatusMessage] = useState('')
  const [userId, setUserId] = useState('')
  const location = useLocation()

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const line_id_data = searchParams.get('line_id')
    if (line_id_data) {
      Login({ line_id: line_id_data })
    }
  }, [])

  const Login = async data => {
    const body = {
      line_id: data.line_id,
    }
    const res = await postData(`${authentication}/login`, body)
    let searchParams = new URLSearchParams(location.search)
    let params = searchParams.get('param')
    let complaint_id = searchParams.get('complaint_id')
    let guest = searchParams.get('guest')
    let service = searchParams.get('service')

    if (res) {
      if (res?.code === 200) {
        localStorage.setItem('access_token', res?.data?.access_token)
        localStorage.setItem('refresh_token', res?.data?.refresh_token)

        if (params && complaint_id) {
          if (params == 'view') {
            navigate('/view-complaint', { state: { complaint_id: complaint_id, guest: guest, service: service } })
          }
          if (params == 'edit') {
            navigate('/edit-complaint', { state: { complaint_id: complaint_id, guest: guest, service: service } })
          }
        } else {
          // navigate('/home')
          window.replace('/home')
        }
      } else {
        localStorage.setItem('access_token', res?.data?.access_token)
        localStorage.setItem('refresh_token', res?.data?.refresh_token)
        console.log('Error', res)
        if (params && complaint_id) {
          if (params == 'view') {
            navigate('/view-complaint', { state: { complaint_id: complaint_id, guest: guest, service: service } })
          }
          if (params == 'edit') {
            navigate('/edit-complaint', { state: { complaint_id: complaint_id, guest: guest, service: service } })
          }
        }
      }
    } else {
      console.log('Error', res)
      localStorage.setItem('access_token', res?.data?.access_token)
      localStorage.setItem('refresh_token', res?.data?.refresh_token)
      if (params && complaint_id) {
        if (params == 'view') {
          navigate('/view-complaint', { state: { complaint_id: complaint_id } })
        }
        if (params == 'edit') {
          navigate('/edit-complaint', { state: { complaint_id: complaint_id } })
        }
      }
    }
  }

  useEffect(() => {
    setLoading(true)
    liff
      .init({ liffId: `${process.env.REACT_APP_LINELIFF}` }) //SIT
      .then(() => {
        if (liff.isLoggedIn()) {
          liff
            .getProfile()
            .then(profile => {
              setDisplayName(profile.displayName)
              setUrlProfile(
                profile.pictureUrl
                  ? profile.pictureUrl
                  : 'https://gateway.koratcity.go.th/sit/media/images/33db1291-8fac-4bd8-b5c1-8b275b26af50.png'
              )
              setUserId(profile.userId)
              testLogin(
                profile.userId,
                profile.displayName,
                profile.pictureUrl
                  ? profile.pictureUrl
                  : 'https://gateway.koratcity.go.th/sit/media/images/33db1291-8fac-4bd8-b5c1-8b275b26af50.png'
              )
            })
            .catch(err => console.error(err))
        } else {
          setLoading(false)
        }
      })
      .catch(err => {
        localStorage.clear()
        setLoading(false)
      })
  }, [])

  const testLogin = async (id, displayName, pictureUrl) => {
    const body = {
      line_id: id ? id : userId,
    }

    const res = await postData(`${authentication}/login`, body)

    if (res) {
      if (res) {
        if (res?.code === 200) {
          localStorage.setItem('access_token', res?.data?.access_token)
          localStorage.setItem('refresh_token', res?.data?.refresh_token)
          navigate('/home')
        } else if (res?.code === 401 || 404) {
          navigate('/consent', {
            state: { line_id: id, line_name: displayName, line_picture: pictureUrl, registerChannel: 'line' },
          })
        }
      }
    }
  }

  const lineData = {
    idToken: idToken,
    displayName: displayName,
    statusMessage: statusMessage,
    userId: userId,
  }

  const logout = () => {
    liff.logout()
    window.location.reload()
  }

  const styleTooltip = {
    fontFamily: 'Prompt',
    backgroundColor: '#597081',
    borderRadius: '12px',
    boxShadow: '0px 2px 16px rgba(200, 211, 218, 0.3)',
    fontSize: '10px',
    fontWeight: 500,
    width: '263px',
    height: '36px',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '12px 11px',
  }

  const openNotification = placement => {
    api.info({
      message: 'ออกจากระบบสำเร็จ',
      placement,
      icon: <IconSurprised />,
      closeIcon: <IconClose />,
      duration: 3,
    })
  }

  const isSizeMobile = 768

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleClickLoginEmail = () => {
    // sessionStorage.setItem('guest', 'true')
    navigate('/login-email')
  }

  const callfunc = async () => {
    await getProfileLine()
  }

  const getProfileLine = async () => {
    setLoading(true)
    await liff
    liff
      .init({ liffId: `${process.env.REACT_APP_LINELIFF}` }) //SIT
      .then(() => {
        if (liff.isLoggedIn()) {
          liff
            .getProfile()
            .then(profile => {
              setDisplayName(profile.displayName)
              setUrlProfile(profile.pictureUrl)
              setUserId(profile.userId)
              testLogin(profile.userId, profile.displayName, profile.pictureUrl)
            })
            .catch(err => console.error(err))
        } else {
          liff.login()
        }
      })
      .catch(err => {
        console.log('err', err)
        localStorage.clear()
        navigate('/login')
      })
  }

  return (
    <>
      {notiStyle && <Styled.NotiStyle />}
      {contextHolder}
      {loading ? (
        // <div style={{ width: '100%', height: '100vh' }}>
        <div
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100vh' }}
        >
          <Loader loading={loading} />
        </div>
      ) : (
        <Styled.Container>
          <>
            <Styled.BoxOther>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <LogoKorat />
              </div>
              <Styled.BtLoginLine onClick={() => callfunc()}>
                <LogoLine />
                ลงทะเบียนผ่านไลน์
              </Styled.BtLoginLine>
              <Styled.BtLoginEmail onClick={() => navigate('/consent', { state: { registerChannel: 'email' } })}>
                ลงทะเบียนผ่านอีเมล
              </Styled.BtLoginEmail>
              <Styled.BoxOther>
                <Styled.CustomDivider>อื่นๆ</Styled.CustomDivider>
              </Styled.BoxOther>
              <Styled.BtSkip border={windowWidth <= isSizeMobile ? true : false} onClick={handleClickLoginEmail}>
                เข้าสู่ระบบ
              </Styled.BtSkip>
            </Styled.BoxOther>
          </>
          <Styled.BoxVersion>{version}</Styled.BoxVersion>
        </Styled.Container>
      )}
    </>
  )
}

export default LoginPage
