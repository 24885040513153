import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import { useNavigate, useLocation } from 'react-router-dom'

import {
  CustomDivider,
  InputEditName,
  StyleDropdown,
  BoxData,
  WrapProfileResponsive,
  BoxProfileDetial,
  BtLogout,
  ModalSuccessEditProfile,
  BtOk,
  BtSubmit,
  CustomModal,
} from './Header.style'
import { Input } from 'components/input'
import { InputEditProfile } from 'components/Inputs'
import { defaultValues, mockUserData, Schema } from './container'
import DropdownEditProfile from 'components/Inputs/DropdownEditProfile/DropdownEditProfile'
import { authentication, masterdata } from 'core/schemas'
import Loader from 'components/Layout/Loader'
import axios from 'axios'

import { ReactComponent as IconClear } from 'assets/images/svg/icon-close-drawer.svg'
import { ReactComponent as IconAllowClear } from 'assets/images/svg/icon-allowclear.svg'
import { ReactComponent as BackButtonMobile } from 'assets/images/svg/back-button-profile.svg'
import { ReactComponent as Success } from 'assets/images/svg/success-edit-profile.svg'

import IconProfileDrawer from 'assets/images/svg/icon-profile-drawer.svg'
import { getData, postData, putData } from 'core/action/collection'
import { httpClient } from 'core/action/httpClient'
import { Button } from 'antd'
import InputComponent from 'components/input/Input'
import ExclamationCircle from 'assets/images/svg/offline/exclamation-circle'
const EditProfileDrawer = props => {
  const { onCloseDrawer, getMeHeader, onLogout, statusEdit } = props
  const navigate = useNavigate()

  const [edit, setEdit] = React.useState(true)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [loading, setLoading] = useState(false)

  const [userId, setUserId] = useState('')
  const [firstName, setFirstName] = useState('-')
  const [lastName, setLastName] = useState('-')
  const [mobileNumber, setMobileNumber] = useState('-')
  const [groupUser, setGroupUser] = useState('-')
  const [address, setAddress] = useState('-')
  const [province, setProvince] = useState('-')
  const [district, setDistrict] = useState('-')
  const [subDistrict, setSubDistrict] = useState('-')
  const [postCode, setPostCode] = useState('-')
  const [lineId, setLineId] = useState('')
  const [singUpChannel, setSingUpChannel] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const [provinceId, setProvinceId] = useState('')
  const [provinceData, setProvinceData] = useState([])
  const [districtData, setDistrictData] = useState([])
  const [subDistrictData, setSubDistrictData] = useState([])
  const [districtId, setDistrictId] = useState('')
  const [subDistrictId, setSubDistrictId] = useState('')

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [complainById, setcomplainById] = useState({})
  const location = useLocation()

  const isSizeMobile = 768
  const token = localStorage.getItem('access_token')

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    getcomplainid()
  }, [])

  const handleResize = () => {
    setWindowWidth(window.innerWidth)
  }

  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema: Schema,
    enableReinitialize: true,
    onSubmit: values => {
      // console.log(values)
    },
  })

  const getcomplainid = async () => {
    axios
      .get(`${process.env.REACT_APP_BASEURL}complaint/api/v1/complaint/` + location?.state?.complaint_id, {
        // await axios.get('https://gateway.koratcity.go.th/sit/complaint/api/v1/complaint/1752695403628720128', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => {
        if (res?.data?.code === 200) {
          let dataById = res?.data?.data
          setcomplainById(dataById)
          formik.setFieldValue('first_name', dataById?.name?.first_name)
          formik.setFieldValue('last_name', dataById?.name?.last_name)
          formik.setFieldValue('mobile_number', dataById?.mobile_number)
          formik.setFieldValue('description_complain', dataById?.description)
        }
      })
      .catch(err => {
        console.log('err', err)
      })
  }

  const phoneNumberAutoFormat = phoneNumber => {
    const number = phoneNumber.trim().replace(/[^0-9]/g, '')

    if (number.length < 4) return number
    if (number.length < 7) return number.replace(/(\d{3})(\d{1})/, '$1-$2')
    if (number.length < 11) return number.replace(/(\d{3})(\d{3})(\d{1})/, '$1-$2-$3')
    return number.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
  }

  const setDefalut = async () => {
    formik.setFieldValue('first_name', mockUserData.first_name)
    formik.setFieldValue('last_name', mockUserData.last_name)
    formik.setFieldValue('mobile_number', mockUserData.mobile_number)
    formik.setFieldValue('description_complain', mockUserData.description_complain)
  }

  const onPhoneNumberChange = e => {
    e.preventDefault()
    const targetValue = phoneNumberAutoFormat(e.target.value)
    formik.setFieldValue('mobile_number', targetValue)
  }

  const onSubmit = async () => {
    let departmendata = []
    complainById?.department_data.map(item => {
      departmendata.push(item.id)
    })

    let body = {
      address: complainById?.address,
      category_id: complainById?.category?.id,
      sub_category_id: complainById?.sub_category_data?.id,
      channel: complainById.channel,
      complaint_name: complainById?.complaint_name,
      coordinates: {
        latitude: complainById?.coordinates?.latitude,
        longitude: complainById?.coordinates?.longitude,
      },
      department_data: departmendata,
      description: formik?.values?.description_complain,
      district_id: complainById?.district_id,
      duedate_at: complainById?.duedate_at,
      line_id: complainById.line_id,
      media_url: complainById?.media_url,
      mobile_number: complainById.mobile_number,
      name: {
        first_name: complainById?.name?.first_name,
        last_name: complainById?.name?.last_name,
      },
      notidate_at: complainById?.notidate_at,
      org_id: '19118133238380',
      province_id: complainById?.province_id,
      sub_district_id: complainById?.sub_district_id,
      urgency_id: complainById?.urgency_id,
      zib_code: complainById?.zib_code,
    }

    axios
      .put(`${process.env.REACT_APP_BASEURL}complaint/api/v1/complaint/` + complainById?.id, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => {
        navigate('/home')
      })
      .catch(err => {
        console.log(err)
      })
  }

  const isValidForm = formik.isValid && formik.dirty

  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleOk = () => {
    setIsModalOpen(false)
    setEdit(false)
    onCloseDrawer()
    getMeHeader()
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <Loader loading={loading}>
        {windowWidth <= isSizeMobile && (
          <>
            {/* Mobile */}
            <WrapProfileResponsive>
              <div className="header">
                <div className="box-header-left">
                  <span>แก้ไขเรื่องร้องเรียน</span>
                </div>
              </div>
              <BoxProfileDetial>
                <BoxData>
                  <span className="title-resposive">ชื่อ</span>
                  {edit ? (
                    <InputEditProfile
                      name="first_name"
                      placeholder="กรอกชื่อของคุณ"
                      type="text"
                      required={true}
                      errors={formik.errors}
                      touched={formik.touched}
                      value={formik?.values?.first_name}
                      form={formik}
                      status={formik.status}
                      maxLength={255}
                      requiredPadding={1}
                      fontsize="14px"
                      disabled={true}
                    />
                  ) : (
                    <span className="data">{firstName}</span>
                  )}
                </BoxData>
                <BoxData>
                  <span className="title-resposive">นามสกุล</span>
                  {edit ? (
                    <InputEditProfile
                      name="last_name"
                      placeholder="กรอกนามสกุลของคุณ"
                      type="text"
                      required={true}
                      errors={formik.errors}
                      touched={formik.touched}
                      value={formik?.values?.last_name}
                      form={formik}
                      status={formik.status}
                      maxLength={255}
                      requiredPadding={1}
                      fontsize="14px"
                      disabled={true}
                    />
                  ) : (
                    <span className="data">{lastName}</span>
                  )}
                </BoxData>
                <BoxData>
                  <span className="title-resposive">เบอร์โทรติดต่อ</span>
                  {edit ? (
                    <InputEditProfile
                      label="เบอร์โทรศัพท์"
                      name="mobile_number"
                      placeholder="0XX-XXX-XXXX"
                      type="text"
                      required={true}
                      errors={formik.errors}
                      touched={formik.touched}
                      form={formik}
                      status={formik.status}
                      // maxLength={10}
                      requiredPadding={1}
                      fontsize="14px"
                      onChange={onPhoneNumberChange}
                      inputMode="tel"
                      disabled={true}
                    />
                  ) : (
                    <span className="data">{mobileNumber}</span>
                  )}
                </BoxData>
                <BoxData>
                  <InputComponent
                    label="รายละเอียดเพิ่มเติม (ถ้ามี)"
                    type="textarea"
                    name="description_complain"
                    placeholder="ระบุรายละเอียดบริเวณที่เกิดปัญหา หรือรายละเอียดของ
                ปัญหาเพิ่มเติม”"
                    required={false}
                    value={formik.values.description_complain}
                    errors={formik.errors}
                    touched={formik.touched}
                    form={formik}
                    status={formik.status}
                    // maxLength={255}
                    height={120}
                  />
                </BoxData>
                <BoxData>
                  <BtSubmit onClick={() => showModal()}>แก้ไขเรื่องร้องเรียน</BtSubmit>
                </BoxData>
              </BoxProfileDetial>
            </WrapProfileResponsive>
          </>
        )}
      </Loader>

      <CustomModal
        transitionName=""
        centered
        open={isModalOpen}
        onOk={() => onSubmit()}
        onCancel={() => handleCancel()}
        closable={false}
        okText="ตกลง"
        cancelText="ยกเลิก"
        zIndex={1200}
      >
        <div style={{ paddingBottom: '10px' }}>
          <ExclamationCircle />
        </div>
        <p className="modal-title">ยืนยันการแก้ไข?</p>
        <p className="modal-message">หากคุณแก้ไขข้อมูลนี้ จะไม่สามารถกู้คืนข้อมูลเดิมได้</p>
      </CustomModal>
      {/* <ModalSuccessEditProfile open={isModalOpen} footer={false} closable={false} onCancel={handleCancel} zIndex={1050}>
        <Success />
        <div className="title">แก้ไขข้อมูลส่วนตัวสำเร็จ</div>
        <div className="description">แก้ไขข้อมูลส่วนตัวสำเร็จ กดตกลงเพื่อกลับไปที่หน้าแรก</div>
        <BtOk onClick={() => handleOk()}>ตกลง</BtOk>
      </ModalSuccessEditProfile> */}
    </>
  )
}

export default EditProfileDrawer
