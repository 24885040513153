import styled from 'styled-components'

export const InputContainer = styled.div`
  /* padding-bottom: 16px; */
  /* padding-bottom: 24px; */
  font-family: 'Prompt';
  min-height: 50px;
  .ant-input-affix-wrapper .ant-input {
    /* background-color: blue !important; */
  }
  @media only screen and (max-width: 768px) {
    min-height: 50px;
  }

  @media only screen and (max-width: 375px) {
    min-height: 50px;
  }

  /* label {
    font-family: 'Prompt';
    padding-bottom: 8px;
    font-weight: 400;
    font-size: 16px;
    color: #150f0b;
  } */

  .ant-input {
    border: 1px solid #ffeee7;
    border-radius: 8px;
    height: 40px;
    max-width: 824px;
    color: #150f0b;
    font-size: 16px;
    font-weight: 400;

    :focus {
      /* box-shadow: 0 0 0 1px rgb(24 144 255 / 20%); */
      box-shadow: none;
      border: 1px solid #fc6521;
      color: #150f0b;
    }

    @media only screen and (max-width: 768px) {
      height: 30px;
      font-size: 12px;
    }

    @media only screen and (max-width: 375px) {
      height: 30px;
      font-size: 12px;
    }
  }

  .ant-input-affix-wrapper {
    /* border: 1px solid #ffeee7;
    border-radius: 8px; */
    padding: 8px;
    border: none;
    height: 32px;
    max-width: 824px;
    color: #150f0b;

    .ant-input {
      border: none;
      /* border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0; */
    }
    /* :focus {
      box-shadow: 0 0 0 1px rgb(24 144 255 / 20%);
      border: 1px solid #fc6521;
    } */

    @media only screen and (max-width: 768px) {
      border: 1px solid #fc6521;
      border: 1px solid #ffeee7;
      border-radius: 8px;

      :focus {
        box-shadow: 0 0 0 1px rgb(24 144 255 / 20%);
        border: 1px solid #fc6521;
      }
    }

    @media only screen and (max-width: 375px) {
      border: 1px solid #fc6521;
      border: 1px solid #ffeee7;
      border-radius: 8px;

      :focus {
        box-shadow: 0 0 0 1px rgb(24 144 255 / 20%);
        border: 1px solid #fc6521;
      }
    }
  }

  .ant-input-affix-wrapper:focus .ant-input {
    //box-shadow: 0 0 0 1px rgb(24 144 255 / 20%);
    /* border: 1px solid #fc6521; */
    border-radius: none;

    border-right: none;
    border-left: none;

    @media only screen and (max-width: 768px) {
      border: 1px solid #fc6521;
    }

    @media only screen and (max-width: 375px) {
      border: 1px solid #fc6521;
    }
  }
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    /* border-color: #fc6521; */
    border-color: none !important;
    //box-shadow: none;
    @media only screen and (max-width: 768px) {
      border-color: #fc6521;
    }

    @media only screen and (max-width: 375px) {
      border-color: #fc6521;
    }
  }
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-focused):hover {
    /* border: 1px solid #ffeee7; */
    border: none !important;

    @media only screen and (max-width: 768px) {
      border: 1px solid #ffeee7;
    }

    @media only screen and (max-width: 375px) {
      border: 1px solid #ffeee7;
    }
  }
  .ant-input-affix-wrapper-focused {
    border-color: #fc6521;
    box-shadow: none;
  }
  .ant-input[disabled],
  .ant-input-affix-wrapper-disabled {
    background-color: #fff3ee;
    border-color: #fff3ee !important;
    color: #ffc4aa;
  }

  .ant-input::placeholder {
    /* color: #ffc4aa !important; */
    color: #ffc4aa !important;
    font-family: 'Prompt';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }

  .ant-input-clear-icon {
    display: flex;
  }
`
