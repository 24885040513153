import SelectANT from 'antd/lib/select'
import styled from 'styled-components'

export const SelectContainer = styled.div`
  padding: 10px 0;
`

export const Select = styled(SelectANT)`
  width: 100%;
  box-shadow: none;
  /* height: 50px; */
  line-height: 21px;
  background-color: #fff;
  font-size: 14px;
  outline: none;
  border-radius: 16px;

  .ant-select-selection-search-input {
    height: -webkit-fill-available !important;
  }

  &::placeholder {
    color: #ccc;
  }

  &:focus::placeholder {
    color: transparent;
    transition: color 0.2s;
  }

  .ant-select-selector {
    border-radius: 16px !important;
    font-weight: 400;
    border: 1px solid #d6d6d6 !important;
    /* padding: 0.75rem 1.125rem !important; */
    padding: 0rem 1.125rem !important;
    min-height: 50px !important;
    color: #181818 !important;
  }

  &.ant-select-disabled {
    .ant-select-selector {
      background-color: #eee !important;
    }
  }

  .ant-select-selection-item{
    line-height: ${(props) => props.mode !== 'multiple' && '50px'}!important;
  } 
  .ant-select-selection-placeholder{
    line-height: ${(props) => props.mode !== 'multiple' && '50px'}!important;
  }
`
