import React, { useState, useEffect, useRef, useContext } from 'react'
import iconarrowright from 'assets/images/svg/icon-arrow-right-org.svg'
import iconpinLocation from 'assets/images/svg/icon-pin-location.svg'
import iconcurrentLocation from 'assets/images/svg/icon-current-location.svg'
import CurrantLocation from './CurrantLocation'
import { ButtonOpenMap, ModalMap, mapStyle, BoxSelect, BoxMaparea, BoxMapSearch, Boxtextlocation } from './Map.style'
import axios from 'axios'
import { getList } from 'core/action/collection'
import {
  GoogleMap,
  Marker,
  Autocomplete,
  DirectionsRenderer,
  InfoWindow,
  InfoWindowF,
  LoadScript,
  Polygon,
} from '@react-google-maps/api'
// import { ReactComponent as IconcurrentPosition } from 'assets/images/svg/Current Location.svg'
// import { ReactComponent as IconLocation } from 'assets/images/svg/Current Location2.svg'
import { ReactComponent as IconZoomIn } from 'assets/images/svg/icon-zoom-in.svg'
import { ReactComponent as IconZoomOut } from 'assets/images/svg/icon-zoom-out.svg'
import { Button, Row, Col, Input, Collapse, Modal, Image, AutoComplete as AutoCompleteAntd } from 'antd'

import { ReactComponent as File } from './image/svg/File.svg'
import { ReactComponent as View } from './image/svg/View.svg'
import { ReactComponent as Update } from './image/svg/Update.svg'
import { ReactComponent as Edit } from './image/svg/Edit.svg'
import { ReactComponent as Export } from './image/svg/Export.svg'
import { ReactComponent as Transfer } from './image/svg/Transfer.svg'
import { ReactComponent as Arrow } from './image/svg/Arrow.svg'
import { ReactComponent as Pin } from './image/svg/Pin 32 px.svg'
import { ReactComponent as AddComplant } from './image/svg/AddComplant.svg'
import { ReactComponent as IconClear } from 'assets/images/svg/icon-close-drawer.svg'

import GoogleMapApi from 'components/GoogleMap'
import InfoDetail from './InfoDetail'
import { conversDate, conversFullDate } from 'core/utils/DateTimeFormat'
import NakhonRatchasima from 'core/utils/NakhonRatchasima_municipality.json'
import NakhonRatchasima_provice from 'core/utils/NakhonRatchasima_provice.json'
import UserContext from 'core/contexts/userContext'

const MapHome = ({
  responsive,
  onClickShowDetail,
  onResponsiveClickShowDetail,
  eserviceData,
  dataMap,
  checkBoxComplaint,
  checkBoxEservice,
}) => {
  const token = localStorage.getItem('access_token')
  const { meData } = useContext(UserContext)
  const [currentPosition, setCurrentPosition] = useState({ lat: 0, lng: 0 })
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [meLocation, setMeLocation] = useState(null)
  const [loading, setLoading] = useState(true)
  const [map, setMap] = useState(null)
  const [center, setCenter] = useState({ lat: 13.726378266560928, lng: 100.51013946533203 })
  // const [centerMap, setCenterMap] = useState({ lat: 0, lng: 0 })

  let latlng = NakhonRatchasima?.features[0]?.geometry?.coordinates[0]
  let latlng_provice = NakhonRatchasima_provice?.features[0]?.geometry?.coordinates[0]

  useEffect(() => {
    let arr = []
    latlng?.map((item, index) => {
      arr.push({ lat: item[1], lng: item[0] })
    })
    let arrProvice = []
    latlng_provice?.map((item, index) => {
      arrProvice.push({ lat: item[1], lng: item[0] })
    })
    const bermudaTriangle = new window.google.maps.Polygon({
      paths: arr,
      strokeColor: '#f36b24',
      strokeOpacity: 1,
      strokeWeight: 2,
      fillColor: 'transparent',
      fillOpacity: 0.35,
    })
    const bermudaTriangleProvice = new window.google.maps.Polygon({
      paths: arrProvice,
      strokeColor: '#f00505',
      strokeOpacity: 1,
      strokeWeight: 2,
      fillColor: 'transparent',
      fillOpacity: 0.35,
    })
    bermudaTriangleProvice.setMap(map)
    bermudaTriangle.setMap(map)
    return () => {
      bermudaTriangle.setMap(null)
      bermudaTriangleProvice.setMap(null)
    }
  }, [latlng, map])

  const handleOpenModal = () => {
    setIsModalVisible(true)
  }

  const handleCloseModal = () => {
    setIsModalVisible(false)
  }

  const handleOpenMap = () => {
    if (currentPosition) {
      handleOpenModal()
    } else {
      console.warn('Current position not available.')
    }
  }

  const zoomInRef = useRef(null)
  const zoomOutRef = useRef(null)
  const [zoom, setZoom] = useState(13)
  const [valueSearch, setValueSearch] = useState('')
  const [error, setError] = useState(null)
  const [nearbyLocations, setNearbyLocations] = useState([])
  const [locationName, setLocationName] = useState('')

  const [idInfor, setIdInfor] = useState('')

  const covertTothai = async position => {
    try {
      const apiKey = 'AIzaSyBOrbrRCU9ZyF1FxHyBkJwuBMGA7vtZa14'
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=${apiKey}&language=th`
      )
      const data = await response.json()

      if (data.status === 'OK' && data.results.length > 0) {
        setLocationName(data.results[0].formatted_address)
      }
    } catch (error) {
      console.error('Error fetching location name:', error)
    }
  }

  const handleGetCurrentPosition = type => {
    if (meLocation?.lat && meLocation?.lng && type === 'getLocation') {
      setMeLocation({})
    } else {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            setMeLocation({
              id: Date.now(),
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            })
            setCenter({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            })
            // setZoom(16)
            covertTothai(position)
          },
          error => {
            switch (error.code) {
              case error.PERMISSION_DENIED:
                console.error('User denied the request for Geolocation.')
                break
              case error.POSITION_UNAVAILABLE:
                console.error('Location information is unavailable.')
                break
              case error.TIMEOUT:
                console.error('The request to get user location timed out.')
                break
              default:
                console.error('An unknown error occurred.')
                break
            }
          }
        )
        getNearbyLocations()
      } else {
        console.error('Geolocation is not supported by this browser.')
      }
    }
  }

  const handleZoomIn = () => {
    if (map) {
      map.setZoom(map.getZoom() + 1)
    }
  }

  const handleZoomOut = () => {
    if (map) {
      map.setZoom(map.getZoom() - 1)
    }
  }

  const onLoad = map => {
    setMap(map)
  }

  const getNearbyLocations = async () => {
    if (currentPosition) {
      try {
        const apiKey = 'AIzaSyBOrbrRCU9ZyF1FxHyBkJwuBMGA7vtZa14'
        const location = '-33.8670522,151.1957362' // Replace with your desired location
        const radius = '1500' // Replace with your desired radius
        const type = 'restaurant' // Replace with your desired place type

        const response = await fetch(
          `https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=${location}&radius=${radius}&type=${type}&key=${apiKey}&language=th`
        )

        const data = await response.json()

        // Extract location data from the API response
        const locations = response.data.results.map(result => ({
          lat: result.geometry.location.lat,
          lng: result.geometry.location.lng,
        }))

        setNearbyLocations(locations)
      } catch (error) {
        console.error('Error fetching nearby locations:', error.message)
      }
    }
  }

  const handleGetLocation = () => {
    setLoading(true)
    setError(null)

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const { latitude, longitude } = position.coords
          setCurrentPosition({ lat: latitude, lng: longitude })
        },
        err => {
          setError(`Error getting user location: ${err.message}`)
        },
        {
          enableHighAccuracy: true,
        }
      )
    } else {
      setError('Geolocation is not supported by this browser.')
    }

    setLoading(false)
  }

  useEffect(() => {
    handleGetLocation()
    handleGetCurrentPosition()
    // token ? getAllDataComplant() : getComplantGuest()
    // token ? getAllDataEservice() : getEserviceGuest()
  }, [])

  const defaultCenter = {
    lat: currentPosition.lat,
    lng: currentPosition.lng,
  }

  const mapStyles = {
    height: '100%',
    width: '100%',
    borderRadius: '16px',
  }

  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <GoogleMapApi center={currentPosition || defaultCenter} zoom={zoom} setMap={setMap}>
            {dataMap && checkBoxComplaint
              ? dataMap?.map((value, index, array) => {
                  return (
                    <InfoDetail
                      pin={
                        <Pin
                          style={{
                            cursor: 'pointer',
                            width: idInfor === value?.key ? '60px' : '40px',
                            height: idInfor === value?.key ? '60px' : '40px',
                          }}
                        />
                      }
                      setCenter={setCenter}
                      key={value?.complaint_no}
                      id={value?.complaint_no}
                      coordinates={{
                        lat: value?.coordinates?.latitude,
                        lng: value?.coordinates?.longitude,
                      }}
                      setIdInfor={setIdInfor}
                      idInfor={idInfor}
                      data={value}
                      conversFullDate={conversFullDate}
                      onClickShowDetail={() => onClickShowDetail(value)}
                      onResponsiveClickShowDetail={() => onResponsiveClickShowDetail(value)}
                      responsive={responsive}
                    />
                  )
                })
              : ''}
            {eserviceData && checkBoxEservice
              ? eserviceData?.map((value, index, array) => {
                  return (
                    <InfoDetail
                      pin={
                        <Pin
                          style={{
                            cursor: 'pointer',
                            width: idInfor === value?.key ? '60px' : '40px',
                            height: idInfor === value?.key ? '60px' : '40px',
                          }}
                        />
                      }
                      setCenter={setCenter}
                      key={value?.complaint_no}
                      id={value?.complaint_no}
                      coordinates={{
                        lat: value?.coordinates?.latitude,
                        lng: value?.coordinates?.longitude,
                      }}
                      setIdInfor={setIdInfor}
                      idInfor={idInfor}
                      data={value}
                      conversFullDate={conversFullDate}
                      onClickShowDetail={() => onClickShowDetail(value)}
                      onResponsiveClickShowDetail={() => onResponsiveClickShowDetail(value)}
                      responsive={responsive}
                    />
                  )
                })
              : ''}
            {meLocation?.lat && <CurrantLocation meLocation={meLocation} />}
          </GoogleMapApi>
        </>
      )}
      {/* </ModalMap> */}
    </div>
  )
}

export default MapHome
