import React, { useEffect, useRef, useState } from 'react'
import {
  TitleHead,
  Title,
  PIndent,
  Container,
  BoxContent,
  WrapConsent,
  WrapNumber,
  PNomal,
  CustomCheckbox,
  BoxCheckBox,
  BoxButton,
  BtSubmit,
} from './consent.style'
import BgLogin2 from 'assets/images/png/bg-login-x2.png'
import BgLoginMobile from 'assets/images/png/bg-login-mobile.png'
import { useLocation, useNavigate } from 'react-router-dom'

const Index = () => {
  const navigate = useNavigate()
  const location = useLocation()
  // const { line_id, line_name, line_picture } = location?.state

  // const wrapConsentRef = useRef(null)
  // const [canCheck, setCanCheck] = useState(false)

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [open, setOpen] = useState(false)
  const [mobile, setMobile] = useState(false)
  const [checkPdpa, setCheckPdpa] = useState(false)

  useEffect(() => {
    if (!location?.state) {
      navigate('/login')
      localStorage.clear()
    }
  }, [])

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (wrapConsentRef.current) {
  //       const { bottom } = wrapConsentRef.current.getBoundingClientRect()
  //       const windowHeight = window.innerHeight || document.documentElement.clientHeight
  //       const distanceToBottom = windowHeight - bottom

  //       if (distanceToBottom >= 0) {
  //         setCanCheck(true)
  //       } else {
  //         setCanCheck(false)
  //       }
  //     }
  //   }

  //   console.log('wrapConsentRef.current', wrapConsentRef.current.getBoundingClientRect())

  //   window.addEventListener('scroll', handleScroll)

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll)
  //   }
  // }, [])

  const isSizeMobile = 768

  useEffect(() => {
    if (windowWidth <= isSizeMobile) {
      setOpen(false)
      setMobile(true)
    } else {
      setOpen(true)
      setMobile(false)
    }
  }, [windowWidth])

  const onCheckBoxChange = e => {
    setCheckPdpa(e.target.checked)
  }

  const nextPage = () => {
    if (location?.state?.registerChannel === 'line') {
      navigate('/register', {
        state: {
          line_id: location?.state?.line_id,
          line_name: location?.state?.line_name,
          line_picture: location?.state?.line_picture,
          accept_pdpa: checkPdpa,
        },
      })
    } else {
      navigate('/register-email', { state: { accept_pdpa: checkPdpa } })
    }
  }

  return (
    <>
      <Container
        url={windowWidth <= isSizeMobile ? BgLoginMobile : BgLogin2}
        mobile={windowWidth <= 414 ? true : false}
      >
        <BoxContent>
          <WrapConsent>
            <TitleHead>หนังสือให้ความยินยอมในการเปิดเผยข้อมูลส่วนบุคคล</TitleHead>
            <PIndent>
              เทศบาลนครนครราชสีมา ให้ความสำคัญต่อการคุ้มครองข้อมูลส่วนบุคคลตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล
              พ.ศ.2562 ดังนั้นเพื่อให้เทศบาลนครนครราชสีมาสามารถ เก็บ รวบรวม
              ใช้หรือเปิดเผยให้สอดคล้องกับพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ.2562 เทศบาลนครนครราชสีมา
              ใคร่ขอความยินยิมจากผู้ใช้งานในฐานะเจ้าของข้อมูลส่วนบุคคลในการเก็บ รวบรวม ใช้หรือ เปิดเผย โดยมีรายละเอียด
              ดังนี้{' '}
            </PIndent>
            <Title>ข้อที่ 1. วัตถุประสงค์การเก็บ รวบรวม ใช้หรือเปิดเผยข้อมูลส่วนบุคคล</Title>
            <PNomal>
              หน่วยงาน มีการเก็บ รวบรวม ใช้และเปิดเผยข้อมูลส่วนบุคคลของผู้ใช้งานเพื่อวัตถุประสงค์ ดังต่อไปนี้
            </PNomal>
            <WrapNumber>
              <ol>
                <li>เพื่อสร้าง และจัดการบัญชีผู้ใช้งาน</li>
                <li>เพื่อปรับปรุงบริการ หรือประสบการณ์การใช้งาน</li>
                <li>เพื่อรวบรวมข้อเสนอแนะ</li>
                <li>เพื่อบริการจัดการข้อมูลภายในหน่วยงาน</li>
                <li>เพื่อปฏิบัติตามกฎหมายหรือกฎ ระเบียบใดๆ ที่มีผลบังคับใช้กับหน่วยงานราชการ</li>
              </ol>
            </WrapNumber>
            <PNomal>
              ทั้งนี้ หากภายหลังหน่วยงานได้มีการเปลี่ยนแปลงวัตถุประสงค์ในการเก็บ รวบรวม
              ใช้และเปิดเผยข้อมูลส่วนบุคคลหน่วยงานจะแจ้งให้ท่านทราบและขอความยินยอมก่อนการเก็บ รวบรวม ใช้หรือเปิดเผย
              ทั้งนี้ หน่วยงานได้กำหนดให้มีการบันทึกการแก้ไขเพิ่มเติมไว้เป็นหลักฐานด้วย
            </PNomal>
            <Title>ข้อที่ 2. ประเภทข้อมูลส่วนบุคคล</Title>
            <PNomal>ข้อมูลส่วนบุคคลซึ่งหน่วยงาน ได้เก็บ รวบรวม ใช้หรือเปิดเผย มีรายละเอียดดังต่อไปนี้</PNomal>
            <WrapNumber>
              <ol>
                <li>ข้อมูลส่วนบุคคล เช่น ชื่อ ชื่อสกุล เป็นต้น</li>
                <li>ข้อมูลในการติดต่อ เช่น เบอร์โทรศัพท์ ที่อยู่ เป็นต้น</li>
                <li>ข้อมูลทางเทคนิค เช่น IP address, ประวัติการใช้งาน (Activity Log) เป็นต้น</li>
                <li>ข้อมูลอื่นๆ เช่น ข้อมูลพิกัดผู้ใช้งาน เป็นต้น</li>
              </ol>
            </WrapNumber>
            <Title>ข้อที่ 3. การเปิดเผยข้อมูลส่วนบุคคล</Title>
            <PNomal>
              เทศบาลนครนครราชสีมาจะไม่เปิดเผยข้อมูลส่วนบุคคลใดโดยปราศจากการอนุญาตจากเจ้าของข้อมูล อย่างไรก็ดี
              เพื่อประโยชน์ในการดำเนินการตามวัตถุประสงค์การเก็บ รวบรวม
              ใช้หรือเปิดเผยตามที่ระบุไว้ข้างต้นให้ผู้ใช้งานรับทราบและยินยอมว่าเทศบาลนครนครราชสีมา
              อาจเปิดเผยข้อมูลส่วนบุคคลของเจ้าของข้อมูลให้กับบุคคลอื่น ดังต่อไปนี้
            </PNomal>
            <PNomal>
              3.1 บุคคลหรือองค์กรที่เทศบาลนครนครราชสีมา
              ได้ว่าจ้างให้ดำเนินงานที่เกี่ยวข้องกับข้อมูลส่วนบุคคลเพื่อประโยชน์ในการบริหารจัดการองค์กรของเทศบาลนครนครราชสีมา
              เช่น ผู้ให้บริการเกี่ยวกับเทคโนโลยีสารสนเทศผู้ให้บริการวิเคราะห์ ข้อมูล สถิติ
              การวิจัยและพัฒนาผลิตภัณฑ์การออกแบบ เป็นต้น
            </PNomal>
            <PNomal>
              ทั้งนี้เทศบาลนครนครราชสีมา
              จะดำเนินการให้บุคคลหรือองค์กรเหล่านั้นมีมาตรการคุ้มครวงข้อมูลส่วนบุคคลตามที่กฎหมายกำหนด
              และอยู่บนพื้นฐานการรู้เท่าที่จำเป็นอย่างเคร่งครัดเพื่อการปฏิบัติตามกฎหมายหรือสัญญา
            </PNomal>
            <Title>ข้อที่ 4. สิทธิของผู้สมัครงานในฐานะเจ้าของข้อมูลส่วนบุคคล</Title>
            <Title>ผู้ใช้งานในฐานะเจ้าของข้อมูลส่วนบุคคลมีสิทธิดังนี้</Title>
            <WrapNumber>
              <ol>
                <li>
                  แจ้งให้หน่วยงานดำเนินการให้ข้อมูลส่วนบุคคลของตนถูกต้องเป็นปัจจุบัน สมบูรณ์
                  และไม่ก่อให้เกิดความเข้าใจผิด
                </li>
                <li>
                  คัดค้านการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลที่เกี่ยวกับตน
                  ที่กฎหมายอนุญาตให้เก็บได้โดยไม่ต้องได้รับความยินยอมจากเจ้าของข้อมูล เมื่อใดก็ได้
                </li>
                <li>
                  ขอให้เทศบาลนครนครราชสีมา ดำเนินการลบหรือทำลาย
                  หรือทำให้ข้อมูลที่ไม่สามารถระบุตัวบุคคลที่เป็นเจ้าของข้อมูลส่วนบุคคลได้ ในกรณีตามที่กฎหมายกำหนด
                  ในกรณีที่มีการร้องขอให้ลบข้อมูลส่วนบุคคลจากระบบนั้น
                  ข้อมูลดังกล่าวอาจจะยังคงได้รับการบันทึกหรือทำสำเนาไว้ที่เซิร์ฟเวอร์ (Server) หรือระบบสำรอง (Backup
                  System) ของเทศบาลนครนครราชสีมา เพื่อป้องกันการเข้าสู่ระบบภายหลังโดยบุคคลที่ไม่ได้รับอนุญาต
                  หรือเพื่อเป็นการสำรองข้อมูลในกรณีที่เกิดความผิดพลาด บกพร่อง หรือเกิดจากความขัดข้องของระบบ
                  หรือในกรณีที่เกิดจากการ กระทำใดๆ ที่มีจุดประสงค์มุ่งร้ายของบุคคลหรือซอฟต์แวร์อื่น
                </li>
                <li>ขอให้เทศบาลนครนครราชสีมา ระงับการใช้ข้อมูลส่วนบุคคลได้ ในกรณีตามที่กฎหมายกำหนด</li>
                <li>ถอนความยินยอมเสียเมื่อใดก็ได้</li>
                <li>
                  ร้องเรียนในกรณีที่เทศบาลนครนครราชสีมา หรือผู้ประมวลข้อมูลส่วนบุคคล
                  รวมทั้งลูกจ้างหรือผู้รับจ้างของเทศบาลนครนครราชสีมา
                  ฝ่าฝืนหรือไม่ปฏิบัติตามกฎหมายคุ้มครองข้อมูลส่วนบุคคล
                </li>
              </ol>
            </WrapNumber>
            <PNomal>
              อย่างไรก็ตามเทศบาลนครนครราชสีมา
              มีสิทธิของผู้ใช้งานในฐานะเจ้าของข้อมูลส่วนบุคคลตามที่ระบุไว้ข้างต้นโดยอาศัยเหตุตามที่พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล
              พ.ศ.2562 หรือที่จะมีการแก้ไขเพิ่มเติมในภายหน้า หรือกฎหมายอื่นที่อนุญาต
            </PNomal>
            <Title>ข้อที่ 5. ผลของการถอนความยินยอม</Title>
            <PNomal>
              ความยินยอมที่ผู้ใช้งานให้ไว้เพื่อเก็บรวบรวมใช้หรือเปิดเผยตามวัตถุประสงค์ดังกล่าวข้างต้นจะยังคงมีผลบังคับใช้จนกว่าผู้สมัครงานจะได้บอกถอนความยินยอมเป็นหนังสือแจ้งต่อเทศบาลนครนครราชสีมา
            </PNomal>
            <PNomal>
              เมื่อเทศบาลนครนครราชสีมาได้รับแจ้งความประสงค์ของผู้ลงทะเบียนตามที่ระบุไว้ในข้อที่ 4. แล้ว
              เทศบาลนครนครราชสีมาจะดำเนินการตรวจสอบและแจ้งผลกระทบที่อาจจะเกิดขึ้นจากการร้องขอของผู้ใช้งาน (ถ้ามี)
              โดยจะดำเนินการให้เสร็จสิ้นตามคำขอภายใน 30 วัน
              นับจากวันที่ได้รับการแจ้งเป็นหนังสือตามแบบฟอร์มที่เทศบาลนครนครราชสีมา กำหนดจากผู้ลงทะเบียน
            </PNomal>
            <PNomal>
              การเพิกถอนความยินยอมของผู้ลงทะเบียน จะไม่ส่งผลกระทบต่อการเก็บรวบรวม ใช้
              หรือเปิดเผยข้อมูลส่วนบุคคลที่ผู้ใช้งานได้ให้ความยินยอมไปแล้วก่อนหน้านั้น
            </PNomal>
            <Title>ข้อที่ 6. ช่องทางการติดต่อ</Title>
            <PNomal>ผู้ใช้งานสามารถติดต่อ เทศบาลนครนครราชสีมา ได้ตามช่องทาง ดังต่อไปนี้</PNomal>
            <PNomal>
              <span style={{ fontWeight: '500' }}>E-mail : </span>saraban@koratcity.go.th
            </PNomal>
            <PNomal>
              <span style={{ fontWeight: '500' }}>Website : </span>www.koratcity.go.th
            </PNomal>
            <PNomal>
              <span style={{ fontWeight: '500' }}>โทรสายด่วน </span>1132
            </PNomal>
            <PNomal>
              <span style={{ fontWeight: '500' }}>ที่อยู่ </span>635 ถ.โพธิ์กลาง อ.เมือง จ.นครราชสีมา 30000
            </PNomal>
          </WrapConsent>
          <BoxCheckBox>
            <CustomCheckbox onChange={onCheckBoxChange} />
            <span style={{ marginLeft: '0.6rem' }}>
              การให้ความยินยอมการเปิดเผยข้อมูลภายใต้พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคลพ.ศ.2562 (Personal Data
              Protection Act: PDPA)
            </span>
          </BoxCheckBox>
          <BoxButton>
            <BtSubmit disabled={!checkPdpa} onClick={() => nextPage()}>
              ยืนยัน
            </BtSubmit>
          </BoxButton>
        </BoxContent>
      </Container>
    </>
  )
}

export default Index
