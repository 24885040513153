import React, { useRef, useEffect } from 'react'
import 'moment/locale/th'
import { BoxTitle, BoxReport, BoxHeaderNoti, WrapDropdownNoti } from './list.style'
import { Dropdown, Button } from 'antd'
import BoxNotification from 'components/BoxNotification'
import Inputs from 'components/Inputs/Input'

import iconArrowUp from 'assets/images/svg/icon-arrow-up.svg'
import iconInvertChevron from 'assets/images/svg/filter-invert-chevron-white.svg'
import iconChevron from 'assets/images/svg/filter-chevron-white.svg'
import iconExport from 'assets/images/svg/icon-export-document.svg'
import IconNoti from 'assets/images/svg/icon-notification.svg'
import IconCloseNoti from 'assets/images/svg/icon-close-notification.svg'
import IconNewNoti from 'assets/images/svg/icon-new-notification.svg'

import { mockDataNoti } from './container'

const Index = () => {
  const [isToggleExport, setIsToggleExport] = React.useState(false)
  const [openNoti, setOpenNoti] = React.useState(false)
  const [newNoti, setNewNoti] = React.useState(false)

  const items = [
    {
      label: 'พิมพ์เอกสาร',
      key: 'พิมพ์เอกสาร',
    },
    {
      label: 'ดาวน์โหลดเอกสาร PDF',
      key: 'ดาวน์โหลดเอกสาร PDF',
    },
  ]

  const handleMenuClick = e => {
    // openExportingNotification()
    // setIsLoading(true)
    // setTimeout(() => {
    //   if (e.key === 'พิมพ์เอกสาร') {
    //     handlePrint()
    //   } else {
    //     handlePrintPDF()
    //   }
    // }, 100)
    // setIsToggleExport(false)
  }

  const menuProps = {
    items,
    onClick: handleMenuClick,
  }

  const handleDropdownVisibleChange = visible => {
    setOpenNoti(visible)
  }

  const BoxNoti = () => {
    return (
      <WrapDropdownNoti>
        <h1>Test</h1>
      </WrapDropdownNoti>
    )
  }

  const menuNoti = (
    <WrapDropdownNoti>
      {mockDataNoti.map((items, index) => {
        return <BoxNotification data={items} key={index} />
      })}
    </WrapDropdownNoti>
  )

  return (
    <>
      <BoxHeaderNoti>
        <span style={{ fontSize: '16px', fontWeight: '500', color: '#FC6521' }}>สถิติเรื่องร้องเรียน</span>
        <Dropdown overlay={menuNoti} trigger={['click']} onVisibleChange={handleDropdownVisibleChange}>
          <img src={newNoti ? IconNewNoti : openNoti ? IconCloseNoti : IconNoti} alt="noti" />
        </Dropdown>
        {/* <img
          src={openNoti ? IconCloseNoti : IconNoti}
          alt="noti"
          style={{ position: 'absolute', right: '-18px', top: '-17px', cursor: 'pointer' }}
        /> */}
      </BoxHeaderNoti>
      <BoxTitle>
        <div style={{ fontWeight: '500', fontSize: '24px' }}>
          แดชบอร์ดแสดงสถานะการทำงานของเจ้าหน้าที่สำนักปลัดเทศบาล
        </div>
        <div id="btnExportActivityLogs">
          <Dropdown
            menu={menuProps}
            trigger="click"
            overlayClassName="dropdown-overlay-export"
            // disabled={isNoDataGroup && isNoDataHourly && isNoDataMainMenu && isNoDataSubMenu}
          >
            <Button
              icon={<img src={iconExport} alt="add" style={{ display: 'inline', paddingBottom: '2px' }} />}
              type="primary"
              open={isToggleExport}
              onBlur={() => {
                setIsToggleExport(false)
              }}
              style={{ width: '160px', height: '40px' }}
              onClick={() => {
                setIsToggleExport(!isToggleExport)
              }}
            >
              <span
                style={{
                  paddingLeft: '8px',
                  paddingTop: '1px',
                  fontSize: '14px',
                  fontWeight: '500',
                  textShadow: 'none',
                }}
              >
                ส่งออกข้อมูล
              </span>
              <img src={isToggleExport ? iconInvertChevron : iconChevron} alt="" style={{ paddingLeft: '10px' }} />
            </Button>
          </Dropdown>
        </div>
      </BoxTitle>
    </>
  )
}

export default Index
