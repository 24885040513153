import styled from 'styled-components'

export const TextAreaContainer = styled.div`
  font-family: 'Prompt';
  padding-bottom: 16px;

  label {
    font-weight: 400;
    font-size: 16px;
    color: ${props => (!props.isDisabled ? '#001f34' : '#9BAAB1')};
    display: flex;
  }

  textarea.ant-input::placeholder {
    color: #ffc4aa !important;
  }

  .icon-bin {
    position: relative;
    justify-content: end;
    display: flex;
    padding-right: 25px;
    margin-top: -40px;
    margin-bottom: 20px;
  }

  .ant-input {
    margin-top: 8px;
    border: 1px solid #e2e5f8;
    border-radius: 16px;
    max-width: 824px;
    padding: 11px;
    color: #001f34;
    caret-color: #3f54d1;

    :focus {
      box-shadow: 0 0 0 1px rgb(24 144 255 / 20%);
      border: 1px solid #3f54d1;
    }
  }
  .ant-input[disabled] {
    background-color: #fbfbfb;
    border-color: #fbfbfb !important;
    color: #9baab1;
  }

  .ant-input::placeholder {
    color: #ffc4aa !important;
  }

  .ant-input.custom-input {
    margin-top: -22px !important;
    padding: 30px 25px 11px 25px;
    box-shadow: none;
  }

  .headet-input {
    position: relative;
    z-index: 1;
    width: 100%;
    padding: 10px 15px 10px 25px;
    height: 44px;
    background: ${props => (!props.isDisabled ? '#e2e5f8' : '#E1EAF0')};
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    border: 1px solid #e2e5f8;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.focus-input {
      border: 1px solid #3f54d1;
      transition: all 0.3s, height 0s;
    }

    svg {
      cursor: ${props => (!props.isDisabled ? 'pointer' : 'not-allowed')} !important;
    }
  }

  .custom-validate {
    position: relative;
    z-index: 1;
    width: 100%;
    background: #fce3e7;
    padding: 5px 16px;
    border-left: 1px solid;
    border-right: 1px solid;
    border-color: #e2e5f8;

    &.focus-input-validate {
      border-color: #3f54d1;
      transition: all 0.3s, height 0s;
    }

    p {
      text-align: left !important;
    }
  }
`
