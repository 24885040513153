import * as Yup from 'yup'

export const defaultValues = {
  first_name: '',
  last_name: '',
  mobile_number: '',
  description_complain:'',
}

export const Schema = Yup.object().shape({
  first_name: Yup.string()
  .max(255,'กรุณากรอกเป็นตัวอักษรไม่เกิน 255 ตัวอักษร')
  .required('')
  .matches(/^[a-zA-Z0-9\u0E00-\u0E7F\s]+$/, 'กรุณากรอกเป็นตัวอักษรไม่เกิน 255 ตัวอักษร'),
  last_name: Yup.string()
  .max(255,'กรุณากรอกเป็นตัวอักษรไม่เกิน 255 ตัวอักษร')
  .required('')
  .matches(/^[a-zA-Z0-9\u0E00-\u0E7F\s]+$/, 'กรุณากรอกเป็นตัวอักษรไม่เกิน 255 ตัวอักษร'),
  mobile_number: Yup.string()
    .required('')
    // .matches(/^[0-9]*$/g, 'กรุณากรอกหมายเลขโทรศัพท์')
    .min(12, ''),
  description_complain: Yup.string()  
  .max(255,'กรุณากรอกเป็นตัวอักษรไม่เกิน 255 ตัวอักษร')
  .when('category_id',{
    is: '1730459515117105153',
    then: Yup.string().required(''),
    otherwise: Yup.string()
  })
  // .required('')
})

export const mockUserData = {
  first_name: '-',
  last_name: '-',
  mobile_number: '-',
  description_complain:'-',

}
// export const mockUserData = {
//   first_name: '',
//   last_name: '',
//   mobile_number: '',
//   groupuser: '',
//   address: '',
//   province: '',
//   district: '',
//   sub_districte: '',
//   postcode: '',
//   category_id:'',
//   name_complain:'',
//   description_complain:'',

// }
