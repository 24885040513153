import moment from 'moment'
import { conversDate } from 'core/utils/DateTimeFormat'
import * as Yup from 'yup'

export const defaultValues = {
  first_name: '',
  last_name: '',
  mobile_number: '',
  group_id: '',
  address: '',
  province_id: '',
  district_id: '',
  sub_district_id: '',
  postcode: '',
  category_id: '',
  name_complain: '',
  description_complain: '',
}

export const Schema = Yup.object().shape({
  first_name: Yup.string()
    .max(255, 'กรุณากรอกเป็นตัวอักษรไม่เกิน 255 ตัวอักษร')
    .required('')
    .matches(/^[a-zA-Z0-9\u0E00-\u0E7F\s]+$/, 'กรุณากรอกเป็นตัวอักษรไม่เกิน 255 ตัวอักษร'),
  last_name: Yup.string()
    .max(255, 'กรุณากรอกเป็นตัวอักษรไม่เกิน 255 ตัวอักษร')
    .required('')
    .matches(/^[a-zA-Z0-9\u0E00-\u0E7F\s]+$/, 'กรุณากรอกเป็นตัวอักษรไม่เกิน 255 ตัวอักษร'),
  mobile_number: Yup.string()
    .required('')
    // .matches(/^[0-9]*$/g, 'กรุณากรอกหมายเลขโทรศัพท์')
    .min(12, ''),
  address: Yup.string()
    .max(35, 'กรุณากรอกไม่เกิน 35 ตัวอักษร')
    .required('')
    .matches(/^[a-zA-Z0-9\u0E00-\u0E7F\s]+$/, 'กรุณากรอกไม่เกิน 35 ตัวอักษร'),
  province_id: Yup.string().required(''),
  district_id: Yup.string().required(''),
  sub_district_id: Yup.string().required(''),
  category_id: Yup.string().required(''),
  name_complain: Yup.string().required(''),
  description_complain: Yup.string()
    .max(255, 'กรุณากรอกเป็นตัวอักษรไม่เกิน 255 ตัวอักษร')
    .when('category_id', {
      is: '1730459515117105153',
      then: Yup.string().required(''),
      otherwise: Yup.string(),
    }),
  // .required('')
})

export const mockUserData = {
  first_name: 'พงศธร',
  last_name: 'เลิศรัตนโสภาพิบูรณ์ชัยศรีรัตน์',
  mobile_number: '094-231-4568',
  groupuser: 'เจ้าหน้าที่ภายนอกหน่วยงาน',
  address: '215/12 ซอย 4  ถนนสุรนารี',
  province: 'นครราชสีมา',
  district: 'เมืองนครราชสีมา',
  sub_districte: 'หนองบัวศาลา',
  postcode: '',
  category_id: '',
  name_complain: '',
  description_complain: '',
}

export const checkColorStatusBg = val => {
  if (val === 'received') {
    return '#EFE9FE'
  }
  if (val === 'in progress') {
    return '#FDF5DD'
  }
  if (val === 'done') {
    return '#DFF6F5'
  }
  if (val === 'outside the area') {
    return '#E2E5F8'
  }
  if (val === 'tracking') {
    return '#FFE6DB'
  }
  if (val === 'over duedate') {
    return '#FFE4E4'
  }
  if (val === 'transfer') {
    return '#EAEAEB'
  }
}

export const checkColorStatusText = val => {
  if (val === 'received') {
    return '#915EFF'
  }
  if (val === 'in progress') {
    return '#FFBB0B'
  }
  if (val === 'done') {
    return '#13AFA6'
  }
  if (val === 'outside the area') {
    return '#3F54D1'
  }
  if (val === 'tracking') {
    return '#F36B24'
  }
  if (val === 'over duedate') {
    return '#F85858'
  }
  if (val === 'transfer') {
    return '#717171'
  }
}

export const checkStatusText = val => {
  if (val === 'received') {
    return 'รับเรื่อง'
  }
  if (val === 'in progress') {
    return 'อยู่ระหว่างดำเนินการ'
  }
  if (val === 'done') {
    return 'ดำเนินการแล้วเสร็จ'
  }
  if (val === 'outside the area') {
    return 'ไม่อยู่ในความรับผิดชอบ'
  }
  if (val === 'tracking') {
    return 'ติดตาม'
  }
  if (val === 'over duedate') {
    return 'เกินระยะเวลากำหนด'
  }
  if (val === 'transfer') {
    return 'โอนย้ายหน่วยงาน'
  }
}

export const checkRating = val => {
  if (val === 1) {
    return 'ควรปรับปรุง'
  }
  if (val === 2) {
    return 'พอใช้'
  }
  if (val === 3) {
    return 'ดี'
  }
  if (val === 4) {
    return 'ดีมาก'
  }
  if (val === 5) {
    return 'ยอดเยี่ยม'
  }
}

export const converttimedate = val => {
  const GMT_OFFSET = 7 // GMT+7
  const THAI_ERA_OFFSET = 543 // Thai Buddhist era offset
  const gmt7Moment = moment.unix(val).utcOffset(GMT_OFFSET * 60)
  const thaiYear = gmt7Moment.year() + THAI_ERA_OFFSET

  const formattedDate = gmt7Moment.locale('th').format('DD MMM ') + thaiYear.toString().slice(-2)
  const formattedTime = gmt7Moment.locale('th').format('HH:mm')

  let data = {
    date: formattedDate,
    time: formattedTime,
  }

  return data
}

export const converttimedateDesktop = val => {
  const GMT_OFFSET = 7 // GMT+7
  const THAI_ERA_OFFSET = 543 // Thai Buddhist era offset
  const gmt7Moment = moment.unix(val).utcOffset(GMT_OFFSET * 60)
  const thaiYear = gmt7Moment.year() + THAI_ERA_OFFSET

  const formattedDate = gmt7Moment.locale('th').format('DD MMM ') + thaiYear.toString()
  const formattedTime = gmt7Moment.locale('th').format('HH:mm')

  let data = {
    date: formattedDate,
    time: formattedTime,
  }

  return data
}

export const checkDueDate = val => {
  let currant = moment().unix() * 1000
  let due = val * 1000
  const date1 = moment(due)
  const date2 = moment(currant)
  const diffInDays = date1.diff(date2, 'days') + 1
  const formattedDate1 = date1.format('DD/MM/YYYY')
  if (diffInDays > 0 && diffInDays <= 3) {
    return 'เหลืออีก ' + diffInDays + ' วัน'
  } else {
    const dateMoment = moment(formattedDate1, 'DD/MM/YYYY')
    const unixTimestamp = dateMoment.valueOf() / 1000 // Convert milliseconds to seconds
    return formatThaiDate(conversDate(unixTimestamp))
  }
}
export const formatThaiDate = dateString => {
  const [day, month, year] = dateString?.split(' ')
  const formattedDay = day?.length === 1 ? `0${day}` : day // Add leading zero if the day is single-digit
  return `${formattedDay} ${month} ${year}`
}
