/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useMemo } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { notification, Divider, Button, Popover, Segmented, Dropdown } from 'antd'
import { useFormik } from 'formik'

import {
  Container,
  NotiStyle,
  BoxTitle,
  CustomDrawer,
  CustomDivider,
  CustomInputDrawer,
  BtSubmit,
  CustomheadTime,
  StyleDropdown,
  CustomPopover,
  LabelText,
  CustomUploadfile,
  ButtonOpenMap,
  LabelTexthead,
  ClearableTextArea,
  BoxContentDetail,
  BoxSeeDetail,
  WrapBoxComplaint,
  WrapMap,
  Menucustom,
} from './HomePage.style'
import { postData, putData, getData, getList } from 'core/action/collection'

import DropdownEditProfile from 'components/Inputs/DropdownEditProfile/DropdownEditProfile'
import InputComponent from 'components/input/Input'
import { mockUserData, defaultValues, Schema } from './container'
import iconAdd from 'assets/images/svg/icon-add-org.svg'
import iconTime from 'assets/images/svg/icon-time-head.svg'
import iconInfo from 'assets/images/svg/icon-info.svg'
import iconpinLocation from 'assets/images/svg/icon-pin-location.svg'
import iconarrowright from 'assets/images/svg/icon-arrow-right-org.svg'
// import iconclose from 'assets/images/svg/icon-close.svg'
import { ReactComponent as IconClear } from 'assets/images/svg/icon-clear-complain.svg'
// import IconAllowClear from 'assets/images/svg/icon-add-org.svg'
import iconHome from 'assets/images/png/icon-homepage.png'
import support from 'assets/images/png/logo-support.png'
import lineqrcode from 'assets/images/png/lineqrcode.png'
import { ReactComponent as IconClose } from 'assets/images/svg/icon-close.svg'
import { ReactComponent as IconSurprised } from 'assets/images/svg/icon-logout-surprised.svg'
import { authentication, masterdata, complaint } from 'core/schemas'
import { httpClient } from 'core/action/httpClient'
import axios from 'axios'
import UploadFile from 'components/Inputs/UploadFile'
import { ReactComponent as IconClearinput } from 'assets/images/svg/icon-clear-orange.svg'
import IconArrowDown from 'assets/images/svg/icon-arrow-down.svg'
import MapButton from './GoogleMap/Map'
import MapHome from './GoogleMap/MapHome'
import { disable } from 'ol/rotationconstraint'
import BoxComplaintMe from 'components/BoxComplaintMe/BoxComplaintMe'
import styled from 'styled-components'

import BoxSeeMoreDetail from 'components/BoxSeeMoreDetail/BoxSeeMoreDetail'

const HomePageGuest = () => {
  const navigate = useNavigate()
  const token = localStorage.getItem('access_token')
  const [api, contextHolder] = notification.useNotification()
  const [openDrawer, setOpenDrawer] = React.useState(false)
  const [openDrawerSuccess, setOpenDrawerSuccess] = React.useState(false)
  const [defaultValueFromList, setDefaultValueFromList] = React.useState({
    first_name: '',
    last_name: '',
    mobile_number: '',
    groupuser: '',
    address: '',
    province: '',
    district: '',
    sub_districte: '',
    postcode: '',
    categories: '',
    name_complain: '',
    description_complain: '',
    // first_name: mockUserData ? mockUserData.first_name : '',
    // last_name: mockUserData ? mockUserData.last_name : '',
    // mobile_number: mockUserData ? mockUserData.mobile_number : '',
    // groupuser: mockUserData ? mockUserData.groupuser : '',
    // address: mockUserData ? mockUserData.address : '',
    // province: mockUserData ? mockUserData.province : '',
    // district: mockUserData ? mockUserData.district : '',
    // sub_districte: mockUserData ? mockUserData.sub_districte : '',
    // postcode: mockUserData ? mockUserData.postcode : '',
    // categories: mockUserData ? mockUserData.categories : '',
    // name_complain: mockUserData ? mockUserData.name_complain : '',
    // description_complain: mockUserData ? mockUserData.description_complain : '',
  })

  const [edit, setEdit] = React.useState(true)
  const [submit, setsubmit] = React.useState({})
  const [formattedDate, setFormattedDate] = useState('')

  const [provinceId, setProvinceId] = useState('')
  const [provinceData, setProvinceData] = useState([])
  const [districtData, setDistrictData] = useState([])
  const [subDistrictData, setSubDistrictData] = useState([])
  const [districtId, setDistrictId] = useState('')
  const [subDistrictId, setSubDistrictId] = useState('')
  const [categories, setCategories] = useState([])
  const [loading, setLoading] = useState(true)
  const [mapOpen, setMapOpen] = useState()
  const [cover, setCover] = React.useState([])
  const [locationme, setlocationme] = React.useState({})
  const [arrow, setArrow] = useState('Show')
  const [showDetail, setShowDetail] = useState(true)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [complainById, setcomplainById] = useState({})
  const location = useLocation()

  const formik = useFormik({
    initialValues: defaultValueFromList,
    validationSchema: Schema,
    // onSubmit: handlerSubmit,
    enableReinitialize: true,
    onSubmit: values => {
      // console.log(values)
    },
  })

  const checkimg = cover.length > 0 ? true : false
  const checkmap = Object.keys(locationme).length === 0 || locationme == {} ? false : true
  const isValidForm = formik.isValid && formik.dirty && checkimg && checkmap
  const [checkchange, setcheckchange] = React.useState(false)

  useEffect(() => {
    let checkguest = location?.state?.guest
    let service = location?.state?.service
    if (service == 'complain') {
      if (checkguest == 'true') {
        getguestcomplainid()
      } else {
        getcomplainid()
      }
    } else {
      if (checkguest == 'true') {
        getGuesteserviceid()
      } else {
        geteserviceid()
      }
    }
  }, [])

  const getcomplainid = async () => {
    let datalocation = location?.state?.complaint_id
    axios
      .get(`${process.env.REACT_APP_BASEURL}complaint/api/v1/complaint/` + String(datalocation), {
        // await axios.get('https://gateway.koratcity.go.th/sit/complaint/api/v1/complaint/1752276938572951552',{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => {
        if (res?.data?.code === 200) {
          setcomplainById(res?.data?.data)
        }
      })
      .catch(err => {
        console.log('err', err)
      })
  }

  const getguestcomplainid = async () => {
    let datalocation = location?.state?.complaint_id
    axios
      .get(`${process.env.REACT_APP_BASEURL}complaint/api/v1/guest/complaint/` + String(datalocation))
      .then(res => {
        if (res?.data?.code === 200) {
          setcomplainById(res?.data?.data)
        }
      })
      .catch(err => {
        console.log('err', err)
      })
  }

  const geteserviceid = async () => {
    let datalocation = location?.state?.complaint_id
    axios
      .get(`${process.env.REACT_APP_BASEURL}complaint/api/v1/e-service/complaint/` + String(datalocation), {
        // await axios.get('https://gateway.koratcity.go.th/sit/complaint/api/v1/complaint/1752276938572951552',{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => {
        if (res?.data?.code === 200) {
          setcomplainById(res?.data?.data)
        }
      })
      .catch(err => {
        console.log('err', err)
      })
  }
  const getGuesteserviceid = async () => {
    let datalocation = location?.state?.complaint_id
    let url = `${process.env.REACT_APP_BASEURL}complaint/api/v1/guest/eservice/` + String(datalocation)
    axios
      .get(url)
      .then(res => {
        if (res?.data?.code === 200) {
          setcomplainById(res?.data?.data)
        }
      })
      .catch(err => {
        console.log('err', err)
      })
  }

  return (
    <>
      <div style={{ display: 'flex' }}>
        <div>
          <BoxContentDetail showDetail={showDetail}>
            {/* <WrapMap>
                  <MapHome onClickShowDetail={onClickShowDetail} />
                </WrapMap> */}
            {showDetail && <BoxSeeMoreDetail responsive={true} data={complainById} />}
          </BoxContentDetail>
        </div>
      </div>
    </>
  )
}

export default HomePageGuest
