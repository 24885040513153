export const checkColorStatusBg = val => {
  if (val === 'received') {
    return '#EFE9FE'
  }
  if (val === 'in progress') {
    return '#FDF5DD'
  }
  if (val === 'done') {
    return '#DFF6F5'
  }
  if (val === 'outside the area') {
    return '#E2E5F8'
  }
  if (val === 'tracking') {
    return '#FFE6DB'
  }
  if (val === 'over duedate') {
    return '#FFE4E4'
  }
  if (val === 'transfer') {
    return '#EAEAEB'
  }
}

export const checkColorStatusText = val => {
  if (val === 'received') {
    return '#915EFF'
  }
  if (val === 'in progress') {
    return '#FFBB0B'
  }
  if (val === 'done') {
    return '#13AFA6'
  }
  if (val === 'outside the area') {
    return '#3F54D1'
  }
  if (val === 'tracking') {
    return '#F36B24'
  }
  if (val === 'over duedate') {
    return '#F85858'
  }
  if (val === 'transfer') {
    return '#717171'
  }
}

export const checkStatusText = val => {
  if (val === 'received') {
    return 'รับเรื่อง'
  }
  if (val === 'in progress') {
    return 'อยู่ระหว่างดำเนินการ'
  }
  if (val === 'done') {
    return 'ดำเนินการแล้วเสร็จ'
  }
  if (val === 'outside the area') {
    return 'ไม่อยู่ในความรับผิดชอบ'
  }
  if (val === 'tracking') {
    return 'ติดตาม'
  }
  if (val === 'over duedate') {
    return 'เกินระยะเวลากำหนด'
  }
  if (val === 'transfer') {
    return 'โอนย้ายหน่วยงาน'
  }
}

export const checkRating = val => {
  if (val === 1) {
    return 'ควรปรับปรุง'
  }
  if (val === 2) {
    return 'พอใช้'
  }
  if (val === 3) {
    return 'ดี'
  }
  if (val === 4) {
    return 'ดีมาก'
  }
  if (val === 5) {
    return 'ยอดเยี่ยม'
  }
}
