import React, { useEffect, useState } from 'react'
import {
  CustomDrawerSeemoreDeatil,
  BoxDetail,
  BoxLocation,
  GridCategory,
  BoxGridDetail,
  GridImage,
  ModalPreviewImage,
  ModelContent,
} from './HomePage.style'
import { ReactComponent as IconClear } from 'assets/images/svg/icon-clear-complain.svg'
import { ReactComponent as VectorDrawer } from 'assets/images/svg/VectorDrawer.svg'

import { ReactComponent as ButtonCloseSeeDeail } from 'assets/images/svg/button-close-see-detail.svg'
import { ReactComponent as IconLocationComMe } from 'assets/images/svg/icon-location-card-complaint-me.svg'
import { ReactComponent as IconClockMe } from 'assets/images/svg/icon-clock-card-complaint-me.svg'
import { ReactComponent as IconCategoryMe } from 'assets/images/svg/icon-category-card-complaint-me.svg'
import { ReactComponent as IconNumberMe } from 'assets/images/svg/icon-number-card-complaint-me.svg'
import { ReactComponent as IconClosePreview } from 'assets/images/svg/icon-close-preview-img.svg'
import { ReactComponent as DotGreen } from 'assets/images/svg/dot-timeline-green.svg'
import { ReactComponent as DotOrange } from 'assets/images/svg/dot-timeline-orange.svg'
import { ReactComponent as DotPurple } from 'assets/images/svg/dot-timeline-purple.svg'
import { ReactComponent as DotYellow } from 'assets/images/svg/dot-timeline-yellow.svg'
import { ReactComponent as Star1 } from 'assets/images/svg/star-1.svg'
import { ReactComponent as Star2 } from 'assets/images/svg/star-2.svg'
import { ReactComponent as Star3 } from 'assets/images/svg/star-3.svg'
import { ReactComponent as Star4 } from 'assets/images/svg/star-4.svg'
import { ReactComponent as Star5 } from 'assets/images/svg/star-5.svg'

import {
  checkStatusText,
  checkColorStatusBg,
  checkColorStatusText,
  converttimedate,
  converttimedateDesktop,
} from './container'

const DrawerComplaintMe = ({ onCloseDerwerDeaitlComplaint, openDrawerDetailComplaint, data, isResponsive }) => {
  const Dotcolor = val => {
    if (val === 'received') {
      return <DotPurple />
    }
    if (val === 'in progress') {
      return <DotYellow />
    }
    if (val === 'done') {
      return <DotGreen />
    }
    if (val === 'outside the area') {
      return <DotOrange />
    }
    if (val === 'tracking') {
      return <DotOrange />
    }
    if (val === 'over duedate') {
      return <DotOrange />
    }
    if (val === 'transfer') {
      return <DotOrange />
    }
  }

  let namestatus
  let colorbgnamestatus
  let colortextnamestatus
  let createcomplaint
  let laststatustime
  let laststatus
  let timestatus
  let datestatus
  let dot

  if (data) {
    let formatdate = converttimedate(data?.created_at)
    createcomplaint = formatdate?.time + ' น, ' + formatdate?.date

    if (data?.status_data) {
      laststatus = data?.status_data[data?.status_data.length - 1]
      namestatus = checkStatusText(laststatus?.name)
      colorbgnamestatus = checkColorStatusBg(laststatus?.name)
      colortextnamestatus = checkColorStatusText(laststatus?.name)
      laststatustime = converttimedate(laststatus?.created_at)
      timestatus = laststatustime.time + ' น'
      datestatus = laststatustime.date
      dot = Dotcolor(laststatus?.name)
    }
  }

  const [previewImg, setPreviewImg] = useState(null)
  const [previewVdo, setPreviewVdo] = useState(null)
  const [OpenModalVdo, setOpenModalVdo] = useState(false)

  useEffect(() => {
    !isResponsive && onCloseDerwerDeaitlComplaint()
  }, [isResponsive])

  return (
    <>
      <CustomDrawerSeemoreDeatil
        placement="bottom"
        onClose={() => onCloseDerwerDeaitlComplaint()}
        closable={false}
        open={openDrawerDetailComplaint}
        closeIcon={<IconClear />}
        // zIndex={2000}
        key={openDrawerDetailComplaint}
      >
        <div style={{ textAlign: 'center' }} onClick={onCloseDerwerDeaitlComplaint}>
          <VectorDrawer />
        </div>

        <div className="contianer">
          <div className="wrap-box-title">
            <div className="box-title">
              <div className="title">
                <img
                  src={data?.type_of_complaint ? data?.type_of_complaint?.icon_url : data?.category?.icon_url}
                  alt="icon"
                  style={{ width: '24px', height: '24px', marginRight: '8px' }}
                />
                <div>
                  {data?.title_complaint ? data?.title_complaint : data?.complaint_name ? data?.complaint_name : '-'}
                </div>
              </div>
              <div className="box-status">
                <div className="status" style={{ backgroundColor: colorbgnamestatus, color: colortextnamestatus }}>
                  {namestatus}
                </div>
              </div>
            </div>
            <div className="description">{data?.description ? data?.description : '-'}</div>
            <div className="divi"></div>
            <BoxDetail>
              <BoxLocation>
                <div style={{ width: '5%' }}>
                  <IconLocationComMe />
                </div>
                <div style={{ width: '100%' }}>
                  <p className="title">สถานที่:</p>
                  <p className="description">{data?.address ? data?.address : '-'}</p>
                </div>
              </BoxLocation>
              <GridCategory>
                <div>
                  <BoxGridDetail>
                    <div style={{ width: '10%' }}>
                      <IconClockMe />
                    </div>
                    <div style={{ width: '100%' }}>
                      <p className="title">วันและเวลาแจ้งเรื่อง:</p>
                      <p className="description">{createcomplaint}</p>
                    </div>
                  </BoxGridDetail>
                </div>
                <div>
                  <BoxGridDetail>
                    <div style={{ width: '10%' }}>
                      <IconNumberMe />
                    </div>
                    <div style={{ width: '100%' }}>
                      <p className="title">เลขที่:</p>
                      <p className="description">{data?.complaint_no ? data?.complaint_no : '-'}</p>
                    </div>
                  </BoxGridDetail>
                </div>
                <div>
                  <BoxGridDetail>
                    <div style={{ width: '10%' }}>
                      <IconCategoryMe />
                    </div>
                    <div style={{ width: '100%' }}>
                      <p className="title">ประเภท:</p>
                      <p className="description">
                        {data?.category?.name
                          ? data?.category?.name
                          : data?.type_of_complaint?.name
                          ? data?.type_of_complaint?.name
                          : '-'}
                      </p>
                    </div>
                  </BoxGridDetail>
                </div>
                <div>
                  {data?.sub_category_data?.name && (
                    <BoxGridDetail>
                      <div style={{ width: '10%' }}>
                        <IconCategoryMe />
                      </div>
                      <div style={{ width: '100%' }}>
                        <p className="title">ประเภทย่อย:</p>
                        <p className="description">
                          {data?.sub_category_data?.name ? data?.sub_category_data?.name : '-'}
                        </p>
                      </div>
                    </BoxGridDetail>
                  )}
                </div>
              </GridCategory>
              <GridImage>
                {data?.media_url?.map(item => {
                  if (item.endsWith('.mp4')) {
                    return (
                      <div style={{ height: '100%', width: '100%' }}>
                        <div
                          className="show-image-div click"
                          onClick={() => {
                            setPreviewVdo(item)
                            setOpenModalVdo(true)
                          }}
                        >
                          <video style={{ width: '98px', height: 'auto', maxHeight: '56px', borderRadius: '8px' }}>
                            <source src={item} type="video/mp4" />
                          </video>
                        </div>
                      </div>
                    )
                  } else {
                    return (
                      <div style={{ height: '100%', width: '100%' }}>
                        <img
                          src={item}
                          alt="img"
                          style={{ borderRadius: '8px' }}
                          onClick={() => {
                            setPreviewImg(item)
                          }}
                        />
                      </div>
                    )
                  }
                })}
              </GridImage>
            </BoxDetail>
          </div>
        </div>

        <ModalPreviewImage
          title=""
          centered
          transitionName=""
          closable={false}
          open={previewImg}
          footer={false}
          onOk={() => {
            setPreviewImg(null)
          }}
          onCancel={() => {
            setPreviewImg(null)
          }}
          zIndex={1040}
        >
          <ModelContent>
            <IconClosePreview
              className="close-ui"
              onClick={() => {
                setPreviewImg(null)
              }}
            />
            {previewImg ? (
              <div className="vdo-content">
                <img src={previewImg} style={{ width: '100%', height: 'auto' }} alt="img" />
              </div>
            ) : null}
          </ModelContent>
        </ModalPreviewImage>
      </CustomDrawerSeemoreDeatil>
    </>
  )
}

export default DrawerComplaintMe
