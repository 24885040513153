import * as React from 'react'
import { Marker, InfoWindowF } from '@react-google-maps/api'
import styled from 'styled-components'
import './CurrantLocation.css'
import PulseMarker from 'components/PulseMarker'
import iconcurrentLocation from 'assets/images/svg/icon-current-location.svg'


const CurrantLocation = ({ meLocation, onClickMap , changeLocation }) => {
  return (
    <div>
    {/* <div onClick={() => onClickMap(meLocation, 'clickMelocation')}> */}
      <Marker position={{ lat: meLocation?.lat, lng: meLocation?.lng }} visible={false}>
        <InfoWindowF
          position={{ lat: meLocation?.lat, lng: meLocation?.lng }}
          options={{
            pixelOffset: new window.google.maps.Size(0, 70),
            maxWidth: 400,
            zIndex: 1,
          }}
        >
          <PulseMarker />
        </InfoWindowF>
      </Marker>
    </div>
  )
}

export default CurrantLocation
