import React, { useState, useEffect } from 'react'

export default function useGetAddress(coordinates) {
  const [address, setAddress] = useState('')
  const apiKey = 'AIzaSyA3KClJ3vnKpsmZ68blrysrBJYXgQmeFg0'
  const fetchAddress = async () => {
    const { lat, lng } = coordinates
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}&language=th`
    try {
      const response = await fetch(apiUrl)
      const data = await response.json()

      if (data.status === 'OK') {
        const address = data.results
        setAddress(address)
      } else {
        console.error('Error fetching data:', data.status)
      }
    } catch (error) {
      console.error('Error calling API:', error)
    }
  }
  useEffect(() => {
    fetchAddress()
  }, [coordinates])
  return address
}
