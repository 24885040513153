import * as React from 'react'
import { Input } from 'antd'
import styled from 'styled-components'

const Inputs = props => {
  React.useEffect(() => {})

  return (
    <Content>
      <Input
        allowClear={true}
        key={props?.defaultValue ? props?.defaultValue : ''}
        placeholder={props?.placeholder}
        onPressEnter={e => props?.onPressEnter(e)}
        onChange={e => {
          if (e.target.value === '') {
            props?.onChange(e)
          }
        }}
        defaultValue={props?.defaultValue ? props?.defaultValue : ''}
        prefix={props.prefix ? props.prefix : ''}
      />
    </Content>
  )
}

export default Inputs
const Content = styled.div`
  .ant-input-affix-wrapper .ant-input:placeholder-shown {
    padding-left: 20px !important;
  }
  .ant-input-affix-wrapper-focused {
    border: 1px solid #fe814a !important;
    box-shadow: none;
  }
  .ant-input-affix-wrapper {
    border: 1px solid #fee0d3 !important;
    display: flex;
    align-items: center;
    padding-left: 1rem;
    border-radius: 8px;
    width: 100%;
    height: 48px;
    :hover {
      border: 1px solid #fe814a !important;
    }
  }
`
