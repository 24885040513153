import React, { createContext, useState, useContext } from 'react'

const SearchContext = createContext()

export const SearchProvider = ({ children }) => {
  const [sharedValue, setSharedValue] = useState('')

  const setSharedValueHandler = newValue => {
    setSharedValue(newValue)
  }

  return (
    <SearchContext.Provider value={{ sharedValue, setSharedValue: setSharedValueHandler }}>
      {children}
    </SearchContext.Provider>
  )
}

export const useSearchContext = () => {
  return useContext(SearchContext)
}
