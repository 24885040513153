import React, { useState, useEffect } from 'react'
import { TreeSelect, Tooltip } from 'antd'
import { DownOutlined, UpOutlined } from '@ant-design/icons'

import { StyleTreeSelect } from './treeSelect.style'

const { SHOW_PARENT, TreeNode } = TreeSelect

const Treeselect = props => {
  const { treeDatas, onChange, treeData, width, disabled, showSearch } = props
  const [visible, setVisible] = useState(false)
  const [dataText, setDataText] = useState()
  const data = []
  const styleIcon = { paddingRight: '10px' }

  useEffect(() => {
    setDataText(data.join(', '))
  }, [data])

  const tagRender = e => {
    data.push(e?.label?.props?.title === '' ? 'ทั้งหมด' : e?.label?.props?.title)

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span>{titleRender(dataText?.length > 0 ? dataText : 'ทั้งหมด')}</span>
      </div>
    )
  }

  const titleRender = title => {
    return (
      <Tooltip overlayClassName="tree-select-tooltip" placement="right" title={title !== 'ทั้งหมด' ? title : ''}>
        {title}
      </Tooltip>
    )
  }

  const tProps = {
    value: treeDatas,
    onChange: onChange && onChange,
    treeCheckable: true,
    showArrow: true,
    allowClear: true,
    suffixIcon: visible ? <UpOutlined style={styleIcon} /> : <DownOutlined style={styleIcon} />,
    onDropdownVisibleChange: e => setVisible(e),
    tagRender: tagRender,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'ทั้งหมด',
    style: {
      width: width ? width : '100%',
    },
    showSearch: showSearch ? showSearch : false,
    dropdownClassName: 'tree-select',
    disabled: disabled ? true : false,
  }

  return (
    <StyleTreeSelect>
      <TreeSelect {...tProps} getPopupContainer={triggerNode => triggerNode.parentNode}>
        {treeData.map(item => {
          return (
            <TreeNode key={item?.value} value={item?.value} title={titleRender(item?.title)}>
              {item?.children &&
                item?.children.map(i => {
                  return (
                    <TreeNode key={i?.value} value={i?.value} title={titleRender(i?.title)}>
                      {i?.children &&
                        i?.children.map(x => {
                          return <TreeNode key={x?.title} value={x?.title} title={titleRender(x?.title)} />
                        })}
                    </TreeNode>
                  )
                })}
            </TreeNode>
          )
        })}
      </TreeSelect>
    </StyleTreeSelect>
  )
}

export default Treeselect
