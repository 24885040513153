import styled from 'styled-components'
import { Button, Drawer, Modal } from 'antd'

export const BoxSeeDetail = styled.div`
  height: 100%;
  background-color: #ffffff;
  font-family: 'Prompt';
  padding: 1rem;
  border-radius: 16px;

  .wrap-box-title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 1.35px solid #f4f4f4;
    padding-bottom: 1rem;
  }
  .box-title {
    width: 100%;
    display: flex;
    flex-direction: column;
    .title {
      display: flex;
      font-size: 16px;
      font-weight: 500;
      color: #150f0b;
      margin-bottom: 8px;

      img {
        margin-right: 8px;
      }
    }
    .description {
      font-size: 12px;
      font-weight: 300;
    }
  }

  .box-status {
    width: 100%; /* Use full width on smaller screens */
    /* max-width: 20%; Limit width on larger screens */
    display: flex;
    position: relative;
    justify-content: flex-end;

    .status {
      height: 24px;
      border-radius: 8px;
      /* background-color: #efe9fe; */
      /* color: #915eff; */
      font-weight: 400;
      font-size: 12px;
      padding: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 8px;
    }
  }

  @media only screen and (max-width: 768px) {
    .box-status {
      max-width: 100%; /* Use full width on smaller screens */
    }
  }
`

export const WrapBoxDescription = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    left: 0%;
  }
`

export const BoxDetail = styled.div``

export const GridDetail = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 16px;
`

export const BoxLocation = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 1rem;

  .title {
    font-weight: 400;
    font-size: 10px;
    color: #aba9a8;
    margin: 0px;
  }

  .description {
    font-size: 12px;
    font-weight: 300;
    color: #150f0b;
    margin: 0px;
  }
`

export const GridCategory = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 16px;
  margin-bottom: 1rem;
`

export const BoxGridDetail = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;

  .title {
    font-weight: 400;
    font-size: 10px;
    color: #aba9a8;
    margin: 0px;
  }

  .description {
    font-size: 12px;
    font-weight: 300;
    color: #150f0b;
    margin: 0px;
  }
`

export const GridImage = styled.div`
  /* display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 8px;
  grid-row-gap: 0px; */
  display: flex;

  img {
    width: 98px;
    height: auto;
    max-height: 56px;
    object-fit: cover;
  }
`

export const ModalPreviewImage = styled(Modal)`
  font-family: 'IBM Plex Sans Thai', sans-serif;
  z-index: 1201;

  .close-ui {
    position: fixed;
    top: 35px;
    right: 50px;
    padding: 0px;
    background: #fbfbfb;
    width: 35px;
    height: 35px;
    border-radius: 35px;
    cursor: pointer;
    line-height: 35px;
    font-size: 14px;
    font-weight: 800;
    color: #3f54d1;
    z-index: 1200;
    transition: none;
    img {
      width: 14px;
      height: 14px;
    }
  }
  .ant-modal-content {
    border-radius: 16px;
    width: auto;
    height: auto;
    margin: 0 auto;
    background-color: transparent;
    box-shadow: none;
  }
  .ant-modal-header {
    border-radius: 16px;
    color: white;
    border: 0;
  }
  .ant-modal-body {
    text-align: center;
    padding-bottom: 0px;
    padding: 0px;
  }
  .ant-modal-footer {
    border: 0;
    text-align: center;
    padding-bottom: 0px;
    padding: 0px;
    .ant-btn-default {
      border-radius: 12px;
      border-color: #00ada4 !important;
      color: #00ada4 !important;
      font-size: 16px;
      width: 87px;
      height: 40px;
      font-weight: 500;
    }
    .ant-btn-primary {
      border-radius: 12px;
      background-color: #00ada4 !important;
      border-color: #00ada4;
      font-size: 16px;
      width: 87px;
      height: 40px;
      font-weight: 500;
      margin-top: 20px;
    }
  }
`

export const ModelContent = styled.div`
  .vdo-content {
    position: relative;
    padding: 0;
    /* video {
      max-height: 70vh;
      max-width: 80vw;
    }
    img {
      height: 70vh;
      max-width: 80vw;
    } */
  }
  .model-ui {
    font-style: normal;
    .header-ui {
      font-style: normal;
      font-weight: 600;
      font-size: 21px;
      line-height: 47px;
      color: #19263f;
      text-align: left;
    }
    .model-crop {
      position: relative;
      width: 100%;
      height: 100%;
      min-height: 300px;
    }
    .controls {
      background: #f8e2e2;
      border-radius: 35px;
      width: 200px;
      padding: 0.2em 12px;
      margin: auto;
      margin-top: 10px;
      display: flex;
      .slider {
        width: 160px;
        padding-left: 4px;
        padding-right: 4px;
      }
      .button-zoom {
        line-height: 34px;
        font-style: normal;
        font-weight: 600;
        font-size: 25px;
        color: #1f3983;
        width: 20px;
        height: 20px;
        user-select: none;
      }
    }
  }
`

export const BoxTimeline = styled.div`
  font-family: 'Prompt' !important;
  overflow: hidden;
  /* overflow-y: scroll; */

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: #eaeaeb;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #f36b24;
    border-radius: 8px;
  }

  .ant-timeline {
    padding-top: 20px !important;
    padding-right: 20px !important;
  }

  .ant-timeline-item-label {
    font-weight: 300;
    font-size: 12px;
    /* padding-right: 15px; */
  }

  /* .ant-timeline-item-content {

  } */

  .timeline-title {
    font-size: 12px;
    font-weight: 400;
    color: #150f0b;
    margin-bottom: 5px;
  }

  .timeline-desription {
    font-weight: 400;
    font-size: 10px;
    color: #aba9a8;
  }
  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;

    .ant-timeline.ant-timeline-label .ant-timeline-item-label {
      width: 80px;
    }
    .ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
    .ant-timeline.ant-timeline-label .ant-timeline-item-head-custom,
    .ant-timeline.ant-timeline-right .ant-timeline-item-head-custom {
      left: 35%;
    }
    .ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content,
    .ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content,
    .ant-timeline.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content {
      left: 35%;
    }
  }
`

export const BtEdit = styled(Button)`
  font-size: 12px;
  width: 100%;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(200, 211, 218, 0.3);
  border-radius: 8px;
  border: 1px solid var(--web-backend-border-primary, #f36b24);
  border-color: #f36b24;
  color: black;
  :focus,
  :hover {
    color: black;
    /* border-color: transparent; */
    border-color: #f36b24;
  }
  span {
    color: #f36b24;
  }
`

export const BtEnding = styled(Button)`
  font-size: 14px;
  font-weight: 500;
  width: 140px;
  height: 40px;
  background: ${props => (props.type === 'done' ? '#F36B24' : '#ffffff')} !important;
  box-shadow: 0px 4px 16px rgba(200, 211, 218, 0.3);
  border-radius: 8px;
  border: 1px solid #f36b24;
  border-color: #f36b24;
  color: ${props => (props.type === 'done' ? '#ffffff' : '#f36b24')} !important;
  :focus,
  :hover {
    border-color: #f36b24;
  }
`

export const BtConfirm = styled(Button)`
  font-size: 14px;
  font-weight: 500;
  width: 120px;
  height: 40px;
  background: ${props => (props.type === 'confirm' ? '#F36B24' : '#ffffff')};
  box-shadow: 0px 4px 16px rgba(200, 211, 218, 0.3);
  border-radius: 8px;
  border: 1px solid #f36b24;
  border-color: #f36b24;
  color: ${props => (props.type === 'confirm' ? '#ffffff' : '#f36b24')};
  :focus,
  :hover {
    border-color: #f36b24;
    background: ${props => (props.type === 'confirm' ? '#F36B24' : '#ffffff')};
    color: ${props => (props.type === 'confirm' ? '#ffffff' : '#f36b24')};
  }

  &.ant-btn[disabled] {
    background: #fee0d3 !important;
    border-color: #fee0d3 !important;
    border-color: 1px solid #fee0d3 !important;
    color: #ffffff !important;
  }
`

export const BtConfirmRate = styled(Button)`
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  height: 56px;
  background: ${props => (props.type === 'confirm' ? '#F36B24' : '#ffffff')};
  box-shadow: 0px 4px 16px rgba(200, 211, 218, 0.3);
  border-radius: 8px;
  border: 1px solid #f36b24;
  border-color: #f36b24;
  color: ${props => (props.type === 'confirm' ? '#ffffff' : '#f36b24')};
  :focus,
  :hover {
    border-color: #f36b24;
    background: ${props => (props.type === 'confirm' ? '#F36B24' : '#ffffff')};
    color: ${props => (props.type === 'confirm' ? '#ffffff' : '#f36b24')};
  }

  &.ant-btn[disabled] {
    background: #fee0d3 !important;
    border-color: #fee0d3 !important;
    border-color: 1px solid #fee0d3 !important;
    color: #ffffff !important;
  }
`

export const WrapBoxBtEnding = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const CustomModal = styled(Modal)`
  font-family: 'Prompt' !important;

  .ant-modal-content {
    border-radius: 24px;
    width: 560px;
    height: 342px;
  }

  .ant-modal-body {
  }

  .box-title {
    width: 100%;
    display: block;
    text-align: center;
  }

  .text-title {
    font-size: 20px;
    font-weight: 500;
    color: #150f0b;
  }

  .text-title-description {
    color: #c4c4c4;
    font-size: 12px;
    font-weight: 300;
  }

  .ant-input {
    border-radius: 8px;
    border-color: #ffeee7;
    height: 40px;
    color: #f36b24 !important;
  }
  .ant-input::placeholder {
    color: #ffc4aa !important;
  }
  .ant-input:focus {
    border-color: #f36b24;
    box-shadow: none;
  }
`

export const CustomDrawer = styled(Drawer)`
  font-family: 'Prompt' !important;

  .ant-drawer-wrapper-body {
    /* padding: 0 40px; */
  }

  .ant-drawer-content {
    box-shadow: 0px 4px 30px rgba(46, 60, 79, 0.04);
  }

  .ant-drawer-mask {
    background: rgba(0, 31, 52, 0.5);
    backdrop-filter: blur(8px);
  }

  .ant-drawer-header {
    border: none;
  }

  .ant-drawer-body {
    padding: 0 !important;
    overflow: scroll;
    overflow-x: hidden;
    ::-webkit-scrollbar {
      width: 5px;
    }
    ::-webkit-scrollbar-track {
      background-color: #eaeaeb !important; /* Set the scrollbar track color */
      border-radius: 8px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #f36b24 !important; /* Set the scrollbar thumb color */
      color: red !important;
      border-radius: 8px;
    }
  }

  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    h1 {
      color: #150f0b;
      text-align: center;
      font-family: 'Prompt';
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      margin-top: 8px;
    }
  }

  .info-title {
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #150f0b;
    margin-bottom: 5px;
    /* padding-left: 40px; */
  }

  .edit-profile {
    font-family: 'Prompt';
    font-weight: 500;
    font-size: 18px;
    color: #fc6521;
  }

  .top {
    margin-top: 70px;
  }

  .red-star {
    color: #f85858;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
  }

  .row-data {
    width: 100%;
    display: flex;
    padding-bottom: 24px;
    padding-top: 24px;

    .col-1 {
      /* .ant-input{
        &:hover,
        &:active,
        &:focus {
            border: 1px solid #fc6521 !important;
            color: #fc6521 !important;
          }      }
   */

      .ant-input:not(.ant-input-disabled):hover {
        border-color: #fc6521;
      }

      /* .ant-input:not(.ant-inpu-focused):hover {
          border: 1px solid #FEE0D3;
       } */
      width: 100%;
      label {
        font-family: 'Prompt';
        font-weight: 400;
        font-size: 14px !important;
        color: #150f0b;
      }

      .ant-input[disabled] {
        border: none;

        background-color: #fff3ee !important;
        :hover {
          border: none;
        }
      }
      .ant-input::placeholder {
        color: #ffc4aa !important;
        font-family: 'Prompt';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
      }
      textarea.ant-input {
        border: 1px solid var(--mobile-extended-orange-500, #fee0d3);
        &:hover,
        &:active,
        &:focus {
          border: 1px solid #fc6521 !important;
          color: #fc6521 !important;
        }
      }
    }
  }

  .text-head {
    color: var(--mobile-extended-neutral-800, var(--mobile-neutral-800, #150f0b));
    leading-trim: both;
    text-edge: cap;
    font-family: Prompt;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }

  .text-data {
    padding-bottom: 10px;
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #150f0b;
    /* padding-left: 40px; */
    margin-bottom: 0;
  }

  .text-head-right {
    margin-bottom: 0;
    padding-left: 32px;
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #c4c4c4;
    padding-top: 14px;
    padding-bottom: 10px;
  }

  .text-data-right {
    padding-bottom: 10px;
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #150f0b;
    padding-left: 32px;
    margin-bottom: 0;
  }

  .text-data-department {
    padding-bottom: 10px;
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #556a82;
    padding-left: 32px;
    margin-bottom: 0;
  }

  .ant-drawer-header-title {
    justify-content: flex-end;
  }

  .close-icon {
    position: absolute;
    left: 0px;
    cursor: pointer;
    margin-left: 32px;
  }
`

export const WrapBoxStar = styled.div`
  /* background-color: brown; */
  width: 100%;
  padding: 0 64px;
  /* border-bottom: 2px solid red; */

  /* &.ant-rate-star &.ant-rate-star-full {
    &.anticon svg {
      color: red !important;
    }
  } */

  .ant-input {
    border-radius: 8px;
    border-color: #ffeee7;
    height: 40px;
    color: #f36b24 !important;
  }
  .ant-input::placeholder {
    color: #ffc4aa !important;
  }
  .ant-input:focus {
    border-color: #f36b24;
    box-shadow: none;
  }

  .ant-rate-star-full .ant-rate-star-second,
  .ant-rate-star-half .ant-rate-star-first {
    color: #f36b24 !important;
  }

  .ant-rate {
    svg {
      height: 40px;
      width: 40px;
    }
  }
`

export const WrapBoxRateInDetail = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const BoxRating = styled.div`
  width: 336px;
  height: 40px;
  border-radius: 8px;
  background-color: #fff3ee;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const TextRating = styled.div`
  font-family: 'Prompt' !important;
  font-weight: 400;
  font-size: 12px;
  color: #150f0b;
  padding-left: 24px;
`

export const BoxRatingInDetail = styled.div`
  padding-right: 24px;

  .ant-rate-star-full .ant-rate-star-second,
  .ant-rate-star-half .ant-rate-star-first {
    color: #f36b24 !important;
  }
`
