import * as Yup from 'yup'

export const defaultValues = {
  first_name: '',
  last_name: '',
  mobile_number: '',
  // group_id: '',
  // address: '',
  // province_id: '',
  // district_id: '',
  // sub_district_id: '',
  // postcode: '',
  type_of_complaint: '',
  title_complaint: '',
  description_complain: '',
}

export const Schema = Yup.object().shape({
  // first_name: Yup.string()
  //   .max(255, 'กรุณากรอกเป็นตัวอักษรไม่เกิน 255 ตัวอักษร')
  //   .required('')
  //   .matches(/^[a-zA-Z0-9\u0E00-\u0E7F\s]+$/, 'กรุณากรอกเป็นตัวอักษรไม่เกิน 255 ตัวอักษร'),
  // last_name: Yup.string()
  //   .max(255, 'กรุณากรอกเป็นตัวอักษรไม่เกิน 255 ตัวอักษร')
  //   .required('')
  //   .matches(/^[a-zA-Z0-9\u0E00-\u0E7F\s]+$/, 'กรุณากรอกเป็นตัวอักษรไม่เกิน 255 ตัวอักษร'),
  // mobile_number: Yup.string()
  //   .required('')
  //   // .matches(/^[0-9]*$/g, 'กรุณากรอกหมายเลขโทรศัพท์')
  //   .min(12, ''),
  // address: Yup.string()
  //   .max(35, 'กรุณากรอกไม่เกิน 35 ตัวอักษร')
  //   .required('')
  //   .matches(/^[a-zA-Z0-9\u0E00-\u0E7F\s]+$/, 'กรุณากรอกไม่เกิน 35 ตัวอักษร'),
  // province_id: Yup.string().required(''),
  // district_id: Yup.string().required(''),
  // sub_district_id: Yup.string().required(''),
  // sub_category_id: Yup.string().required(''),
  // type_of_complaint: Yup.string().required(''),
  // title_complaint: Yup.string().required(''),
  description_complain: Yup.string().max(255, 'กรุณากรอกเป็นตัวอักษรไม่เกิน 255 ตัวอักษร'),
})

export const SchemaGuest = Yup.object().shape({
  first_name: Yup.string()
    .max(255, 'กรุณากรอกเป็นตัวอักษรไม่เกิน 255 ตัวอักษร')
    .required('')
    .matches(/^[a-zA-Z0-9\u0E00-\u0E7F\s]+$/, 'กรุณากรอกเป็นตัวอักษรไม่เกิน 255 ตัวอักษร'),
  last_name: Yup.string()
    .max(255, 'กรุณากรอกเป็นตัวอักษรไม่เกิน 255 ตัวอักษร')
    .required('')
    .matches(/^[a-zA-Z0-9\u0E00-\u0E7F\s]+$/, 'กรุณากรอกเป็นตัวอักษรไม่เกิน 255 ตัวอักษร'),
  mobile_number: Yup.string()
    .required('')
    // .matches(/^[0-9]*$/g, 'กรุณากรอกหมายเลขโทรศัพท์')
    .min(12, ''),
  address: Yup.string()
    .max(35, 'กรุณากรอกไม่เกิน 35 ตัวอักษร')
    .required('')
    .matches(/^[a-zA-Z0-9\u0E00-\u0E7F\s]+$/, 'กรุณากรอกไม่เกิน 35 ตัวอักษร'),
  province_id: Yup.string().required(''),
  district_id: Yup.string().required(''),
  sub_district_id: Yup.string().required(''),
  sub_category_id: Yup.string().required(''),
  category_id: Yup.string().required(''),
  name_complain: Yup.string().required(''),
  description_complain: Yup.string()
    .max(255, 'กรุณากรอกเป็นตัวอักษรไม่เกิน 255 ตัวอักษร')
    .when('category_id', {
      is: '1730459515117105153',
      then: Yup.string().required(''),
      otherwise: Yup.string(),
    })
    .required(''),
})

export const mockUserData = {
  first_name: 'พงศธร',
  last_name: 'เลิศรัตนโสภาพิบูรณ์ชัยศรีรัตน์',
  mobile_number: '094-231-4568',
  groupuser: 'เจ้าหน้าที่ภายนอกหน่วยงาน',
  address: '215/12 ซอย 4  ถนนสุรนารี',
  province: 'นครราชสีมา',
  district: 'เมืองนครราชสีมา',
  sub_districte: 'หนองบัวศาลา',
  postcode: '',
  category_id: '',
  name_complain: '',
  description_complain: '',
}
// export const mockUserData = {
//   first_name: '',
//   last_name: '',
//   mobile_number: '',
//   groupuser: '',
//   address: '',
//   province: '',
//   district: '',
//   sub_districte: '',
//   postcode: '',
//   category_id:'',
//   name_complain:'',
//   description_complain:'',

// }
