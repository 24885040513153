import styled from 'styled-components'
import { Button, Checkbox, Divider } from 'antd'

export const Container = styled.div`
  /* display: flex; */
  width: 100%;
  height: 100%;
  /* min-height: 720px; */
  /* overflow: auto; */

  background-image: ${props => (props.url ? `url(${props.url})` : '#fcfcfc')};
  background-position: ${props => (props.mobile ? `0% 375%` : 'center center')};
  background-repeat: ${props => (props.mobile ? `none` : 'no-repeat')};
  background-size: ${props => (props.mobile ? `none` : 'cover')};

  /* background-position: center center;
  background-repeat: no-repeat;
  background-size: cover; */

  /* align-items: center;
  justify-content: center;
  flex-direction: column; */

  /* @media only screen and (max-width: 414px) {
    background-position: 0% 310%;
    background-repeat: none !important;
    background-size: none !important;
  } */

  .ant-tooltip-disabled-compatible-wrapper {
    width: 100%;
  }
`

export const WrapBoxRegister = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`

export const BoxHeader = styled.div`
  /* height: 140px; */
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  /* width: 100%; */
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  /* margin-top: 0rem; */

  color: #150f0b;
  font-size: 24px;
  font-family: 'Prompt';
  font-weight: 500;

  svg {
    margin-right: 24px;
  }

  @media only screen and (max-width: 768px) {
    margin-top: 2rem;
    margin-bottom: 1.5rem;
  }

  @media only screen and (max-width: 375px) {
    margin-top: 2rem;
    margin-bottom: 1.5rem;
  }
`

export const BoxForm = styled.div`
  background-color: #ffffff;
  border-radius: 24px 24px 0px 0px;
  width: 964px;
  height: auto;
  /* margin-left: auto;
  margin-right: auto; */
  padding: 54px 52px;
  /* margin-top: 1.5rem; */
  margin-top: 0rem;

  @media only screen and (max-width: 768px) {
    padding: 24px;
    width: 100%;
    border-radius: 0px 0px 0px 0px;
  }

  @media only screen and (max-width: 375px) {
    padding: 24px;
    width: 100%;
    border-radius: 0px 0px 0px 0px;
  }
`

export const BoxGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  margin-bottom: 4rem;

  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }

  @media only screen and (max-width: 375px) {
    grid-template-columns: 1fr;
  }
`

export const BoxFlex = styled.div`
  display: flex;
  margin-bottom: 4rem;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }

  @media only screen and (max-width: 375px) {
    flex-direction: column;
  }
`

export const CustomDivider = styled(Divider)`
  /* margin: 0; */
  /* margin: 0 68px 0 60px; */
  /* margin: 32px 68px 25px 60px; */
  margin-top: 2rem;
  margin-right: 68px;
  margin-left: 60px;
  margin-bottom: 1.563rem;
  top: 0;
  height: auto !important;

  &.ant-divider {
    border-top: 1px solid #f4f4f4 !important;
  }

  &.ant-divider-vertical {
    border-left: 2px solid #f4f4f4 !important;
  }
`

export const BtSubmit = styled(Button)`
  background: #f36b24;
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: white;
  line-height: 6px;
  padding: 18px 8px;
  width: 366px;
  border: none;
  cursor: pointer;
  height: 56px;
  margin-bottom: 5.5rem;

  ${props =>
    props.type === 'primary' &&
    `
      background: #F36B24;

       &:hover,
        :focus {
         background: #F36B24 !important;
         color: white;
        }
    `}

  &:disabled {
    background-color: #fee0d3;
    border-color: #fee0d3;
    color: white;
    cursor: not-allowed;

    &:hover,
    :focus {
      background: #fee0d3;
      color: white;
    }
  }

  &:hover,
  :focus {
    background: #f36b24;
    color: white;
  }

  &:active {
    box-shadow: none;
    border-color: #f36b24 !important;
  }

  @media only screen and (max-width: 768px) {
    height: 56px;
    font-size: 18px;
    width: 100%;
    margin-bottom: 3rem;
  }

  @media only screen and (max-width: 375px) {
    height: 56px;
    font-size: 18px;
    width: 100%;
    margin-bottom: 3rem;
  }
`

export const BoxFormLeft = styled.div`
  height: auto;
  /* border-right: 1px solid #f4f4f4; */
  padding-right: 60px;
  /* flex: 1; */

  h2 {
    font-family: 'Prompt';
    font-size: 18px;
    font-weight: 500;
    color: #000000;
    margin-bottom: 1.5rem;
  }

  .box-back-button {
    position: relative;
    height: 72px;

    @media only screen and (max-width: 768px) {
      display: none;
    }
  }

  .back-button {
    display: block;
    position: absolute;
    left: -10px;
  }

  @media only screen and (max-width: 768px) {
    padding-right: 0px;
    width: 100%;
    border-right: none;

    .back-button {
      display: none;
    }

    h2 {
      display: none;
    }
  }

  @media only screen and (max-width: 375px) {
    padding-right: 0px;
    width: 100%;
    border-right: none;

    .back-button {
      display: none;
    }

    h2 {
      display: none;
    }
  }
`

export const BoxFormRight = styled.div`
  /* border-left: 1px solid #f4f4f4; */
  /* padding-left: 68px; */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  /* flex: 1; */

  h2 {
    font-family: 'Prompt';
    font-size: 18px;
    font-weight: 500;
    color: #000000;
    margin-bottom: 0.75rem;
  }

  h4 {
    font-family: 'Prompt';
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    margin-bottom: 1.5rem;
  }

  .wrap-form-right {
    padding-left: 68px;
    border-left: 2px solid #f4f4f4;
  }

  @media only screen and (max-width: 768px) {
    border-left: none;
    padding-left: 0px;
    width: 100%;

    h2 {
      margin-bottom: 0px;
    }

    .wrap-form-right {
      padding-left: 0px;
      border-left: none;
    }
  }

  @media only screen and (max-width: 375px) {
    border-left: none;
    padding-left: 0px;
    width: 100%;

    h2 {
      margin-bottom: 0px;
    }

    .wrap-form-right {
      padding-left: 0px;
      border-left: none;
    }
  }
`
export const StyleDropdown = styled.div`
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 8px;
    border: 1px solid #fee0d3;
    height: 40px;
    width: 100%;
    padding: 4px 11px;

    @media only screen and (max-width: 768px) {
      padding: 12px 16px;
    }

    @media only screen and (max-width: 375px) {
      padding: 12px 16px;
    }
  }

  .ant-select-disabled .ant-select-selector {
    border: 1px solid #fff3ee !important;
    background-color: #fff3ee !important;
    color: #fff3ee !important;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-disabled .ant-select-selector {
    border: 1px solid #fff3ee !important;
  }
  .ant-select-disabled .ant-select-arrow svg > path {
    stroke: #fff3ee;
  }
`

export const LabelText = styled.div`
  font-family: 'Prompt';
  display: flex;
  font-size: 14px;
  font-weight: 400;
  color: #150f0b;
  padding-bottom: 8px;
`

export const WrapLogo = styled.div`
  /* margin-top: 74px;
  margin-bottom: 56px; */
  margin-top: 32px;
  margin-bottom: 32px;

  .text-span1 {
    font-family: 'Prompt';
    font-size: 14px;
    font-weight: 400;
    color: #150f0b;
  }
  .text-span2 {
    font-family: 'Prompt';
    font-size: 14px;
    font-weight: 500;
    color: #150f0b;
  }

  @media only screen and (max-width: 768px) {
    margin: 0px 0px 24px 0px;
  }

  @media only screen and (max-width: 375px) {
    margin: 0px 0px 24px 0px;
  }
`

export const BoxPDPA = styled.div`
  font-family: 'Prompt';
  margin-left: 1rem;
  font-size: 14px;
  font-weight: 400;
  color: #150f0b;

  a {
    color: #150f0b;
    text-decoration: underline;
  }
`

export const CustomCheckbox = styled(Checkbox)`
  .ant-checkbox-inner {
    width: 24px !important;
    height: 24px !important;
    border-radius: 4px !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #f36b24;
    border-color: #f36b24;
  }

  .ant-checkbox-checked .ant-checkbox-inner:after {
    left: 7px !important;
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: #f36b24 !important;
  }

  .ant-checkbox-checked:after {
    border: 1px solid #f36b24 !important;
  }
`
