import styled, { createGlobalStyle } from 'styled-components'
import { Button, Menu, Drawer, Divider, Input, Popover } from 'antd'

export const CustomPopover = styled(Popover)`
  .ant-popover-inner {
    background: #001f34;
    box-shadow: 0px 2px 4px rgba(17, 24, 39, 0.08);
    backdrop-filter: blur(6px);
    border-radius: 24px;
  }

  .ant-popover-title {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #fbfbfb;
  }
  .ant-popover-inner-content {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #fbfbfb;
  }
`

export const LabelText = styled.span`
  font-family: 'Prompt';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`

export const LabelTexthead = styled.div`
  font-family: 'Prompt';
  display: flex;
  font-size: 14px;
  font-weight: 400;
  color: #150f0b;
`
export const ClearableTextArea = styled.div`
  cursor: pointer;
  position: absolute;
  /* right: 16px !important; */
  /* width: 100%; */
  top: 40px;
  right: 15px;
`

export const CustomInputDrawer = styled(Input)`
  /* border-color: #ffeee7 !important; */
  font-family: 'Prompt';
  font-weight: 400;
  font-size: 16px;
  border: 1px solid var(--mobile-extended-orange-500, #fee0d3);
  color: #150f0b !important;
  border-radius: 12px;
  /* width: 500px; */
  height: 48px;
  width: 100%;

  .ant-input[disabled] {
    background-color: #ffc4aa !important;
  }
  .ant-input-disabled {
    background-color: #ffc4aa !important;
  }
  ::placeholder {
    color: #ffc4aa !important;
    font-family: 'Prompt';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.2px; /* 120% */
  }

  &:hover,
  &:active,
  &:focus {
    border: 1px solid #fc6521 !important;
    box-shadow: none;
    color: #fc6521 !important;
  }

  &.ant-input-affix-wrapper > .ant-input {
    &:focus,
    &:active {
      color: #fc6521 !important;
      border-color: #fc6521 !important;
    }
  }

  &.ant-input-affix-wrapper-focused,
  &.ant-input-affix-wrapper:focus {
    box-shadow: none !important;
  }

  &.ant-input-affix-wrapper-focused {
    box-shadow: none !important;
    border: 1px solid #fc6521;
  }

  .ant-input-clear-icon,
  .anticon.ant-input-clear-icon {
    display: flex;
  }

  &.ant-input-affix-wrapper-disabled {
    border-color: transparent !important;
    background-color: #ffc4aa !important;
  }
`

export const CustomDrawer = styled(Drawer)`
  .ant-drawer-content-wrapper {
    width: 600px !important;

    @media only screen and (max-width: 820px) {
      width: 100% !important;
    }
  }

  .ant-drawer-wrapper-body {
    /* padding: 0 40px; */
  }

  .ant-drawer-content {
    box-shadow: 0px 4px 30px rgba(46, 60, 79, 0.04);
  }

  .ant-drawer-mask {
    background: rgba(0, 31, 52, 0.5);
    backdrop-filter: blur(8px);
  }

  .ant-drawer-header {
    border: none;
  }

  .ant-drawer-body {
    padding: 0 !important;
    overflow: scroll;
    overflow-x: hidden;
    ::-webkit-scrollbar {
      width: 5px;
    }
    ::-webkit-scrollbar-track {
      background-color: #eaeaeb !important; /* Set the scrollbar track color */
      border-radius: 8px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #f36b24 !important; /* Set the scrollbar thumb color */
      color: red !important;
      border-radius: 8px;
    }
  }

  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-top: 6.5em; */
    h1 {
      color: #150f0b;
      text-align: center;
      font-family: 'Prompt';
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      margin-top: 8px;
    }
  }

  .info-title {
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #150f0b;
    margin-bottom: 5px;
    /* padding-left: 40px; */
  }

  .edit-profile {
    font-family: 'Prompt';
    font-weight: 500;
    font-size: 18px;
    color: #fc6521;
  }

  .top {
    margin-top: 70px;
  }

  .red-star {
    color: #f85858;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
  }

  .row-data {
    width: 100%;
    display: flex;
    padding-bottom: 24px;
    padding-top: 24px;

    .col-1 {
      /* .ant-input{
        &:hover,
        &:active,
        &:focus {
            border: 1px solid #fc6521 !important;
            color: #fc6521 !important;
          }      }
   */

      .ant-input:not(.ant-input-disabled):hover {
        border-color: #fc6521;
      }

      /* .ant-input:not(.ant-inpu-focused):hover {
          border: 1px solid #FEE0D3;
       } */
      width: 100%;
      label {
        font-family: 'Prompt';
        font-weight: 400;
        font-size: 14px !important;
        color: #150f0b;
      }

      .ant-input[disabled] {
        border: none;

        background-color: #fff3ee !important;
        :hover {
          border: none;
        }
      }
      .ant-input::placeholder {
        color: #ffc4aa !important;
        font-family: 'Prompt';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
      }
      textarea.ant-input {
        border: 1px solid var(--mobile-extended-orange-500, #fee0d3);
        &:hover,
        &:active,
        &:focus {
          border: 1px solid #fc6521 !important;
          color: #fc6521 !important;
        }
      }
    }
  }

  .row-data-responsive {
    width: 100%;
    display: flex;
    padding-bottom: 24px;
    padding-top: 24px;

    .col-1 {
      min-height: 97px;
    }
  }

  .text-head {
    color: var(--mobile-extended-neutral-800, var(--mobile-neutral-800, #150f0b));
    leading-trim: both;
    text-edge: cap;
    font-family: Prompt;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }

  .text-data {
    padding-bottom: 10px;
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #150f0b;
    /* padding-left: 40px; */
    margin-bottom: 0;
  }

  .text-head-right {
    margin-bottom: 0;
    padding-left: 32px;
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #c4c4c4;
    padding-top: 14px;
    padding-bottom: 10px;
  }

  .text-data-right {
    padding-bottom: 10px;
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #150f0b;
    padding-left: 32px;
    margin-bottom: 0;
  }

  .text-data-department {
    padding-bottom: 10px;
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #556a82;
    padding-left: 32px;
    margin-bottom: 0;
  }

  .ant-drawer-header-title {
    justify-content: flex-end;
  }

  .close-icon {
    position: absolute;
    left: 0px;
    cursor: pointer;
    margin-left: 32px;
  }
`
export const BoxTitle = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  padding-bottom: 10px;
  padding-top: 22px;
  border-radius: 8px;

  .ant-btn-primary {
    border-color: #f36b24;
    background-color: #f36b24;
    height: 40px;
    width: 162px;
    border-radius: 8px;
  }
`

export const ButtonOpenMap = styled.div`
  cursor: ${props => (props.disable === 'true' ? 'unset' : 'pointer')};
  width: 100%;
  height: 80px;
  background: #ffffff;
  box-shadow: 0px 2px 10px 0px #f4f4f4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 16px;
  order: 1;
  flex-grow: 0;
  transition: 0.3s;
  padding: 16px 22px;

  /* &:hover {
    border: 1px solid #00b7ad;
  } */
  h3 {
    margin: 0;
    margin-left: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .title {
    display: flex;
    align-items: center;
  }
  .col-location-1 {
    width: 1%;
    padding-right: 5px;
  }
  .col-location-2 {
    font-family: 'Prompt';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    width: 80%;

    .detaillocation {
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: 14.4px;
      color: #aba9a8;
    }
  }
  .col-location-3 {
    width: 5%;
  }
`

export const CustomUploadfile = styled.div`
  .upload {
    border: 1px dashed #ffc4aa !important;
  }
  .dest1 {
    font-family: 'Prompt';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16.8px; /* 120% */
    color: #150f0b !important;
  }
  .dest2 {
    font-family: 'Prompt';
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    color: #150f0b !important;
  }
  .underline {
    color: #f36b24 !important;
  }
`

export const CustomDivider = styled(Divider)`
  margin: 0;
  top: 0;
  height: auto !important;

  &.ant-divider {
    border-top: 1px solid #ffeee7 !important;
  }

  &.ant-divider-vertical {
    border-left: 1px solid #ffeee7 !important;
  }
`
export const StyleDropdown = styled.div`
  font-family: 'Prompt';

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 12px;
    height: 48px;
    width: 100%;
    padding: 8px 10px;
    border: 1px solid var(--mobile-extended-orange-500, #fee0d3);

    /* @media only screen and (max-width: 768px) {
      padding: 12px 16px;
    }

    @media only screen and (max-width: 375px) {
      padding: 12px 16px;
    } */
  }
  .ant-select-selection-placeholder {
    color: #ffc4aa !important;
    font-family: 'Prompt';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.2px; /* 120% */
    padding-left: 5px;
  }

  .ant-select-disabled .ant-select-selector {
    border: 1px solid #fbfbfb !important;
    background-color: #fff3ee !important;
    color: #9ac8bb !important;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-disabled .ant-select-selector {
    border: 1px solid #fbfbfb !important;
  }
  .ant-select-disabled .ant-select-arrow svg > path {
    stroke: #fbfbfb;
  }
`
export const CustomheadTime = styled.div`
  color: #150f0b;
  display: flex;
  justify-content: space-around;
  font-family: 'Prompt';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14.4px;
  background-color: #fff3ee;
  border-radius: 12px;
  padding: 10px 16px 10px 16px;
  width: 236px;
  margin-bottom: 1rem;
  align-items: center;
`

export const Container = styled.div`
  width: 50%;

  textarea.ant-input {
    border: 1px solid var(--mobile-extended-orange-500, #fee0d3);
    &:hover,
    &:active,
    &:focus {
      border: 1px solid #fc6521 !important;
      color: #fc6521 !important;
    }
  }

  @media only screen and (max-width: 820px) {
    width: 100%;
  }
`

export const NotiStyle = createGlobalStyle`
  body {
    .ant-notification-notice {
        background: #E2E5F8;
        box-shadow: 0px 4px 16px rgba(200, 211, 218, 0.3);
        border-radius: 12px;
        width: 309px;
        height: 64px;
        align-items: center;
        justify-content: center;

    }
    .ant-notification-top {
      margin-left: 60px;
    }
    .ant-notification-notice-content {
      justify-content: center;
      align-items: center;
    }
    .ant-notification-notice-message {
  font-family: 'Prompt', sans-serif;
      font-style: normal;
      font-size: 16px !important;
      font-weight: 500;
      line-height: 18px;
      color: #001F34;
      padding-top: 5px;
      display: flex;
    }
    .ant-notification-notice-close {
      padding-top: 8px;
    }
  }
`
export const BtSubmit = styled(Button)`
  background-color: #fc6521;
  /* background-color: ${props => (props.disabled ? 'transparent' : '#fc6521')}; */
  font-family: 'Prompt';
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  border-radius: 8px;
  width: 100%;
  height: 56px;
  justify-content: center;

  &:hover,
  &:active,
  &:focus {
    color: #ffffff;
    background-color: #fc6521;
    border-color: #fc6521;
  }

  &.ant-btn[disabled] {
    background: rgba(252, 101, 33, 1) !important;
    color: #ffffff;
    opacity: 0.2;
  }
`

export const BoxData = styled.div`
  min-height: 64px;
  border-bottom: 1px solid #f4f4f4;
  padding: 16px 0;
  width: 100%;
  font-family: 'Prompt';
  display: flex;
  flex-direction: column;

  .title-resposive {
    color: #c4c4c4;
    font-size: 10px;
    font-weight: 400;
    margin-bottom: 16px;
  }

  .data {
    font-size: 12px;
    font-weight: 400;
    color: #150f0b;
  }
`
export const WrapProfileResponsive = styled.div`
  background-color: #fffaf8;
  /* background-color: green; */
  height: auto;
  width: 100%;
  padding: 1rem;
  font-family: 'Prompt';

  .header {
    margin: 16px 0px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .box-header-left {
    position: relative;
    display: flex;
    align-items: center;

    svg {
      position: absolute;
      left: -16px;
      top: -28px;
    }

    span {
      padding-left: 50px;
      margin: 0;
      font-family: 'Prompt';
      font-weight: 500;
      font-size: 20px;
      color: #150f0b;
    }
  }

  .box-header-right {
    span {
      color: #f36b24;
      font-family: 'Prompt';
      font-weight: 500;
      font-size: 14px;
      cursor: pointer;
    }
  }
`

export const WrapBoxComplaint = styled.div`
  background-color: #ffffff;
  height: 70vh;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: #eaeaeb;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #f36b24;
    border-radius: 8px;
  }
`

export const WrapMap = styled.div`
  background-color: #fffaf8;
  /* padding: 1rem; */
  height: 100%;

  & > div {
    height: 100%;
  }
`

export const BoxContentDetail = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: ${props => (props.showDetail ? 'repeat(2, 1fr)' : '1fr')};
  grid-column-gap: 0px;
  grid-row-gap: 16px;
  height: 100%;
  transition: grid-template-rows 1s;
  background-color: #fffaf8;
  padding: 1rem;
`

export const BoxSeeDetail = styled.div`
  height: 100%;
  background-color: #ffffff;
  font-family: 'Prompt';
  padding: 1rem;
  border-radius: 16px;

  .wrap-box-title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 1.35px solid #f4f4f4;
    padding-bottom: 1rem;
  }
  .box-title {
    width: 100%;
    display: flex;
    flex-direction: column;
    .title {
      display: flex;
      font-size: 16px;
      font-weight: 500;
      color: #150f0b;
      margin-bottom: 8px;

      img {
        margin-right: 8px;
      }
    }
    .description {
      font-size: 12px;
      font-weight: 300;
    }
  }

  .box-status {
    width: 20%;
    display: flex;
    position: relative;
    justify-content: flex-end;

    .status {
      height: 24px;
      border-radius: 8px;
      background-color: #efe9fe;
      color: #915eff;
      font-weight: 400;
      font-size: 12px;
      padding: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 8px;
    }
  }
`

export const ButtonBack = styled(Button)`
  width: 40px;
  height: 40px;
  border: none !important;
  box-shadow: 0px 4px 16px rgba(200, 211, 218, 0.3);
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  :focus,
  :hover {
    border-color: transparent;
    background: #fbfbfb !important;
  }
`

export const BoxBtnInMap = styled.div`
  .ant-btn {
    box-shadow: none;
    width: 100%;
    border-radius: 6px;
    color: #ffffff;
    border: none;
    background: #f36b24;
  }
`

export const DetailLocation = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  color: #aba9a8;
  display: ${props => (props.text === '' || null || undefined ? 'none' : 'block')};
  height: 20px;
  text-overflow: ellipsis;
`
