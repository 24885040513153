import { Navigate, Outlet } from 'react-router-dom'
import UserContext from 'core/contexts/userContext'
import { useContext } from 'react'

const RouteGuest = props => {
  // const guest = localStorage.getItem('guest')
  const guest = sessionStorage.getItem('guest')
  console.log('guest', guest)

  return guest ? <Outlet /> : <Navigate to="/login" />
}

export default RouteGuest
