import * as Yup from 'yup'

export const defaultValues = {
  first_name: '',
  mobile_number: '',
  group_id: '',
  address: '',
  province_id: '',
  district_id: '',
  sub_district_id: '',
  postcode: '',
}

export const Schema = Yup.object().shape({
  first_name: Yup.string().required(''),
  // // .matches(/^[A-Za-zก-๏\s]+$/, 'กรุณาระบุเป็นตัวอักษรเท่านั้น'),
  last_name: Yup.string().required(''),
  mobile_number: Yup.string()
    .required('')
    .min(10, 'รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง')
    .max(10, 'รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง'),
  address: Yup.string().required(''),
  group_id: Yup.string().required(''),
  province_id: Yup.string().required(''),
  district_id: Yup.string().required(''),
  sub_district_id: Yup.string().required(''),
})

export const mockUserData = {
  name: 'พงศธร เลิศรัตนโสภาพิบูรณ์ชัยศรีรัตน์',
  phone: '094-231-4568',
  groupuser: 'เจ้าหน้าที่ภายนอกหน่วยงาน',
  address: '215/12 ซอย 4  ถนนสุรนารี',
  province: 'นครราชสีมา',
  district: 'เมืองนครราชสีมา',
  sub_districte: 'หนองบัวศาลา',
  code: '30000',
}

export const mockDataNoti = [
  {
    title: 'ท่อน้ำแตกชำรุด',
    description: 'ท่อแตก น้ำท่วมถึงหัวเข่า',
    image: 'https://cdn.pixabay.com/photo/2023/09/18/13/46/beach-8260614_1280.jpg',
  },
  {
    title: 'น้ำประปาไม่ไหล/ไหลอ่อน',
    description: 'น้ำไหลเบา บ่อย',
    image: 'https://cdn.pixabay.com/photo/2016/10/18/21/22/beach-1751455_1280.jpg',
  },
  {
    title: 'ท่อน้ำแตกชำรุด',
    description: 'ท่อแตก น้ำท่วมถึงหัวเข่า',
    image: 'https://cdn.pixabay.com/photo/2018/08/14/13/23/ocean-3605547_1280.jpg',
  },
  // {
  //   title: 'ท่อน้ำแตกชำรุด',
  //   description: 'ท่อแตก น้ำท่วมถึงหัวเข่า',
  //   image: 'https://cdn.pixabay.com/photo/2018/08/14/13/23/ocean-3605547_1280.jpg',
  // },
  // {
  //   title: 'ท่อน้ำแตกชำรุด',
  //   description: 'ท่อแตก น้ำท่วมถึงหัวเข่า',
  //   image: 'https://cdn.pixabay.com/photo/2018/08/14/13/23/ocean-3605547_1280.jpg',
  // },
]
