import React, { useState } from 'react'
import { ReactComponent as TitleLoginEmail } from 'assets/images/svg/TitleLoginEmail.svg'
import { ReactComponent as SubTitleLoginEmail } from 'assets/images/svg/SubTitleLoginEmail.svg'
import { ReactComponent as BackToPage } from 'assets/images/svg/back-button-profile.svg'
import BackgroundPage from 'assets/images/png/BackgroundPage-login.png'
import styled from 'styled-components'
import InputComponent from 'components/input/Input'
import { Button, Form } from 'antd'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

export default function Index() {
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [disableBtn, setDisableBtn] = useState(true)
  const [form] = Form.useForm()
  const onLogin = () => {
    setLoading(true)
    axios
      .post(`${process.env.REACT_APP_BASEURL}/authen/api/v1/login-email`, {
        email: email,
        password: password,
      })
      .then(res => {
        setLoading(false)
        if (res.data.code === 200) {
          localStorage.setItem('access_token', res.data.data?.access_token)
          localStorage.setItem('refresh_token', res.data.data?.refresh_token)
          navigate('/home')
        } else {
        }
      })
      .catch(err => {
        form.setFields([
          {
            name: 'email',
            value: email,
            errors: ['อีเมลหรือรหัสผ่านไม่ถูกต้อง'],
          },
          {
            name: 'password',
            value: password,
            errors: [''],
          },
        ])
        setLoading(false)
      })
  }

  const onEmailChange = (e, type) => {
    setEmail(e.target.value)
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    if (e.target.value) {
      if (!mailformat.test(e.target.value)) {
        form.setFields([
          {
            name: 'email',
            value: email,
            errors: ['รูปแบบอีเมลไม่ถูกต้อง'],
          },
        ])
        setDisableBtn(true)
      }
      if (mailformat.test(e.target.value)) {
        if (password) {
          setDisableBtn(false)
        } else {
          setDisableBtn(true)
        }
        form.setFields([
          {
            name: 'email',
            value: email,
            errors: [],
          },
        ])
      }
    } else {
      setDisableBtn(true)
      form.setFields([
        {
          name: 'email',
          value: email,
          errors: [],
        },
      ])
    }
  }

  const onPasswordChange = e => {
    setPassword(e.target.value)
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    if (mailformat.test(email) && e.target.value) {
      setDisableBtn(false)
    } else {
      setDisableBtn(true)
      form.setFields([
        {
          name: 'password',
          value: e.target.value,
          errors: [],
        },
      ])
    }
  }

  return (
    <BoxLoginEmail>
      <div className="box-logo-title">
        <TitleLoginEmail className="svg-title" />
      </div>
      <div className="box-login">
        <div>
          <BackToPage className="svg-back" onClick={() => navigate('/login')} />
        </div>
        <div>
          <SubTitleLoginEmail className="svg-sub-title" />
        </div>
        <p>เข้าสู่ระบบ</p>
        <Form form={form}>
          <div className="box-input">
            <Form.Item name="email">
              <InputComponent
                label={'อีเมล'}
                required={true}
                values={email}
                placeholder={'กรอกอีเมลของคุณ'}
                onChange={onEmailChange}
              />
            </Form.Item>
            <Form.Item name="password">
              <InputComponent
                label={'รหัสผ่าน'}
                required={true}
                values={password}
                type={'password'}
                placeholder={'กรอกรหัสผ่านของคุณ'}
                onChange={onPasswordChange}
                name="password"
              />
            </Form.Item>
          </div>
        </Form>
        <div className="box-btn">
          <Button disabled={disableBtn} onClick={onLogin} loading={loading}>
            เข้าสู่ระบบ
          </Button>
        </div>
      </div>
    </BoxLoginEmail>
  )
}

const BoxLoginEmail = styled.div`
  background-image: url(${BackgroundPage});
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 5% 15%;
  width: 100%;
  .box-logo-title {
    display: flex;
    justify-content: center;
    margin-bottom: 5%;
  }
  .box-input {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-left: 5%;
    grid-gap: 50px;
  }
  @media only screen and (max-width: 700px) {
    padding: 30% 10%;
    .box-input {
      grid-template-columns: 1fr;
      grid-gap: 0px;
    }
    .svg-title {
      width: 40%;
      height: 40%;
    }
  }
  .box-login {
    background-color: #ffffff;
    border-radius: 24px;
    padding: 0% 5% 5% 5%;

    .ant-input:focus {
      box-shadow: none !important;
    }
    .ant-input::placeholder {
      color: #ffc4aa !important;
    }
    .ant-input-affix-wrapper:hover {
      border-right-width: 1px;
    }
    .ant-input-affix-wrapper {
      border-right-width: 1px;
      border-radius: 12px;
    }
    .ant-btn[disabled],
    .ant-btn[disabled]:active,
    .ant-btn[disabled]:focus,
    .ant-btn[disabled]:hover {
      color: #fff;
      border-color: #fee0d3 !important;
      background: #fee0d3;
      text-shadow: none;
      box-shadow: none;
    }
    .svg-back {
      cursor: pointer;
    }
    @media only screen and (max-width: 700px) {
      .ant-input {
        height: 38px;
        color: #f36b24 !important;
        width: 100%;
      }
      .box-btn {
        margin-top: 0%;
        display: flex;
        justify-content: center;
      }
      .svg-back {
        width: 70px;
        height: 70px;
        margin-left: -4%;
      }
      .svg-sub-title {
        width: 200px;
        height: 50px;
        margin-top: -10%;
      }
      p {
        margin-left: 5%;
        font-size: 14px;
        font-family: 'Prompt';
        margin-top: 2%;
      }
      label {
        font-size: 12px !important;
      }
    }
    @media only screen and (min-width: 701px) {
      .ant-input {
        height: 48px;
        color: #f36b24 !important;
        width: 100%;
      }
      .box-btn {
        margin-top: 5%;
        display: flex;
        justify-content: center;
      }
      .svg-back {
        width: 10%;
        height: 10%;
      }
      .svg-sub-title {
        width: 25%;
        height: 25%;
        margin-left: 2%;
        margin-top: -3%;
      }
      p {
        margin-left: 5%;
        font-size: 18px;
        font-family: 'Prompt';
        margin-top: 2%;
      }
      label {
        font-size: 16px !important;
      }
    }
  }
  .ant-btn {
    width: 50%;
    height: 48px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Prompt';
    background-color: #f36b24;
    border: #f36b24;
    color: white;
  }
`
