import styled from 'styled-components'

const checkColorStatusBg = val => {
  if (val === 'received') {
    return '#EFE9FE'
  }
  if (val === 'in progress') {
    return '#FDF5DD'
  }
  if (val === 'done') {
    return '#DFF6F5'
  }
  if (val === 'outside the area') {
    return '#E2E5F8'
  }
  if (val === 'tracking') {
    return '#FFE6DB'
  }
  if (val === 'over duedate') {
    return '#FFE4E4'
  }
  if (val === 'transfer') {
    return '#EAEAEB'
  }
}

const checkColorStatusText = val => {
  if (val === 'received') {
    return '#915EFF'
  }
  if (val === 'in progress') {
    return '#FFBB0B'
  }
  if (val === 'done') {
    return '#13AFA6'
  }
  if (val === 'outside the area') {
    return '#3F54D1'
  }
  if (val === 'tracking') {
    return '#F36B24'
  }
  if (val === 'over duedate') {
    return '#F85858'
  }
  if (val === 'transfer') {
    return '#717171'
  }
}

export const WrapContainer = styled.div`
  padding: 12px;
  background-color: ${props => (props.type === 'Complaint' ? '#fffaf8' : '#F1FCFB')};
  box-shadow: 0px 2px 10px 0px rgba(244, 244, 244, 1);
  margin-right: 10px;
  border-radius: 24px;
  margin-bottom: 8px;
  cursor: pointer;
`

export const Container = styled.div`
  background-color: #ffffff;
  height: 221px;
  width: 100%;
  border-radius: 28px;
  padding: 24px;

  @media only screen and (max-width: 768px) {
    height: 230px;
  }

  @media only screen and (max-width: 376px) {
    height: 230px;
  }

  @media only screen and (max-width: 361px) {
    height: 230px;
    padding: 20px;
  }
`

export const BoxTitle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`

export const TextTitle = styled.div`
  display: flex;
  align-items: center;
  width: 60%;
`

export const TestStatus = styled.div`
  font-family: 'Prompt';
  font-size: 12px;
  font-weight: 400;
  width: auto;
  border-radius: 6px;
  padding: 3px;
  background-color: ${props => {
    switch (props.status) {
      case 'received':
        return '#EFE9FE'
      case 'in progress':
        return '#FDF5DD'
      case 'done':
        return '#DFF6F5'
      case 'outside the area':
        return '#E2E5F8'
      case 'tracking':
        return '#FFE6DB'
      case 'over duedate':
        return '#FFE4E4'
      case 'transfer':
        return '#EAEAEB'
      default:
        return 'transparent'
    }
  }};
  color: ${props => {
    switch (props.status) {
      case 'received':
        return '#915EFF'
      case 'in progress':
        return '#FFBB0B'
      case 'done':
        return '#13AFA6'
      case 'outside the area':
        return '#3F54D1'
      case 'tracking':
        return '#F36B24'
      case 'over duedate':
        return '#F85858'
      case 'transfer':
        return '#717171'
      default:
        return 'inherit'
    }
  }};
`

export const WrapBoxContent = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
`

export const BoxContentLeft = styled.div`
  width: 50%;
  margin-right: 5%;
`

export const BoxContentRight = styled.div`
  width: 50%;
  text-align: right;

  img {
    width: 140px;
    height: 80px;
    border-radius: 16px;
  }

  /* @media only screen and (max-width: 361px) {
    text-align: right;
  } */

  @media only screen and (max-width: 361px) {
    img {
      width: 100%;
    }
  }
`

export const BoxLocation = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  margin-bottom: 0.5rem;

  .title {
    color: #aba9a8;
    font-family: 'Prompt';
    font-weight: 400;
    font-size: 10px;
  }

  .wrap-detail {
    width: 100%;
  }

  .detail {
    color: #150f0b;
    font-family: 'Prompt';
    font-weight: 300;
    font-size: 12px;
    margin: 0px;

    display: -webkit-box;
    -webkit-line-clamp: 2; /* จำนวนบรรทัดที่ต้องการให้แสดง */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const BoxNumber = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  margin-bottom: 0.5rem;
  justify-content: center;

  @media only screen and (max-width: 821px) {
    justify-content: flex-end;
  }

  @media only screen and (max-width: 767px) {
    justify-content: flex-start;
  }

  .title {
    color: #aba9a8;
    font-family: 'Prompt';
    font-weight: 400;
    font-size: 10px;
  }

  .wrap-detail {
    width: 100%;
  }

  .detail {
    color: #150f0b;
    font-family: 'Prompt';
    font-weight: 300;
    font-size: 12px;
    margin: 0px;

    display: -webkit-box;
    -webkit-line-clamp: 2; /* จำนวนบรรทัดที่ต้องการให้แสดง */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
