import RadioANT from 'antd/lib/radio'
import styled from 'styled-components'

export const RadioContainer = styled.div`
  /* padding: 10px 0; */
`

export const RadioGroupANT = RadioANT.Group

export const Input = styled.div`
  margin-bottom: 20px;

  .layout-radio {
    .ant-radio-wrapper {
      margin-left: 8px;
      margin-top: 10px;
      display: inline-flex;
      span:nth-child(2) {
        color: #001f34;
        align-self: center;
      }
    }
    &::last-child {
      margin-bottom: 0px;
    }
  }
`

export const RadioGroup = styled(RadioGroupANT)`
  display: flex;

  label {
    width: auto !important;
  }
.ant-radio-checked .ant-radio-inner{
  background-color: #3F54D1;
  border-color: #3F54D1 !important ;
}

/* .ant-radio-checked .ant-radio-inner:before{
  background-color: red !important;
} */

.ant-radio-inner:after{
  background-color: white;
}

.ant-radio-checked:after{
  background-color: #3F54D1;
}

.ant-radio:hover .ant-radio-inner {
  border-color: #3F54D1 ;
}

.ant-radio-checked ,.ant-radio-inner:focus{
  border-color: #3F54D1;
}

.ant-radio-inner{
  //background-color: #3F54D1;
  border-color: #E2E5F8;
  width: 24px;
  height: 24px;
}
  
.ant-radio-disabled {
  .ant-radio-inner {
    border-color: #E1EAF0 !important;
    background-color: #E1EAF0 !important;
  }
  .ant-radio-inner:after{
    background-color: #9BAAB1;
  }
}

/* .ant-radio-checked,
.ant-radio-inner,
.ant-radio-inner,
.ant-radio-wrapper-checked,
.ant-radio-input:focus,
.ant-radio-inner {
  border-color: #ff4d4f !important;
  color: #ff4d4f !important;
}

.ant-radio:hover,
.ant-radio-wrapper:hover {
  color: #ff4d4f;
} */
`

export const Radio = styled(RadioANT)`
  /* label {
    width: auto !important;
  } */
`
