import React from 'react'
import { Select, Input, Tooltip, Drawer } from 'antd'
import { SearchOutlined } from '@ant-design/icons'

import { ReactComponent as IconSearch } from 'assets/images/svg/icon-search.svg'
import { ReactComponent as Chevron } from 'assets/images/svg/filter-chevron.svg'
import { ReactComponent as InvertChevron } from 'assets/images/svg/filter-invert-chevron.svg'
// import { ReactComponent as Chevron } from 'assets/images/svg/filter-chevron.svg'
// import { ReactComponent as InvertChevron } from 'assets/images/svg/filter-invert-chevron.svg'
import { ReactComponent as IconClear } from 'assets/images/svg/icon-clear.svg'

import { BoxSearch, CustomDrawer, HeaderDrawer, TextType, CloseText, BoxOption } from './DropdownDrawer.style'
import { mockTypeComplaints } from './container'

const { Option } = Select
const Index = ({
  onChange,
  options,
  className,
  placeholder = 'ทั้งหมด',
  onSelectFunc,
  isSearchFunc,
  totalFunc,
  total,
  currentPageFunc,
  currentPage,
  defaultValue,
  name,
  setTypeFilter,
  setStatusFilter,
  showSearch = true,
  showTooltip = false,
  allowClear = false,
  disabled = false,
  dropdownValue,
  popupClassName,
  value,
  onDrawerOptionClick,
  fieldFormik,
  style = { inlineSize: 192, height: 'auto', wordWrap: 'break-word', width: '100%' },
  checkmobile,
}) => {
  const [controlDropdow, setControlDropdow] = React.useState(false)
  const [openDrawer, setOpenDrawer] = React.useState(false)
  // console.log('checkmobile', checkmobile)
  // console.log('controlDropdow', controlDropdow)
  const [content, setContent] = React.useState(null)
  const [selectedValue, setSelectedValue] = React.useState(value)
  const [dataSelect, setDataSelect] = React.useState([])
  // console.log('selectedValue----', selectedValue)

  const showDrawer = () => {
    setOpenDrawer(true)
  }
  const onClose = () => {
    setOpenDrawer(false)
  }

  const onDrawerClick = value => {
    setSelectedValue(value)
    if (setTypeFilter) setTypeFilter(value)
    if (setStatusFilter) {
      setStatusFilter({
        value: value,
        label: value,
      })
    }
    setOpenDrawer(false)
  }

  const myRef = React.createRef()

  if (options.length === 0 || (options.length === 1 && options[0].label === 'ทั้งหมด')) {
    disabled = true
  }

  const newOptions =
    options &&
    options.map(item => {
      if (item.province_name) {
        return {
          value: item.id,
          label: item.province_name.th,
          key: item.key,
          title: '',
        }
      } else {
        return {
          value: item.value,
          label: item.label,
          key: item.key,
          title: '',
        }
      }
    })

  const renderSuffix = () => {
    if (controlDropdow) {
      return <InvertChevron style={{ cursor: disabled ? 'auto' : 'pointer', pointerEvents: 'auto' }} />
    } else {
      return <Chevron style={{ cursor: disabled ? 'auto' : 'pointer', pointerEvents: 'auto' }} />
    }
  }

  return (
    <div>
      <BoxSearch>
        <div style={{ fontSize: '14px', color: '#001F34' }}>{name}</div>
        <Select
          ref={myRef}
          className={className}
          allowClear={allowClear}
          clearIcon={<IconClear />}
          suffixIcon={renderSuffix()}
          defaultValue={defaultValue}
          showSearch={showSearch}
          disabled={disabled}
          open={checkmobile ? false : controlDropdow}
          onClear={() => {
            if (setTypeFilter) setTypeFilter({ value: 'ทั้งหมด', label: 'ทั้งหมด', key: 'ทั้งหมด', title: '' })
            if (setStatusFilter) setStatusFilter('ทั้งหมด')
          }}
          onClick={() => {
            if (!disabled) {
              setControlDropdow(!controlDropdow)
              if (checkmobile) {
                showDrawer()
              }
            }
          }}
          onBlur={() => {
            setControlDropdow(false)
          }}
          onInputKeyDown={e => {
            setControlDropdow(true)
          }}
          // onSelect={(value, option) => {
          //   if (setTypeFilter) setTypeFilter(option)
          //   if (setStatusFilter)
          //     setStatusFilter({
          //       value: value,
          //       label: value,
          //     })
          //   myRef.current.blur()
          // }}
          onSelect={(value, option) => {
            setSelectedValue(value)
            if (setTypeFilter) setTypeFilter(option)
            if (setStatusFilter)
              setStatusFilter({
                value: value,
                label: value,
              })
            myRef.current.blur()
          }}
          placeholder={placeholder}
          filterOption={(inputValue, option) => option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
          listHeight={280}
          // listItemHeight={1}
          onSearch={value => {
            setContent(value)
          }}
          onChange={value => {
            onChange && onChange(value)
            if (dropdownValue) {
              if (setTypeFilter) setTypeFilter(value)
            }
          }}
          value={selectedValue || undefined}
          notFoundContent={<div style={{ color: '#597081' }}>ไม่พบข้อมูลของ "{content}"</div>}
          getPopupContainer={triggerNode => triggerNode.parentElement}
          style={style}
          popupClassName={popupClassName}
        >
          {newOptions.map(obj => {
            return (
              <Option value={obj.value} key={obj.key}>
                {showTooltip && (
                  <Tooltip title={obj.label} placement="bottomLeft" overlayClassName="tooltip-action">
                    {obj.label}
                  </Tooltip>
                )}
                {!showTooltip && obj.label}
              </Option>
            )
          })}
        </Select>
        <CustomDrawer placement="bottom" onClose={onClose} open={openDrawer} closable={false}>
          <HeaderDrawer>
            <TextType>ประเภทเรื่องร้องเรียน</TextType>
            <CloseText onClick={onClose}>ยกเลิก</CloseText>
          </HeaderDrawer>
          {newOptions.map((obj, index) => {
            return (
              <BoxOption
                key={index}
                value={obj.value}
                onClick={() => {
                  onDrawerClick(obj.value)
                  onDrawerOptionClick(obj.value, fieldFormik)
                }}
              >
                {obj.icon && <img src={obj.icon} alt="icon" style={{ marginRight: '8px' }} />}
                <label style={{ color: selectedValue === obj.value ? '#FE814A' : '#150F0B' }}>{obj.label}</label>
              </BoxOption>
            )
          })}
        </CustomDrawer>
      </BoxSearch>
    </div>
  )
}

export default Index
