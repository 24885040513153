import * as DP from './date_picker.style'

import DatePickerAntd from 'antd/lib/date-picker'
import Message from 'components/output/Message'
import React from 'react'
import get from 'lodash.get'
import moment from 'moment'

const DatePickerComponent = props => {
  const {
    name,
    placeholder,
    form: { setFieldValue, setFieldTouched, values },
    disabled,
    isDisabledDate,
    onChange,
    dateStart,
    dateEnd,
    newValue,
    showTime,
    enableBeforeCurrent,
    isEqual,
    futureDisable,
  } = props

  const value = get(values, name, null)
  // let value = moment(new Date())

  const disabledDate = current => {
    // enableBeforeCurrent = enable all of date before current
    // isEqual = if "true" dateEnd and dateStart can equal if "false" dateEnd and dateStart can't equal

    if (enableBeforeCurrent) {
      if (current && dateEnd) {
        return isEqual
          ? current > moment(dateEnd).startOf('day').add('day', 1)
          : current > moment(dateEnd).startOf('day')
      } else {
        return isEqual
          ? current < moment(dateStart).startOf('day')
          : current < moment(dateStart).startOf('day').add('day', 1)
      }
    } else {
      if (current && dateStart) {
        return current < moment(dateStart).startOf('day').add('day', 1)
      } else {
        return current && current < moment().startOf('day')
      }
    }
  }

  const disabledDateFuture = current => {
    return current && current > moment().startOf('day').add('day', 1)
  }

  const onChangeDate = (e, dateString) => {
    onChange && onChange(e, dateString)
    setFieldValue(name, e)
  }

  return (
    <DatePickerAntd
      name={name}
      onBlur={() => {
        setFieldTouched(name)
      }}
      placeholder={placeholder}
      onChange={onChangeDate}
      value={newValue ? moment(newValue) : value !== null ? moment(value) : null}
      allowClear={false}
      bordered={false}
      disabled={disabled}
      format={!showTime ? 'DD/MM/YYYY' : 'DD/MM/YYYY, HH:mm'}
      disabledDate={isDisabledDate ? disabledDate : futureDisable && disabledDateFuture}
      showTime={showTime}
      getPopupContainer={triggerNode => triggerNode.parentNode}
    />
  )
}

const DatePicker = props => {
  const { label, name, errors, touched, required } = props

  return (
    <DP.DatePickerContainer data-testid="test-datepicker">
      <label htmlFor={name}>
        {label}
        {required && '*'}
      </label>
      <div className="input">
        <DatePickerComponent {...props} />

        {errors[name] && touched[name] ? <Message data-testid="test-checkbox-error">{errors[name]}</Message> : null}
      </div>
    </DP.DatePickerContainer>
  )
}

DatePickerComponent.defaultProps = {
  placeholder: 'Input Date',
  showTime: false,
}

export default DatePicker
