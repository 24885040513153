import styled, { createGlobalStyle } from 'styled-components'
import { Button, Space, Modal } from 'antd'

export const BoxHeaderNoti = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 8px;
  height: 40px;
`

export const BoxTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  /* padding-bottom: 10px; */
  /* padding-top: 22px; */

  .ant-btn-primary {
    border-color: #fc6521;
    background-color: #fc6521;
    height: 40px;
    width: 150px;
    border-radius: 12px;
  }
`

export const BoxReport = styled.div`
  background: #ffffff;
  border-radius: 16px;
  padding: 10px 24px;
  font-family: 'Prompt', sans-serif;
`

export const BoxReportTotalUser = styled.div`
  height: 56px;
  border-radius: 12px;
  background: rgba(63, 84, 209, 0.03);
  padding: 16px;
  margin-bottom: 16px;

  .total-user-left {
    //font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #19263f;
    display: flex;
    gap: 8px;
    align-items: center;

    //.total-user-left-sub {
    //  font-weight: 400;
    //}
  }

  .total-user-right {
    font-weight: 600;
    font-size: 24px;
    color: #19263f;
    line-height: 30px;
    text-align: right;
  }
`

export const BoxIcon = styled.div`
  display: flex !important;
  align-items: center !important;
  padding-right: 40px;

  .ant-btn-circle {
    height: 100%;
    display: flex;
    align-items: center;
    border-color: transparent;
    background-color: transparent;
    justify-content: center;
    box-shadow: none !important;
    color: transparent !important;
    min-width: 16px;
    width: 25px;
    /* padding: 0px !important; */

    .ant-btn:focus,
    .ant-btn:hover {
      border-color: transparent;
      background-color: transparent;
      box-shadow: none !important;
      color: transparent !important;
    }
  }
`

export const NotiStyle = createGlobalStyle`
  body {
    .ant-notification-notice {
      background-color: #DFF6F5;
      border-radius: 12px;
      height: 64px;
      min-width: 291px;
      width: auto;
      align-items: center;
      justify-content: center;
    }

    .ant-notification-top {
      margin-left: 60px;
    }

    .ant-notification-notice-content {
      justify-content: center;
      align-items: center;
    }

    .with-undo .ant-notification-notice-message {
      padding-top: 5px;
    }

    .ant-notification-notice-message {
      font-family: 'Prompt', sans-serif;
      font-style: normal;
      font-size: 16px !important;
      font-weight: 500;
      line-height: 18px;
      color: #001F34;
      padding-top: 8px;

      a {
        line-height: 18px;
      }

      img {
        padding-left: 16px;
        padding-right: 11px;
      }
    }

    .ant-notification-notice-close {
      padding-top: 5px;
    }
  }
`

export const NotiStyle2 = createGlobalStyle`
  body {
    .ant-notification-notice {
      background-color: #DFF6F5;
      border-radius: 12px;
      height: 64px;
      min-width: 291px;
      width: auto;
      align-items: center;
      justify-content: center;
    }

    .ant-notification-top {
      margin-left: 60px;
    }

    .ant-notification-notice-content {
      justify-content: center;
      align-items: center;
    }

    .with-undo .ant-notification-notice-message {
      padding-top: 5px;
    }

    .ant-notification-notice-message {
      font-family: 'Prompt', sans-serif;
      font-style: normal;
      font-size: 16px !important;
      font-weight: 500;
      line-height: 18px;
      color: #001F34;
      padding-top: 8px;

      a {
        line-height: 18px;
      }

      img {
        padding-left: 16px;
        padding-right: 11px;
      }
    }

    .ant-notification-notice-close {
      padding-top: 5px;
    }
  }
`

export const CustomModal = styled(Modal)`
  font-family: 'Prompt', sans-serif;

  .ant-modal-content {
    border-radius: 16px;
    width: 360px;
    height: 226px;
    margin: 0 auto;
  }

  .ant-modal-header {
    border-radius: 16px;
    color: white;
    border: 0;
  }

  .ant-modal-body {
    text-align: center;
    padding-bottom: 20px;
  }

  .ant-modal-footer {
    border: 0;
    text-align: center;

    .ant-btn-default {
      border-radius: 12px;
      border-color: #fc6521;
      color: #fc6521;
      font-size: 16px;
      width: 87px;
      height: 40px;
      font-weight: 500;
    }

    .ant-btn-primary {
      border-radius: 12px;
      background-color: #fc6521;
      border-color: #fc6521;
      font-size: 16px;
      width: 87px;
      height: 40px;
      font-weight: 500;
    }
  }
`

export const CustomModal2 = styled(Modal)`
  font-family: 'Prompt', sans-serif;

  .ant-modal-content {
    border-radius: 16px;
    width: 360px;
    height: 226px;
    margin: 0 auto;
  }

  .ant-modal-header {
    border-radius: 16px;
    color: white;
    border: 0;
  }

  .ant-modal-body {
    text-align: center;
    padding-bottom: 0px;
  }

  .ant-modal-footer {
    border: 0;
    text-align: center;
    padding-bottom: 0px;

    .ant-btn-default {
      border-radius: 12px;
      border-color: #fc6521;
      color: #fc6521;
      font-size: 16px;
      width: 87px;
      height: 40px;
      font-weight: 500;
    }

    .ant-btn-primary {
      border-radius: 12px;
      background-color: #fc6521;
      border-color: #fc6521;
      font-size: 16px;
      width: 87px;
      height: 40px;
      font-weight: 500;
    }
  }
`

export const CustomModal3 = styled(Modal)`
  font-family: 'Prompt', sans-serif;

  .ant-modal-content {
    border-radius: 16px;
    width: 360px;
    height: 226px;
    margin: 0 auto;
  }

  .ant-modal-header {
    border-radius: 16px;
    color: white;
    border: 0;
  }

  .ant-modal-body {
    text-align: center;
    padding-bottom: 0px;
  }

  .ant-modal-footer {
    border: 0;
    text-align: center;
    padding-bottom: 0px;

    .ant-btn-default {
      border-radius: 12px;
      border-color: #fc6521;
      color: #fc6521;
      font-size: 16px;
      width: 87px;
      height: 40px;
      font-weight: 500;
    }

    .ant-btn-primary {
      border-radius: 12px;
      background-color: #fc6521;
      border-color: #fc6521;
      font-size: 16px;
      width: 87px;
      height: 40px;
      font-weight: 500;
      margin-top: 20px;
    }
  }
`

export const BoxNoData = styled.div`
  min-height: 240px;
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  border-radius: 20px;

  p {
    font-weight: 400;
    font-size: 16px;
    color: #597081;
  }
`

export const StyleDropdown = styled.div`
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 16px;
    border: 1px solid #e2e5f8 !important;
    height: 40px;
    width: 100%;
    /* display: flex; */
  }
`

export const TitleReport = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #19263f;
  height: 40px;
  padding-top: 15px;
`
export const TitleChart = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #001f34;
  padding-bottom: 16px;
  padding-left: 16px;
`

export const StyleDatePicker = styled.div`
  .ant-picker {
    height: 40px !important;
  }
`

export const WrapDropdownNoti = styled.div`
  border-radius: 24px;
  padding: 24px;
  width: 408px;
  height: auto;
  max-height: 776px;
  background-color: #ffffff;
  box-shadow: 0px 4px 30px 0px rgba(46, 60, 79, 0.08);
  position: absolute;
  right: 4px;
  overflow: scroll;
`
