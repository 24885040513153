import * as Yup from 'yup'

export const defaultValues = {
  name: '',
  mobile_number: '',
  address: '',
  group_id: '',
  // province: '19',
  // district: '215',
  // subdistrict: '300101',
  province_id: '',
  district_id: '',
  sub_district_id: '',
  postcode: '',
}

export const Schema = Yup.object().shape({
  name: Yup.string()
    .required('')
    .matches(/^[A-Za-zก-๏\s]+$/, 'กรุณาระบุเป็นตัวอักษรเท่านั้น'),
  mobile_number: Yup.string()
    .required('')
    // .matches(/^[0-9]*$/g, 'กรุณากรอกหมายเลขโทรศัพท์')
    .min(12, ''),
  // .max(12, 'รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง'),
  address: Yup.string().required(''),
  group_id: Yup.string().required(''),
  province_id: Yup.string().required(''),
  district_id: Yup.string().required(''),
  sub_district_id: Yup.string().required(''),
})

export const mockTypeUser = [
  { value: 'ประชาชนทั่วไป', label: 'ประชาชนทั่วไป' },
  { value: 'เจ้าหน้าที่เทศบาลฯ', label: 'เจ้าหน้าที่เทศบาลฯ' },
  { value: 'อสม.', label: 'อสม.' },
  { value: 'เจ้าหน้าที่ภายนอกหน่วยงาน', label: 'เจ้าหน้าที่ภายนอกหน่วยงาน' },
  { value: 'ผู้บริหารเทศบาล', label: 'ผู้บริหารเทศบาล' },
  { value: 'ผู้บริหารระดับสูง', label: 'ผู้บริหารระดับสูง' },
  { value: 'เจ้าหน้าที่สื่อมวลชน', label: 'เจ้าหน้าที่สื่อมวลชน' },
]

export const mockDataDistrict = [
  { value: 'อำเภอเมืองนครราชสีมา', label: 'อำเภอเมืองนครราชสีมา' },
  { value: 'อำเภอขามทะเลสอ', label: 'อำเภอขามทะเลสอ' },
  { value: 'อำเภอโนนไทย', label: 'อำเภอโนนไทย' },
  { value: 'อำเภอโชคชัย', label: 'อำเภอโชคชัย' },
  { value: 'อำเภอเฉลิมพระเกียรติ', label: 'อำเภอเฉลิมพระเกียรติ' },
  { value: 'อำเภอโนนแดง', label: 'อำเภอโนนแดง' },
  { value: 'อำเภอปักธงชัย', label: 'อำเภอปักธงชัย' },
]

export const mockDataSubdistrict = [
  { value: 'ตำบลในเมือง', label: 'ตำบลในเมือง' },
  { value: 'ตำบลโพธิ์กลาง', label: 'ตำบลโพธิ์กลาง' },
  { value: 'ตำบลหนองจะบก', label: 'ตำบลหนองจะบก' },
  { value: 'ตำบลโคกสูง', label: 'ตำบลโคกสูง' },
  { value: 'ตำบลมะเริง', label: 'ตำบลมะเริง' },
  { value: 'ตำบลหนองระเวียง', label: 'ตำบลหนองระเวียง' },
]
