import styled from 'styled-components'

export const StyleTreeSelect = styled.div`
  .ant-select-selection-overflow {
    .ant-select-selection-overflow-item:nth-last-child(2) {
      span div p {
        display: none;
      }
    }
  }

  .ant-select-selection-search-input {
    font-family: Prompt !important;
  }

  .ant-select-selector {
    font-weight: 400;
    font-size: 18px;
    border: 1px solid #dde0df !important;
    min-height: 52px !important;
    max-height: 52px !important;
    overflow: hidden !important;

    &:hover {
      border: 1px solid #5d38fa !important;
    }

    span {
      font-weight: 400;
      font-size: 18px;
      color: #b1b1b1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .ant-select-multiple .ant-select-selection-placeholder {
    padding-left: 8px;
  }

  .ant-select-arrow {
    color: #1d1d42 !important;
  }

  .ant-select-selection-overflow {
    padding-left: 12px;
  }

  .ant-select-dropdown {
    padding: 8px 8px !important;
  }

  .ant-select-selection-overflow-item {
    display: none;
    :first-child {
      display: block;
    }
  }
  .ant-select-selection-overflow-item span {
    font-weight: 400;
    font-size: 18px;
    color: #1d1d42;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 250px;
  }

  .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
    margin-left: -5px;
  }
`
