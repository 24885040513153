import * as R from './radio.style'

import Message from 'components/output/Message'
import React from 'react'
import get from 'lodash.get'

const RadioComponent = props => {
  const {
    name,
    placeholder,
    options,
    form: { setFieldValue, setFieldTouched, values },
    onChange,
    disabled,
  } = props

  const value = get(values, name, '')
  // console.log('options', options)
  // console.log('name', name)

  return (
    <R.RadioGroup
      name={name}
      onChange={e => {
        onChange && onChange(name, e.target.value)
        setFieldValue(name, e.target.value)
      }}
      onBlur={() => {
        setFieldTouched(name)
      }}
      placeholder={placeholder}
      // defaultValue={value.length > 0 && value}
      value={value.length > 0 && value}
      disabled={disabled}
    >
      {options.map((option, index) => (
        <div className="layout-radio" key={index}>
          <h2>{option.name}</h2>
          {/* <hr style={{ marginTop: '10px' }} /> */}
          {option[name].map((radio, index) => (
            <R.Radio key={'Radio' + index} value={radio.value}>
              {radio.label}
            </R.Radio>
          ))}
        </div>
      ))}
    </R.RadioGroup>
  )
}

const Radio = props => {
  const { label, name, errors, touched: touches, required } = props

  const error = get(errors, name, '')
  const touched = get(touches, name, '')

  return (
    <R.RadioContainer data-testid={`test-radio`}>
      <label htmlFor={name}>
        {label}
        {required && '*'}
      </label>
      <R.Input className="input">
        <RadioComponent {...props} />
        {error || touched ? <Message data-testid={`test-radio-error`}>{error}</Message> : null}
      </R.Input>
    </R.RadioContainer>
  )
}

RadioComponent.defaultProps = {
  placeholder: 'Select options',
  required: false,
}

export default Radio
