import styled from 'styled-components'

export const Container = styled.div`
  /* width: 100%; */
  width: 384px;
  /* max-width: 384px; */
  background-color: ${props => (props.isRead === true ? '#ffffff' : '#edf6fa')};
  height: 132px;
  padding: 24px;
  border-radius: 24px;
  cursor: pointer;
  font-family: 'Prompt' !important;
  margin-bottom: 0.2rem;

  &:hover {
    background-color: #fff8f4;
  }

  .title-complaint {
    color: #150f0b;
    font-weight: 300;
    font-size: 14px;
  }

  .new-status {
    font-weight: 400;
    font-size: 14px;
    color: #150f0b;
    margin-left: 4px;
  }

  .wrap-noti-box {
    display: flex;
  }
`

export const WrapBoxNoti = styled.div`
  display: flex;
`

export const BoxIcon = styled.div`
  width: 10%;
`

export const BoxContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const BoxTitle = styled.div`
  display: flex;
`

export const BoxStatusText = styled.div`
  width: auto;
  height: 20px;
  padding: 5.5px 8px;
  border-radius: 4px;
  font-family: 'Prompt';
  font-weight: 400;
  font-size: 12px;
  background-color: ${props => {
    switch (props.status) {
      case 'received':
        return '#EFE9FE'
      case 'in progress':
        return '#FDF5DD'
      case 'done':
        return '#DFF6F5'
      case 'outside the area':
        return '#E2E5F8'
      case 'tracking':
        return '#FFE6DB'
      case 'over duedate':
        return '#FFE4E4'
      case 'transfer':
        return '#EAEAEB'
      default:
        return 'transparent'
    }
  }};
  color: ${props => {
    switch (props.status) {
      case 'received':
        return '#915EFF'
      case 'in progress':
        return '#FFBB0B'
      case 'done':
        return '#13AFA6'
      case 'outside the area':
        return '#3F54D1'
      case 'tracking':
        return '#F36B24'
      case 'over duedate':
        return '#F85858'
      case 'transfer':
        return '#717171'
      default:
        return 'inherit'
    }
  }};
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const BoxStatus = styled.div`
  width: auto;
  display: flex;
  margin: 6px 0px 16px 0px;
`

export const BoxCategory = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 0px;
`

export const BoxNumber = styled.div`
  display: flex;
  width: 100%;

  .text {
    color: #150f0b;
    font-weight: 300;
    font-size: 12px;
    margin-left: 8px;
  }
`
