import React from 'react'
import get from 'lodash.get'

import Message from 'components/output/Message'

import * as C from './checkbox.style'

const CheckboxComponent = props => {
  const {
    name,
    placeholder,
    options,
    form: { setFieldValue, setFieldTouched, values },
    onChange,
    disabled,
  } = props

  const value = get(values, name, '')

  return (
    <C.CheckboxGroup
      name={name}
      onChange={e => {
        onChange && onChange(name, e)
        setFieldValue(name, e)
      }}
      onBlur={() => {
        setFieldTouched(name)
      }}
      placeholder={placeholder}
      defaultValue={value.length > 0 && value}
      value={value.length > 0 && value}
      disabled={disabled}
    >
      {options.map((option, index) => (
        <div className="layout-checkbox" key={index}>
          <h2>{option.name}</h2>
          <hr />
          {option[name].map((checkbox, index) => (
            <C.Checkbox key={index} value={checkbox.value}>
              {checkbox.label}
            </C.Checkbox>
          ))}
        </div>
      ))}
    </C.CheckboxGroup>
  )
}

const Checkbox = props => {
  const { label, name, errors, touched: touches, required } = props

  const error = get(errors, name, '')
  const touched = get(touches, name, '')

  return (
    <C.CheckboxContainer data-testid={`test-checkbox`}>
      <label htmlFor={name}>
        {label}
        {required && '*'}
      </label>
      <C.Input className="input">
        <CheckboxComponent {...props} />
        {error && touched ? (
          <Message data-testid={`test-checkbox-error`}>{error}</Message>
        ) : null}
      </C.Input>
    </C.CheckboxContainer>
  )
}

CheckboxComponent.defaultProps = {
  placeholder: 'Select options',
  required: false,
}

export default Checkbox
