import styled from 'styled-components'

export const InputContainer = styled.div`
  font-family: 'Prompt';
  /* padding-bottom: 16px; */
  /* padding-bottom: 24px; */
  min-height: 91px;

  @media only screen and (max-width: 768px) {
    min-height: 107px;
  }

  @media only screen and (max-width: 375px) {
    min-height: 107px;
  }

  label {
    font-family: 'Prompt';
    padding-bottom: 8px;
    font-weight: 400;
    font-size: 14px;
    color: #150f0b;
  }

  .ant-input {
    border: 1px solid #fee0d3;
    border-radius: 12px;
    height: 40px;
    /* max-width: 824px; */
    width: 100%;
    color: #150f0b;
    :focus {
      /* box-shadow: 0 0 0 1px rgb(24 144 255 / 20%); */
      box-shadow: none;
      border: 1px solid #fc6521;
      color: #fc6521;
    }

    @media only screen and (max-width: 768px) {
      height: 56px;
      /* font-size: 16px; */
    }

    @media only screen and (max-width: 375px) {
      height: 56px;
      /* font-size: 16px; */
    }
  }
  .ant-input-affix-wrapper {
    border: 1px solid #fee0d3;
    border-radius: 12px;
    height: 48px;
    color: #fc6521;
    width: 100%;
    box-shadow: none !important;

    .ant-input {
      border-right: none;
      border-left: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      background: transparent !important;
      border-color: transparent;
    }
    :focus {
      box-shadow: 0 0 0 1px rgb(24 144 255 / 20%);
      border: 1px solid #fc6521;
    }
  }

  .ant-input-affix-wrapper:focus .ant-input {
    //box-shadow: 0 0 0 1px rgb(24 144 255 / 20%);
    border: 1px solid #fc6521;

    border-right: none;
    border-left: none;
  }
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #fc6521;
    //box-shadow: none;
  }
  /* .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-focused):hover {
    border: 1px solid #FEE0D3;
  } */
  .ant-input-affix-wrapper-focused {
    border-color: #fc6521;
    box-shadow: 0 0 0 1px rgb(24 144 255 / 20%);
  }
  .ant-input[disabled],
  .ant-input-affix-wrapper-disabled {
    background-color: #fff3ee;
    border-color: #fff3ee !important;
    color: #ffc4aa;
  }

  .ant-input::placeholder {
    color: #ffc4aa !important;
  }
`
