import CheckboxANT from 'antd/lib/checkbox'
import styled from 'styled-components'

export const CheckboxContainer = styled.div`
  padding: 10px 0;
`

export const CheckboxGroupANT = CheckboxANT.Group

export const Input = styled.div`
  .layout-checkbox {
    margin-bottom: 20px;
    h2 {
      margin-bottom: 10px;
    }
    .ant-checkbox-wrapper {
      margin-left: 8px;
    }
    &::last-child {
      margin-bottom: 0px;
    }
  }
`

export const CheckboxGroup = styled(CheckboxGroupANT)`
  label {
    width: auto !important;
  }
`

export const Checkbox = styled(CheckboxANT)`
  /* label {
    width: auto !important;
  } */
`
