import styled, { createGlobalStyle } from 'styled-components'
import { Divider, Modal, Button } from 'antd'

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  /* min-height: 720px; */

  background-image: ${props => (props.url ? `url(${props.url})` : '#FCFCFC')};
  /* background-position: center center; */
  /* background-repeat: no-repeat; */
  /* background-size: cover; */
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .ant-tooltip-disabled-compatible-wrapper {
    width: 100%;
  }

  @media only screen and (min-width: 768px) {
    width: 100%;
  }

  /* CSS สำหรับหน้าจอขนาด 375px หรือมากกว่านั้น */
  @media only screen and (min-width: 375px) {
    width: 100%;
  }
`

export const BoxVersion = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
`

export const BoxImage = styled.div`
  width: 50%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;

  /* background-color: rgb(246, 246, 246);
  background-image: ${props => (props.url ? `url(${props.url})` : '#FCFCFC')};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover; */

  .bg-touch {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }

  .text-touch {
    position: absolute;
    right: 26px;
    bottom: 26px;
    min-width: 232px;
  }

  .line-touch {
    position: absolute;
    left: 0;
    bottom: 58px;
    min-width: 434px;
  }

  .star-touch {
    position: absolute;
    top: 130px;
    left: 15px;
    width: 100%;
    height: auto;
    max-width: 551px;

    @media only screen and (min-width: 1920px) {
      max-width: 720px;
    }
  }
`

export const BoxFormLogin = styled.div`
  width: 50%;
  height: 100%;
  padding: 80px 120px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .logo-full {
    width: 100%;
    height: auto;
    max-width: 277px;
  }

  .title-account {
    font-family: 'Prompt';
    margin: 36px 0;

    span:first-child {
      font-weight: 400;
      font-size: 16px;
      color: #597081;
    }
    span:last-child {
      margin-left: 8px;
      font-weight: 600;
      font-size: 24px;
      color: #001f34;
    }
  }

  .box-logo {
    display: flex;
    justify-content: center;
    margin: auto;
    margin-top: 16.94vh;
    img {
      width: 8.65vw;
    }
  }

  .box-form {
    .ant-form-item-explain {
      display: flex;
      justify-content: end;
      position: absolute;
      line-height: 32px;
    }

    .ant-input {
      caret-color: #3f54d1;
      height: 48px;
      background: #ffffff;
      color: #262424 !important;
      border-radius: 16px;
      padding: 4px 22px !important;
      border-color: #e2e5f8;

      &:hover,
      :focus {
        border-color: #3f54d1;
        box-shadow: none !important;
      }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
      }
    }

    .ant-input::placeholder {
      color: #597081 !important;
      font-family: 'Prompt';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
    }

    .ant-input.ant-input-status-error {
      background: #fff6f8 !important;

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px #fff6f8 inset !important;
      }
    }

    .select-login .ant-input-affix-wrapper {
      display: flex;
      flex-direction: row-reverse;

      .ant-input-prefix {
        margin: 0;
      }
    }

    /* .select-login {
      .ant-select-dropdown {
        padding: 15px 4px;
      }
      .rc-virtual-list-scrollbar-thumb {
        left: 6px !important;
      }
    } */

    .ant-select-clear {
      width: 21px !important;
      height: 21px !important;
      padding-right: 0 !important;
    }

    .ant-input-affix-wrapper {
      color: #262424;
      height: 48px;
      border-radius: 16px;
      border-color: #e2e5f8;
      padding: 0 11px 0 0;
      min-width: auto;

      .ant-input-suffix {
        svg {
          width: 21px;
          cursor: pointer;
        }
      }
      .ant-input {
        caret-color: #3f54d1;
        background: #ffffff;
        color: #262424;
        border-radius: 16px;
        border-color: #e2e5f8;
        width: 100%;
        height: 100%;
        background: transparent;
      }

      &:hover,
      :focus {
        border-color: #3f54d1;
        box-shadow: none !important;
      }
    }

    .ant-input-affix-wrapper.ant-input-affix-wrapper-status-error {
      background: #fff6f8 !important;
      border-color: #ed4160 !important;
    }

    .ant-input-affix-wrapper.ant-input-password.ant-input-affix-wrapper-status-error {
      .ant-input:-webkit-autofill,
      .ant-input:-webkit-autofill:hover,
      .ant-input:-webkit-autofill:focus,
      .ant-input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px #fff6f8 inset !important;
      }
    }

    .ant-input-affix-wrapper-focused {
      border-color: #3f54d1;
      box-shadow: none !important;
    }

    .ant-form-item {
      margin-bottom: 16px;
      margin-top: 8px;
    }

    .ant-form-item.password {
      margin-bottom: 60px;
    }
  }
`

export const CustomDivider = styled(Divider)`
  border-top-color: #c4c4c4 !important;
  margin: 33px 0 33px 0 !important;

  .ant-divider-inner-text {
    color: #aba9a8;
    font-family: 'Prompt';
    font-weight: 400;
    font-size: 14px;
  }
`

export const CustomButton = styled(Button)`
  background: #1f3983;
  border-radius: 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: white;
  line-height: 20px;
  padding: 18px 8px;
  width: 100%;
  min-height: 56px;
  border: none;
  cursor: pointer;

  ${props =>
    props.type === 'primary' &&
    `
      background: #3f54d1;

       &:hover,
        :focus {
         background: #3f54d1 !important;
         color: white;
        }
    `}

  &:disabled {
    background-color: #e2e5f8;
    border-color: #e2e5f8;
    color: white;
    cursor: not-allowed;
  }

  &:hover,
  :focus {
    background: #1f3983;
    color: white;
  }
`

export const CustomLabel = styled.label`
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 500;
  font-size: 16px !important;
  color: #001f34;

  span {
    font-weight: 600;
    color: #ed4160;
    margin-left: 4px;
    vertical-align: text-top;
  }
`

export const NotiStyle = createGlobalStyle`
  body {
    .ant-notification-notice {
        background: #E2E5F8;
        box-shadow: 0px 4px 16px rgba(200, 211, 218, 0.3);
        border-radius: 12px;
        width: 309px;
        height: 64px;
        align-items: center;
        justify-content: center;

    }
    .ant-notification-top {
      margin-left: 60px;
    }
    .ant-notification-notice-content {
      justify-content: center;
      align-items: center;
    }
    .ant-notification-notice-message {
    font-family: 'Prompt', sans-serif;
      font-style: normal;
      font-size: 16px !important;
      font-weight: 500;
      line-height: 18px;
      color: #001F34;
      padding-top: 5px;
      display: flex;
    }
    .ant-notification-notice-close {
      padding-top: 8px;
    }
  }
`

export const ModalError = styled(Modal)`
  font-family: 'Prompt', sans-serif;

  .ant-modal-content {
    border-radius: 16px;
    width: 360px;
    height: 226px;
    margin: 0 auto;
  }
  .ant-modal-header {
    border-radius: 16px;
    color: white;
    border: 0;
  }
  .ant-modal-body {
    text-align: center;
    padding-bottom: 0px;
  }
  .ant-modal-footer {
    border: 0;
    text-align: center;
    padding-bottom: 0px;

    .ant-btn-default {
      border-radius: 12px;
      border-color: #fc6521;
      color: #fc6521;
      font-size: 16px;
      width: 87px;
      height: 40px;
      font-weight: 500;
    }
    .ant-btn-primary {
      border-radius: 12px;
      background-color: #fc6521;
      border-color: #fc6521;
      font-size: 16px;
      width: 87px;
      height: 40px;
      font-weight: 500;
      margin-top: 20px;
    }
  }
`

export const ModalFoglogout = styled(Modal)`
  font-family: 'Prompt', sans-serif;
  .ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
    margin-bottom: 0;
    margin-left: 20px !important;
  }

  .ant-modal-content {
    border-radius: 16px;
    width: 364px;
    height: 374px;
    margin: 0 auto;
  }
  .ant-modal-header {
    border-radius: 16px;
    color: white;
    border: 0;
  }
  .ant-modal-body {
    text-align: center;
    padding-bottom: 0px;
  }
  .ant-modal-footer {
    border: 0;
    text-align: center;
    padding-bottom: 0px;

    .ant-btn-default {
      border-radius: 12px;
      border-color: #fc6521;
      color: #fc6521;
      font-size: 16px;
      width: 87px;
      height: 40px;
      font-weight: 500;
    }
    .ant-btn-primary {
      border-radius: 12px;
      background-color: #fc6521;
      border-color: #fc6521;
      font-size: 16px;
      width: 87px;
      height: 40px;
      font-weight: 500;
      margin-top: 20px;
    }
  }
`
export const BtLoginLine = styled(Button)`
  background: #17bf0c;
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: white;
  line-height: 20px;
  padding: 18px 8px;
  width: 340px;
  min-height: 56px;
  border: none;
  cursor: pointer;
  font-family: 'Prompt';
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4em;

  svg {
    margin-right: 8px;
  }

  ${props =>
    props.type === 'primary' &&
    `
      background: #17BF0C;

       &:hover,
        :focus {
         background: #17BF0C !important;
         color: white;
        }
    `}

  &:disabled {
    background-color: #e2e5f8;
    border-color: #e2e5f8;
    color: white;
    cursor: not-allowed;
  }

  &:hover,
  :focus {
    background: #17bf0c;
    color: white;
  }

  @media only screen and (min-width: 768px) {
    font-size: 18px;
  }

  @media only screen and (min-width: 375px) {
    font-size: 16px;
  }
`

export const BtSkip = styled.div`
  font-weight: 500;
  font-size: 18px;
  /* color: ${props => (props.border ? '#aba9a8' : '#150f0b')}; */
  color: #150f0b;
  /* margin-top: 1.5rem; */
  cursor: pointer;
  width: 340px;
  height: 56px;
  border: 1px solid #f36b24;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (min-width: 768px) {
    font-size: 18px;
  }

  @media only screen and (min-width: 375px) {
    font-size: 16px;
  }
`

export const BtLoginEmail = styled(Button)`
  background: #f36b24;
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: white;
  line-height: 20px;
  padding: 18px 8px;
  width: 340px;
  min-height: 56px;
  border: none;
  cursor: pointer;
  font-family: 'Prompt';
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1em;

  svg {
    margin-right: 8px;
  }

  ${props =>
    props.type === 'primary' &&
    `
      background: #f36b24;

       &:hover,
        :focus {
         background: #f36b24 !important;
         color: white;
        }
    `}

  &:disabled {
    background-color: #e2e5f8;
    border-color: #e2e5f8;
    color: white;
    cursor: not-allowed;
  }

  &:hover,
  :focus {
    background: #f36b24;
    color: white;
  }

  @media only screen and (min-width: 768px) {
    font-size: 18px;
  }

  @media only screen and (min-width: 375px) {
    font-size: 16px;
  }
`

export const BoxOther = styled.div`
  @media only screen and (max-width: 769px) {
    width: 50%;
  }

  @media only screen and (max-width: 376px) {
    width: 90%;
  }
`

// export const CustomDivi = styled(Divider)
