import * as React from 'react'
import PulseMarker from 'components/PulseMarker'
import { Marker, InfoWindowF } from '@react-google-maps/api'
import styles from './CurrantLocation.css'
const CurrantLocation = ({ meLocation, onClickMap }) => {
  return (
    <div onClick={() => onClickMap && onClickMap(meLocation, 'clickMelocation')}>
      <Marker position={{ lat: meLocation?.lat, lng: meLocation?.lng }} visible={false}>
        <InfoWindowF
          position={{ lat: meLocation?.lat, lng: meLocation?.lng }}
          options={{
            pixelOffset: new window.google.maps.Size(0, 100),
            zIndex: 1,
          }}
        >
          <PulseMarker />
        </InfoWindowF>
      </Marker>
    </div>
  )
}

export default CurrantLocation
