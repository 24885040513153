import React, { createContext, useState, useContext } from 'react'

const NotificationContext = createContext()

export const NotificationProvider = ({ children }) => {
  const [notiValue, setNotiValue] = useState('')
  const [newMsg, setNewMsg] = useState('')

  const setNotiValueHandler = newValue => {
    setNotiValue(newValue)
  }

  return (
    <NotificationContext.Provider value={{ notiValue, setSharedValue: setNotiValueHandler, setNewMsg, newMsg }}>
      {children}
    </NotificationContext.Provider>
  )
}

export const useNotificationContext = () => {
  return useContext(NotificationContext)
}
