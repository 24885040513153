import * as React from 'react'
import { Button } from 'antd'
import { Content } from './Button.style'

const Buttons = props => {
  return (
    <Content>
      <Button type="primary" icon={props?.iconBtn} onClick={() => props?.handdleFunction()}>
        {props?.textBtn}
      </Button>
    </Content>
  )
}

export default Buttons
