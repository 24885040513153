import { Navigate, Outlet } from 'react-router-dom'
import UserContext from 'core/contexts/userContext'
import { useContext } from 'react'

const ProtectedRoutes = props => {
  const { meData } = useContext(UserContext)
  const access_token = localStorage.getItem('access_token')
  // const guest = localStorage.getItem('guest')
  const guest = sessionStorage.getItem('guest')

  return guest || access_token ? <Outlet /> : <Navigate to="/login" />
  // return access_token ? <Outlet /> : <Navigate to="/login" />
  // return access_token ? <Outlet /> : <Navigate to="/login" />
}

export default ProtectedRoutes
