import * as React from 'react'
import { Modal, Space, Spin } from 'antd'
import styled from 'styled-components'
import { useDropzone } from 'react-dropzone'
import Cropper from 'react-easy-crop'
import {
  CustomModal,
  CustomModal2,
  CustomModal3,
  CustomModal4,
  CustomSlider,
  CustomProgress,
  NotiStyle,
} from './UploadFile.style'
import axios from 'axios'
import { ReactComponent as IvdoPlay } from 'assets/images/svg/vdo-render.svg'
import IconNoti from 'assets/images/svg/icon-noti.svg'
import IconClose from 'assets/images/svg/icon-close.svg'
import IconAlert from 'assets/images/svg/alert.svg'
import AlertError from 'assets/images/png/errorFoglogOut.png'
import CloseError from 'assets/images/png/Group 48095820.png'
import UploadImageError from 'assets/images/png/uploadImageError.png'
import UploadPDFSuccess from 'assets/images/png/upload-pdf-red.png'
import IconDoc from 'assets/images/svg/icon-doc.svg'
import IconImg from 'assets/images/svg/icon-upload-img.svg'

import { notification } from 'antd'
import Plyr from 'plyr-react'
// import "plyr-react/plyr.css"
import './plyr.css'
import { useEffect } from 'react'
// import { NotiStyle2 } from '../../../pages/AnnouncementAndAlert/Announcement/list/list.style'
import { getTokenStorage } from '../../../core/action/collection'

const CROP_AREA_ASPECT = 1440 / 848

const generateVideoThumbnail = file => {
  return new Promise(resolve => {
    const canvas = document.createElement('canvas')
    const video = document.createElement('video')

    // this is important
    video.autoplay = true
    video.muted = true
    video.src = URL.createObjectURL(file)

    video.onloadeddata = () => {
      let ctx = canvas.getContext('2d')

      canvas.width = video.videoWidth
      canvas.height = video.videoHeight

      ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight)
      video.pause()
      return resolve(canvas.toDataURL('image/png'))
    }
  })
}

const getBase64 = file => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.onerror = error => {
      console.log('Error: ', error)
      resolve(false)
    }
  })
}

const createImage = url =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', error => reject(error))
    image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
    image.src = url
  })

const getRadianAngle = degreeValue => {
  return (degreeValue * Math.PI) / 180
}

const makeid = length => {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  let counter = 0
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
    counter += 1
  }
  return result
}

const rotateSize = (width, height, rotation) => {
  const rotRad = getRadianAngle(rotation)

  return {
    width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  }
}

const getCroppedImg = async (
  imageSrc,
  pixelCrop,
  rotation = 0,
  filename,
  filetype,
  flip = { horizontal: false, vertical: false }
) => {
  const image = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  if (!ctx) {
    return null
  }

  const rotRad = getRadianAngle(rotation)
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(image.width, image.height, rotation)
  canvas.width = bBoxWidth
  canvas.height = bBoxHeight
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
  ctx.rotate(rotRad)
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
  ctx.translate(-image.width / 2, -image.height / 2)
  ctx.drawImage(image, 0, 0)

  const data = ctx.getImageData(pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height)
  canvas.width = pixelCrop.width
  canvas.height = pixelCrop.height
  ctx.putImageData(data, 0, 0)
  return new Promise((resolve, reject) => {
    canvas.toBlob(blob => {
      let file = new File([blob], filename, { type: filetype })
      resolve(file)
    }, filetype)
  })
}

const UploadFile = props => {
  let listFiles_ = props.files || []

  const [crop, setCrop] = React.useState({ x: 0, y: 0 })
  const [zoom, setZoom] = React.useState(1)
  const [croppedArea, setCroppedArea] = React.useState(null)
  const [croppedAreaPixels, setCroppedAreaPixels] = React.useState(null)

  const [myImage, setMyImage] = React.useState(false)
  const onCropComplete = React.useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedArea(croppedArea)
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])
  const [api, contextHolder] = notification.useNotification()

  const [open, setOpen] = React.useState(false)
  const [acceptedFileTemp, setAcceptedFileTemp] = React.useState(false)
  const [itemLoading, setItemLoading] = React.useState([])
  const [previewVdo, setPreviewVdo] = React.useState(null)
  const [previewImg, setPreviewImg] = React.useState(null)
  const [isLoadingUpload, setisLoadingUpload] = React.useState(true)

  const removeImage = (item, index) => {
    if (!item?.urlkey) {
      props.onFileRemove(props.files || [], { item, index })
    } else {
      props.abortController?.abort()
      props.onFileRemove(props.files || [], { item, index })
      const schema = process.env.REACT_APP_MEDIA + '/medias'
      const datastruct = {
        status: 'pending',
        url: item?.urlkey,
      }
      axios
        .put(schema, datastruct)
        .then(res => {})
        .catch(err => {
          console.log('err', err)
        })
    }
  }

  const uploadFile = (file, base64, fileVdo = null, baseFile) => {
    let isSend = false
    let keyId = makeid(10)
    let data = new FormData()

    let __itemLoading = [{ keyId: keyId, name: file.name, percent: 0, status: 'loadding' }]
    if (props.typeUpload === 'image') {
      __itemLoading = [{ keyId: keyId, name: file.name, img: base64, percent: 0, status: 'loadding' }]
    }
    if (props.typeUpload === 'imagevdo') {
      __itemLoading = [{ keyId: keyId, name: file.name, img: base64, percent: 0, status: 'loadding' }]
    }
    setItemLoading(__itemLoading)

    if (!props.schemaMedia) {
      data.append('file[]', file)
      // data.append('status', 'active')
      data.append('status', 'padding')
    } else {
      data.append('file', file)
    }
    setisLoadingUpload(true)
    let config = {
      onUploadProgress: progressEvent => {
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        let _itemLoading = [...__itemLoading]
        _itemLoading = _itemLoading.map(r => {
          if (r.keyId === keyId) {
            r.percent = percentCompleted
          }
          return r
        })
        setItemLoading(_itemLoading)
      },
      headers: {
        Authorization: `Bearer ${getTokenStorage()}`,
      },
      signal: props.abortController?.signal,
    }

    let keyTypeFile = ''
    let textType = ''
    if (props.typeUpload === 'image') {
      keyTypeFile = 'img'
      textType = 'รูป'
    } else if (props.typeUpload === 'vdo') {
      keyTypeFile = 'vdo'
      textType = 'วิดีโอ'
    } else if (props.typeUpload === 'imagevdo') {
      if (fileVdo == null) {
        keyTypeFile = 'img'
        textType = 'รูปภาพวิดีโอ'
      } else {
        keyTypeFile = 'vdo'
        textType = 'วิดีโอ'
      }
    } else if (props.typeUpload === 'doc') {
      keyTypeFile = 'url'
      textType = 'เอกสารประกอบ'
    }

    let sizeInMB = file.size ? (file.size / (1024 * 1024)).toFixed(0) : 0
    let resItem = {
      name: file.name,
      status: 'error',
      size: sizeInMB,
      msg: `อัปโหลดไฟล์ไม่สำเร็จ เนื่องจากขนาด${textType}ไม่ถูกต้อง หรือนามสกุลไฟล์ไม่ถูกต้อง`,
    }
    resItem[keyTypeFile] = base64

    if (fileVdo) {
      resItem['img'] = fileVdo
    }

    let schMedia = !props.schemaMedia ? process.env.REACT_APP_MEDIA + '/medias' : props.schemaMedia
    // let schMedia = !props.schemaMedia ? "https://gateway.tgh-platform.com/sit/phk/homecare/media/api/v1/medias" : props.schemaMedia
    let resItemError = {
      name: file?.name || '',
      status: 'error',
      msg: `อัปโหลดไฟล์ไม่สำเร็จ เนื่องจากขนาด${textType}ไม่ถูกต้อง หรือนามสกุลไฟล์ไม่ถูกต้อง`,
    }

    const fnGetSize = async url => {
      return fetch(url)
        .then(resp => resp.blob())
        .then(blob => {
          const calculate = (blob.size / (1024 * 1024)).toFixed(0)
          return calculate
        })
    }

    axios
      .post(schMedia, data, config)
      .then(res => {
        let _itemLoading = [...__itemLoading]
        _itemLoading = _itemLoading.map(r => {
          if (r.keyId === keyId) {
            r.percent = 100
          }
          return r
        })
        let Location = res.data && res.data.data.link[0] ? res.data.data.link[0] : null
        setItemLoading(_itemLoading)
        setTimeout(async () => {
          setItemLoading([])
          if (Location) {
            const sizeImageLink = await fnGetSize(res.data.data.link[0])
            resItem.status = 'success'
            resItem.size = sizeImageLink

            resItem[keyTypeFile] = Location
            resItem.urlkey = res.data.data.url[0]

            let propsFiles = props.files || []
            propsFiles = propsFiles.filter(r => r.status === 'success')
            props.onFileRetuen(propsFiles, resItem)
            baseFile && props.onBaseRetuen && props.onBaseRetuen(baseFile || [])

            isSend = true
          } else {
            if (props?.isValidate) resItem.status = 'success'
            let propsFiles = props.files || []
            props.onFileRetuen(propsFiles, resItem)
            baseFile && props.onBaseRetuen && props.onBaseRetuen(baseFile || [])

            isSend = true
          }
          setisLoadingUpload(false)
        }, 200)
      })
      .catch(err => {
        console.log('err', err)
        setisLoadingUpload(false)
        if (err) {
          if (err?.response?.data?.code === 422) {
            isSend = false
            props.onFileRetuen(props.files || [], resItemError)
            baseFile && props.onBaseRetuen && props.onBaseRetuen(baseFile || [])
            openNotification('top')
          }
          if (err?.message === 'canceled') {
            removeImage(props.files, 0)
          }
          if (!err?.response?.data?.code) {
            openNotification('top')
          }
        }
        let _itemLoading = [...__itemLoading]
        _itemLoading = _itemLoading.map(r => {
          if (r.keyId === keyId) {
            r.percent = 100
          }
          return r
        })

        setItemLoading(_itemLoading)
        setTimeout(() => {
          setItemLoading([])
          if (!isSend && err?.message !== 'canceled') {
            props.onFileRetuen(props.files || [], resItem)
            baseFile && props.onBaseRetuen && props.onBaseRetuen(baseFile || [])
          }
          isSend = true
        }, 200)
      })
  }

  const onCropOk = async () => {
    const croppedImage = await getCroppedImg(
      myImage,
      croppedAreaPixels,
      0,
      acceptedFileTemp.name,
      acceptedFileTemp.type
    )
    getBase64(croppedImage).then(base64 => {
      uploadFile(croppedImage, base64)
      setOpen(false)
    })
  }

  const onDropRejected = React.useCallback(
    acceptedFiles => {
      if (acceptedFiles.length > 0) {
        let keyTypeFile = ''
        let textType = ''
        if (props.typeUpload === 'image') {
          keyTypeFile = 'img'
          textType = 'รูป'
        } else if (props.typeUpload === 'vdo') {
          keyTypeFile = 'vdo'
          textType = 'วิดีโอ'
        } else if (props.typeUpload === 'imagevdo') {
          keyTypeFile = 'imagevdo'
          textType = 'รูปภาพวิดีโอ'
        } else if (props.typeUpload === 'doc') {
          keyTypeFile = 'url'
          textType = 'เอกสารประกอบ'
        }
        let resItem = {
          name: acceptedFiles[0].file.name,
          status: 'error',
          msg: props?.textError
            ? props?.textError
            : `อัปโหลดไฟล์ไม่สำเร็จ เนื่องจากขนาด${textType}ไม่ถูกต้อง หรือนามสกุลไฟล์ไม่ถูกต้อง`,
        }
        getBase64(acceptedFiles[0].file)
          .then(base64 => {
            resItem[keyTypeFile] = base64
            props.onFileRetuen(props.files || [], resItem)
            props.onBaseRetuen && props.onBaseRetuen(acceptedFiles[0] || [])
          })
          .catch(() => {
            props.onFileRetuen(props.files || [], resItem)
            props.onBaseRetuen && props.onBaseRetuen(acceptedFiles[0] || [])
          })
        // props.onFileRetuen(props.files || [],resItem)
        openNotification('top')
      }
    },
    [props.files]
  )

  const onDropAccepted = React.useCallback(acceptedFiles => {}, [props.files])

  const onError = React.useCallback(acceptedFiles => {}, [props.files])

  const onDrop = React.useCallback(
    acceptedFiles => {
      if (acceptedFiles.length > 0) {
        let keyTypeFile = ''
        let textType = ''
        if (props.typeUpload === 'image') {
          keyTypeFile = 'img'
          textType = 'รูป'
        } else if (props.typeUpload === 'vdo') {
          keyTypeFile = 'vdo'
          textType = 'วิดีโอ'
        } else if (props.typeUpload === 'imagevdo') {
          keyTypeFile = 'imagevdo'
          textType = 'รูปภาพวิดีโอ'
        } else {
          keyTypeFile = 'url'
          textType = 'เอกสารประกอบ'
        }
        let file0 = acceptedFiles[0]
        if (file0.type === 'video/mp4') {
          if (file0.size / (1024 * 1024) > 100) {
            let resItem = {
              name: file0.name,
              status: 'error',
              msg: `อัปโหลดไฟล์ไม่สำเร็จ เนื่องจากขนาดไฟล์วิดิโอไม่ใหญ่เกินไป`,
            }

            getBase64(acceptedFiles[0]).then(base64 => {
              resItem[keyTypeFile] = base64
              props.onFileRetuen(props.files || [], resItem)
              props.onBaseRetuen && props.onBaseRetuen(acceptedFiles[0] || [])
            })
            return
          } else {
            getBase64(acceptedFiles[0]).then(base64 => {
              uploadFile(acceptedFiles[0], base64, null, acceptedFiles[0])
            })
          }
        } else {
          if (props.maxSize) {
            if (file0.size > props.maxSize) {
              let resItem = {
                name: file0.name,
                status: 'error',
                msg: props?.textError ? props?.textError : `อัปโหลดไฟล์ไม่สำเร็จ เนื่องจากขนาดไฟล์รูปภาพใหญ่เกินไป`,
              }

              getBase64(acceptedFiles[0]).then(base64 => {
                resItem[keyTypeFile] = base64
                props.onFileRetuen(props.files || [], resItem)
                props.onBaseRetuen && props.onBaseRetuen(acceptedFiles[0] || [])
              })
              openNotification('top')
              return
            }
          }
          if (props.accept) {
            let list_accept = []
            for (let i in props.accept) {
              list_accept.push(i)
            }
            if (list_accept.indexOf(file0.type) === -1) {
              console.log('error')

              let sizeInMB = file0.size ? (file0.size / (1024 * 1024)).toFixed(0) : 0
              let resItem = {
                name: file0.name,
                status: 'error',
                msg: props?.textError
                  ? props?.textError
                  : `อัปโหลดไฟล์ไม่สำเร็จ เนื่องจากขนาด${textType}ไม่ถูกต้อง หรือนามสกุลไฟล์ไม่ถูกต้อง`,
              }
              getBase64(acceptedFiles[0]).then(base64 => {
                resItem[keyTypeFile] = base64
                props.onFileRetuen(props.files || [], resItem)
                props.onBaseRetuen && props.onBaseRetuen(acceptedFiles[0] || [])
              })
              return
            } else {
              if (textType === 'รูป') {
                if (props.isCrop) {
                  getBase64(acceptedFiles[0]).then(base64 => {
                    setZoom(1)
                    setMyImage(base64)
                    setOpen(true)
                    setAcceptedFileTemp(acceptedFiles[0])
                  })
                } else {
                  getBase64(acceptedFiles[0]).then(base64 => {
                    uploadFile(acceptedFiles[0], base64, null, acceptedFiles[0])
                  })
                }
              }
              if (textType === 'รูปภาพวิดีโอ') {
                if (props.isCrop) {
                  getBase64(acceptedFiles[0]).then(base64 => {
                    setZoom(1)
                    setMyImage(base64)
                    setOpen(true)
                    setAcceptedFileTemp(acceptedFiles[0])
                  })
                } else {
                  getBase64(acceptedFiles[0]).then(base64 => {
                    uploadFile(acceptedFiles[0], base64, null, acceptedFiles[0])
                  })
                  if (acceptedFiles[0].type == 'video/mp4') {
                    getBase64(acceptedFiles[0]).then(async base64 => {
                      let image = null
                      try {
                        image = await generateVideoThumbnail(acceptedFiles[0])
                      } catch (e) {
                        // console.log(e)
                      }
                      uploadFile(acceptedFiles[0], base64, image, acceptedFiles[0])
                    })
                  }
                }
              }
            }
          }
        }
      } else {
      }
    },
    [props.files]
  )

  const openNotification = placement => {
    api.info({
      message: `อัปโหลดไม่สำเร็จ`,
      placement,
      icon: (
        <img
          src={IconAlert}
          alt="icon"
          onError={e => {
            e.currentTarget.src = AlertError
          }}
        />
      ),
      closeIcon: (
        <img
          src={IconClose}
          alt="icon"
          onError={e => {
            e.currentTarget.src = CloseError
          }}
        />
      ),
      duration: 3,
      className: 'error-style',
    })
  }

  const checkImageErrorDisplay = img => {
    const acceptArr = Object.keys(props.accept)
    let valid = false
    for (let i = 0; i < acceptArr.length; i++) {
      if (img.includes(acceptArr[i])) {
        valid = true
      }
    }
    if (valid) {
      return img
    } else {
      return UploadImageError
    }
  }

  let opt = {}
  if (props.accept) {
    opt.accept = props.accept
  }

  const { acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDropAccepted,
    onDropRejected,
    onError,
    ...opt,
  })

  let listFiles = props.files || []
  // if max 1 file must show error 1 file
  if (props.maxFile === 1 && props.files && props.files.length > 1) {
    removeImage(props.files[0], 0)
  }
  // if file overload remove old
  if (props.maxFile === 5 && props.files && props.files.length > 5) {
    for (let i = props.files.length - 1; i >= 0; i--) {
      removeImage(props.files[i - 5], i - 5)
      if (i === props.maxFile) break
    }
  }
  if (itemLoading.length > 0) {
    listFiles = [...listFiles, ...itemLoading]
  }
  listFiles = listFiles.map(r => {
    if (r.img) {
      if (!r.name) {
        let imgsplit = r.img.split('/')
        r.name = imgsplit[imgsplit.length - 1]
      }
    }
    if (r.vdo) {
      if (!r.name) {
        let vdosplit = r.vdo.split('/')
        r.name = vdosplit[vdosplit.length - 1]
      }
    }
    return r
  })

  return (
    <>
      <NotiStyle />
      {contextHolder}
      <Content width={props?.width} top={props?.top}>
        <CustomModal4
          title=""
          centered
          transitionName=""
          closable={false}
          open={previewVdo}
          footer={false}
          onOk={() => {
            setPreviewVdo(null)
          }}
          onCancel={() => {
            setPreviewVdo(null)
          }}
        >
          <ModelContent>
            <div
              className="close-ui"
              onClick={() => {
                setPreviewVdo(null)
              }}
            >
              <img
                alt="close icon"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEBSURBVHgBlVQxDoJAELxF7X0CtYWGzkp9ggn26hN8ifoCoVaMT0ALW4QPgD+wJ2G9RUE4jgO3IJvNzLCZuTvGeM3mXn8893TWsopYoM/U9EPe6chgdzsNNyry1AwODHDF8RHEiQH87zp2tTBHIFhXZ7hWkr8VJz1dcy9GBMjsHMUBKbCBzDn7+3nwhGwwM30LgS1lm0jItuuMVrkHKpFsKxm5IiAVKZRIptJEEAEQk30bslTgg+6gOEKSkEHFgWhYWaUaMajIWbx16ZQEVFGpIk49mCyCrSoq6usOW+Uo17kt2wQgMWiDF7c4aiJnm+QR88vkHo3HT/2P60xYegKofwNsnaO0YMOweAAAAABJRU5ErkJggg=="
              />
            </div>
            {previewVdo ? (
              <div className="vdo-content">
                <Plyr
                  source={{
                    type: 'video',
                    sources: [
                      {
                        src: previewVdo,
                        type: 'video/mp4',
                      },
                      // {
                      //   src: "yWtFb9LJs3o",
                      //   provider: "youtube"
                      // }
                    ],
                  }}
                />
                {/**
            <video controls>
              <source src={previewVdo}  type="video/mp4"/>
              Your browser does not support the video tag.
            </video> **/}
              </div>
            ) : null}
          </ModelContent>
        </CustomModal4>

        <CustomModal4
          title=""
          centered
          transitionName=""
          closable={false}
          open={previewImg}
          footer={false}
          onOk={() => {
            setPreviewImg(null)
          }}
          onCancel={() => {
            setPreviewImg(null)
          }}
        >
          <ModelContent>
            <div
              className="close-ui"
              onClick={() => {
                setPreviewImg(null)
              }}
            >
              <img
                alt="close icon"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEBSURBVHgBlVQxDoJAELxF7X0CtYWGzkp9ggn26hN8ifoCoVaMT0ALW4QPgD+wJ2G9RUE4jgO3IJvNzLCZuTvGeM3mXn8893TWsopYoM/U9EPe6chgdzsNNyry1AwODHDF8RHEiQH87zp2tTBHIFhXZ7hWkr8VJz1dcy9GBMjsHMUBKbCBzDn7+3nwhGwwM30LgS1lm0jItuuMVrkHKpFsKxm5IiAVKZRIptJEEAEQk30bslTgg+6gOEKSkEHFgWhYWaUaMajIWbx16ZQEVFGpIk49mCyCrSoq6usOW+Uo17kt2wQgMWiDF7c4aiJnm+QR88vkHo3HT/2P60xYegKofwNsnaO0YMOweAAAAABJRU5ErkJggg=="
              />
            </div>
            {previewImg ? (
              <div className="vdo-content">
                <img alt="vdo" src={previewImg} />
              </div>
            ) : null}
          </ModelContent>
        </CustomModal4>

        <CustomModal3
          title=""
          centered
          transitionName=""
          closable={false}
          okText="บันทึก"
          cancelText="ยกเลิก"
          open={open}
          onOk={() => {
            onCropOk()
          }}
          onCancel={() => {
            setOpen(false)
          }}
        >
          <ModelContent>
            <div className="model-ui">
              <div className="header-ui">
                {props.titleCrop || 'แก้ไขรูปปก'}
                <span className="header-close-ui" style={{ float: 'right' }}>
                  <img
                    alt="header img"
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAgCAYAAAB3j6rJAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFkSURBVHgB1ZfBcQMhDEUlccje4g68LeSWW5yKkhK2BKeTdBDnljJwB84tk5kVAWLPrL0sRsAB67IMiM/bQSAE0IjhZUe/GVb8q17sUA+GD2T4TX8NGirYVNswfyrg3Un7DKR/HHqD6sM1J90azfhcCrOofTc+6N1woKkzI75eOHoNJ+CEoC6EH+IfvyacgSDQekkrFyYC8W+k1jMQA7yHiKYU5iqEX3T8noGQMVv70VABJgnCrnVcM3BqEgViAZyjgbWESufikmeOYMkPYGSCSNg1SrY0CiKBOblDBkQSiAAGciGSQQphklJEMkgmTHKeEoEIYUTJkqARu72taSJYmzi+TVxoTVzxTSS9Jp4BTTyMakCItQTlhBjCO1qfYxDHfMPlhH3Gr6AChAgmVE7Q8oTsau8aDNnScwYCXbCcKC45IzAaOn53jeCpsXwbJnoiNnvoxq0LJqhgTtvHIar72trV7A/szZA3NJmrvQAAAABJRU5ErkJggg=="
                    style={{ width: 16, height: 16, cursor: 'pointer' }}
                    onClick={() => {
                      setOpen(false)
                    }}
                  />
                </span>
              </div>

              <div className="model-crop">
                <Cropper
                  image={myImage}
                  crop={crop}
                  zoom={zoom}
                  // cropSize={{ width: 1040, height: 1040 }}
                  aspect={!props.cropArea ? CROP_AREA_ASPECT : props.cropArea}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                />
              </div>
              <div className="controls">
                <a
                  className="button-zoom"
                  onClick={() => {
                    if (zoom > 1) {
                      setZoom(zoom - 1)
                    }
                  }}
                >
                  -
                </a>
                <div className="slider">
                  <CustomSlider
                    min={1}
                    max={3}
                    step={0.1}
                    value={zoom}
                    onChange={v => {
                      setZoom(v)
                    }}
                  />
                </div>
                <a
                  className="button-zoom"
                  onClick={() => {
                    if (zoom <= 3) {
                      setZoom(zoom + 0.5)
                    }
                  }}
                >
                  +
                </a>
              </div>
            </div>
          </ModelContent>
        </CustomModal3>
        {!props.maxFile ||
        props.maxFile >
          listFiles.filter(r =>
            !props.showUploaded ? r.status === 'success' : r.status === 'success' || r.status === 'error'
          ).length ? (
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            <div className="upload">
              {props.typeUpload === 'imagevdo' ? <img alt="imagevdo" src={IconImg} className="icon-upload" /> : null}
              {props.typeUpload === 'image' ? <img alt="img" src={IconImg} className="icon-upload" /> : null}
              {props.typeUpload === 'vdo' ? <img alt="vdo" src={IconImg} className="icon-upload" /> : null}
              {props.typeUpload === 'doc' ? <img alt="doc" src={IconDoc} className="icon-upload" /> : null}

              <div className="dest1">
                ลากไฟล์
                {props.typeUpload === 'imagevdo' ? 'รูปภาพวิดีโอ' : null}
                {props.typeUpload === 'image' ? 'รูปภาพ' : null}
                {props.typeUpload === 'vdo' ? 'วิดีโอ' : null}
                {props.typeUpload === 'doc' ? 'เอกสารประกอบ' : null}
                ของคุณมาวางที่นี่ หรือ <span className="underline"> เลือกไฟล์จากอุปกรณ์ </span>
              </div>
              {/* <div className="dest2"> {props.text} </div> */}
              <div className="dest2">
                {props?.textUpload
                  ? props?.textUpload
                  : 'ไฟล์ที่อัปโหลดต้องเป็นไฟล์ .jpeg .jpg .png มีขนาดไม่เกิน 10MB ต่อภาพ และ .mp4 มีขนาดไม่เกิน 100MB ต่อวิดีโอ'}
              </div>
            </div>
          </div>
        ) : null}

        {listFiles.length > 0 ? (
          <>
            <ul className="list-image">
              {listFiles.map((r, i) => {
                return (
                  <div keys={i}>
                    {r.status === 'success' ? (
                      <li className="success">
                        <div className="zone1">
                          {r.vdo ? (
                            r.vdo ? (
                              <div
                                className="show-image-div click"
                                onClick={() => {
                                  setPreviewVdo(r.vdo)
                                }}
                              >
                                {r.img ? (
                                  <img
                                    alt="show img"
                                    className="show-image click"
                                    src={r.img}
                                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                  />
                                ) : (
                                  <img
                                    alt="default img"
                                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAgCAYAAACcuBHKAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHZSURBVHgBvZi/S8NAFMdfiqNKV8EhXRxcDLg4CMZ/QDvqZCqIaxFn2+yCnXWo7kId3Nv+B/0PLkPR0SLuz/fyQ2Loj7zcJR/4EhqS3Pfevbt7VwuEIGKdLh5pj+SS6rGYGWkSa2xZ1huYhBp3SUOUoUh9kg068AdIA9RDkdpQhLj3Cs3BnbElBi6wHFQuI/RQE8tFYZTgCw3YaHYIFjFYZkI3CSV43KaVMcA3+1AdvK40siYUXWyoFr+WMuBKDATTz1AGOK2lfjRBQDD9gOOza3h5fQdNnLSJIxDCkfBufWgcnmhFJW3CgYKwATbi956gCKEJ1N1gYrq9x9CMdIhqYJhkiFqkvENk3ETC7PsH6pvruZ5dA8PY21vQv++Ae7Cf+53QBFVAAeUF6NJpX0H78pwisCF6Lx0JLskKzRDu9cPdDTi7O1CEtImx1AT3OOm9Dn97R7xsD/O+yJnPiScN/bxPZTcwNuFCtbSyJlwQRMMAAU2Kxr91gm6M6DKC6vDn3sWovPvC8llePGFU6peJwmWFbsqIh+WgUHj2cNBs5T3EPBGYY4Rz5Bn14BwrdgzMmOE8kR4HuPHuqt5bIASj8XRJpxAVxqykkQCiMp63AP5bYELTfrbqm782RPFEuF34WQAAAABJRU5ErkJggg=="
                                  />
                                )}
                              </div>
                            ) : (
                              <div className="show-image-div" />
                            )
                          ) : (
                            <>
                              {r.url ? (
                                <img
                                  src={props?.iconDoc ? props?.iconDoc : UploadPDFSuccess}
                                  className="show-image doc"
                                  alt="doc"
                                />
                              ) : r?.img?.includes('.mp4') ? (
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    position: 'relative',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    setPreviewVdo(r.img)
                                  }}
                                >
                                  <video className="show-image">
                                    <source src={r.img} type="video/mp4" />
                                  </video>
                                  <div style={{ position: 'absolute' }}>
                                    <IvdoPlay />
                                  </div>
                                </div>
                              ) : (
                                <img
                                  alt="show img"
                                  onClick={() => {
                                    setPreviewImg(r.img)
                                  }}
                                  className="show-image click"
                                  src={r.img}
                                />
                              )}
                            </>
                          )}
                        </div>

                        <div className="zone2">
                          <div className="text1">
                            {r.name}
                            <img
                              alt="status icon"
                              style={{ marginLeft: 8 }}
                              className="icon-status"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAhCAYAAAC4JqlRAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAMVSURBVHgBzZfNTxNBGMafmW0BIYEq1RgjyWqMF0nshYMcTIkxMSZG1sQIGGN70KvhD1CKXk2EKxdAg+hBKhdPEpeLHrzUBIzBBKoQT0A2TUT52B1n1i7dbbdLd1uiv8vsfOy+z/u+M7MzwD+GICCR9GhEFPmqpilJDQGoWIAwSI1wglB6FWAx/mrEOYJxASQDg43rdEvlgrKVfHdPAdywLKFhgBtIwBdkTMfvwb2EeAo4ND1xjxhIlXpbOQwkta70DsKvgNb05Kh/r8sZoUOrSk+/e98+Gy/AxtaUm8niVlrcEJ2eHKi9cQFJRNMvnpS02iutryYSoGQU+4i+o3dp12+pVt0ZAUoGUAXNoTAenjmLt+cv4sZx2XWMFJIcDu4KML0HZFTBo/YY7p44jfbmCIZjHehsPew2TM7bcgrg3t9GFQjDxV638Ii4YrNlzoG/m039EgLSdqAJHy9cdrQt//qJjpk3Zd/RsXlQbN9mBCQ9FENARN6nzsUdbbmdbVx7P+v5nmTUdYvSFMAIDSxA5L2tsdHRdn8uY0bAE0JiuwJ4RS43TnjY1tjk2ueW95HFBbxcyWIvGCEtogx5DRK5neFLqjkcxlxO42FVzfBafWLJ2RFeP/j8CX6gXp13Tp4yjQvE0prqjJsRCZp3N/IRMP/lJZ257W1H3RKxsrERLO92DOObKMwIEIaM25iRpa+Yz2klIi4dPeYcV2He7RBKMrsCdEpVt0EiAsoHtUSEnSB5N23CJkBTerO8UOFTRNC8MyCTt1mYhAzGdLkXyonwnfc8BGzYepash/qeK18IQj2kcNJ1sGkYeP1jGUcaGvgeX4fHC/N4+n0RfuHeZ9dtBxPH1I+mn8UZpHfYV1iSn4zGrJpjH1hV+EGBFcJTe9sYthsXlDkTPhdRiKO2qGtKX1dxo+tOyH+VClc7jlrBv2V+0wXPe0E0PZHi5/qqjmncQP+q0jfk0e9NJD0pS4yl+Ei/JyaVbzZJa70HFmAXQqF380sGvxuWzg/G/yeE7258jc/uYGuo0stqNbdj2V6v9DL63/EH8Ogn4N1BOSQAAAAASUVORK5CYII="
                            />
                          </div>
                          <div className="text2">
                            {' '}
                            {r.size && r.status !== 'error' ? (
                              <span> {r.size}MB </span>
                            ) : (
                              <span>
                                อัปโหลดไฟล์ไม่สำเร็จ เนื่องจากตัวอักษรและคอลัมน์เกิน หรือนามสกุลไฟล์ไม่ถูกต้อง
                              </span>
                            )}{' '}
                          </div>
                          <span
                            className="close-ui"
                            onClick={() => {
                              removeImage(r, i)
                            }}
                          >
                            <img
                              alt="close icon"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKqSURBVHgBxVe/b9NAFH53F1cMiFpUSJQBWiQqBEikIBa6JANTkZAXJMQADK3EBP9B2/+ATkgwtJ2QWNIBZjqxAWEAiSxNQQIkVOQOLDi+63uu7aSJ78751XxDFJ/v3vf53XfPzwAjBoMu8fxVpcQZFEVBXE2DKOU3QrkjFVQf3/O2oAvkEoCkU2MF/gQJHuKla5nuM842gyBcQTF16EfA2lrFbRwXS6DUU+gBKGTdJkQr4MXrShGAV3DCFPQBXF8PQlnWiWA6cgb8HdjTnRe+CmR58b5XBZsA2u+C4J8GSG4UwdvJhRjok7fC5Q6vkK+0AhyHLfW75yYojB2ZugXpFsSp34YjANaM6cSUaQbo6U2LJk9NwK25GzDhntDOOemOR3POnTltCgWCQXqsUwFSspJp0ZUL5zHwJMyX5jJFEPnt0s1ozsz0WVMorA/8QeKFSACVV9vef/xag/9BAGOO0yEiIad7NOfDl29ggds4BsVUANV224pdfw/ebr3vENFO/gbn/MW5NsiYs0A/jOdzfiJiPiYkEYRuyQmCH7zMogwolf/ct2eiF/JWcOgBKmOMZY4OQUD7nuuMaYOU4V5TADYSeRZlGS7LmHmA+aqnAngOATq3606HDQlnJGDhoI3yTQuuX5rRGq5dxLXLF8ECP+ZsekCB3DCtqNV/wM7P31q3JyJ+/dmF2vZ3Uyg0LNts/o8x8pcRDTCQqzBk4NOvt7Znh46hcGA5cedwyKk/DFdaxw4JeOR5aETpgcWQPcLPak47CtHiXa+KhiwPWIRPMbM648xKSCLQKLOD2A5KO5FTzKz72lJMakNMGSjz8TSSS7kq/slZHXks0I7oiHJYxvf2HcjzaSblRqDgWd+fZll4id0TNROCOqiCGE/Gw0b4mcrrQpcfpyPHPktpXlsztEGWAAAAAElFTkSuQmCC"
                            />
                          </span>
                        </div>
                      </li>
                    ) : null}

                    {r.status === 'error' ? (
                      <li className="error">
                        <div className="zone1">
                          {/** r.img?<img className="show-image" src={r.img} />:<div className="show-image-div"/> **/}
                          {r.vdo ? (
                            r.vdo ? (
                              <div className="show-image-div">
                                {r.img ? (
                                  <img
                                    alt="show img"
                                    className="show-image"
                                    src={r.img}
                                    style={{ width: '100%', height: 'auto' }}
                                  />
                                ) : (
                                  <img
                                    alt="default img"
                                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAgCAYAAACcuBHKAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHZSURBVHgBvZi/S8NAFMdfiqNKV8EhXRxcDLg4CMZ/QDvqZCqIaxFn2+yCnXWo7kId3Nv+B/0PLkPR0SLuz/fyQ2Loj7zcJR/4EhqS3Pfevbt7VwuEIGKdLh5pj+SS6rGYGWkSa2xZ1huYhBp3SUOUoUh9kg068AdIA9RDkdpQhLj3Cs3BnbElBi6wHFQuI/RQE8tFYZTgCw3YaHYIFjFYZkI3CSV43KaVMcA3+1AdvK40siYUXWyoFr+WMuBKDATTz1AGOK2lfjRBQDD9gOOza3h5fQdNnLSJIxDCkfBufWgcnmhFJW3CgYKwATbi956gCKEJ1N1gYrq9x9CMdIhqYJhkiFqkvENk3ETC7PsH6pvruZ5dA8PY21vQv++Ae7Cf+53QBFVAAeUF6NJpX0H78pwisCF6Lx0JLskKzRDu9cPdDTi7O1CEtImx1AT3OOm9Dn97R7xsD/O+yJnPiScN/bxPZTcwNuFCtbSyJlwQRMMAAU2Kxr91gm6M6DKC6vDn3sWovPvC8llePGFU6peJwmWFbsqIh+WgUHj2cNBs5T3EPBGYY4Rz5Bn14BwrdgzMmOE8kR4HuPHuqt5bIASj8XRJpxAVxqykkQCiMp63AP5bYELTfrbqm782RPFEuF34WQAAAABJRU5ErkJggg=="
                                  />
                                )}
                              </div>
                            ) : (
                              <div className="show-image-div" />
                            )
                          ) : (
                            <>
                              {r?.img ? (
                                <img
                                  alt="show img"
                                  onClick={() => {
                                    setPreviewImg(r?.img)
                                  }}
                                  className="show-image"
                                  src={checkImageErrorDisplay(r?.img)}
                                />
                              ) : (
                                <img className="show-image doc" src={props?.iconDoc || UploadImageError} alt="doc" />
                              )}
                            </>
                          )}
                        </div>
                        <div className="zone2">
                          <div className="text1"> {r.name}</div>
                          <div className="text2">
                            {r.msg ? r.msg : null} {r.size && r.status !== 'error' && <span> {r.size}MB</span>}
                          </div>
                          <span
                            className="close-ui"
                            onClick={() => {
                              // console.log('r', r)
                              removeImage(r, i)
                            }}
                          >
                            {' '}
                            <img
                              alt="status icon"
                              className="icon-status"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAhCAYAAAC4JqlRAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKPSURBVHgB7Vcxb9NAFP7stqEFhlgCJBgg/AJaMSO5YmSq1BGJMJQRmFkgEj+AmQUjVgZ+AVjAmtKpa12WSIDUsBSUKD7eu/iO2LF9vsRpl36SZfv87n3fvXt39wycMhxYoue3WyuAL4CWC9zgthg4JEd7dI+uhMGejb9KAg78drPp4kks8JRemwbziLyGgxidq2EQYR4BlsRTcB288D4GHcwigEPdcPCJHluYD9FAYLMoGm5e4w+/vU7k32ogZ8iBsM+8j1MRSEbO5NYhN6BPkdjIRiIVAZ7zJOx1kzOk7yPiKBRACfcc9YS9CK14zKGhpyAJ/QFOADQVN9VU6Ag0MsqyWLp+DWs723AveUYbvpdhxZXLGikBEPDLOp3buovGndu4+PJxLgG3XXi2I23YtgyOwIOUgJ9+24dh7odfuuPO51cl0aQIRc7fpO3XLgxoJpw6AuumHsPdffx5/X5KRJacbYbdfVSA5FyWDitm/iAZ2dqjbS1CCVLkA/PoJWjkt7QAWKz7rAgFG/KMEHuMvvcQ/zrS7+L4r2ybBdYC1JxPLse8xLQSQMVFpSIiL+HyErMKuIjRAlBBQB45zzlfM4oItYDlsYB+mfXq/XuF2Z4VYdqImOtyGIRagBcGfSHwtqzH8PMuRoe9wmzntuNX76SNcSMS+KAe9WHEO5M7PooXDpcOIy97GHFITFGoBQKBN1GUpJbhEuQpFWFxiIgwVaSmBHAuUAX8EAsC+/bKSjJGMhW1ixiRT5X5kygsy5OkfIP5SzSO6lYeOaNwK+YOlK2b8yQm7bDsY6OInFHp14yjQYZtx/lfyZSgz1s7Ce+UEVsJUOCSegRZuvHFP6bqGI/o+k1XSCsp5GTGGSriH+80DrqscBDZAAAAAElFTkSuQmCC"
                            />
                          </span>
                        </div>
                      </li>
                    ) : null}

                    {r.status === 'loadding' ? (
                      <li className="loading">
                        {isLoadingUpload ? (
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Spin />
                          </div>
                        ) : (
                          <div className="zone1">
                            {r.img ? (
                              <img alt="show img" className="show-image" src={r.img} />
                            ) : r.vdo ? (
                              <div className="show-image-div" />
                            ) : (
                              <div className="show-image-div" />
                            )}
                          </div>
                        )}

                        <div className="zone2">
                          <div className="text1">
                            {r.name}{' '}
                            <span className="percent-text" style={{ marginLeft: 10 }}>
                              {r.percent}%{' '}
                            </span>
                          </div>
                          <div className="text2">
                            <CustomProgress percent={r.percent} size="small" />
                            <span className="close-ui" onClick={() => removeImage(r, i)}>
                              <img
                                alt="close icon"
                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKqSURBVHgBxVe/b9NAFH53F1cMiFpUSJQBWiQqBEikIBa6JANTkZAXJMQADK3EBP9B2/+ATkgwtJ2QWNIBZjqxAWEAiSxNQQIkVOQOLDi+63uu7aSJ78751XxDFJ/v3vf53XfPzwAjBoMu8fxVpcQZFEVBXE2DKOU3QrkjFVQf3/O2oAvkEoCkU2MF/gQJHuKla5nuM842gyBcQTF16EfA2lrFbRwXS6DUU+gBKGTdJkQr4MXrShGAV3DCFPQBXF8PQlnWiWA6cgb8HdjTnRe+CmR58b5XBZsA2u+C4J8GSG4UwdvJhRjok7fC5Q6vkK+0AhyHLfW75yYojB2ZugXpFsSp34YjANaM6cSUaQbo6U2LJk9NwK25GzDhntDOOemOR3POnTltCgWCQXqsUwFSspJp0ZUL5zHwJMyX5jJFEPnt0s1ozsz0WVMorA/8QeKFSACVV9vef/xag/9BAGOO0yEiIad7NOfDl29ggds4BsVUANV224pdfw/ebr3vENFO/gbn/MW5NsiYs0A/jOdzfiJiPiYkEYRuyQmCH7zMogwolf/ct2eiF/JWcOgBKmOMZY4OQUD7nuuMaYOU4V5TADYSeRZlGS7LmHmA+aqnAngOATq3606HDQlnJGDhoI3yTQuuX5rRGq5dxLXLF8ECP+ZsekCB3DCtqNV/wM7P31q3JyJ+/dmF2vZ3Uyg0LNts/o8x8pcRDTCQqzBk4NOvt7Znh46hcGA5cedwyKk/DFdaxw4JeOR5aETpgcWQPcLPak47CtHiXa+KhiwPWIRPMbM648xKSCLQKLOD2A5KO5FTzKz72lJMakNMGSjz8TSSS7kq/slZHXks0I7oiHJYxvf2HcjzaSblRqDgWd+fZll4id0TNROCOqiCGE/Gw0b4mcrrQpcfpyPHPktpXlsztEGWAAAAAElFTkSuQmCC"
                              />
                            </span>
                          </div>
                        </div>
                      </li>
                    ) : null}
                  </div>
                )
              })}
            </ul>
          </>
        ) : null}
      </Content>
    </>
  )
}

export default UploadFile

const ModelContent = styled.div`
  .vdo-content {
    position: relative;
    padding: 0;
    video {
      max-height: 70vh;
      max-width: 80vw;
      background: #eee;
    }
    .plyr {
      max-height: 70vh;
      max-width: 80vw;
    }
    img {
      height: 70vh;
      max-width: 80vw;
      min-height: 30vh;
    }
  }
  .model-ui {
    font-style: normal;
    .header-ui {
      font-style: normal;
      font-weight: 600;
      font-size: 21px;
      line-height: 47px;
      color: #19263f;
      text-align: left;
    }
    .model-crop {
      position: relative;
      width: 100%;
      height: 100%;
      min-height: 300px;
    }
    .controls {
      background: #e2e5f8;
      border-radius: 35px;
      width: 200px;
      padding: 0.2em 12px;
      margin: auto;
      margin-top: 10px;
      display: flex;
      .slider {
        width: 160px;
        padding-left: 4px;
        padding-right: 4px;
      }
      .button-zoom {
        line-height: 34px;
        font-style: normal;
        font-weight: 600;
        font-size: 25px;
        color: #1f3983;
        width: 20px;
        height: 20px;
        user-select: none;
      }
    }
  }
`

const Content = styled.div`
  .percent-text {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    text-align: right;
    color: #597081;
  }
  .icon-status {
    width: 16px;
    height: 16px;
  }
  .close-ui {
    img {
      width: 16px;
      height: 16px;
    }
    cursor: pointer;
    margin-left: auto;
    margin-right: 6px;
    position: absolute;
    right: 10px;
    top: 25px;
  }
  .list-image {
    list-style: none;
    padding: 0;
    width: ${props => (props.width ? props.width : '100%')};
    margin-top: ${props => (props.top ? props.top : '0')};

    li.success {
      display: flex;
      margin-bottom: 8px;
      .zone2 {
        margin-left: 12px;
        height: 72px;
        background: #f5fefd;
        border-radius: 16px;
        width: 100%;
        padding: 18px 16px;
        position: relative;
        .text1 {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 26px;
          text-align: left;
          color: #1f3983;
          height: 22px;
          word-break: break-all;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          padding-right: 40px;
        }
        .text2 {
          font-style: normal;
          font-weight: 400;
          font-size: 10px;
          line-height: 12px;
          text-align: left;
          color: #686690;
        }
      }
    }

    li.error {
      display: flex;
      margin-bottom: 8px;
      .zone2 {
        margin-left: 12px;
        height: 72px;
        background: #fff6f8;
        border: 1px solid #ed4160;
        border-radius: 16px;
        width: 100%;
        padding: 18px 16px;
        position: relative;
        .text1 {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 26px;
          text-align: left;
          color: #1f3983;
          height: 22px;
          word-break: break-all;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          padding-right: 40px;
        }
        .text2 {
          font-style: normal;
          font-weight: 400;
          font-size: 10px;
          line-height: 12px;
          text-align: left;
          color: #686690;
          color: #ed4160;
        }
      }
    }

    li.loading {
      display: flex;
      margin-bottom: 8px;
      .zone2 {
        margin-left: 12px;
        height: 72px;
        background: #ffeee7;
        border-radius: 16px;
        width: 100%;
        padding: 18px 16px;
        position: relative;
        .text1 {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 26px;
          text-align: left;
          color: #1f3983;
          height: 22px;
          word-break: break-all;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          padding-right: 40px;
        }
        .text2 {
          font-style: normal;
          font-weight: 400;
          font-size: 10px;
          line-height: 12px;
          text-align: left;
          color: #686690;
          color: #ed4160;
        }
      }
    }
  }
  .icon-upload {
    width: 100%;
    max-width: 52px;
    max-height: 52px;
    margin: auto;
  }
  .show-image {
    width: 72px;
    height: 72px;
    border-radius: 10px;
    object-fit: cover;
    &.click {
      cursor: pointer;
    }
  }
  .show-image-div {
    width: 71.75px;
    height: 72px;
    background: #000000;
    opacity: 0.5;
    border-radius: 10px;
    display: flex;
    overflow: hidden;
    &.click {
      cursor: pointer;
    }
    img {
      margin: auto;
      width: 16px;
      height: 16px;
    }
  }
  .upload {
    margin-bottom: 8px;
    margin-top: 8px;

    display: grid;
    cursor: copy;
    justify-content: center;
    align-items: center;
    padding: 24px;
    gap: 8px;
    width: ${props => (props.width ? props.width : '100%')};

    height: 152px;
    border: 1px dashed #3f54d1;
    border-radius: 16px;
    flex: none;
    order: 1;
    flex-grow: 0;

    &:hover {
      background: #fafaff;
    }
  }
  .dest1 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #203983;
    margin: auto;
  }
  .dest2 {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #203983;
    margin: auto;
  }
  .underline {
    color: #3f54d1;
    text-decoration: underline;
    margin-left: 4px;
  }
`
