import styled from 'styled-components'
import { Menu, Drawer, Button, Divider, Input, Modal } from 'antd'

export const Container = styled.div`
  padding: 12px 112px;
  display: flex;
  align-items: center;
  justify-content: ${props => (props.noLogo === 'yes' ? 'end' : 'space-between')};
  z-index: 2;
  top: 0;
  /* position: fixed; */
  position: sticky;

  background-image: url(${props => props.bgImg});
  background-size: cover;
  background-attachment: inherit;
  width: 100%;
  background: #ffffff !important;
  box-shadow: 0px 2px 10px 0px #f4f4f4;

  .logo-phuket-health {
    cursor: pointer;
    height: auto;
  }

  @media only screen and (max-width: 1280px) {
    padding: 10px 60px;
    height: 80px;
  }

  @media only screen and (max-width: 768px) {
    padding: 16px 8px;
    height: 64px;
  }
`

export const TextHello = styled.h1`
  color: '#313744';
  font-weight: 500;
  font-size: 28px;
  margin: 0;

  p {
    font-weight: 400;
    font-size: 14px;
    margin-top: -5px;
    margin-bottom: 0;
  }
`

export const Logos = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Prompt';

  .box-logo-text {
    display: flex;
    flex-direction: column;
    line-height: 17px;
    margin-left: 8px;
  }

  .text-line-1 {
    font-size: 14px;
    font-weight: 400;

    @media only screen and (max-width: 768px) {
      font-size: 12px;
      font-weight: 300;
    }
  }

  .text-line-2 {
    font-size: 14px;
    font-weight: 500;

    @media only screen and (max-width: 768px) {
      font-size: 12px;
      font-weight: 500;
    }
  }

  /* .logo-navbar {
    margin-right: 10px;
    width: 100%;
    max-width: 248px;
    max-height: 40px;
  }

  @media only screen and (max-width: 885px) {
    .logo-navbar {
      max-width: 211px;
      max-height: 32px;
    }
  } */
`

export const CustomMenu = styled(Menu)`
  background: transparent !important;
  color: var(--secondary);
  font-weight: 400;
  font-size: 16px;
  border: none !important;
  padding: 4px 0 4px 20px;
  min-width: 214px;
  margin-bottom: 4px !important;
  font-family: 'IBM Plex Sans Thai';

  /* @media only screen and (max-width: ${props => props.issizemobile && props.issizemobile + 'px'}) {
    padding: 0;
  }

  li {
    @media only screen and (max-width: ${props => props.issizemobile && props.issizemobile + 'px'}) {
      padding: 0 20px !important;
    }
  } */

  li:hover {
    color: var(--primary) !important;
    border: none !important;
  }

  .ant-menu-item-selected {
    color: var(--primary) !important;
    font-weight: 500;

    &::after {
      border: none !important;

      &:hover {
        border: none !important;
      }
    }
  }

  .ant-menu-item:hover:after {
    border: none !important;
  }
  .ant-menu-item::after {
    border: none !important;
    transition: none !important;
  }

  .ant-menu-item:active {
    background: transparent !important;
  }
  .ant-menu-submenu-title:hover {
    color: var(--primary) !important;
  }
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open {
    color: var(--primary) !important;
  }

  @media only screen and (max-width: ${({ issizemobile }) => issizemobile}px) {
    display: none;
  }

  @media only screen and (min-width: 768px) {
    display: block;
  }
`

export const BoxProfile = styled.div`
  width: 100%;
  background: #ffff;
  box-shadow: 0px -1px 0px #eaeaea;
  justify-content: center;
  display: block;
  align-items: center;
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 5;
  padding: 5px 0;
`

export const CustomDrawer = styled(Drawer)`
  z-index: 1050;
  .ant-drawer-wrapper-body {
    /* padding: 0 40px; */
  }

  .ant-drawer-content-wrapper {
    /* transform: none !important; */
    transform: translateX(0px) !important;
  }

  .ant-drawer-content {
    box-shadow: 0px 4px 30px rgba(46, 60, 79, 0.04);
  }

  .ant-drawer-mask {
    background: rgba(0, 31, 52, 0.5);
    backdrop-filter: blur(8px);
  }

  .ant-drawer-header {
    border: none;
  }

  .ant-drawer-body {
    padding: 0 !important;
    overflow: scroll;

    ::-webkit-scrollbar {
      display: none;
    }
  }

  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-top: 6.5em; */
    margin-bottom: 1em;

    h1 {
      font-family: 'Prompt';
      font-weight: 500;
      font-size: 24px;
      color: #150f0b;
      text-align: center;
      margin-bottom: 1.5em;
    }
  }

  .info-title {
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #150f0b;
    margin-bottom: 24px;
    margin-top: 54px;
    padding-left: 40px;
    padding-right: 40px;
  }

  .edit-profile {
    font-family: 'Prompt';
    font-weight: 500;
    font-size: 18px;
    color: #fc6521;
    position: absolute;
    right: 42px;
    top: 32px;
    cursor: pointer;
  }

  .edit-profile-disable {
    font-family: 'Prompt';
    font-weight: 500;
    font-size: 18px;
    color: #fc6521;
    position: absolute;
    right: 42px;
    top: 32px;
    cursor: not-allowed;
    opacity: 0.5;
  }

  .top {
    margin-top: 70px;
  }

  .row-data {
    width: 100%;
    display: flex;
    padding-left: 40px;
    padding-right: 40px;

    .col-1 {
      width: 100%;
      padding-right: 32px;
    }

    .col-2 {
      width: 2%;
      display: flex;
      justify-content: center;
      /* height: 97px; */
      height: auto;
      color: #ffeee7 !important;
    }

    .col-3 {
      width: 100%;
      padding-left: 32px;
    }

    .col-full {
      width: 100%;
    }
  }

  .text-head {
    margin-bottom: 0;
    /* padding-left: 40px; */
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #c4c4c4;
    padding-top: 18px;
    padding-bottom: 12px;
  }

  .text-data {
    padding-bottom: 27px;
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #150f0b;
    /* padding-left: 40px; */
    line-height: 20px;
    margin-bottom: 0;
  }

  .text-data-disabled {
    padding-bottom: 27px;
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #aba9a8;
    /* padding-left: 40px; */
    line-height: 20px;
    margin-bottom: 0;
  }

  .text-head-right {
    margin-bottom: 0;
    /* padding-left: 32px;   */
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #c4c4c4;
    padding-top: 18px;
    padding-bottom: 12px;
  }

  .text-data-right {
    padding-bottom: 27px;
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #150f0b;
    /* padding-left: 32px; */
    margin-bottom: 0;
  }

  .text-data-right-disabled {
    padding-bottom: 27px;
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #aba9a8;
    /* padding-left: 32px; */
    margin-bottom: 0;
  }

  .text-data-department {
    padding-bottom: 10px;
    font-family: 'Prompt';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #556a82;
    padding-left: 32px;
    margin-bottom: 0;
  }

  .ant-drawer-header-title {
    justify-content: flex-end;
  }

  .close-icon {
    position: absolute;
    left: 0px;
    cursor: pointer;
  }
`

export const CustomMenuResponsive = styled(Menu)`
  font-family: 'IBM Plex Sans Thai';
  font-weight: 400;
  font-size: 16px;
  align-items: center;
  color: #0d3331;

  .ant-menu-title-content {
    margin: 8px 0 !important;
  }

  .ant-menu-vertical > .ant-menu-item {
    height: 50px !important;
    line-height: 50px !important;
  }

  li {
    border-bottom: 1px solid #b4c0bf;
    /* height: 58px !important; */
    /* margin-top: 8px !important;
    margin-bottom: 8px !important; */
  }

  .ant-menu-item-selected {
    font-weight: 500 !important;
  }
`

export const BoxRight = styled.div`
  display: flex;
  align-items: center;
`

export const BoxName = styled.div`
  /* position: absolute; */
  /* bottom: 0;
  left: 0px;
  right: 0px; */
  /* height: 64px; */
  display: flex;
  align-items: center;
  justify-content: center;
  color: #150f0b;
  font-weight: 400;
  font-size: 12px;
  font-family: 'Prompt';
  gap: 8px;
  padding: 4px 8px;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: #fff3ee;
  }

  p {
    /* width: 100px; */
    width: auto;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: break-word;
    margin-bottom: 0;
  }

  img {
    max-width: 32px;
    max-width: 32px;
  }
`

export const WrapDropdownProfile = styled.div`
  background-color: #ffffff;
  width: 356px;
  height: 200px;
  border-radius: 26px;
  box-shadow: 0px 2px 10px 0px #f4f4f4;
  padding: 16px;
`

export const MenuProfile = styled.div`
  width: 100%;
  height: 80px;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: #fffaf8;
  }
`

export const MenuLogout = styled.div`
  width: 100%;
  height: 56px;
  border-radius: 16px;
  padding: 16px;
  display: flex;
  cursor: pointer;
  font-family: 'Prompt';

  &:hover {
    background-color: #fffaf8;
  }
`

export const CustomDivider = styled(Divider)`
  margin: 0;
  top: 0;
  height: auto !important;

  &.ant-divider {
    border-top: 1px solid #f4f4f4 !important;
  }

  &.ant-divider-vertical {
    border-left: 1px solid #f4f4f4 !important;
  }
`

export const BtLogout = styled(Button)`
  width: 100%;
  height: 40px;
  border-radius: 8px;
  color: #f85858;
  border-color: #f85858;
  font-family: 'Prompt';
  font-weight: 500;
  font-size: 12px;
  box-shadow: none;
  margin-top: 40px;

  &:hover,
  &:active,
  &:focus {
    color: #ffffff;
    border-color: #f85858;
    background-color: #f85858;
  }
`

export const CustomInputDrawer = styled(Input)`
  /* border-color: #ffeee7 !important; */
  font-family: 'Prompt';
  font-weight: 400;
  font-size: 16px;
  border-color: ${props => (props.disabled ? 'transparent' : '#ffeee7')};
  color: #150f0b !important;
  border-radius: 8px;
  width: 100%;
  height: 40px;

  &:hover,
  &:active,
  &:focus {
    border: 1px solid #fc6521 !important;
    box-shadow: none;
    color: #fc6521 !important;
  }

  &.ant-input-affix-wrapper > .ant-input {
    &:focus,
    &:active {
      color: #fc6521 !important;
      border-color: #fc6521 !important;
    }
  }

  &.ant-input-affix-wrapper-focused,
  &.ant-input-affix-wrapper:focus {
    box-shadow: none !important;
  }

  &.ant-input-affix-wrapper-focused {
    box-shadow: none !important;
    border: 1px solid #fc6521;
  }

  .ant-input-clear-icon,
  .anticon.ant-input-clear-icon {
    display: flex;
  }

  &.ant-input-affix-wrapper-disabled {
    border-color: transparent !important;
    background-color: transparent !important;
  }
  .ant-input[disabled] {
    color: #150f0b !important;
  }
`

export const BtSubmit = styled(Button)`
  background-color: #fc6521;
  /* background-color: ${props => (props.disabled ? 'transparent' : '#fc6521')}; */
  font-family: 'Prompt';
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  border-radius: 8px;
  width: 100%;
  height: 48px;

  &:hover,
  &:active,
  &:focus {
    color: #ffffff;
    background-color: #fc6521;
    border-color: #fc6521;
  }

  &.ant-btn[disabled] {
    background: rgba(252, 101, 33, 1) !important;
    color: #ffffff;
    opacity: 0.2;
  }
`
export const StyleDropdown = styled.div`
  font-family: 'Prompt';
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 8px;
    /* border: 1px solid #fee0d3; */
    border: none;
    height: 40px;
    width: 100%;
    padding: 4px 0px;

    @media only screen and (max-width: 768px) {
      padding: 12px 16px;
    }

    @media only screen and (max-width: 375px) {
      padding: 12px 16px;
    }
  }

  .ant-select-disabled .ant-select-selector {
    border: 1px solid #fbfbfb !important;
    background-color: #f5f5f5 !important;
    color: #9ac8bb !important;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-disabled .ant-select-selector {
    border: 1px solid #fbfbfb !important;
  }
  .ant-select-disabled .ant-select-arrow svg > path {
    stroke: #fbfbfb;
  }
`

export const InputEditName = styled(Input)`
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Prompt';
  padding-bottom: 22px;
  border: none !important;

  .ant-input {
    height: 20px;
  }

  .ant-input-clear-icon {
    display: flex;
  }

  &:hover,
  &:focus,
  &:active {
    border: none !important;
  }

  &.ant-input-affix-wrapper-focused,
  &.ant-input-affix-wrapper:focus {
    border: none !important;
    box-shadow: none !important;
  }
`

export const WrapProfileResponsive = styled.div`
  background-color: #fffaf8;
  /* background-color: green; */
  height: auto;
  width: 100%;
  padding: 1rem;
  font-family: 'Prompt';

  .header {
    margin: 16px 0px;
    height: 32px;
    display: flex;
    align-items: center;
  }

  .box-header-left {
    position: relative;
    display: flex;
    align-items: center;

    svg {
      position: absolute;
      left: -16px;
      top: -28px;
    }

    span {
      padding-left: 50px;
      margin: 0;
      font-family: 'Prompt';
      font-weight: 500;
      font-size: 20px;
      color: #150f0b;
    }
  }

  .box-header-right {
    span {
      color: #f36b24;
      font-family: 'Prompt';
      font-weight: 500;
      font-size: 14px;
      cursor: pointer;
    }
  }
`
export const CustomModal = styled(Modal)`
  font-family: 'Prompt', sans-serif;

  .ant-modal-content {
    border-radius: 16px;
    width: 670px;
    height: 515px;
    margin: 0 auto;
  }
  .ant-modal-header {
    border-radius: 16px;
    color: white;
    border: 0;
  }
  .ant-modal-body {
    text-align: center;
    padding-bottom: 0px;
  }
  .ant-modal-footer {
    border: 0;
    text-align: right;
    padding-bottom: 15px;

    .ant-btn-default {
      border-radius: 12px;
      border-color: #fc6521;
      color: #fc6521;
      font-size: 16px;
      width: 87px;
      height: 40px;
      font-weight: 500;
    }
    .ant-btn-primary {
      border-radius: 12px;
      background-color: #fc6521;
      border-color: #fc6521;
      font-size: 16px;
      width: 87px;
      height: 40px;
      font-weight: 500;
      margin-top: 20px;
    }
  }
  @media only screen and (max-width: 1280px) {
    /* padding: 10px 60px;
    height: 80px; */
  }

  @media only screen and (max-width: 768px) {
    .ant-modal-content {
    border-radius: 16px;
    width: 100%;
    height:200px;
    margin: 0 auto;
  }
  .ant-modal-header {
    border-radius: 16px;
    color: white;
    border: 0;
  }
  .ant-modal-body {
    text-align: center;
    padding-bottom: 0px;
  }
  .ant-modal-footer {
    border: 0;
    text-align: center;
    padding-bottom: 15px;

    .ant-btn-default {
      border-radius: 12px;
      border-color: #fc6521;
      color: #fc6521;
      font-size: 16px;
      width: 87px;
      height: 40px;
      font-weight: 500;
    }
    .ant-btn-primary {
      border-radius: 12px;
      background-color: #fc6521;
      border-color: #fc6521;
      font-size: 16px;
      width: 87px;
      height: 40px;
      font-weight: 500;
      margin-top: 20px;
    }
  }
  }
`
export const BoxProfileDetial = styled.div`
  background-color: #ffffff;
  width: 100%;
  height: auto;
  padding: 32px 16px 24px 16px;
  border-radius: 16px;
  /* min-height: 829px; */
  /* display: flex;
  align-items: center;
  flex-direction: column; */
  font-family: 'Prompt';

  h3 {
    font-size: 16px;
    font-weight: 500;
    color: #150f0b;
    margin: 0;
  }

  h4 {
    font-size: 14px;
    font-weight: 500;
    color: #150f0b;
    margin: 0;
    margin-top: 24px;
  }
`

export const BoxData = styled.div`
  min-height: 64px;
  border-bottom: 1px solid #f4f4f4;
  padding: 16px 0;
  width: 100%;
  font-family: 'Prompt';
  display: flex;
  flex-direction: column;

  .title-resposive {
    color: #c4c4c4;
    font-size: 10px;
    font-weight: 400;
    margin-bottom: 16px;
  }

  .data {
    font-size: 12px;
    font-weight: 400;
    color: #150f0b;
  }
`

export const ModalSuccessEditProfile = styled(Modal)`
  font-family: 'Prompt' !important;

  .ant-modal-content {
    width: 461px !important;
    height: auto;
    border-radius: 24px !important;
  }

  .ant-modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .title {
    color: #150f0b;
    font-size: 16px;
    font-weight: 400;
    margin-top: 1rem;
    margin-bottom: 8px;
  }

  .description {
    color: #c4c4c4;
    font-size: 12px;
    font-weight: 300;
  }
`
export const BtOk = styled(Button)`
  background-color: #f36b24;
  font-family: 'Prompt';
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  border-radius: 8px;
  width: 120px;
  height: 40px;
  justify-content: center;
  border: none;
  margin-top: 32px;

  &:hover,
  &:active,
  &:focus {
    color: #ffffff;
    background-color: #f36b24;
    border-color: #f36b24;
  }

  &.ant-btn[disabled] {
    background: rgba(252, 101, 33, 1) !important;
    color: #ffffff;
    opacity: 0.2;
  }
`
