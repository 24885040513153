import React from 'react'

import { RadioContainer } from './radioGroup.style'

const RadioGroupComponent = ({ options, defaultValue, setReportType }) => {
  const onChange = (e) => {
    setReportType && setReportType(e.target.value)
  }

  return (
    <RadioContainer
      size="large"
      options={options}
      onChange={onChange}
      defaultValue={defaultValue && defaultValue}
      optionType="button"
    />
  )
}

export default RadioGroupComponent
