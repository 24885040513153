export const Input = require('./Input').default
export const Select = require('./Select').default
export const Option = require('./Select').Option
export const Password = require('./Password').default
export const Textarea = require('./Textarea').default
export const DatePicker = require('./DatePicker').default
// export const ReportDatePicker = require('./ReportDatePicker').default
// export const RangePicker = require('./RangePicker').default
// export const Upload = require('./Upload').default
export const Checkbox = require('./Checkbox').default
export const Radio = require('./Radio').default
// export const Video = require('./Video').default
export const Editor = require('./Editor').default
// export const MultipleUpload = require('./MultipleUpload').default
export const RadioGroup = require('./RadioGroup').default
export const TreeSelect = require('./TreeSelect').default
