import { Drawer } from 'antd'
import styled from 'styled-components'

export const BoxSearch = styled.div`
  padding-bottom: 24px;

  .ant-select {
    color: #fe814a;
  }
  .ant-select-dropdown {
    background: #ffffff;
    box-shadow: 0px 4px 16px rgb(200 211 218 / 50%) !important;
    border-radius: 8px !important;
  }
  .ant-select-arrow {
    left: 11px !important;
    font-size: 18px;
    justify-content: end;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    /* padding-left: 10px;
    padding-top: 4px; */
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 8px;
    border: 1px solid #fee0d3;
    height: 40px;
    /* width: 100%; */

    @media only screen and (max-width: 768px) {
      height: 56px;
    }

    @media only screen and (max-width: 375px) {
      height: 56px;
    }
  }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-search {
    display: flex;
    align-items: center;
    /* padding-left: 10px; */
  }

  .ant-select-disabled .ant-select-selector {
    background: #f5f5f5 !important;
    border: 1px solid #f5f5f5 !important;
    color: #fe814a !important;
  }
  .ant-select-disabled .ant-select-arrow svg > path {
    stroke: #9ac8bb;
  }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #fe814a;
    box-shadow: none;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #fe814a;
    box-shadow: none;
  }

  .ant-select,
  .ant-select-single,
  .ant-select-show-arrow,
  .ant-select-show-search {
    /* width: 100%; */
  }

  .ant-select-selection-placeholder {
    color: #c4c4c4 !important;
    font-weight: 300;
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: rgba(254, 129, 74, 0.08);
    color: #fe814a;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    font-weight: 500;
  }

  .ant-select-selection-item {
    @media only screen and (max-width: 768px) {
      font-size: 16px;
    }

    @media only screen and (max-width: 375px) {
      font-size: 16px;
    }
  }
`

export const CustomDrawer = styled(Drawer)`
  .ant-drawer-content-wrapper {
    height: 85% !important;
  }

  .ant-drawer-content {
    border-radius: 12px 12px 0px 0px;
  }
`

export const HeaderDrawer = styled.div`
  height: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
`

export const TextType = styled.span`
  font-family: 'Prompt';
  font-size: 16px;
  font-weight: 500;
  color: #ffc4aa;
`

export const CloseText = styled.span`
  font-family: 'Prompt';
  font-size: 16px;
  font-weight: 500;
  color: #fe814a;
`

export const BoxOption = styled.div`
  height: 48px;
  width: 100%;
  margin-bottom: 8px;
  border-bottom: 1px solid #ece9e9;
  display: flex;
  align-items: center;

  label {
    font-family: 'Prompt';
    font-size: 16px;
    font-weight: 400;
    color: #150f0b;
  }
`
