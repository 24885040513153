import * as React from 'react'
import { Input } from 'antd'
import styled from 'styled-components'

import { ReactComponent as IconSearch } from 'assets/images/svg/icon-search.svg'
import { ReactComponent as IconAllowClear } from 'assets/images/svg/icon-allowclear.svg'
import UserContext from 'core/contexts/userContext'

const InputSearch = props => {
  const { setSearchValue } = React.useContext(UserContext)

  // React.useEffect(() => {})

  const { defaultValue, placeholder, onPressEnter, onChange, handleClearValueSearch } = props
  const [valueSearch, setValueSearch] = React.useState('')

  return (
    <Content>
      <Input
        allowClear={{ clearIcon: <IconAllowClear onClick={e => handleClearValueSearch && handleClearValueSearch()} /> }}
        key={defaultValue ? defaultValue : ''}
        placeholder={placeholder}
        onPressEnter={e => onPressEnter(valueSearch)}
        onChange={e => {
          setValueSearch(e.target.value)

          if (e.target.value === '') {
          }
        }}
        defaultValue={defaultValue ? defaultValue : ''}
        prefix={<IconSearch />}
      />
    </Content>
  )
}

export default InputSearch

const Content = styled.div`
  /* width: 357px; */

  .ant-input {
    /* width: 357px; */
    :focus,
    :hover {
      color: #fe814a !important;
    }
  }

  .ant-input::placeholder {
    color: #ffc4aa !important;
    font-size: 12px;
    font-weight: 400;
    font-family: 'Prompt';
  }

  .ant-input-affix-wrapper .ant-input:placeholder-shown {
    /* padding-left: 20px !important; */
  }

  .ant-input-affix-wrapper-focused {
    border: 1px solid #fe814a !important;
    box-shadow: none;

    .ant-input-prefix svg {
      circle {
        stroke: #fe814a;
      }
    }
  }
  .ant-input-affix-wrapper {
    border: 1px solid #fee0d3 !important;
    display: flex;
    align-items: center;
    padding-left: 1rem;
    border-radius: 8px;
    /* max-width: 320px; */
    width: 357px;
    height: 40px;
    :focus,
    :active,
    :hover {
      border: 1px solid #fe814a !important;
    }

    &.ant-input-affix-wrapper-focused {
      border: 1px solid #fe814a !important;
    }

    /* @media only screen and (max-width: 820px) {
      width: 300px;
    } */

    @media only screen and (max-width: 768px) {
      width: 370px;
    }

    @media only screen and (max-width: 431px) {
      width: 310px;
    }

    @media only screen and (max-width: 391px) {
      width: 300px;
    }

    @media only screen and (max-width: 376px) {
      width: 290px;
    }
  }

  .ant-input-clear-icon {
    display: flex;
  }
`
