import React, { useState, useEffect, useCallback } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import Loader from 'components/Layout/Loader'
import BgLogin from 'assets/images/png/bg-login.png'
import BgLogin2 from 'assets/images/png/bg-login-x2.png'
import BgLoginMobile from 'assets/images/png/bg-login-mobile.png'
import { ReactComponent as IconRegister } from 'assets/images/svg/icon-header-register.svg'
import { ReactComponent as BackButton } from 'assets/images/svg/button-back-login.svg'
import { ReactComponent as IconKorat2 } from 'assets/images/svg/icon-korat-register-logo-text.svg'
// import IconKorat from 'assets/images/svg/icon-korat-register.svg'
import { ReactComponent as IconKorat } from 'assets/images/svg/icon-korat-register.svg'

import { getById, getData, postData } from 'core/action/collection'

import {
  Container,
  BoxHeader,
  BoxForm,
  BoxGrid,
  BtSubmit,
  WrapBoxRegister,
  BoxFormLeft,
  BoxFormRight,
  StyleDropdown,
  LabelText,
  WrapLogo,
  CustomDivider,
  BoxFlex,
  BoxPDPA,
  CustomCheckbox,
} from './formregister.style'
import { Input } from 'components/input'
import DropdownSearch from 'components/Inputs/DropdownSearch'
import DropdownDrawer from 'components/Inputs/DropdownDrawer'
import DropdownCustom from 'components/Inputs/DropdownCustom'
import { Select } from 'antd'

import { mockTypeUser, mockDataDistrict, mockDataSubdistrict, defaultValues, Schema } from './container'
import { mockTypeComplaints } from 'components/Inputs/DropdownCustom/container'
import { useLocation, useNavigate } from 'react-router-dom'
import { authentication, masterdata } from 'core/schemas'
import liff from '@line/liff'

const { Option } = Select

const Index = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [mobile, setMobile] = useState(false)
  const [open, setOpen] = useState(false)
  const [defaultValueFromList, setDefaultValueFromList] = useState(defaultValues)
  const [formattedMobileNumber, setFormattedMobileNumber] = useState('')
  const [provinceData, setProvinceData] = useState([])
  const [districtData, setDistrictData] = useState([])
  const [subDistrictData, setSubDistrictData] = useState([])
  const [groupUser, setGroupUser] = useState([])
  const [loading, setLoading] = useState(true)
  const [whileLoading, setWhileLoading] = useState(true)
  const [provinceId, setProvinceId] = useState('')
  const [districtId, setDistrictId] = useState('')
  const [subDistrictId, setSubDistrictId] = useState('')

  const [displayName, setDisplayName] = useState('')
  const [statusMessage, setStatusMessage] = useState('')
  const [userId, setUserId] = useState('')
  const [checkPdpa, setCheckPdpa] = useState(false)
  const [urlProfile, setUrlProfile] = useState('')

  const getProfileLine = async () => {
    await liff
      .init({ liffId: `${process.env.REACT_APP_LINELIFF}` }) //SIT
      // .init({ liffId: '2003227421-ZneRvvAM' }) //PROD
      .then(() => {
        if (liff.isLoggedIn()) {
          liff
            .getProfile()
            .then(profile => {
              setDisplayName(profile.displayName)
              setUrlProfile(profile.pictureUrl)
              setUserId(profile.userId)
              testLogin(profile.userId)
            })
            .catch(err => console.error(err))
        } else {
          liff.login()
        }
      })
      .catch(err => {
        console.log('err', err)
        localStorage.clear()
        navigate('/login')
      })
  }

  const testLogin = async PropsuserId => {
    const body = {
      line_id: PropsuserId ? PropsuserId : userId,
    }

    const res = await postData(`${authentication}/login`, body)

    if (res) {
      if (res) {
        if (res?.code === 200) {
          localStorage.setItem('access_token', res?.data?.access_token)
          localStorage.setItem('refresh_token', res?.data?.refresh_token)
          navigate('/home')
        } else {
          console.log('err', res)
          setLoading(false)
        }
      }
    }
  }

  useEffect(() => {
    if (!location?.state?.accept_pdpa || !location?.state) {
      localStorage.clear()
      navigate('/login')
    }
  }, [])

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const isSizeMobile = 768

  useEffect(() => {
    if (windowWidth <= isSizeMobile) {
      setOpen(false)
      setMobile(true)
    } else {
      setOpen(true)
      setMobile(false)
    }
  }, [windowWidth])

  useEffect(() => {
    getMasterData()
  }, [])

  useEffect(() => {
    provinceId && getDistrict()
  }, [provinceId])

  useEffect(() => {
    districtId && getSubDistrict()
  }, [districtId])

  useEffect(() => {
    subDistrictData.length > 0 && formik.values.subdistrict !== '' && checkPostCode()
  }, [subDistrictId, subDistrictData])

  const getMasterData = async () => {
    // await getProfileLine()
    await getGroupUser()
    await getProvince()
    setTimeout(() => {
      setWhileLoading(false)
    }, 1000)
    setLoading(false)
  }

  const getGroupUser = async () => {
    const res = await getData({
      schema: `${masterdata}/all/group`,
      params: {},
      url: '',
      baseURL: '',
    })

    setGroupUser(res.data)
  }

  const getProvince = async () => {
    const res = await getData({
      schema: `${masterdata}/all/province`,
      params: {},
      url: '',
      baseURL: '',
    })

    if (res) {
      const newData = res.data.slice()
      newData.sort((a, b) => (a.id === '19' ? -1 : b.id === '19' ? 1 : 0))

      setProvinceData(newData)
    }
  }

  const getDistrict = async () => {
    const res = await getData({
      schema: `${masterdata}/all/district/` + provinceId,
      params: {},
      url: '',
      baseURL: '',
    })

    if (res) {
      const newData = res.data.slice()
      newData.sort((a, b) => (a.id === '215' ? -1 : b.id === '215' ? 1 : 0))

      setDistrictData(newData)
    }
  }

  const getSubDistrict = async () => {
    const res = await getData({
      schema: `${masterdata}/all/sub-district/` + districtId,
      params: {},
      url: '',
      baseURL: '',
    })
    // setSubDistrictData(res?.data)

    if (res) {
      const newData = res.data.slice()
      newData.sort((a, b) => (a.id === '300101' ? -1 : b.id === '300101' ? 1 : 0))

      setSubDistrictData(newData)
    }
  }

  const formik = useFormik({
    initialValues: defaultValueFromList,
    validationSchema: Schema,
    // onSubmit: handlerSubmit,
    enableReinitialize: true,
    onSubmit: values => {
      // console.log(values)
    },
  })

  const checkPostCode = () => {
    const selectedSubDistrict = subDistrictData?.find(subDistrict => subDistrict.id === subDistrictId)

    if (selectedSubDistrict) {
      formik.setFieldValue('postcode', selectedSubDistrict.postcode)
    }
  }

  const onChangeProvince = () => {
    formik.setFieldValue('district_id', '')
    formik.setFieldValue('postcode', '')
    formik.setFieldValue('sub_district_id', '')
    // getDistrict()
    // getSubDistrict()
  }

  const onChangeDistrict = async value => {
    formik.setFieldValue('postcode', '')
    formik.setFieldValue('sub_district_id', '')
    // getSubDistrict()
  }

  const isValidForm = formik.isValid && formik.dirty

  const handalSubmit = async () => {
    const phone = formik.values.mobile_number.replace(/-/g, '')

    const body = {
      address: formik.values.address,
      district_id: formik.values.district_id,
      email: '',
      group_id: formik.values.group_id,
      line_id: location?.state?.line_id,
      mobile_number: phone,
      name: {
        first_name: formik.values.name,
        last_name: formik.values.last_name,
        required_firstname: true,
        required_lastname: true,
      },
      password: '',
      province_id: formik.values.province_id,
      sign_up_channel: 'line OA',
      sub_district_id: formik.values.sub_district_id,
      accept_pdpa: location?.state?.accept_pdpa,
      display_name: location?.state?.line_name,
      line_name: location?.state?.line_name,
      profile_url: location?.state?.line_picture,
    }

    const res = await postData(`${authentication}/users`, body)
    if (res?.code === 201) {
      setLoading(true)
      await testLogin(location?.state?.line_id)
    } else if (res?.code === 422) {
      formik.setErrors({ mobile_number: 'หมายเลขโทรศัพท์นี้ ถูกใช้งานแล้ว กรุณากรอกหมายเลขอื่น' })
    } else {
      console.log('err', res)
    }
  }

  const handleDrawerOptionClick = (value, fieldFormik) => {
    if (fieldFormik === 'group_id') {
      formik.setFieldValue('group_id', value)
    }
    if (fieldFormik === 'province_id') {
      formik.setFieldValue('province_id', value)
      formik.setFieldValue('postcode', '')
      formik.setFieldValue('district_id', '')
      formik.setFieldValue('sub_district_id', '')
      setProvinceId(value)
    }
    if (fieldFormik === 'district_id') {
      formik.setFieldValue('district_id', value)
      formik.setFieldValue('postcode', '')
      formik.setFieldValue('sub_district_id', '')
      setDistrictId(value)
    }
    if (fieldFormik === 'sub_district_id') {
      formik.setFieldValue('sub_district_id', value)
      setSubDistrictId(value)
    }
  }

  const optionsTypeUser = groupUser?.map((items, index) => ({
    value: items?.id,
    label: items?.name,
    key: index,
  }))

  const optionsProvince = provinceData?.map((items, index) => ({
    value: items?.id,
    label: items?.province_name.th,
    key: index,
  }))

  const optionsDistrict = districtData?.map((items, index) => ({
    value: items?.id,
    label: items?.district_name.th,
    key: index,
  }))

  const optionsSubdistrict = subDistrictData?.map((items, index) => ({
    value: items?.id,
    label: items?.sub_district_name.th,
    key: index,
  }))

  const phoneNumberAutoFormat = phoneNumber => {
    const number = phoneNumber.trim().replace(/[^0-9]/g, '')

    if (number.length < 4) return number
    if (number.length < 7) return number.replace(/(\d{3})(\d{1})/, '$1-$2')
    if (number.length < 11) return number.replace(/(\d{3})(\d{3})(\d{1})/, '$1-$2-$3')
    return number.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
  }

  const onPhoneNumberChange = e => {
    const targetValue = phoneNumberAutoFormat(e.target.value)
    formik.setFieldValue('mobile_number', targetValue)
  }

  const uniqueValidate = value => {
    if (value?.mobile_number?.length < 12) {
      setTimeout(() => {
        formik.setErrors({ mobile_number: 'รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง' })
      }, 100)
    }
    if (formik.values.mobile_number.length < 12) {
      formik.setErrors({ mobile_number: 'รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง' })
    }
  }

  const onCheckBoxChange = e => {
    setCheckPdpa(e.target.checked)
  }

  return (
    <>
      {whileLoading ? (
        <div
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100vh' }}
        >
          <Loader loading={loading} />
        </div>
      ) : (
        <Loader loading={loading}>
          <Container
            url={windowWidth <= isSizeMobile ? BgLoginMobile : BgLogin2}
            mobile={windowWidth <= 414 ? true : false}
          >
            <WrapBoxRegister>
              <BoxHeader>
                <IconRegister />
                ลงทะเบียน
              </BoxHeader>
              <BoxForm>
                <div style={{ height: 'auto' }}>
                  <BoxGrid>
                    <BoxFormLeft>
                      <div className="box-back-button">
                        <BackButton className="back-button" onClick={() => navigate('/login')} />
                      </div>
                      <WrapLogo>
                        <div style={{ display: 'flex', height: '40px' }}>
                          {/* <img src={IconKorat} alt="logokorat" style={{ height: '100%' }} /> */}
                          <IconKorat />
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              lineHeight: '16px',
                              justifyContent: 'center',
                              marginLeft: '8px',
                            }}
                          >
                            <span className="text-span1">เทศบาลนครนครราชสีมา</span>
                            <span className="text-span2">ระบบร้องเรียนและบริการสาธารณะ</span>
                          </div>
                        </div>
                      </WrapLogo>
                      <div>
                        <h2>ข้อมูลส่วนตัว</h2>
                        <Input
                          label="ชื่อ"
                          name="name"
                          placeholder="กรอกชื่อของคุณ"
                          type="text"
                          required={true}
                          errors={formik.errors}
                          touched={formik.touched}
                          form={formik}
                          status={formik.status}
                          maxLength={255}
                          requiredPadding={1}
                          fontsize="14px"
                        />
                        <Input
                          label="นามสกุล"
                          name="last_name"
                          placeholder="กรอกนามสกุลของคุณ"
                          type="text"
                          required={true}
                          errors={formik.errors}
                          touched={formik.touched}
                          form={formik}
                          status={formik.status}
                          maxLength={255}
                          requiredPadding={1}
                          fontsize="14px"
                        />
                        <Input
                          label="เบอร์โทรศัพท์"
                          name="mobile_number"
                          placeholder="0XX-XXX-XXXX"
                          type="text"
                          required={true}
                          errors={formik.errors}
                          touched={formik.touched}
                          form={formik}
                          status={formik.status}
                          requiredPadding={1}
                          maxLength={12}
                          fontsize="14px"
                          onChange={onPhoneNumberChange}
                          inputMode="tel"
                          uniqueValidate={uniqueValidate}
                        />

                        <div style={{ minHeight: windowWidth <= isSizeMobile ? '107px' : '91px' }}>
                          <LabelText>
                            กลุ่มผู้ใช้บริการ
                            <div className="red-star" style={{ paddingLeft: '2px' }}>
                              *
                            </div>
                          </LabelText>
                          <StyleDropdown>
                            <DropdownCustom
                              title="กลุ่มผู้ใช้บริการ"
                              showSearch={false}
                              required={true}
                              className="big-input"
                              options={optionsTypeUser}
                              value={formik.values.group_id}
                              checkmobile={windowWidth <= isSizeMobile ? true : false}
                              onChange={value => {
                                formik.setFieldValue('group_id', value)
                              }}
                              onDrawerOptionClick={handleDrawerOptionClick}
                              fieldFormik="group_id"
                            />
                          </StyleDropdown>
                        </div>
                      </div>
                    </BoxFormLeft>
                    <BoxFormRight>
                      <div className="wrap-form-right">
                        <h2>ที่อยู่ปัจจุบันของคุณ</h2>
                        <h4>เพื่อแจ้งข่าวสารที่เกิดขึ้นในบริเวณใกล้คุณ</h4>
                        <Input
                          label="รายละเอียดที่อยู่"
                          name="address"
                          placeholder="กรอกรายละเอียดที่อยู่"
                          type="text"
                          required={true}
                          errors={formik.errors}
                          touched={formik.touched}
                          form={formik}
                          status={formik.status}
                          maxLength={255}
                          requiredPadding={1}
                          fontsize="14px"
                        />
                        <div style={{ minHeight: windowWidth <= isSizeMobile ? '107px' : '91px' }}>
                          <LabelText>
                            จังหวัด
                            <div className="red-star" style={{ paddingLeft: '2px' }}>
                              *
                            </div>
                          </LabelText>
                          <StyleDropdown>
                            <DropdownCustom
                              title="จังหวัด"
                              placeholder="เลือกจังหวัด"
                              showSearch={false}
                              required={true}
                              onChange={value => {
                                formik.setFieldValue('province_id', value)
                                setProvinceId(value)
                                onChangeProvince()
                              }}
                              className="big-input"
                              options={optionsProvince}
                              value={formik?.values?.province}
                              checkmobile={windowWidth <= isSizeMobile ? true : false}
                              onDrawerOptionClick={handleDrawerOptionClick}
                              fieldFormik="province_id"
                            />
                          </StyleDropdown>
                        </div>
                        <div style={{ minHeight: windowWidth <= isSizeMobile ? '107px' : '91px' }}>
                          <LabelText>
                            อำเภอ
                            <div className="red-star" style={{ paddingLeft: '2px' }}>
                              *
                            </div>
                          </LabelText>
                          <StyleDropdown>
                            <DropdownCustom
                              title="อำเภอ"
                              placeholder="เลือกอำเภอ"
                              showSearch={false}
                              required={true}
                              onChange={value => {
                                formik.setFieldValue('district_id', value)
                                setDistrictId(value)
                                onChangeDistrict(value)
                              }}
                              className="big-input"
                              options={optionsDistrict}
                              value={formik.values.district_id}
                              checkmobile={windowWidth <= isSizeMobile ? true : false}
                              onDrawerOptionClick={handleDrawerOptionClick}
                              fieldFormik="district_id"
                            />
                          </StyleDropdown>
                        </div>
                        <div style={{ minHeight: windowWidth <= isSizeMobile ? '107px' : '91px' }}>
                          <LabelText>
                            ตำบล
                            <div className="red-star" style={{ paddingLeft: '2px' }}>
                              *
                            </div>
                          </LabelText>
                          <StyleDropdown>
                            <DropdownCustom
                              title="ตำบล"
                              placeholder="เลือกตำบล"
                              showSearch={false}
                              required={true}
                              onChange={value => {
                                formik.setFieldValue('sub_district_id', value)
                                setSubDistrictId(value)
                              }}
                              className="big-input"
                              options={optionsSubdistrict}
                              value={formik?.values?.sub_district_id}
                              disabled={formik?.values?.district_id === ''}
                              checkmobile={windowWidth <= isSizeMobile ? true : false}
                              onDrawerOptionClick={handleDrawerOptionClick}
                              fieldFormik="sub_district_id"
                            />
                          </StyleDropdown>
                        </div>
                        <Input
                          label="รหัสไปรษณีย์"
                          name="postcode"
                          placeholder="รหัสไปรษณีย์"
                          type="text"
                          required={true}
                          errors={formik.errors}
                          touched={formik.touched}
                          form={formik}
                          status={formik.status}
                          maxLength={35}
                          disabled={true}
                          requfiredPadding={1}
                          fontsize="14px"
                        />
                      </div>
                    </BoxFormRight>
                  </BoxGrid>

                  {/* <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginBottom: '1rem',
                    }}
                  >
                    <CustomCheckbox onChange={onCheckBoxChange} />
                    <BoxPDPA>
                      <span>การให้ความยินยอมการเปิดเผยข้อมูลภายใต้</span>
                      <a href="https://gateway-prd.koratcity.go.th/prod/media/documents/e0a984a8-9b2e-44fb-86d8-c7e02a50a953.pdf">
                        พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคลพ.ศ.2562 (Personal Data Protection Act: PDPA)
                      </a>
                    </BoxPDPA>
                  </div> */}
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <BtSubmit disabled={!isValidForm} onClick={handalSubmit}>
                      ลงทะเบียน
                    </BtSubmit>
                  </div>
                </div>
              </BoxForm>
            </WrapBoxRegister>
          </Container>
        </Loader>
      )}
    </>
  )
}

export default Index
