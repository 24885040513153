import * as I from './index.style'
import { ReactComponent as IconAllowClear } from 'assets/images/svg/icon-allowclear.svg'
import { Input as InputAntd } from 'antd'
import {
  Checkbox,
  DatePicker,
  Password,
  Radio,
  Select,
  Textarea,
  Upload,
  Video,
  Editor,
  MultipleUpload,
} from 'components/input'

import { TextareaEditProfile } from '..'

import Message from 'components/output/Message'
import React from 'react'
import get from 'lodash.get'

const CustomInputComponent = props => {
  const { label, name, errors, touched } = props
  return (
    <I.InputContainer>
      <label htmlFor={name}>{label}*</label>
      <div className="input">
        <InputAntd {...props} />
        {errors[name] && touched[name] ? <Message>{errors[name]}</Message> : null}
      </div>
    </I.InputContainer>
  )
}

const Input = props => {
  const {
    label,
    name,
    type,
    placeholder,
    errors,
    touched: touches,
    form,
    required,
    disabled,
    uniqueFunc,
    uniqueValidate,
    setIsChange,
    showCount = false,
    readOnly = false,
    className,
    fontsize,
    onChange,
    inputMode,
    allowClear,
  } = props
  // const value = get(form.values, name, '').trim()
  let error = ''
  const [touched, setTouched] = React.useState(get(touches, name, ''))
  const [trigger, setTrigger] = React.useState('none')
  const [isMax, setIsMax] = React.useState(false)
  const value = get(form.values, name, '')

  error = get(errors, name, '')

  if (isMax) {
    if (trigger === 'trigger') {
      // console.log('trigger UP')
      error = `กรุณากรอกไม่เกิน ${props.maxLength} ตัวอักษร`
    } else {
      // console.log('trigger DOWN')
      error = get(errors, name, '')
    }
  }

  function getStyles(error, touched) {
    if (error && touched) {
      return {
        border: '1px solid #ED4160',
        background: '#FFF6F8',
      }
    }
  }

  // console.log('touch', touched)
  // console.log('error', error)
  // console.log('isMax', isMax)
  // console.log('trigger', trigger)

  return (
    <I.InputContainer>
      {/* <label style={{ display: 'flex', fontSize: fontsize || '16px' }} htmlFor={name}>
        {label}
        <div style={{ paddingLeft: props.requiredPadding ? props.requiredPadding : '4px' }} className="red-star">
          {' '}
          {required && '*'}
        </div>
      </label> */}
      {/* <div className="input"> */}
      <InputAntd
        id="query-input"
        type={type}
        name={name}
        inputMode={inputMode}
        autoComplete="off"
        placeholder={placeholder}
        prefix={props.prefix}
        suffix={props.suffix}
        readOnly={readOnly}
        onChange={onChange ? onChange : form.handleChange}
        onBlur={e => {
          if (uniqueValidate) uniqueValidate(true)
          if (error) return
          form.handleBlur(e)
        }}
        value={value}
        disabled={disabled}
        style={getStyles(error, touched)}
        maxLength={props.maxLength}
        onKeyUp={() => {
          setTouched(true)
          if (isMax) {
            setTrigger('trigger')
            // if (uniqueValidate) uniqueValidate(true)
          }
          if (value.length === props.maxLength) {
            setIsMax(true)
          }
          if (value.length < props.maxLength) {
            setIsMax(false)
            setTrigger('')
          }
          if (uniqueFunc) uniqueFunc(false)
        }}
        onClick={() => {
          setTouched(true)
          if (uniqueFunc) uniqueFunc(false)
        }}
        onMouseLeave={() => {
          if (uniqueValidate) uniqueValidate(true)
        }}
        className={className}
        allowClear={{ clearIcon: <IconAllowClear /> }}
      />

      {error && touched ? (
        <Message style={{ fontWeight: 400 }}>{error}</Message>
      ) : showCount ? (
        <div style={{ textAlign: 'right', color: '#597081' }}>
          {value.length}/{props.maxLength}
        </div>
      ) : null}
      {/* </div> */}
    </I.InputContainer>
  )
}

const InputComponent = props => {
  const typeInput = {
    input: <Input {...props} data-testid="test-input" />,
    select: <Select {...props}>{props.children}</Select>,
    checkbox: <Checkbox {...props} />,
    textarea: <TextareaEditProfile {...props} />,
    text: <Input {...props} />,
    email: <Input {...props} />,
    password: <Password {...props} />,
    date: <DatePicker {...props} />,
    // image: <Upload {...props} />,
    radio: <Radio {...props} />,
    // video: <Video {...props} />,
    editor: <Editor {...props} />,
    // multiple_upload: <MultipleUpload {...props} />,
  }

  if (typeInput[props.type] !== undefined) {
    return !props.hide && typeInput[props.type]
  } else {
    return <CustomInputComponent {...props} data-testid="test-custom-input" />
  }
}

InputComponent.defaultProps = {
  type: 'text',
  placeholder: 'Input text',
}

export default InputComponent
