import React, { useEffect, useState } from 'react'
import {
  Container,
  BoxStatus,
  BoxIcon,
  WrapBoxNoti,
  BoxContent,
  BoxTitle,
  BoxStatusText,
  BoxCategory,
  BoxNumber,
} from './boxnotification.style'
import { ReactComponent as TestIcon } from 'assets/images/svg/icon-test-noti-detail.svg'
import { ReactComponent as IconCategoryMe } from 'assets/images/svg/icon-category-card-complaint-me.svg'
import { ReactComponent as IconNumberMe } from 'assets/images/svg/icon-number-card-complaint-me.svg'
import { checkColorStatusBg, checkColorStatusText, checkStatusText } from './container'
import { getData } from 'core/action/collection'
import { complaint } from 'core/schemas'
import axios from 'axios'

const Index = ({ data, setNotiValue, toggleDropdown }) => {
  const [timeElapsed, setTimeElapsed] = useState('')

  useEffect(() => {
    const calculateTimeElapsed = () => {
      const createdAtTimestamp = data?.created_at
      const currentTime = Math.floor(Date.now() / 1000) // เวลาปัจจุบันในรูปแบบ timestamp มิลลิวินาที
      const differenceInSeconds = currentTime - createdAtTimestamp
      const minutesElapsed = Math.floor(differenceInSeconds / 60)
      if (minutesElapsed < 60) {
        setTimeElapsed(`${minutesElapsed} นาที`)
      } else if (minutesElapsed < 1440) {
        const hoursElapsed = Math.floor(minutesElapsed / 60)
        setTimeElapsed(`${hoursElapsed} ชั่วโมง`)
      } else {
        const daysElapsed = Math.floor(minutesElapsed / 1440)
        setTimeElapsed(`${daysElapsed} วัน`)
      }
    }

    calculateTimeElapsed()

    const interval = setInterval(() => {
      calculateTimeElapsed()
    }, 60000) // ให้คำนวณเวลาทุกๆ 1 นาที

    return () => clearInterval(interval)
  }, [data?.created_at])

  const getDataById = async () => {
    if (data?.complaint === true) {
      const res = await getData({
        schema: `${complaint}/complaint/` + data?.complaint_id,
        params: {},
        url: '',
        baseURL: '',
      })

      if (res) {
        if (res?.code === 200) {
          setNotiValue(res?.data)
        }
      }
    } else {
      const res = await getData({
        schema: `${complaint}/e-service/complaint/` + data?.complaint_id,
        params: {},
        url: '',
        baseURL: '',
      })

      if (res) {
        if (res?.code === 200) {
          setNotiValue(res?.data)
        }
      }
    }
  }

  const updateReadNoti = async NotiId => {
    try {
      const respUpdate = await axios.put(`${process.env.REACT_APP_BASEURL}complaint/api/v1/noti/user/${NotiId}`)

      if (respUpdate) {
      }
    } catch (error) {}
  }

  const onClickCard = async () => {
    await getDataById()
    updateReadNoti(data?.id)
    toggleDropdown()
  }

  return (
    <>
      <Container isRead={data?.is_read} onClick={() => onClickCard()}>
        <WrapBoxNoti>
          <BoxIcon>
            <TestIcon />
          </BoxIcon>
          <BoxContent>
            <BoxTitle>
              <div className="title-complaint">{data?.complaint_name}</div>
              <div className="new-status">มีการอัปเดทสถานะใหม่</div>
            </BoxTitle>
            <BoxStatus>
              <BoxStatusText status={data?.status_data?.name}>{checkStatusText(data?.status_data?.name)}</BoxStatusText>
              <div style={{ marginLeft: '8px', color: '#ABA9A8', fontSize: '12px', fontWeight: '300' }}>
                {timeElapsed}
              </div>
            </BoxStatus>
            <BoxCategory>
              <BoxNumber>
                <IconNumberMe />
                <div className="text">{data?.complaint_no}</div>
              </BoxNumber>
              <BoxNumber>
                <IconCategoryMe />
                <div className="text">{data?.category?.name}</div>
              </BoxNumber>
            </BoxCategory>
          </BoxContent>
        </WrapBoxNoti>
      </Container>
    </>
  )
}

export default Index
