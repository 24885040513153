import { useState, useEffect, useRef, useContext } from 'react'
import { Card, Divider, Row, Input, Typography, Button, Col, Spin } from 'antd'
import { CustomButton, Container, TextTime } from './chartBot.style.jsx'
import { ReactComponent as SendMessen } from 'assets/images/svg/SendMessen.svg'
import { ReactComponent as OpenMessen } from 'assets/images/svg/Chatbot-Button.svg'
import { ReactComponent as CloseMessen2 } from 'assets/images/svg/CloseMessen2.svg'
import { postData } from 'core/action/collection.js'
import { chatHistory, chatlog } from 'core/schemas'
import { io } from 'socket.io-client'
import UserContext from 'core/contexts/userContext.js'
const { Meta } = Card
const { Paragraph } = Typography
const baseURL = process.env.REACT_APP_BASEURL_CHATBOT
const ioClient = io.connect(baseURL)

export default function PopUpMsg() {
  const { meData } = useContext(UserContext)
  const [sendMessage, setSendMessage] = useState('')
  const [messages, setMessages] = useState([])
  const messagesEndRef = useRef(null)
  const [openMessand, setOpenMessand] = useState(false)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [openTitle, setOpenTitle] = useState(false)
  const [loaddingChat, setLoaddingChat] = useState(true)
  const [socketIO, setSocketIO] = useState(null)

  const scrollToBottom = () => {
    const domNode = messagesEndRef.current
    if (domNode) {
      domNode.scrollTop = domNode.scrollHeight
    }
  }

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    scrollToBottom()
  })

  const styleFrom = {
    marginLeft: '5px',
    background: '#F36B24',
    padding: '10px 10px',
    borderRadius: '2px 16px 16px 16px',
    color: '#FFFFFF',
    maxWidth: '260px',
    marginTop: '10px',
    fontSize: '12px',
  }
  const styleTo = {
    background: '#FFF3EE',
    padding: '5px 18px',
    borderRadius: '16px 2px 16px 16px',
    color: '#150F0B',
    maxWidth: '260px',
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
  }

  const styleBackground = {
    background: 'white',
    borderColor: 'unset',
  }

  const handleMessageSender = (text, type) => {
    sendChat()
  }

  const handleKeyDown = event => {
    if (event.key === 'Enter' && event.target.value.trim().length > 0) {
      handleMessageSender()
    }
  }

  const textSplitNewLine = msg => {
    const text = msg?.split('\n')
    return text?.map((t, index) => {
      return text[index]?.length === 0 ? (
        <div style={{ height: '20px' }}>{t}</div>
      ) : (
        <p style={{ marginBottom: 0 }}>{`${t}`}</p>
      )
    })
  }
  const createChatHistory = async (msgUser, msgBot, msgTime, userID, intent_name) => {
    try {
      const body = {
        chat_msg: {
          msg: msgUser,
          time: msgTime,
        },
        chat_reply: {
          msg: msgBot,
          time: msgTime,
        },
        intent_name: intent_name,
        user_id: userID,
      }
      const res = await postData(chatHistory, body, baseURL)
    } catch (error) {
      createChatHistory(msgUser, msgBot, msgTime, userID, intent_name)
    }
  }

  let newMsgPayload = []

  useEffect(() => {
    ioClient.on('res:message_user', payload => {
      payload.created_at = `${new Date().getHours()}:${new Date().getMinutes()}`
      setMessages(messages.concat(payload))
      setLoaddingChat(false)
    })

    ioClient.on('res:message', (payload, text) => {
      payload.created_at = `${new Date().getHours()}:${new Date().getMinutes()}`
      if (Array.isArray(payload)) {
        setMessages(messages.concat(payload))
      } else {
        newMsgPayload.push(payload)
        setMessages(messages.concat(newMsgPayload.flat()))
      }
    })
  }, [messages])

  const uniqueArrayByProperty = (arr, prop) => {
    const seen = new Map()
    return arr.filter(item => {
      const key = item[prop]
      if (seen.has(key)) {
        return false
      } else {
        seen.set(key, true)
        return true
      }
    })
  }

  useEffect(() => {
    ioClient.on('connect', socket => {
      setSocketIO(socket)
      console.log('connected')
    })
    setTimeout(() => {
      if (meData?.id && openMessand) {
        let line_id = meData?.line_id ? meData?.line_id : meData?.id
        const payload = {
          line_id: line_id,
          url_profile: meData.profile_url,
          name: meData?.display_name ? meData?.display_name : `${meData?.name?.first_name} ${meData?.name?.last_name}`,
          text: 'text',
          action: 'send',
        }
        ioClient.emit('get:history_user', line_id, payload)
        setLoaddingChat(true)
      }
    }, 2000)
  }, [meData?.id, openMessand])

  const sendChat = async text => {
    let line_id = meData?.line_id ? meData?.line_id : meData?.id
    const payload = {
      line_id: line_id,
      url_profile: meData?.profile_url,
      name: meData?.display_name ? meData?.display_name : `${meData?.name?.first_name} ${meData?.name?.last_name}`,
      text: sendMessage,
      action: 'send',
    }
    await ioClient.emit('channel', line_id, payload)
    setTimeout(() => {
      ioClient.emit('get:line_users')
    }, 2000)
    setSendMessage('')
  }

  const conversDateChat = val => {
    const dateString = val
    const date = new Date(dateString)
    const localDate = new Date(date.getTime())
    const formattedTime = localDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })
    const [hoursStr, minutesStr] = val.split(':')
    const hours = parseInt(hoursStr, 10)
    let minutes = parseInt(minutesStr, 10)
    const formattedTimeFull = `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`
    return formattedTime === 'Invalid Date' ? formattedTimeFull : formattedTime
  }

  return (
    <Container
      style={{
        position: 'fixed',
        zIndex: 9999,
        bottom: '2%',
        right: windowWidth <= 885 ? '4%' : '1%',
        width: openTitle || openMessand ? '344px' : '50px',
        height: openMessand ? '440px' : 'auto',
      }}
      onMouseMove={() => {
        document.body.style.position = 'fixed'
      }}
      onMouseLeave={() => {
        document.body.style.position = 'static'
      }}
    >
      <Card
        className="card-chat"
        style={{
          width: '100%',
          display: !openMessand ? 'none' : 'block',
        }}
        actions={[
          <div
            style={{
              display: 'flex',
              padding: '0 10px',
              alignItems: 'center',
            }}
          >
            <Input
              className="input-send-messen"
              onKeyDown={handleKeyDown}
              value={sendMessage}
              onChange={e => setSendMessage(e.target.value)}
              size="large"
              placeholder="ถามคำถาม"
            />
            <Button
              style={{ marginLeft: '5px', ...styleBackground, padding: '0px 0px' }}
              type="primary"
              onClick={sendMessage.trim().length > 0 ? handleMessageSender : null}
              shape="circle"
            >
              <SendMessen />
            </Button>
          </div>,
        ]}
      >
        <Meta
          title={
            <div className="title-chat-bot">
              <p style={{ margin: 0 }}>เทศบาลนครนครราชสีมา</p>
              <CloseMessen2
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  newMsgPayload = []
                  setLoaddingChat(true)
                  setMessages([])
                  setOpenMessand(item => !item)
                  document.body.style.position = 'static'
                }}
              />
            </div>
          }
        />
        <Divider style={{ borderBlockStart: '1px solid #FFE6DB' }} />
        {loaddingChat ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
            <Spin size="middle" />
          </div>
        ) : (
          <div ref={messagesEndRef} className="detail-chat" id="scrollbar-chat">
            {messages?.map((item, index) => (
              <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                <Row
                  key={index}
                  justify={item.action === 'send' ? 'end' : 'start'}
                  style={{
                    display: 'flxe',
                    flexDirection: 'column',
                    width: '100%',
                    alignItems: item.action === 'send' ? 'end' : 'start',
                  }}
                >
                  <Row>
                    <Paragraph style={item.action === 'send' ? styleTo : styleFrom}>
                      {textSplitNewLine(item.text)}
                    </Paragraph>
                  </Row>
                  <Row>
                    <TextTime>{conversDateChat(item.created_at)}</TextTime>
                  </Row>
                </Row>
              </div>
            ))}
          </div>
        )}
      </Card>

      {!openTitle && !openMessand && (
        <CustomButton
          open={openTitle}
          onClick={() => {
            setOpenMessand(item => !item)
          }}
        >
          <OpenMessen />
        </CustomButton>
      )}
    </Container>
  )
}
