import React from 'react'
import { Spin } from 'antd'
import { Container } from './index.style'

const Index = ({ children, loading }) => {
  return (
    <Container>
      <Spin size="large" spinning={loading}>
        {children}
      </Spin>
    </Container>
  )
}

export default Index
