export const authentication = `authen/api/v1`
export const masterdata = `master/api/v1`

// export const adminAuth = '/api/v1'
export const pathLogin = `/admin/auth/api/v1`
// export const usagelog = `${adminAuth}/usagelog`
export const login = `${pathLogin}/login`
export const request = `${authentication}/request`
export const complaint = 'complaint/api/v1'
export const complaintmap = 'maps/api/v1'
export const me = `authen/api/v1/me`
export const noti = `complaint/api/v1/noti/user`
export const chatlog = `complaint/api/v1/chatlog`

//ทดสอบ
export const chatHistory = '/chatbot/history'
