import styled, { createGlobalStyle } from 'styled-components'
import { Button, Space, Modal } from 'antd'
import { Slider, Progress } from 'antd'

export const CustomSlider = styled(Slider)`
  .ant-slider-rail {
    background: #3f54d1 !important;
    opacity: 0.38 !important;
  }
  .ant-slider-track {
    background: #3f54d1 !important;
  }
  .ant-slider-handle {
    background-color: #3f54d1;
    border: 2px solid #3f54d1;
  }
`

export const CustomProgress = styled(Progress)`
  .ant-progress-bg,
  .ant-progress-success-bg {
    background-color: #3f54d1;
  }
  .ant-progress-outer {
    padding: 0;
  }
  .ant-progress-inner {
    background-color: #e2e5f8;
  }
  .ant-progress-text {
    display: none;
  }
`

export const CustomModal3 = styled(Modal)`
  font-family: 'Prompt', sans-serif;

  .ant-modal-content {
    border-radius: 16px;
    width: 670px;
    height: 515px;
    margin: 0 auto;
  }
  .ant-modal-header {
    border-radius: 16px;
    color: white;
    border: 0;
  }
  .ant-modal-body {
    text-align: center;
    padding-bottom: 0px;
  }
  .ant-modal-footer {
    border: 0;
    text-align: right;
    padding-bottom: 15px;

    .ant-btn-default {
      border-radius: 12px;
      border-color: #fc6521;
      color: #fc6521;
      font-size: 16px;
      width: 87px;
      height: 40px;
      font-weight: 500;
    }
    .ant-btn-primary {
      border-radius: 12px;
      background-color: #fc6521;
      border-color: #fc6521;
      font-size: 16px;
      width: 87px;
      height: 40px;
      font-weight: 500;
      margin-top: 20px;
    }
  }
`

export const CustomModal4 = styled(Modal)`
  font-family: 'Prompt', sans-serif;

  .close-ui {
    position: fixed;
    top: 35px;
    right: 50px;
    padding: 0px;
    background: #c5ccf1;
    width: 35px;
    height: 35px;
    border-radius: 35px;
    cursor: pointer;
    line-height: 35px;
    font-size: 14px;
    font-weight: 800;
    color: #3f54d1;
    z-index: 100;
    transition: none;
    img {
      width: 14px;
      height: 14px;
    }
  }
  .ant-modal-content {
    border-radius: 16px;
    width: auto;
    height: auto;
    margin: 0 auto;
    background-color: transparent;
    box-shadow: none;
  }
  .ant-modal-header {
    border-radius: 16px;
    color: white;
    border: 0;
  }
  .ant-modal-body {
    text-align: center;
    padding-bottom: 0px;
    padding: 0px;
  }
  .ant-modal-footer {
    border: 0;
    text-align: center;
    padding-bottom: 0px;
    padding: 0px;
    .ant-btn-default {
      border-radius: 12px;
      border-color: #fc6521;
      color: #fc6521;
      font-size: 16px;
      width: 87px;
      height: 40px;
      font-weight: 500;
    }
    .ant-btn-primary {
      border-radius: 12px;
      background-color: #fc6521;
      border-color: #fc6521;
      font-size: 16px;
      width: 87px;
      height: 40px;
      font-weight: 500;
      margin-top: 20px;
    }
  }
`

export const BoxNoData = styled.div`
  min-height: 600px;
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  border-radius: 20px;

  p {
    font-weight: 400;
    font-size: 16px;
    color: #597081;
  }
`

export const NotiStyle = createGlobalStyle`
  body {
    .ant-notification-notice.error-style {
      background-color: #FFEEF1;
      border-radius: 12px;
      height: 64px;
      min-width: 291px;
      width: auto;
      align-items: center;
      justify-content: center;
    }
    .ant-notification-top {
      margin-left: 60px;
    }
    .ant-notification-notice-content {
      justify-content: center;
      align-items: center;
    }
    .with-undo .ant-notification-notice-message {
      padding-top: 5px;
    }
    .ant-notification-notice-message {
      font-family: 'Prompt', sans-serif;
      font-style: normal;
      font-size: 16px !important;
      font-weight: 500;
      line-height: 18px;
      color: #001F34;
      padding-top: 8px;
      a {
        line-height: 18px;
      }
      img {
        padding-left: 16px;
        padding-right: 11px;
      }
    }
    .ant-notification-notice-close {
      padding-top: 5px;
    }
  }
`
