import axios from 'axios'
import { setRequestHeadersInterceptor } from './interceptors'
import { postData } from './collection'
let token = JSON.parse(localStorage.getItem('token')) || ''

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
  timeout: 60000,
  headers: {
    'Accept-Language': '*',
    Authorization: `Bearer ${token?.access_token}`,
  },
})

instance.interceptors.request.use(setRequestHeadersInterceptor)

instance.interceptors.response.use(
  response => {
    if (response?.data?.code === 401) {
      resetCookie()
    } else {
      return response
    }
  },
  error => {
    // console.log('error :>> ', error)
    if (error?.response?.status) {
      if (error?.response?.status === 401) {
        resetCookie()
      } else {
        if (axios.isCancel(error)) {
          return error
        }

        return error?.response
      }
    }
    return Promise.reject(error)
  }
)
const logout = async () => {
  let ip = await fetch('https://api.ipify.org?format=json')
    .then(response => response.json())
    .then(data => {
      return data.ip
    })
  try {
    const res = await postData('/logout')
      .then(res => {
        return res
      })
      .catch(err => {
        console.log(err)
        return err
      })
    return res
  } catch (error) {
    return error
  }
}

const resetCookie = async () => {
  // const res = await logout()
  // // if (res?.code === 200) {
  // localStorage.user = ''
  // localStorage.removeItem('token')
  // // localStorage.clear()
  // localStorage.removeItem('state')
  // // localStorage.setItem('exp', true)
  // window.location.href = '/login?expired=true'
  // }
}

export const httpClient = instance
