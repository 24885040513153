import React, { useState } from 'react'
import { InfoWindow } from '@react-google-maps/api'
import styled from 'styled-components'
import { Button } from 'antd'
import PinInfor from './PinInfor'
import BoxComplaintMe from 'components/BoxComplaintMe/BoxComplaintMe'
import { checkColorStatusBg, checkColorStatusText } from './container'

import { ReactComponent as IconLocationComMe } from 'assets/images/svg/icon-location-card-complaint-me.svg'
import { ReactComponent as IconClockMe } from 'assets/images/svg/icon-clock-card-complaint-me.svg'
import { ReactComponent as IconCategoryMe } from 'assets/images/svg/icon-category-card-complaint-me.svg'
import { ReactComponent as IconNumberMe } from 'assets/images/svg/icon-number-card-complaint-me.svg'
import TestIcon from 'assets/images/svg/tset-icon-category.svg'
import { unixToDate } from 'core/utils/DateTimeFormat'

export default function InfoDetail({
  data,
  id,
  coordinates,
  setIdInfor,
  idInfor,
  conversFullDate,
  setCenter,
  onClickShowDetail,
}) {
  const [clickPin, setClickPin] = useState(false)

  const handleInfoWindowClick = (id, condinate) => {
    setIdInfor(prevId => (prevId === id ? null : id))
  }

  const handleInfoWindowBlur = () => {
    setIdInfor('')
  }

  const checkColorStatusText = val => {
    if (val === 'รับเรื่อง') {
      return '#915EFF'
    }
    if (val === 'อยู่ระหว่างดำเนินการ') {
      return '#FFBB0B'
    }
    if (val === 'ดำเนินการเสร็จสิ้น') {
      return '#13AFA6'
    }
    if (val === 'ไม่อยู่ในความรับผิดชอบ') {
      return '#3F54D1'
    }
    if (val === 'ติดตาม') {
      return '#F36B24'
    }
    if (val === 'เกินระยะเวลาที่กำหนด') {
      return '#F85858'
    }
  }

  const checkBgColorStatus = val => {
    if (val === 'รับเรื่อง') {
      return '#EFE9FE'
    }
    if (val === 'อยู่ระหว่างดำเนินการ') {
      return '#FDF5DD'
    }
    if (val === 'ดำเนินการเสร็จสิ้น') {
      return '#E2E5F8'
    }
    if (val === 'ไม่อยู่ในความรับผิดชอบ') {
      return '#FFE6DB'
    }
    if (val === 'ติดตาม') {
      return '#FFE6DB'
    }
    if (val === 'เกินระยะเวลาที่กำหนด') {
      return '#FFE4E4'
    }
  }

  const convertUnixToDateTime = unixTimestamp => {
    const dateObject = new Date(unixTimestamp * 1000)

    const hour = dateObject.getHours().toString().padStart(2, '0')
    const minute = dateObject.getMinutes().toString().padStart(2, '0')
    const day = dateObject.getDate().toString().padStart(2, '0')

    const months = ['ม.ค', 'ก.พ', 'มี.ค', 'เม.ย', 'พ.ค', 'มิ.ย', 'ก.ค', 'ส.ค', 'ก.ย', 'ต.ค', 'พ.ย', 'ธ.ค']
    const month = months[dateObject.getMonth()]

    const year = (dateObject.getFullYear() + 543).toString() // แปลงเป็น พ.ศ.

    const formattedDate = `${hour}:${minute}, ${day} ${month} ${year}`

    return formattedDate
  }

  let statusColor = data?.status_data?.length ? data?.status_data[data?.status_data?.length - 1]?.description : ''
  return (
    <div className="box-info-detail">
      <InfoWindow
        position={coordinates}
        options={{
          pixelOffset: new window.google.maps.Size(-10, 20),
        }}
        zIndex={2}
        // onMouseOut={() => setIdInfor('')}
      >
        <div
          onMouseOver={() => handleInfoWindowClick(id, coordinates)}
          onBlur={handleInfoWindowBlur}
          onMouseOut={() => {
            setIdInfor('')
            setClickPin(false)
          }}
          onClick={() => {
            setClickPin(true)
            setIdInfor('')
            onClickShowDetail()
          }}
        >
          <PinInfor
            idInfor={idInfor}
            id={id}
            img={
              data?.category?.icon_url
                ? data?.category?.icon_url
                : data?.type_of_complaint?.icon_url
                ? data?.type_of_complaint?.icon_url
                : '-'
            }
            status={statusColor}
            clickPin={clickPin}
          />
        </div>
      </InfoWindow>
      {idInfor === id ? (
        <InfoWindow
          position={coordinates}
          options={{
            pixelOffset: new window.google.maps.Size(0, -20),
          }}
          zIndex={2}
        >
          {/* <BoxInfor>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p style={{ fontSize: '16px', fontWeight: '500', color: '#150F0B' }}>
                <img src={data?.category?.icon_url} className="icon-complate" />
                {data?.category?.name}
              </p>
              <TextStatus
                bg={
                  data?.status_data?.length
                    ? checkColorStatusBg(data?.status_data[data?.status_data?.length - 1]?.description)
                    : ''
                }
                text={
                  data?.status_data?.length
                    ? checkColorStatusText(data?.status_data[data?.status_data?.length - 1]?.description)
                    : ''
                }
              >
                {data?.status_data?.length ? data?.status_data[data?.status_data?.length - 1]?.description : '-'}
              </TextStatus>
            </div>
            <div style={{ display: 'grid', gridTemplateColumns: '2fr 1fr' }}>
              <div style={{ display: 'grid', gridTemplateColumns: '2fr 2fr' }}>
                <div>
                  <TextStyle>ผู้ร้องเรียน</TextStyle>
                  <p>
                    {data?.name?.first_name && data?.name?.last_name
                      ? `${data?.name?.first_name} ${data?.name?.last_name}`
                      : '-'}
                  </p>
                </div>
                <div>
                  <TextStyle>ประเภทเรื่องร้องเรียน</TextStyle>
                  <p> {data?.category?.name ? data?.category?.name : '-'}</p>
                </div>
                <div>
                  <TextStyle>ประเภทย่อย</TextStyle>
                  <p>{data?.sub_category_name ? data?.sub_category_name : '-'}</p>
                </div>
              </div>
              <div>
                <img className="img-complaint" src={data?.media_url[0]} />
              </div>
            </div>
            <hr></hr>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
              <div>
                <TextStyle>เลขที่เรื่องร้องเรียน</TextStyle>
                <p>{data?.complaint_no ? data?.complaint_no : '-'}</p>
              </div>
              <div>
                <TextStyle>เวลาและวันที่</TextStyle>
                <p>{conversFullDate(data?.created_at)}</p>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
              <Button onClick={() => setIdInfor('')} className="btn-close">
                ปิด
              </Button>
              <Button className="btn-update">อัปเดตผลการดำเนินการ</Button>
            </div>
          </BoxInfor> */}
          <WrapContainer>
            <Container>
              <BoxTitle>
                <TextTitle>
                  <div style={{ width: 'auto' }}>
                    <img
                      src={TestIcon}
                      alt="icon-cateogry"
                      style={{ width: '24px', height: '24px', marginRight: '8px' }}
                    />
                  </div>
                  <div style={{ width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {/* <span>ต้นไม้พันสายไฟฟ้าต้นไม้พันสายไฟฟ้าขยะล้นถังเหม็นมาก</span> */}
                    <span>
                      {data?.title_complaint
                        ? data?.title_complaint
                        : data?.complaint_name
                        ? data?.complaint_name
                        : '-'}
                    </span>
                  </div>
                </TextTitle>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                  <TestStatus color={checkColorStatusText(statusColor)} bgcolor={checkBgColorStatus(statusColor)}>
                    {statusColor ? statusColor : '-'}
                  </TestStatus>
                </div>
              </BoxTitle>
              <WrapBoxContent>
                <BoxContentLeft>
                  <BoxLocation>
                    <div style={{ marginRight: '1rem' }}>
                      <IconLocationComMe />
                    </div>
                    <div style={{ overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
                      <div>
                        <div className="title">สถานที่:</div>
                        <div
                          style={{
                            width: '100%',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          <p className="detail">{data?.address ? data?.address : '-'}</p>
                        </div>
                      </div>
                    </div>
                  </BoxLocation>
                  <BoxLocation>
                    <div style={{ marginRight: '1rem' }}>
                      <IconClockMe />
                    </div>
                    <div style={{ overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
                      <div>
                        <div className="title">วันและเวลาแจ้งเรื่อง:</div>
                        <div
                          style={{
                            width: '100%',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          <p className="detail">{convertUnixToDateTime(data?.created_at)}</p>
                        </div>
                      </div>
                    </div>
                  </BoxLocation>
                </BoxContentLeft>
                <BoxContentRight>
                  <img
                    src="https://cdn.pixabay.com/photo/2023/12/08/23/46/cat-8438334_1280.jpg"
                    alt="img"
                    style={{ width: '140px', height: '80px', borderRadius: '16px' }}
                  />
                </BoxContentRight>
              </WrapBoxContent>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <BoxLocation style={{ marginRight: '1rem' }}>
                  <div style={{ marginRight: '1rem' }}>
                    <IconCategoryMe />
                  </div>
                  <div style={{ ooverflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
                    <div>
                      <div className="title">ประเภท:</div>
                      <div
                        style={{
                          width: '100%',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        <p className="detail">
                          {data?.type_of_complaint?.name
                            ? data?.type_of_complaint?.name
                            : data?.category?.name
                            ? data?.category?.name
                            : '-'}
                        </p>
                      </div>
                    </div>
                  </div>
                </BoxLocation>
                <BoxLocation>
                  <div style={{ marginRight: '1rem' }}>
                    <IconNumberMe />
                  </div>
                  <div style={{ ooverflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
                    <div>
                      <div className="title">เลขที่:</div>
                      <div
                        style={{
                          width: '100%',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        <p className="detail">1008256600001</p>
                      </div>
                    </div>
                  </div>
                </BoxLocation>
              </div>
            </Container>
          </WrapContainer>
        </InfoWindow>
      ) : (
        ''
      )}
    </div>
  )
}

const BoxInfor = styled.div`
  width: 395px;
  height: auto;
  background: #ffffff;
  border-radius: 16px;
  padding: 20px;
  font-family: 'Prompt';
  .icon-complate {
    width: 18px;
    height: 18px;
    margin-right: 5px;
  }
  .img-complaint {
    border-radius: 10px;
    width: 144px;
    height: 82px;
  }
  .btn-close.ant-btn {
    box-shadow: none !important;
    cursor: pointer;
    color: #f36b24 !important;
    border: 1px solid #f36b24;
    background: #fff;
    width: 80px;
    height: 28px;
    border-radius: 4px;
    font-size: 12px;
  }
  .btn-update.ant-btn {
    box-shadow: none !important;
    cursor: pointer;
    color: #fff !important;
    border: 1px solid #f36b24;
    background: #f36b24;
    width: 150px;
    height: 28px;
    border-radius: 4px;
    font-size: 12px;
  }
  hr {
    border: 1px solid #ffeee7;
  }
`

const TextStyle = styled.div`
  color: #c4c4c4;
  font-size: 10px;
  font-weight: 400;
`
const TextStatus = styled.div`
  width: auto;
  height: 24px;
  background: ${props => (props?.bg ? props?.bg : '')};
  border-radius: 4px;
  text-align: center;
  padding: 3px;
  color: ${props => (props?.text ? props?.text : '')};
`

const WrapContainer = styled.div`
  font-family: 'Prompt';
  padding: 12px;
  background-color: #fffaf8;
  box-shadow: 0px 2px 10px 0px rgba(244, 244, 244, 1);
  margin-right: 10px;
  border-radius: 24px;
  margin-bottom: 8px;
  cursor: pointer;
`

const Container = styled.div`
  background-color: #ffffff;
  height: 221px;
  width: 100%;
  border-radius: 28px;
  padding: 24px;
  min-width: 406px;
`

const BoxTitle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`

const TextTitle = styled.div`
  display: flex;
  align-items: center;
  width: 60%;
`

const TestStatus = styled.div`
  font-family: 'Prompt';
  font-size: 12px;
  font-weight: 400;
  width: auto;
  border-radius: 6px;
  padding: 3px;
  background-color: ${props => (props.bgcolor ? props.bgcolor : '')};
  color: ${props => (props.color ? props.color : '')};
`

const WrapBoxContent = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
`

const BoxContentLeft = styled.div`
  width: 50%;
  margin-right: 5%;
`

const BoxContentRight = styled.div`
  width: 50%;
`

const BoxLocation = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  margin-bottom: 0.5rem;

  .title {
    color: #aba9a8;
    font-family: 'Prompt';
    font-weight: 400;
    font-size: 10px;
  }

  .wrap-detail {
    width: 100%;
  }

  .detail {
    color: #150f0b;
    font-family: 'Prompt';
    font-weight: 300;
    font-size: 12px;
    margin: 0px;
  }
`
