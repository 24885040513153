import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  align-items: center;
  .ant-btn-primary {
    display: flex;
    align-items: center;
    height: 45px;
    font-size: 16px;
    color: #fff;
    border-color: #00b7ad;
    border-radius: 20px;
    background: #00b7ad;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
    &:hover{
      background: #3F54D1 !important;
    }
  }

  .ant-btn .anticon {
    display: flex;
  }
`
