import React, { useMemo } from 'react'
import get from 'lodash.get'
import { Input, Popover } from 'antd'
import { ReactComponent as IconAllowClear } from 'assets/images/svg/icon-allowclear.svg'

import Message from 'components/output/Message'

import { ReactComponent as IconSurprised } from 'assets/images/svg/icon-logout-surprised.svg'
import { ReactComponent as IconBin } from 'assets/images/svg/icon-bin.svg'

import * as TA from './index.style'

const TextareaEditProfile = props => {
  const {
    setCurrent,
    label,
    name,
    placeholder,
    errors,
    touched: touches,
    form,
    required,
    disabled,
    uniqueFunc,
    uniqueValidate,
    rows,
    showCount = false,
    readOnly = false,
    autoSize,
    header,
    footer,
    textHeader,
    onDelete,
    isValidate,
  } = props
  let error = get(errors, name, '')
  const [touched, setTouched] = React.useState(get(touches, name, ''))
  const [trigger, setTrigger] = React.useState('')
  const [isMax, setIsMax] = React.useState(false)
  const [isFocus, setIsFocus] = React.useState(false)

  const value = get(form.values, name, '')

  const styleIcon = { width: 16, height: 16, cursor: 'pointer' }

  if (isMax && trigger === 'trigger') {
    error = `กรุุณากรอกไม่เกิน ${props.maxLength} ตัวอักษร`
  }

  function getStyles(error, touched) {
    if (error && touched) {
      return {
        border: '1px solid #ED4160',
        background: '#FFF6F8',
      }
    }
  }

  return (
    <TA.TextAreaContainer data-testid="test-textarea" isDisabled={disabled}>
      {/* {!header && (
        <label htmlFor={name} style={{ display: 'flex' }}>
          {label}
          {required && (
            <div style={{ paddingLeft: props.requiredPadding ? props.requiredPadding : '4px' }} className="red-star">
              *
            </div>
          )}
        </label>
      )} */}
      <div className="input">
        {textHeader && (
          <>
            <label className={`headet-input ${isFocus ? 'focus-input' : ''}`}>
              <label>
                {textHeader}
                {required && (
                  <div
                    style={{ paddingLeft: props.requiredPadding ? props.requiredPadding : '4px' }}
                    className="red-star"
                  >
                    *
                  </div>
                )}
              </label>

              {!disabled ? (
                <Popover
                  placement="left"
                  content="ข้อความตอบกลับ จะถูกส่งในรูปแบบการสุ่มตอบ"
                  getPopupContainer={triggerNode => triggerNode.parentNode}
                >
                  <IconSurprised style={styleIcon} />
                </Popover>
              ) : (
                <IconSurprised style={styleIcon} />
              )}
            </label>
            {isValidate && error && touched && (
              <div className={`custom-validate ${isFocus ? 'focus-input-validate' : ''}`}>
                <Message style={{ fontWeight: 400 }}>{error}</Message>
              </div>
            )}
          </>
        )}
        <Input.TextArea
          className={header ? 'custom-input' : ''}
          autoSize={autoSize && autoSize}
          rows={rows ? rows : 4}
          name={name}
          autoComplete="off"
          placeholder={placeholder}
          readOnly={readOnly}
          onChange={form.handleChange}
          onFocus={() => setIsFocus(true)}
          onBlur={e => {
            setIsFocus(false)

            if (uniqueValidate) uniqueValidate(true)
            if (errors) return
            form.handleBlur(e)
          }}
          value={value}
          disabled={disabled}
          style={!isValidate && getStyles(error, touched)}
          maxLength={props.maxLength}
          onKeyDown={() => setCurrent && setCurrent()}
          onKeyUp={() => {
            setCurrent && setCurrent()
            setTouched(true)
            if (value.length === props.maxLength) {
              setIsMax(true)
            }
            if (isMax) {
              setTrigger('trigger')
            }
            if (value.length < props.maxLength) {
              setIsMax(false)
              setTrigger('')
            }
            if (uniqueFunc) uniqueFunc(false)
          }}
          onClick={() => {
            setTouched(true)
            if (uniqueFunc) uniqueFunc(false)
          }}
          onMouseLeave={() => {
            if (uniqueValidate) uniqueValidate(true)
          }}
          allowClear={{ clearIcon: <IconAllowClear /> }}
        />
        {footer && (
          <div className="icon-bin">
            <IconBin
              style={styleIcon}
              onClick={e => {
                e.stopPropagation()
                onDelete && onDelete()
              }}
            />
          </div>
        )}

        {!isValidate && error && touched ? (
          <Message style={{ fontWeight: 400 }}>{error}</Message>
        ) : showCount ? (
          <div style={{ textAlign: 'right', color: '#597081', fontWeight: 400 }}>
            {value.length}/{props.maxLength}
          </div>
        ) : null}
      </div>
    </TA.TextAreaContainer>
  )
}

TextareaEditProfile.defaultProps = {
  type: 'textarea',
  placeholder: 'Input text',
}

export default TextareaEditProfile
