import styled from 'styled-components'
import { Radio } from 'antd'

export const RadioContainer = styled(Radio.Group)`
  width: 100%;
  display: contents;

  .ant-radio-button-wrapper:first-child {
    border-radius: 16px 0 0 16px;
  }

  .ant-radio-button-wrapper:last-child {
    border-radius: 0px 16px 16px 0px;
  }

  .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']) {
    background: #23146c;

    span {
      color: white;
    }
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: #23146c;
    width: 0 !important;
  }

  .ant-radio-button-wrapper {
    color: #1d1d42;
    border: none;
    background: var(--white);
    box-shadow: 0px 1px 10px #eaeaea;
    padding: 0 24px !important;
    height: 54px;
    line-height: 54px !important;

    span {
      font-size: 18px !important;
    }

    @media only screen and (max-width: 768px) {
      height: 40px !important;
      line-height: 40px !important;
      width: 33.33%;
      padding: 0 20px !important;

      span {
        font-size: 14px !important;
        display: flex;
        justify-content: center;
      }
    }
  }

  .ant-radio-button-wrapper:not(:first-child)::before {
    height: 50% !important;
    top: 14px !important;
    background-color: #e6e6e6 !important;

    @media only screen and (max-width: 768px) {
      top: 10px !important;
    }
  }
`
