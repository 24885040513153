import React from 'react'
import get from 'lodash.get'
import { Input } from 'antd'
import Message from 'components/output/Message'

import * as P from './password.style'

const Password = props => {
  const {
    label,
    name,
    type,
    placeholder,
    errors,
    touched: touches,
    form,
    required,
    disabled,
    fontsize,
    values,
    onChange,
    uniqueFunc,
  } = props

  const error = get(errors, name, '')
  const [touched, setTouched] = React.useState(get(touches, name, ''))

  return (
    <P.InputContainer data-testid="test-password">
      <label style={{ display: 'flex', fontSize: fontsize || '16px' }} htmlFor={name}>
        {label}
        <div style={{ paddingLeft: props.requiredPadding ? props.requiredPadding : '4px' }} className="red-star">
          {' '}
          {required && '*'}
        </div>
      </label>
      <div className="input">
        <Input.Password
          type={type}
          name={name}
          value={values && values}
          autoComplete="new-password"
          placeholder={placeholder}
          onChange={onChange ? onChange : form?.handleChange}
          disabled={disabled}
          onClick={() => {
            setTouched(true)
            if (uniqueFunc) uniqueFunc(false)
          }}
        />
        {error && touched && <Message style={{ fontWeight: 400 }}>{error}</Message>}
      </div>
    </P.InputContainer>
  )
}

export default Password
