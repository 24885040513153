import styled from 'styled-components'

export const CustomButton = styled.div`
  cursor: pointer;
  margin-top: 16px;
  width: 56px !important;
  height: 56px !important;
  border-radius: 50%;
  background: ${props => (props.open ? 'linear-gradient(270deg, #214090 0%, #5276D2 97.8%)' : '#ffffff')};
  box-shadow: 1px 1px 21px rgba(128, 128, 128, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
`
export const TextTime = styled.p`
  margin-left: 5px;
  color: #b9b9b9;
  font-size: 10px;
`
export const Container = styled.div`
  position: relative;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: end;
  -webkit-justify-content: flex-end;
  :hover {
    body {
      overflow: hidden;
    }
  }
  #scrollbar-chat::-webkit-scrollbar {
    width: 4px;
    height: 204px;
    background-color: #e7ebea;
  }
  #scrollbar-chat::-webkit-scrollbar-track {
    border-radius: 2px;
    background-color: #e7ebea;
  }
  #scrollbar-chat::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #b4c0bf;
  }
  img {
    border-radius: 5px;
  }
  .detail-chat {
    height: calc(100% - 60px);
    overflow: auto;
    padding: 5px 10px;
  }
  .title-chat-bot {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
  }
  .ant-divider-horizontal {
    display: flex;
    clear: both;
    width: 100%;
    min-width: 100%;
    margin: 0;
  }
  .ant-card-extra {
    padding: 0;
    height: 60px;
  }
  .ant-row,
  .ant-row:after,
  .ant-row:before {
    display: flex;
  }
  .ant-typography {
    margin-bottom: 4px;
  }

  .card-chat {
    .ant-card-body {
      padding: 0 !important;
    }
  }
  .ant-card-body {
    padding: 10px 15px;
  }
  .ant-card-head {
    min-height: 60px;
    margin-bottom: -1px;
    padding: 0 15px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 16px;
    background: transparent;
    border-bottom: none;
    border-radius: 2px 2px 0 0;
  }
  .ant-card-meta-detail {
    width: 100%;
  }
  .ant-input {
    border: none;
  }
  .ant-input.input-send-messen {
    background-color: #fff !important;
  }
  .ant-input:focus {
    border-color: none;
    box-shadow: none;
  }
  .ant-card-actions {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
  .ant-btn-default {
    width: 100% !important;
    height: 40px !important;
    background-color: #0c2e2c !important;
    border-color: #0c2e2c !important;
    color: #f6f6f6 !important;
    &:hover {
      background-color: #057a5e !important;
      border-color: #057a5e !important;
    }
  }

  .ant-card-body {
    height: 374px;
    position: relative;
  }

  .call-box-reveiw {
    cursor: pointer;
    position: absolute;
    bottom: 5px;
    background-color: darkgray;
    border-radius: 20px;
    padding: 3px 5px;
    font-size: 10px;
    color: #fff;
  }

  .ant-input-affix-wrapper {
    caret-color: #214090;
    outline: none;
    box-shadow: none;
  }
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #214090;
    box-shadow: none;
  }

  .ant-input-affix-wrapper:focus {
    box-shadow: none;
    border-color: #214090;
  }
  .ant-input-affix-wrapper:focused {
    box-shadow: none;
    border-color: #214090;
  }

  &.ant-btn {
    padding: 0px 15px !important;
  }
`
