import React, { useRef, useContext, useState, useEffect, useMemo } from 'react'
import { Dropdown, Menu, Divider, Input } from 'antd'
import { useNavigate, useLocation, useOutletContext } from 'react-router-dom'
import { useFormik } from 'formik'
import IconProfile from 'assets/images/svg/icon-profile-drawer.svg'
import { ReactComponent as NotiNormal } from 'assets/images/svg/icon-noti-normal.svg'
import { ReactComponent as NotiNew } from 'assets/images/svg/noti-new.svg'
import { ReactComponent as CloseNoti } from 'assets/images/svg/close-noti.svg'
import { ReactComponent as IconMore } from 'assets/images/svg/icon-more.svg'
import { ReactComponent as ArrowDown } from 'assets//images/svg/icon-arrow-down-header.svg'
import { ReactComponent as IconAllowClear } from 'assets/images/svg/icon-allowclear.svg'
import { ReactComponent as IconArrowRight } from 'assets/images/svg/icon-arrow-right.svg'
import { ReactComponent as IconLogout } from 'assets/images/svg/icon-logout.svg'
// import { ReactComponent as IconProfile } from 'assets/images/svg/profile-menu.svg'
import { ReactComponent as IconClear } from 'assets/images/svg/icon-close-drawer.svg'
import { ReactComponent as BackButtonMobile } from 'assets/images/svg/back-button-profile.svg'
import LogoKorat from 'assets/images/svg/logo-korat-city-header.svg'
import { ReactComponent as IconSearchMobile } from 'assets/images/svg/icon-search-header-mobile.svg'
// import { getById, getData, postData } from 'core/action/collection'
import EditProfileDrawer from './EditProfileDrawer'
import BoxNotification from 'components/BoxNotification'
import { noti } from 'core/schemas/index'

import * as Style from './Header.style'
import { CustomDrawer } from './Header.style'
import InputSearch from 'components/Inputs/InputSearch/InputSearch'
import liff from '@line/liff'
import { postData, getData, getList } from 'core/action/collection'
import { authentication, masterdata } from 'core/schemas'
import { httpClient } from 'core/action/httpClient'
import axios from 'axios'
import { mockUserData, defaultValues, Schema, mockDataNoti } from './container'
import DropdownEditProfile from 'components/Inputs/DropdownEditProfile/DropdownEditProfile'
import Loader from 'components/Layout/Loader'
import UserContext from 'core/contexts/userContext'
import styled from 'styled-components'
import { useSearchContext } from 'core/contexts/useSearch'
import { useNotificationContext } from 'core/contexts/useNotification'

const Header = ({ noLogo, noFix, setIsModalOpen, onSearch }) => {
  const { meData } = useContext(UserContext)
  const { setSharedValue } = useSearchContext()
  const { setNotiValue } = useNotificationContext()
  const baseURL = process.env.REACT_APP_BASEURL
  const token = localStorage.getItem('access_token')
  const header = useRef(null)
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const [current, setCurrent] = useState('home')
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [openSetting, setOpenSetting] = useState(false)
  const [openDrawer, setOpenDrawer] = useState(false)
  const [edit, setEdit] = React.useState(false)
  const [provinceId, setProvinceId] = useState('')
  const [provinceData, setProvinceData] = useState([])
  const [districtData, setDistrictData] = useState([])
  const [subDistrictData, setSubDistrictData] = useState([])
  const [districtId, setDistrictId] = useState('')
  const [subDistrictId, setSubDistrictId] = useState('')
  const [whileLoading, setWhileLoading] = useState(true)
  const [loading, setLoading] = useState(true)

  const [userId, setUserId] = useState('')
  const [firstName, setFirstName] = useState('-')
  const [lastName, setLastName] = useState('-')
  const [mobileNumber, setMobileNumber] = useState('-')
  const [groupUser, setGroupUser] = useState('-')
  const [address, setAddress] = useState('-')
  const [province, setProvince] = useState('-')
  const [district, setDistrict] = useState('-')
  const [subDistrict, setSubDistrict] = useState('-')
  const [postCode, setPostCode] = useState('-')
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [dataNoti, setDataNoti] = useState([])

  const [clickSearchResponsive, setClickSearchResponsive] = useState(false)

  const checkDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
  const isSizeMobile = 768

  const formik = useFormik({
    initialValues: defaultValues,
    // validationSchema: Schema,
    enableReinitialize: true,
    onSubmit: values => {},
  })

  useEffect(() => {
    window.addEventListener('resize', handleResize)
  }, [])

  const handleResize = () => {
    setWindowWidth(window.innerWidth)
  }
  const handleClickLogo = () => {
    if (token) {
      navigate(`/home`)
    } else {
      navigate('/home-guest')
    }
  }

  const onOpenDrawer = () => {
    setOpenDrawer(true)
  }

  const onCloseDrawer = () => {
    setOpenDrawer(false)
    setEdit(false)
  }

  const onChangeProvince = () => {
    formik.setFieldValue('district_id', '')
    formik.setFieldValue('postcode', '')
    formik.setFieldValue('sub_district_id', '')
    // getDistrict()
    // getSubDistrict()
  }

  const lineLogout = () => {
    liff
      .init({ liffId: '2000994746-0wglpjzY' })
      .then(() => {
        if (liff.isLoggedIn()) {
          liff.logout()
        } else {
          liff.logout()
        }
      })
      .catch(err => {
        console.log('err', err)
      })
  }

  const postLogout = async () => {
    const res = await postData(`${process.env.REACT_APP_BASEURL}authen/api/v1/logout`)

    if (res) {
      if (res?.code === 200) {
        console.log('logout')
      } else {
        console.log('logoutErr')
      }
    }
  }

  const getMasterData = async () => {
    await getMeHeader()
    await getNotification()
    // await getProvince()

    setLoading(false)
  }

  const getNotification = async () => {
    const res = await getList({
      schema: `${noti}/all`,
      params: {
        page: 1,
        per_page: 10,
        sorts: [`created_at:desc`],
        search: [],
        filters: [`line_id:eq:${meData?.line_id}`],
      },
      url: '',
      baseURL: '',
    })

    if (res) {
      if (res?.code === 200) {
        setDataNoti(res?.items)
      }
    }
  }

  const getMeHeader = async () => {
    formik.setFieldValue('first_name', meData?.name?.first_name)
    formik.setFieldValue('last_name', meData?.name?.last_name)
    formik.setFieldValue('mobile_number', meData?.mobile_number)
    formik.setFieldValue('group_id', meData?.group_id)
    formik.setFieldValue('address', meData?.address)
    formik.setFieldValue('province_id', meData?.province?.id)
    formik.setFieldValue('district_id', meData?.district?.id)
    formik.setFieldValue('sub_district_id', meData?.sub_district?.id)
    formik.setFieldValue('postcode', meData?.sub_district?.post_code)

    setUserId(meData?.id)
    setFirstName(meData?.name?.first_name)
    setLastName(meData?.name?.last_name)
    setMobileNumber(meData?.mobile_number)
    setGroupUser(meData?.group?.name)
    setAddress(meData?.address)
    setProvince(meData?.province?.name_th)
    setDistrict(meData?.district?.name_th)
    setSubDistrict(meData?.sub_district?.name_th)
    setPostCode(meData?.sub_district?.post_code)

    setProvinceId(meData?.province?.id)
    setDistrictId(meData?.district?.id)
  }

  const onLogout = () => {
    lineLogout()
    postLogout()
    localStorage.clear()
    // localStorage.removeItem('token')
    navigate('/login')
  }

  // const BoxRightResposive = (
  //   <Style.BoxRight>
  //     <IconSearchMobile style={{ cursor: 'pointer' }} onClick={() => setClickSearchResponsive(true)} />
  //     <Dropdown
  //       overlay={boxnoti}
  //       trigger={['click']}
  //       onVisibleChange={toggleDropdown}
  //       placement="bottom"
  //       open={isDropdownOpen}
  //     >
  //       <NotiNormal style={{ margin: '0 12px' }} />
  //     </Dropdown>
  //     <img src={IconProfile} alt="profile" style={{ width: '24px', height: '24px' }} onClick={onOpenDrawer} />
  //   </Style.BoxRight>
  // )

  const BoxSearch = () => {
    return (
      <>
        <div
          style={{
            padding: '0px 8px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <InputSearch
            placeholder="พิมพ์เบอร์โทรศัพท์ที่แจ้ง หรือเลขที่เรื่องร้องเรียน"
            onPressEnter={onPressEnter}
            handleClearValueSearch={handleClearValueSearch}
          />
          <div
            onClick={() => {
              setClickSearchResponsive(false)
              setSharedValue('')
            }}
            style={{ color: '#F36B24' }}
          >
            ยกเลิก
          </div>
        </div>
      </>
    )
  }

  const menu = (
    <Style.WrapDropdownProfile>
      <Style.MenuProfile onClick={onOpenDrawer}>
        <img src={IconProfile} alt="profile" style={{ width: '48px', height: '48px', marginRight: '1rem' }} />
        <div style={{ width: '182px', wordBreak: 'break-word', textOverflow: 'ellipsis', fontFamily: 'Prompt' }}>
          <span>
            {firstName} {lastName}
          </span>
        </div>
        <IconArrowRight style={{ marginLeft: '1.375rem' }} />
      </Style.MenuProfile>
      <Divider style={{ border: '1px solid #ECE9E9', margin: '1rem 0' }} />
      <Style.MenuLogout onClick={onLogout}>
        <IconLogout style={{ marginRight: '1rem' }} />
        <span>ออกจากระบบ</span>
      </Style.MenuLogout>
    </Style.WrapDropdownProfile>
  )

  const boxnoti = () => {
    return (
      <>
        <Style.WrapBackgroundNoti>
          {dataNoti?.length > 0 ? (
            dataNoti?.map((items, index) => <BoxNotification data={items} />)
          ) : (
            <Style.WrapBackgroundNoti>
              <p>ไม่มีการแจ้งเตือน</p>
            </Style.WrapBackgroundNoti>
          )}
        </Style.WrapBackgroundNoti>
      </>
    )
  }

  useEffect(() => {
    // getProfileLine()
    getMasterData()
  }, [])

  const phoneNumberAutoFormat = phoneNumber => {
    const number = phoneNumber.trim().replace(/[^0-9]/g, '')

    if (number.length < 4) return number
    if (number.length < 7) return number.replace(/(\d{3})(\d{1})/, '$1-$2')
    if (number.length < 11) return number.replace(/(\d{3})(\d{3})(\d{1})/, '$1-$2-$3')
    return number.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
  }

  const onPhoneNumberChange = e => {
    e.preventDefault()
    const targetValue = phoneNumberAutoFormat(e.target.value)
    formik.setFieldValue('mobile_number', targetValue)
  }

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen)

    if (!isDropdownOpen) {
      getNotification()
    }
  }

  const onPressEnter = value => {
    setSharedValue(value)
  }

  const handleClearValueSearch = () => {
    setSharedValue('')
  }

  // const boxnoti = (
  //   <>
  //     <Style.WrapBackgroundNoti>
  //       {dataNoti?.map((items, index) => (
  //         <BoxNotification data={items} setNotiValue={setNotiValue} key={items?.id} toggleDropdown={toggleDropdown} />
  //       ))}
  //     </Style.WrapBackgroundNoti>
  //   </>
  // )

  const BoxHeaderResponsive = () => {
    return (
      <>
        {clickSearchResponsive ? (
          BoxSearch()
        ) : (
          <>
            <Style.Logos
              onClick={() => handleClickLogo()}
              style={{
                cursor: 'pointer',
              }}
            >
              <img src={LogoKorat} alt="LogoIcon" className="logo-navbar" />
              <div className="box-logo-text">
                <span className="text-line-1">เทศบาลนครนครราชสีมา</span>
                <span className="text-line-2">ระบบร้องเรียนและบริการสาธารณะ</span>
              </div>
            </Style.Logos>
            <Style.BoxRight>
              <IconSearchMobile style={{ cursor: 'pointer' }} onClick={() => setClickSearchResponsive(true)} />
              {token ? (
                <>
                  <Dropdown
                    overlay={boxnoti}
                    trigger={['click']}
                    onVisibleChange={toggleDropdown}
                    placement="bottom"
                    open={isDropdownOpen}
                  >
                    <NotiNormal style={{ margin: '0 12px' }} />
                  </Dropdown>
                  <img
                    src={IconProfile}
                    alt="profile"
                    style={{ width: '24px', height: '24px' }}
                    onClick={onOpenDrawer}
                  />
                </>
              ) : (
                ''
              )}
            </Style.BoxRight>
          </>
        )}
      </>
    )
  }

  return (
    <>
      {/* <Loader loading={loading}> */}
      <Style.Container
        isSizeMobile={isSizeMobile}
        // noLogo={noLogo ? 'yes' : 'no'}
        ref={header}
        id="sticky"
        // bgImg={BGHeader}
      >
        {windowWidth <= isSizeMobile ? (
          BoxHeaderResponsive()
        ) : (
          <>
            <Style.Logos
              onClick={() => handleClickLogo()}
              style={{
                cursor: 'pointer',
              }}
            >
              <img src={LogoKorat} alt="LogoIcon" className="logo-navbar" />
              <div className="box-logo-text">
                <span className="text-line-1">เทศบาลนครนครราชสีมา</span>
                <span className="text-line-2">ระบบร้องเรียนและบริการสาธารณะ</span>
              </div>
            </Style.Logos>

            <Style.BoxRight>
              <InputSearch
                placeholder="พิมพ์เบอร์โทรศัพท์ที่แจ้ง หรือเลขที่เรื่องร้องเรียน"
                onPressEnter={onPressEnter}
                handleClearValueSearch={handleClearValueSearch}
              />
              {token && (
                <>
                  <div style={{ marginLeft: '24px', marginRight: '16px' }}>
                    <Dropdown
                      overlay={boxnoti}
                      trigger={['click']}
                      onVisibleChange={toggleDropdown}
                      placement="bottom"
                      open={isDropdownOpen}
                    >
                      {isDropdownOpen ? (
                        <CloseNoti style={{ cursor: 'pointer' }} />
                      ) : dataNoti?.find(item => item?.is_read === false || item?.is_read === null) ? (
                        <NotiNew style={{ cursor: 'pointer' }} />
                      ) : (
                        <NotiNormal style={{ cursor: 'pointer' }} />
                      )}
                    </Dropdown>
                  </div>
                  <Dropdown overlay={menu} trigger={['click']}>
                    <Style.BoxName>
                      <img src={IconProfile} alt="profile" />
                      <p>{firstName}</p>
                      <ArrowDown style={{ cursor: 'pointer' }} />
                    </Style.BoxName>
                  </Dropdown>
                </>
              )}
            </Style.BoxRight>
          </>
        )}
      </Style.Container>

      <CustomDrawer
        placement="right"
        onClose={onCloseDrawer}
        closable={false}
        open={openDrawer}
        closeIcon={<IconClear />}
        width={600}
        key={openDrawer ? 'openDw' : 'closeDw'}
        className="drawer-profile"
      >
        <EditProfileDrawer
          onCloseDrawer={onCloseDrawer}
          getMeHeader={getMeHeader}
          onLogout={onLogout}
          statusEdit={edit}
        />
      </CustomDrawer>
      {/* </Loader> */}
    </>
  )
}

export default Header
