export const mockDataNoti = [
  {
    title: 'ท่อน้ำแตกชำรุด',
    description: 'ท่อแตก น้ำท่วมถึงหัวเข่า',
    image: 'https://cdn.pixabay.com/photo/2023/09/18/13/46/beach-8260614_1280.jpg',
  },
  {
    title: 'น้ำประปาไม่ไหล/ไหลอ่อน',
    description: 'น้ำไหลเบา บ่อย',
    image: 'https://cdn.pixabay.com/photo/2016/10/18/21/22/beach-1751455_1280.jpg',
  },
  {
    title: 'ท่อน้ำแตกชำรุด',
    description: 'ท่อแตก น้ำท่วมถึงหัวเข่า',
    image: 'https://cdn.pixabay.com/photo/2018/08/14/13/23/ocean-3605547_1280.jpg',
  },
  {
    title: 'ท่อน้ำแตกชำรุด',
    description: 'ท่อแตก น้ำท่วมถึงหัวเข่า',
    image: 'https://cdn.pixabay.com/photo/2018/08/14/13/23/ocean-3605547_1280.jpg',
  },
  {
    title: 'ท่อน้ำแตกชำรุด',
    description: 'ท่อแตก น้ำท่วมถึงหัวเข่า',
    image: 'https://cdn.pixabay.com/photo/2018/08/14/13/23/ocean-3605547_1280.jpg',
  },
]
