import React, { useState, useEffect } from 'react'
import {
  Container,
  NotiStyle,
  BoxTitle,
  CustomDrawer,
  CustomDivider,
  CustomInputDrawer,
  BtSubmit,
  CustomheadTime,
  StyleDropdown,
  CustomPopover,
  LabelText,
  CustomUploadfile,
  ButtonOpenMap,
  LabelTexthead,
  ClearableTextArea,
  BoxContentDetail,
  BoxSeeDetail,
  WrapBoxComplaint,
  WrapMap,
  ButtonBack,
  BoxBtnInMap,
  DetailLocation,
} from './FormCreateEservice.style.'

import { postData, putData, getData, getList } from 'core/action/collection'

import DropdownEditProfile from 'components/Inputs/DropdownEditProfile/DropdownEditProfile'
import InputComponent from 'components/input/Input'
import { mockUserData, defaultValues, Schema } from './container'
import iconAdd from 'assets/images/svg/icon-add-org.svg'
import iconTime from 'assets/images/svg/icon-time-head.svg'
import iconInfo from 'assets/images/svg/icon-info.svg'
import iconpinLocation from 'assets/images/svg/icon-pin-location.svg'
import iconarrowright from 'assets/images/svg/icon-arrow-right-org.svg'
// import iconclose from 'assets/images/svg/icon-close.svg'
import { ReactComponent as IconClear } from 'assets/images/svg/icon-clear-complain.svg'
// import IconAllowClear from 'assets/images/svg/icon-add-org.svg'
import iconHome from 'assets/images/png/icon-homepage.png'
import support from 'assets/images/png/logo-support.png'
import lineqrcode from 'assets/images/png/lineqrcode.png'
import { ReactComponent as IconClose } from 'assets/images/svg/icon-close.svg'
import { ReactComponent as IconSurprised } from 'assets/images/svg/icon-logout-surprised.svg'
import { authentication, masterdata, complaint } from 'core/schemas'
import { httpClient } from 'core/action/httpClient'
import axios from 'axios'
import UploadFile from 'components/Inputs/UploadFile'
import { ReactComponent as IconClearinput } from 'assets/images/svg/icon-clear-orange.svg'
import { ReactComponent as IconArrowLeft } from 'assets/images/svg/icon-arrow-left-org.svg'
import TestIcon from 'assets/images/svg/tset-icon-category.svg'
import { ReactComponent as ButtonCloseSeeDeail } from 'assets/images/svg/button-close-see-detail.svg'
import MapButton from './GoogleMap/Map'
import MapHome from './GoogleMap/MapHome'
import { disable } from 'ol/rotationconstraint'
import BoxComplaintMe from 'components/BoxComplaintMe/BoxComplaintMe'
import { useFormik } from 'formik'
import { useNavigate, useParams } from 'react-router-dom'
import liff from '@line/liff'
import Viewmap from './MapSelect'
import useGetAddress from 'core/hooks/useGetAddress'
import { conversDate } from 'core/utils/DateTimeFormat'
import moment from 'moment'
import NakhonRatchasima from 'core/utils/NakhonRatchasima_municipality.json'
import { Button, Modal } from 'antd'
import { ReactComponent as CloseModelMap } from './image/svg/CloseModelMap.svg'
import BeforeIcon from './image/png/Before Success.png'

const Index = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  const [formattedDate, setFormattedDate] = useState('')
  const [provinceId, setProvinceId] = useState('')
  const [provinceData, setProvinceData] = useState([])
  const [districtData, setDistrictData] = useState([])
  const [subDistrictData, setSubDistrictData] = useState([])
  const [categories, setCategories] = useState([])
  const [districtId, setDistrictId] = useState('')
  const [subDistrictId, setSubDistrictId] = useState('')
  const [cover, setCover] = useState([])
  const [locationme, setlocationme] = useState({})
  const [submit, setsubmit] = useState({})
  const [categoryId, setCategoryId] = useState('')
  const [subCategories, setSubCategory] = useState([])
  const [lineId, setLineId] = useState('')
  const [dataById, setDataById] = useState('')

  const [openDrawerSuccess, setOpenDrawerSuccess] = useState(false)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [clickedLatLng, setClickedLatLng] = React.useState(null)
  const [meLocation, setMeLocation] = useState({ lat: 0, lng: 0 })
  const [isModalOpenError, setIsModalOpenError] = useState(false)
  const [autocompleteValue, setAutocompleteValue] = useState(null)
  const [searchValue, setSearchValue] = useState('')
  const [centerMap, setCenterMap] = useState({ lat: 0, lng: 0 })
  const [zoomMap, setZoomMap] = useState(14)
  const [loaddingCraete, setloaddingCraete] = useState(false)
  const [disableBtn, setDisableBtn] = useState(false)

  const address = useGetAddress(clickedLatLng ? clickedLatLng : meLocation)

  let dataNow = conversDate(moment().unix())
  let arrArear = []
  NakhonRatchasima?.features[0]?.geometry?.coordinates[0]?.map((item, index) => {
    arrArear.push({ lat: item[1], lng: item[0] })
  })

  const [defaultValueFromList, setDefaultValueFromList] = React.useState({
    first_name: '',
    last_name: '',
    mobile_number: '',
    groupuser: '',
    address: '',
    province: '',
    district: '',
    sub_districte: '',
    postcode: '',
    categories: '',
    title_complaint: '',
    description_complain: '',
  })

  const formik = useFormik({
    initialValues: defaultValueFromList,
    validationSchema: Schema,
    // onSubmit: handlerSubmit,
    enableReinitialize: true,
    onSubmit: values => {
      // console.log(values)
    },
  })

  useEffect(() => {
    const fetchUserLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            const { latitude, longitude } = position.coords
            setMeLocation({ lat: latitude, lng: longitude })
            setCenterMap({ lat: latitude, lng: longitude })
          },
          error => {
            console.error('Error getting location:', error.message)
          }
        )
      } else {
        console.error('Geolocation is not supported by this browser.')
      }
    }
    fetchUserLocation() // Call the function when the component mounts
  }, [])

  useEffect(() => {
    getCategories()
    getComplainById()
    // getProfileLine()
  }, [])

  useEffect(() => {
    categoryId !== '' && getSubCategory()
  }, [categoryId])

  const checkimg = cover.length > 0 ? true : false
  const checkmap = Object.keys(locationme).length === 0 || locationme == {} ? false : true
  const isValidForm = formik.isValid && formik.dirty && !disableBtn

  const token = localStorage.getItem('access_token')

  const getComplainById = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASEURL}complaint/api/v1/e-service/complaint/` + id, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => {
        if (res?.data?.code === 200) {
          let dataById = res?.data?.data
          setDataById(dataById)
          setClickedLatLng({
            lat: dataById?.coordinates?.latitude,
            lng: dataById?.coordinates?.longitude,
          })
          const mobile = phoneNumberAutoFormat(dataById?.mobile_number)
          formik.setFieldValue('first_name', dataById?.name?.first_name)
          formik.setFieldValue('last_name', dataById?.name?.last_name)
          formik.setFieldValue('mobile_number', mobile)
          formik.setFieldValue('description_complain', dataById?.description)
        }
      })
      .catch(err => {
        console.log('err', err)
      })
  }

  const getCategories = async () => {
    const res = await getData({
      schema: `${masterdata}/all/eservice`,
      params: {},
      url: '',
      baseURL: '',
    })
    // setSubDistrictData(res?.data)
    if (res) {
      const newData = res.data
      setCategories(newData)
    }
  }

  const getSubCategory = async () => {
    const res = await getData({
      schema: `${masterdata}/all/subcategories/` + categoryId,
      params: {},
      url: '',
      baseURL: '',
    })

    if (res) {
      const newData = res.data
      setSubCategory(newData)
    }
  }

  const optionsCategories = categories?.map((items, index) => ({
    value: items?.id,
    label: items?.name,
    icon_url: items?.icon_url,
    key: index,
  }))

  const optionsSubCategories = subCategories?.map((items, index) => ({
    value: items?.ID,
    label: items?.name,
    key: index,
  }))

  const DatetimeNowThai = () => {
    const currentDate = new Date()

    const options = {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      eraDisplay: 'narrow',
    }

    const thaiLocale = 'th-TH'

    // Format the date using Intl.DateTimeFormat
    const formattedDate = new Intl.DateTimeFormat(thaiLocale, options).format(currentDate)
    setFormattedDate(formattedDate)
  }

  useEffect(() => {
    DatetimeNowThai()
  }, [])

  const getProfileLine = async () => {
    await liff
      .init({ liffId: '2000994746-0wglpjzY' })
      .then(() => {
        if (liff.isLoggedIn()) {
          liff
            .getProfile()
            .then(profile => {
              setLineId(profile.userId)
            })
            .catch(err => console.error(err))
        } else {
          liff.login()
        }
      })
      .catch(err => {
        console.log('err', err)
        localStorage.clear()
        navigate('/login')
      })
  }

  const phoneNumberAutoFormat = phoneNumber => {
    const number = phoneNumber.trim().replace(/[^0-9]/g, '')

    if (number.length < 4) return number
    if (number.length < 7) return number.replace(/(\d{3})(\d{1})/, '$1-$2')
    if (number.length < 11) return number.replace(/(\d{3})(\d{3})(\d{1})/, '$1-$2-$3')
    return number.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
  }

  const onPhoneNumberChange = e => {
    const targetValue = phoneNumberAutoFormat(e.target.value)
    formik.setFieldValue('mobile_number', targetValue)
  }

  const uniqueValidate = value => {
    if (value?.mobile_number?.length < 12) {
      setTimeout(() => {
        formik.setErrors({ mobile_number: 'รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง' })
      }, 100)
    }
    if (formik.values.mobile_number.length < 12) {
      formik.setErrors({ mobile_number: 'รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง' })
    }
  }

  const onChangeProvince = () => {
    formik.setFieldValue('district_id', '')
    formik.setFieldValue('postcode', '')
    formik.setFieldValue('sub_district_id', '')
    // getDistrict()
    // getSubDistrict()
  }

  const handleDrawerOptionClick = (value, fieldFormik) => {
    if (fieldFormik === 'group_id') {
      formik.setFieldValue('group_id', value)
    }
    if (fieldFormik === 'province_id') {
      formik.setFieldValue('province_id', value)
      formik.setFieldValue('postcode', '')
      formik.setFieldValue('district_id', '')
      formik.setFieldValue('sub_district_id', '')
      setProvinceId(value)
    }
    if (fieldFormik === 'district_id') {
      formik.setFieldValue('district_id', value)
      formik.setFieldValue('postcode', '')
      formik.setFieldValue('sub_district_id', '')
      setDistrictId(value)
    }
    if (fieldFormik === 'sub_district_id') {
      formik.setFieldValue('sub_district_id', value)
      setSubDistrictId(value)
    }
  }

  const onChangeDistrict = async value => {
    formik.setFieldValue('postcode', '')
    formik.setFieldValue('sub_district_id', '')
    // getSubDistrict()
  }

  const checkArear = val => {
    const bermudaTriangle = new window.google.maps.Polygon({
      paths: arrArear,
    })
    const pointToCheck = new window.google.maps.LatLng(val?.lat, val?.lng)
    if (
      window.google.maps.geometry &&
      window.google.maps.geometry.poly &&
      window.google.maps.geometry.poly.containsLocation
    ) {
      const isInside = window.google.maps.geometry.poly.containsLocation(pointToCheck, bermudaTriangle)
      return isInside
    } else {
      console.error("Google Maps 'containsLocation' method is not available.")
      return false // Return false assuming the location is outside if the check cannot be performed
    }
  }

  const handlerUpdate = async () => {
    const phone = formik.values.mobile_number.replace(/-/g, '')

    const { first_name, last_name, type_of_complaint, title_complaint, description_complain } = formik.values

    const dataurl = []

    cover.map(item => {
      dataurl.push('https://' + item.urlkey)
    })

    let isInsidePolygon = await checkArear({
      lat: clickedLatLng?.lat || meLocation?.lat,
      lng: clickedLatLng?.lng || meLocation?.lng,
    })

    if (!isInsidePolygon) {
      setIsModalOpenError(true)
      setDisableBtn(true)
    } else {
      const body = {
        address: dataById?.address,
        type_of_complaint: dataById?.type_of_complaint,
        channel: 'Web application',
        title_complaint: dataById?.title_complaint,
        coordinates: {
          latitude: dataById?.coordinates?.latitude,
          longitude: dataById?.coordinates?.longitude,
        },
        description: dataById?.description,
        line_id: dataById?.lineId,
        media_url: dataById?.media_url,
        mobile_number: dataById?.mobile_number,
        name: {
          first_name: dataById?.name?.first_name,
          last_name: dataById?.name?.last_name,
        },
        org_id: '19118133238380',
        province_id: dataById?.province_id,
        sub_district_id: dataById?.sub_district_id,
        urgency_id: dataById?.urgency_id,
        zib_code: dataById?.zib_code,
      }
      const res = await putData(`${complaint}/e-service/complaint`, dataById?.id, body)
      if (res.code === 201 || 200) {
        localStorage.setItem('notiEdit', 'true')
        navigate('/')
      } else {
        console.log('Err', res)
      }
    }
  }

  const handleOk = () => {
    if (clickedLatLng?.lat || clickedLatLng?.lng) {
      setIsModalOpen(false)
      // handleChangeForm()
    } else {
      if (checkArear(meLocation)) {
        setClickedLatLng({
          lat: meLocation?.lat,
          lng: meLocation?.lng,
        })
        setIsModalOpen(false)
        // handleChangeForm()
      } else {
        setIsModalOpenError(true)
      }
    }
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const handleClickMap = event => {
    if (isModalOpen) {
      const cord = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      }

      if (checkArear(cord)) {
        setAutocompleteValue('')
        setSearchValue('')
        setClickedLatLng({
          lat: event.latLng.lat(),
          lng: event.latLng.lng(),
        })
        setCenterMap({
          lat: event.latLng.lat(),
          lng: event.latLng.lng(),
        })
        setZoomMap(14)
      } else {
        setIsModalOpenError(true)
      }
    }
  }

  return (
    <>
      <div style={{ padding: '0 7%' }}>
        <div style={{ height: '3.5em', position: 'relative', marginTop: '40px' }}>
          {/* <div className="close-icon" onClick={() => oncloseComplain()}>
            <IconClear />
          </div> */}
          <div className="title" style={{ display: 'flex' }}>
            <ButtonBack
              shape="circle"
              icon={<IconArrowLeft />}
              onClick={() => navigate('/')}
              style={{ marginRight: '8px' }}
            />
            <h1>แจ้งขอรับบริการสาธารณะ</h1>
          </div>
        </div>

        {formik.values && (
          <>
            <Container>
              <form onSubmit={formik.handleSubmit}>
                <div style={{ padding: '25px 0px 20px 0px' }}>
                  <CustomheadTime>
                    <div>
                      <img src={iconTime} />
                    </div>
                    <div>เวลาแจ้งเรื่อง :</div>
                    <div
                      style={{
                        fontSize: '16px',
                        fontWeight: '400',
                      }}
                    >
                      {' '}
                      {formattedDate}{' '}
                    </div>
                  </CustomheadTime>

                  <div className="row-data">
                    <div className="col-1">
                      <div style={{ position: 'relative' }}>
                        <InputComponent
                          label="รายละเอียดเพิ่มเติม (ถ้ามี)"
                          type="textarea"
                          name="description_complain"
                          placeholder="ระบุรายละเอียดบริเวณที่เกิดปัญหา หรือรายละเอียดของ
                          ปัญหาเพิ่มเติม"
                          required={false}
                          value={formik.values.description_complain}
                          errors={formik.errors}
                          touched={formik.touched}
                          form={formik}
                          status={formik.status}
                          // maxLength={255}
                          height={120}
                          fontsize={14}
                        />
                        <ClearableTextArea
                          style={{ display: formik?.values?.description_complain.length > 0 ? '' : 'none' }}
                        >
                          <IconClearinput
                            onClick={async () => {
                              formik.setFieldValue('description_complain', '')
                            }}
                          />
                        </ClearableTextArea>
                      </div>
                    </div>
                  </div>

                  <div className="row-data" style={{ margin: '1rem 0rem' }}>
                    <div className="col-1">
                      {/* <MapButton
                        OnReturnLocation={(lat, lng) => {
                          setlocationme({
                            lat: lat,
                            lng: lng,
                          })
                        }}
                        submitcheck={submit}
                      ></MapButton> */}
                      <div>
                        <div style={{ marginBottom: '10px' }}>สถานที่แจ้งเรื่อง</div>

                        <div>
                          <ButtonOpenMap onClick={setIsModalOpen}>
                            <div className="col-location-1">
                              <img src={iconpinLocation} />
                            </div>
                            <div className="col-location-2">
                              <div>เลือกตำแหน่งที่ตั้ง</div>
                              <DetailLocation text={address[2]?.formatted_address}>
                                {address[2]?.formatted_address}
                              </DetailLocation>
                            </div>
                            <div className="col-location-3">
                              <img src={iconarrowright} alt="arrow" />
                            </div>
                          </ButtonOpenMap>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row-data">
                    <div className="col-1">
                      <BtSubmit onClick={() => handlerUpdate()} disabled={!isValidForm}>
                        ส่งเรื่องขอรับบริการ
                      </BtSubmit>
                    </div>
                  </div>
                </div>
              </form>
            </Container>
          </>
        )}

        <CustomDrawer placement="right" closable={false} open={openDrawerSuccess} closeIcon={<IconClear />} width={600}>
          <div
            style={{
              position: 'relative',
              padding: '160px 64px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div
              className="title"
              style={{
                fontFamily: 'Prompt',
                fontSize: '24px',
                fontWeight: '500',
              }}
            >
              <h1>ส่งเรื่องร้องเรียนสำเร็จ</h1>
            </div>
            <div
              style={{
                marginTop: '80px',
                boxShadow: '0px 2px 10px 0px #F4F4F4',
              }}
            >
              <img src={lineqrcode} />
            </div>

            <div
              style={{
                fontFamily: 'Prompt',
                fontSize: '16px',
                fontWeight: '500',
                marginTop: '16px',
              }}
            >
              <div>ท่านสามารถเชื่อมต่อข้อมูลเบอร์โทรศัพท์ผ่านไลน์</div>
              <div>เพื่อรับการแจ้งเตือนสถานะการของเรื่องร้องเรียน</div>
            </div>

            <div
              style={{
                fontFamily: 'Prompt',
                fontSize: '16px',
                fontWeight: '400',
                marginBottom: '80px',
              }}
            >
              ผ่านการแสกน QR Code ด้านบน หรือ LineOA:@Koratcity
            </div>

            <BtSubmit onClick={() => navigate('/')}>กลับสู่หน้าหลัก</BtSubmit>
          </div>
        </CustomDrawer>

        <Modal
          open={isModalOpen}
          closeIcon={<CloseModelMap onClick={handleCancel} />}
          className="model-map-create-complant"
          title="เลือกตำแหน่งที่ตั้ง"
          onCancel={handleCancel}
          footer={null}
          bodyStyle={{ height: '80vh', width: '90vw', borderRadius: '10px' }}
          style={{ borderRadius: '10px' }}
          centered
        >
          <Viewmap
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            clickedLatLng={clickedLatLng}
            setClickedLatLng={setClickedLatLng}
            meLocation={meLocation}
            address={address[2]?.formatted_address}
            arrArear={arrArear}
            setIsModalOpenError={setIsModalOpenError}
            handleOk={handleOk}
            handleClickMap={handleClickMap}
            checkArear={checkArear}
            centerMap={centerMap}
            setCenterMap={setCenterMap}
            setZoomMap={setZoomMap}
            zoomMap={zoomMap}
            autocompleteValue={autocompleteValue}
            setAutocompleteValue={setAutocompleteValue}
            setSearchValue={setSearchValue}
            searchValue={searchValue}
          />
        </Modal>
        <Modal
          closable={false}
          open={isModalOpenError}
          footer={null}
          className="model-map-create-complant-error"
          centered
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontFamily: 'Prompt , sans-serif',
              textAlign: 'center',
            }}
          >
            <div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '15%',
                }}
              >
                <img src={BeforeIcon} style={{ width: '35px', height: '35px' }} />
              </div>
              <p style={{ fontSize: '20px', margin: 0 }}>ไม่อยู่ในเขตที่รับผิดชอบ</p>
              <p style={{ fontSize: '16px', color: '#C4C4C4', marginBottom: '15%' }}>กรุณาเลือกตำแหน่งใหม่</p>
              <BoxBtnInMap>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    style={{ width: '100px' }}
                    onClick={() => {
                      setIsModalOpenError(false)
                      setloaddingCraete(false)
                    }}
                  >
                    ตกลง
                  </Button>
                </div>
              </BoxBtnInMap>
            </div>
          </div>
        </Modal>
      </div>
    </>
  )
}

export default Index
