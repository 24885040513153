import { Drawer } from 'antd'
import styled from 'styled-components'

export const BoxSearch = styled.div`
  /* padding-bottom: 24px; */
  margin-bottom: 27px;

  @media only screen and (max-width: 768px) {
    margin-bottom: 0px;
    width: 100%;
  }

  @media only screen and (max-width: 375px) {
    margin-bottom: 0px;
    width: 100%;
  }

  .rc-virtual-list-scrollbar {
    width: 6px !important;
  }

  .rc-virtual-list-scrollbar-thumb {
    background: #ffe6db !important;
    width: 6px !important;
    border-radius: 8px !important;
    height: 96px !important;
  }

  .ant-select {
    color: #150f0b;
  }
  .ant-select-dropdown {
    background: #ffffff;
    box-shadow: 0px 4px 16px rgb(200 211 218 / 50%) !important;
    border-radius: 8px !important;
  }
  .ant-select-arrow {
    left: 11px !important;
    font-size: 18px;
    justify-content: end;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    /* padding-left: 10px;
    padding-top: 4px; */
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 8px;
    /* border: 1px solid #fee0d3; */
    border: none;
    height: 30px;
    padding: 1px 0px;

    @media only screen and (max-width: 768px) {
      padding: 0 8px;
    }

    @media only screen and (max-width: 375px) {
      padding: 0 8px;
    }

    svg {
      visibility: hidden !important;
    }

    img {
      display: none !important;
    }

    @media only screen and (max-width: 768px) {
      height: 32px;
      border: 1px solid #fee0d3;
    }

    @media only screen and (max-width: 375px) {
      height: 32px;
      border: 1px solid #fee0d3;
    }
  }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-search {
    display: flex;
    align-items: center;
    /* padding-left: 10px; */
  }

  .ant-select-disabled .ant-select-selector {
    /* background: #fff3ee !important; */
    background: transparent !important;
    /* border: 1px solid #fff3ee !important; */
    border: 1px solid transparent;
    color: #fe814a !important;
  }
  .ant-select-disabled .ant-select-arrow svg > path {
    stroke: #ffc4aa;
  }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #fe814a;
    box-shadow: none;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #fe814a;
    box-shadow: none;
  }

  .ant-select,
  .ant-select-single,
  .ant-select-show-arrow,
  .ant-select-show-search {
    /* width: 100%; */
  }

  .ant-select-selection-placeholder {
    color: #fee0d3 !important;
    font-weight: 400;
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: rgba(254, 129, 74, 0.08);
    color: #fe814a;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    font-weight: 500;
  }

  .ant-select-selection-item {
    @media only screen and (max-width: 768px) {
      font-size: 12px;
    }

    @media only screen and (max-width: 375px) {
      font-size: 12px;
    }
  }

  /* .ant-select .big-input .ant-select-single .ant-select-show-arrow {
    svg {
      display: block;
    }
  } */
  .ant-select {
    svg {
      display: block !important;
    }
  }
`

export const CustomDrawer = styled(Drawer)`
  z-index: 2000;

  &.ant-drawer-content-wrapper {
    height: 90% !important;
  }

  .ant-drawer-right > .ant-drawer-content-wrapper {
    width: 600px !important;

    @media only screen and (max-width: 768px) {
      width: 100% !important;
    }
  }

  .ant-drawer-content {
    border-radius: 12px 12px 0px 0px;
  }

  .ant-drawer-body {
    padding: 0px;
  }
`

// export const HeaderDrawer = styled.div`
//   height: 25px;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   margin-bottom: 32px;
//   width: 100%;
//   /* position: fixed;
//   background-color: #fff;
//   height: 79px;
//   padding: 36px 24px;
//   border-radius: 12px 12px 0px 0px;
//   z-index: 1000; */
// `

// export const TextType = styled.span`
//   font-family: 'Prompt';
//   font-size: 16px;
//   font-weight: 500;
//   color: #ffc4aa;
// `

// export const CloseText = styled.span`
//   font-family: 'Prompt';
//   font-size: 16px;
//   font-weight: 500;
//   color: #fe814a;
// `

// export const BoxOption = styled.div`
//   height: 48px;
//   width: 100%;
//   margin-bottom: 8px;
//   border-bottom: ${props => (props.select ? '1px solid #FFF3EE' : '1px solid #ece9e9')};
//   background-color: ${props => (props.select ? '#FFF3EE' : '#ffffff')};
//   display: flex;
//   align-items: center;

//   label {
//     font-family: 'Prompt';
//     font-size: 16px;
//     font-weight: 400;
//     color: #150f0b;
//   }
// `

export const HeaderDrawer = styled.div`
  height: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 32px; */
  width: 100%;
  position: sticky; /* Change to sticky */
  height: 79px;
  top: 0; /* Stick it to the top */
  background-color: #fff;
  z-index: 1000;
  padding: 32px 24px; /* Adjust the padding as needed */
`

export const TextType = styled.span`
  font-family: 'Prompt';
  font-size: 16px;
  font-weight: 500;
  color: #ffc4aa;
`

export const CloseText = styled.span`
  font-family: 'Prompt';
  font-size: 16px;
  font-weight: 500;
  color: #fe814a;
`

export const BoxOption = styled.div`
  height: 48px;
  width: 100%;
  padding: 0 24px;
  margin-bottom: 8px;
  border-bottom: ${props => (props.select ? '1px solid #FFF3EE' : '1px solid #ece9e9')};
  background-color: ${props => (props.select ? '#FFF3EE' : '#ffffff')};
  display: flex;
  align-items: center;
  justify-content: space-between;

  label {
    font-family: 'Prompt';
    font-size: 16px;
    font-weight: 400;
    color: #150f0b;
  }
  overflow-y: auto;
`
