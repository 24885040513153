export const setRequestHeadersInterceptor = config => {
  let token = JSON.parse(localStorage.getItem('token')) || ''

  // hardcode token for test internal mm
  if (config.baseURL && config.baseURL.includes('10.1.2.135')) {
    config.headers.Authorization = `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiOTQxNjMiLCJsb2dpbiI6ImFtcDAwMDAyIiwidXNlcl90eXBlIjoibW9waCIsImRhdGEiOiIzMzQ2MzQzNTMyMzgzNDM4MzI0MjM0NDQyMTY5ZWVmMzBjMDk3NzNhZTE3ODM0NDYxYTYwYzk3ZDBjOGM3YjI3YTY5NjUzYzE1NTUzODU1MWYyIiwiZXhwIjoxNjk2MjAxNzQ2fQ.UFxt-kPsP-YXaZyF9Im_J388mtq0iPAqvZ2kTC2dt-0`
  } else {
    if (token) {
      config.headers.Authorization = `Bearer ${token?.access_token}`
    }
  }


  return config
}
