import React, { useState, useEffect, useContext } from 'react'
import { useFormik } from 'formik'

import {
  CustomDivider,
  InputEditName,
  StyleDropdown,
  BoxData,
  WrapProfileResponsive,
  BoxProfileDetial,
  BtLogout,
  ModalSuccessEditProfile,
  BtOk,
} from './Header.style'
import { Input } from 'components/input'
import { InputEditProfile } from 'components/Inputs'
import { defaultValues, Schema } from './container'
import DropdownEditProfile from 'components/Inputs/DropdownEditProfile/DropdownEditProfile'
import { authentication, masterdata } from 'core/schemas'
import Loader from 'components/Layout/Loader'

import { ReactComponent as IconClear } from 'assets/images/svg/icon-close-drawer.svg'
import { ReactComponent as IconAllowClear } from 'assets/images/svg/icon-allowclear.svg'
import { ReactComponent as BackButtonMobile } from 'assets/images/svg/back-button-profile.svg'
import { ReactComponent as Success } from 'assets/images/svg/success-edit-profile.svg'

import IconProfileDrawer from 'assets/images/svg/icon-profile-drawer.svg'
import { getData, postData, putData } from 'core/action/collection'
import { httpClient } from 'core/action/httpClient'
import { Button } from 'antd'
import UserContext from 'core/contexts/userContext'

const EditProfileDrawer = props => {
  const { onCloseDrawer, getMeHeader, onLogout, statusEdit } = props
  const { meData, getMeData } = useContext(UserContext)

  const [edit, setEdit] = React.useState(statusEdit)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [loading, setLoading] = useState(true)

  const [userId, setUserId] = useState('')
  const [firstName, setFirstName] = useState('-')
  const [lastName, setLastName] = useState('-')
  const [mobileNumber, setMobileNumber] = useState('-')
  const [groupUser, setGroupUser] = useState('-')
  const [address, setAddress] = useState('-')
  const [province, setProvince] = useState('-')
  const [district, setDistrict] = useState('-')
  const [subDistrict, setSubDistrict] = useState('-')
  const [postCode, setPostCode] = useState('-')
  const [lineId, setLineId] = useState('')
  const [singUpChannel, setSingUpChannel] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const [provinceId, setProvinceId] = useState('')
  const [provinceData, setProvinceData] = useState([])
  const [districtData, setDistrictData] = useState([])
  const [subDistrictData, setSubDistrictData] = useState([])
  const [districtId, setDistrictId] = useState('')
  const [subDistrictId, setSubDistrictId] = useState('')

  const [isModalOpen, setIsModalOpen] = useState(false)

  const isSizeMobile = 768

  useEffect(() => {
    window.addEventListener('resize', handleResize)
  }, [])

  const handleResize = () => {
    setWindowWidth(window.innerWidth)
  }

  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema: Schema,
    enableReinitialize: true,
    onSubmit: values => {},
  })

  useEffect(() => {
    getMasterData()
  }, [])

  const getMasterData = async () => {
    await setProfile()
    await getProvince()

    setLoading(false)
  }

  const setProfile = async () => {
    formik.setFieldValue('first_name', meData?.name?.first_name)
    formik.setFieldValue('last_name', meData?.name?.last_name)
    formik.setFieldValue('mobile_number', meData?.mobile_number)
    formik.setFieldValue('group_id', meData?.group?.id)
    formik.setFieldValue('address', meData?.address)
    formik.setFieldValue('province_id', meData?.province?.id)
    formik.setFieldValue('district_id', meData?.district?.id)
    formik.setFieldValue('sub_district_id', meData?.sub_district?.id)
    formik.setFieldValue('postcode', meData?.sub_district?.post_code)

    setUserId(meData?.id)
    setFirstName(meData?.name?.first_name)
    setLastName(meData?.name?.last_name)
    setMobileNumber(meData?.mobile_number)
    setGroupUser(meData?.group?.name)
    setAddress(meData?.address)
    setProvince(meData?.province?.name_th)
    setDistrict(meData?.district?.name_th)
    setSubDistrict(meData?.sub_district?.name_th)
    setPostCode(meData?.sub_district?.post_code)

    setProvinceId(meData?.province?.id)
    setDistrictId(meData?.district?.id)
  }

  const getProvince = async () => {
    const res = await getData({
      schema: `${masterdata}/all/province`,
      params: {},
      url: '',
      baseURL: '',
    })

    if (res) {
      const newData = res.data.slice()
      newData.sort((a, b) => (a.id === '19' ? -1 : b.id === '19' ? 1 : 0))

      setProvinceData(newData)
    }
  }

  const getDistrict = async () => {
    const res = await getData({
      schema: `${masterdata}/all/district/` + provinceId,
      params: {},
      url: '',
      baseURL: '',
    })

    if (res) {
      const newData = res.data.slice()
      newData.sort((a, b) => (a.id === '215' ? -1 : b.id === '215' ? 1 : 0))

      setDistrictData(newData)
    }
  }

  const getSubDistrict = async () => {
    const res = await getData({
      schema: `${masterdata}/all/sub-district/` + districtId,
      params: {},
      url: '',
      baseURL: '',
    })
    // setSubDistrictData(res?.data)

    if (res) {
      const newData = res.data.slice()
      newData.sort((a, b) => (a.id === '300101' ? -1 : b.id === '300101' ? 1 : 0))

      setSubDistrictData(newData)
    }
  }

  useEffect(() => {
    provinceId && getDistrict()
  }, [provinceId])

  useEffect(() => {
    districtId && getSubDistrict()
  }, [districtId])

  const optionsProvince = provinceData?.map((items, index) => ({
    value: items?.id,
    label: items?.province_name.th,
    key: index,
  }))

  const optionsDistrict = districtData?.map((items, index) => ({
    value: items?.id,
    label: items?.district_name.th,
    key: index,
  }))

  const optionsSubdistrict = subDistrictData?.map((items, index) => ({
    value: items?.id,
    label: items?.sub_district_name.th,
    key: index,
  }))

  const onChangeProvince = () => {
    formik.setFieldValue('district_id', '')
    formik.setFieldValue('postcode', '')
    formik.setFieldValue('sub_district_id', '')
    // getDistrict()
    // getSubDistrict()
  }

  const handleDrawerOptionClick = (value, fieldFormik) => {
    if (fieldFormik === 'group_id') {
      formik.setFieldValue('group_id', value)
    }
    if (fieldFormik === 'province_id') {
      formik.setFieldValue('province_id', value)
      formik.setFieldValue('postcode', '')
      formik.setFieldValue('district_id', '')
      formik.setFieldValue('sub_district_id', '')
      setProvinceId(value)
    }
    if (fieldFormik === 'district_id') {
      formik.setFieldValue('district_id', value)
      formik.setFieldValue('postcode', '')
      formik.setFieldValue('sub_district_id', '')
      setDistrictId(value)
    }
    if (fieldFormik === 'sub_district_id') {
      formik.setFieldValue('sub_district_id', value)
      setSubDistrictId(value)
    }
  }

  const onChangeDistrict = async value => {
    formik.setFieldValue('postcode', '')
    formik.setFieldValue('sub_district_id', '')
    // getSubDistrict()
  }

  const phoneNumberAutoFormat = phoneNumber => {
    const number = phoneNumber.trim().replace(/[^0-9]/g, '')

    if (number.length < 4) return number
    if (number.length < 7) return number.replace(/(\d{3})(\d{1})/, '$1-$2')
    if (number.length < 11) return number.replace(/(\d{3})(\d{3})(\d{1})/, '$1-$2-$3')
    return number.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
  }

  const onPhoneNumberChange = e => {
    e.preventDefault()
    const targetValue = phoneNumberAutoFormat(e.target.value)
    formik.setFieldValue('mobile_number', targetValue)
  }

  const checkPostCode = () => {
    const selectedSubDistrict = subDistrictData?.find(subDistrict => subDistrict.id === subDistrictId)

    if (selectedSubDistrict) {
      formik.setFieldValue('postcode', selectedSubDistrict.postcode)
    }
  }

  useEffect(() => {
    subDistrictData.length > 0 && formik.values.subdistrict !== '' && checkPostCode()
  }, [subDistrictId, subDistrictData])

  const getTokenStorage = baseURL => {
    // fix token for test internal mm
    let token = localStorage.getItem('access_token')
    if (token) {
      // if (typeof JSON.parse(token) === 'object') {
      //   return JSON.parse(token)['access_token']
      // } else {
      //   return token
      // }

      return token
    } else {
      return ''
    }
  }

  const onUpdateMe = async () => {
    // edit ? setEdit(false) : setEdit(true)
    // showModal()
    const body = {
      address: formik.values.address,
      district_id: formik.values.district_id,
      email: '',
      group_id: formik.values.group_id,
      id: userId,
      line_id: meData?.line_id,
      mobile_number: formik.values.mobile_number,
      name: {
        first_name: formik.values.first_name,
        last_name: formik.values.last_name,
        required_firstname: true,
        required_lastname: true,
      },
      password: '',
      province_id: formik.values.province_id,
      sign_up_channel: meData?.sign_up_channel,
      sub_district_id: formik.values.sub_district_id,
    }

    if (edit && isValidForm) {
      const res = await putData(`${authentication}/me`, '', body)
      if (res) {
        if (res?.code === 200) {
          showModal()
        }
      } else {
        console.log('Err', res)
      }
    } else {
      setEdit(true)
    }
  }

  // const isValidForm = formik.isValid && formik.dirty
  const isValidForm = formik.dirty

  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleOk = () => {
    setEdit(false)
    onCloseDrawer()
    getMasterData()
    getMeData()
    setIsModalOpen(false)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <Loader loading={loading}>
        {windowWidth <= isSizeMobile ? (
          <>
            {/* Mobile */}
            <WrapProfileResponsive>
              <div className="header">
                <div className="box-header-left">
                  <BackButtonMobile onClick={onCloseDrawer} />
                  <span>{edit ? 'แก้ไขข้อมูลส่วนตัว' : 'โปรไฟล์ผู้ใช้งาน'}</span>
                </div>
                <div className="box-header-right">
                  <span onClick={onUpdateMe} style={{ opacity: edit && !isValidForm ? '0.5' : '1' }}>
                    {edit ? 'เสร็จสิ้น' : 'แก้ไขข้อมูล'}
                  </span>
                </div>
              </div>
              <BoxProfileDetial>
                <div style={{ height: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <img src={IconProfileDrawer} alt="profile icon" style={{ width: '64px', height: '64px' }} />
                </div>
                <div
                  style={{
                    marginTop: '40px',
                    padding: '0',
                    borderBottom: '1px solid #f4f4f4',
                    paddingBottom: '10px',
                    marginBottom: '32px',
                  }}
                >
                  <h3>ข้อมูลส่วนตัว</h3>
                </div>
                <BoxData>
                  <span className="title-resposive">ชื่อ</span>
                  {edit ? (
                    <InputEditProfile
                      name="first_name"
                      placeholder="กรอกชื่อของคุณ"
                      type="text"
                      required={true}
                      errors={formik.errors}
                      touched={formik.touched}
                      value={formik?.values?.first_name}
                      form={formik}
                      status={formik.status}
                      maxLength={255}
                      requiredPadding={1}
                      fontsize="14px"
                    />
                  ) : (
                    <span className="data">{firstName}</span>
                  )}
                </BoxData>
                <BoxData>
                  <span className="title-resposive">นามสกุล</span>
                  {edit ? (
                    <InputEditProfile
                      name="last_name"
                      placeholder="กรอกนามสกุลของคุณ"
                      type="text"
                      required={true}
                      errors={formik.errors}
                      touched={formik.touched}
                      value={formik?.values?.last_name}
                      form={formik}
                      status={formik.status}
                      maxLength={255}
                      requiredPadding={1}
                      fontsize="14px"
                    />
                  ) : (
                    <span className="data">{lastName}</span>
                  )}
                </BoxData>
                <BoxData>
                  <span className="title-resposive">เบอร์โทรติดต่อ</span>
                  <span className="data">{mobileNumber}</span>
                </BoxData>
                <BoxData>
                  <span className="title-resposive">กลุ่มผู้ใช้บริการ</span>
                  <span className="data">{groupUser}</span>
                </BoxData>
                <h4>ที่อยู่ปัจจุบันของคุณ</h4>
                <BoxData>
                  <span className="title-resposive">รายละเอียดที่อยู่</span>
                  {edit ? (
                    <InputEditProfile
                      name="address"
                      placeholder="กรอกที่อยู่ของคุณ"
                      type="textarea"
                      required={true}
                      errors={formik.errors}
                      touched={formik.touched}
                      value={formik?.values?.first_name}
                      form={formik}
                      status={formik.status}
                      maxLength={255}
                      requiredPadding={1}
                      fontsize="14px"
                    />
                  ) : (
                    <span className="data">{address}</span>
                  )}
                </BoxData>
                <BoxData>
                  <span className="title-resposive">จังหวัด</span>
                  {edit ? (
                    <StyleDropdown>
                      <DropdownEditProfile
                        title="จังหวัด"
                        placeholder="เลือกจังหวัด"
                        showSearch={false}
                        required={true}
                        onChange={value => {
                          formik.setFieldValue('province_id', value)
                          setProvinceId(value)
                          onChangeProvince()
                        }}
                        className="big-input"
                        // options={[{ value: 'นครราชสีมา', label: 'นครราชสีมา' }]}
                        options={optionsProvince}
                        value={formik?.values?.province_id}
                        // defaultValue={formik.values.province}
                        checkmobile={windowWidth <= isSizeMobile ? true : false}
                        onDrawerOptionClick={handleDrawerOptionClick}
                        fieldFormik="province_id"
                      />
                    </StyleDropdown>
                  ) : (
                    <span className="data">{province}</span>
                  )}
                </BoxData>
                <BoxData>
                  <span className="title-resposive">อำเภอ</span>
                  {edit ? (
                    <StyleDropdown>
                      <DropdownEditProfile
                        title="อำเภอ"
                        placeholder="เลือกอำเภอ"
                        showSearch={false}
                        required={true}
                        onChange={value => {
                          formik.setFieldValue('district_id', value)
                          setDistrictId(value)
                          onChangeDistrict(value)
                        }}
                        className="big-input"
                        options={optionsDistrict}
                        value={formik.values.district_id}
                        // defaultValue={formik.values.district_id}
                        checkmobile={windowWidth <= isSizeMobile ? true : false}
                        onDrawerOptionClick={handleDrawerOptionClick}
                        fieldFormik="district_id"
                      />
                    </StyleDropdown>
                  ) : (
                    <span className="data">{district}</span>
                  )}
                </BoxData>
                <BoxData>
                  <span className="title-resposive">ตำบล</span>
                  {edit ? (
                    <StyleDropdown>
                      <DropdownEditProfile
                        title="ตำบล"
                        placeholder="เลือกตำบล"
                        showSearch={false}
                        required={true}
                        onChange={value => {
                          formik.setFieldValue('sub_district_id', value)
                          setSubDistrictId(value)
                        }}
                        className="big-input"
                        options={optionsSubdistrict}
                        value={formik?.values?.sub_district_id}
                        disabled={formik?.values?.district_id === ''}
                        // defaultValue={formik?.values?.subdistrict}
                        checkmobile={windowWidth <= isSizeMobile ? true : false}
                        onDrawerOptionClick={handleDrawerOptionClick}
                        fieldFormik="sub_district_id"
                      />
                    </StyleDropdown>
                  ) : (
                    <span className="data">{subDistrict}</span>
                  )}
                </BoxData>
                <BoxData>
                  <span className="title-resposive">รหัสไปรษณีย์</span>
                  <span className="data">{edit ? formik.values.postcode : postCode}</span>
                </BoxData>
                {edit ? '' : <BtLogout onClick={onLogout}>ออกจากระบบ</BtLogout>}
              </BoxProfileDetial>
            </WrapProfileResponsive>
          </>
        ) : (
          <>
            {/* Desktop */}
            <div style={{ height: '6.5em', position: 'relative' }}>
              <div className="close-icon" onClick={onCloseDrawer}>
                <IconClear />
              </div>

              <div className={edit && !isValidForm ? 'edit-profile-disable' : 'edit-profile'} onClick={onUpdateMe}>
                {/* <div className={edit && !isValidForm ? 'edit-profile-disable' : 'edit-profile'} onClick={onUpdateMe}> */}
                {edit ? 'เสร็จสิ้น' : 'เเก้ไขข้อมูล'}
                {/* <Button disabled={!isValidForm}>Test</Button> */}
              </div>
            </div>
            <div className="title">
              <h1>{edit ? 'แก้ไขข้อมูลส่วนตัว' : 'โปรไฟล์ผู้ใช้งาน'}</h1>
              <img src={IconProfileDrawer} alt="profile icon" />
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p className="info-title">ข้อมูลส่วนตัว</p>
            </div>
            <CustomDivider />
            <div className="row-data">
              <div className="col-1">
                <p className="text-head">ชื่อ</p>
                <div style={{ height: '48px' }}>
                  {edit ? (
                    <InputEditProfile
                      label="ชื่อ"
                      name="first_name"
                      placeholder="กรอกชื่อของคุณ"
                      type="text"
                      required={true}
                      errors={formik.errors}
                      touched={formik.touched}
                      value={formik?.values?.first_name}
                      form={formik}
                      status={formik.status}
                      maxLength={255}
                      requiredPadding={1}
                      fontsize="14px"
                      allowClear={{ clearIcon: <IconAllowClear /> }}
                    />
                  ) : (
                    <p className="text-data">{firstName}</p>
                  )}
                </div>
              </div>
              <div className="col-2">
                <CustomDivider type="vertical" />
              </div>
              <div className="col-3">
                <p className="text-head-right">นามสกุล</p>
                <div style={{ height: '48px' }}>
                  {edit ? (
                    <InputEditProfile
                      label="ชื่อ"
                      name="last_name"
                      placeholder="กรอกนามสกุลของคุณ"
                      type="text"
                      required={true}
                      errors={formik.errors}
                      touched={formik.touched}
                      value={formik?.values?.last_name}
                      form={formik}
                      status={formik.status}
                      maxLength={255}
                      requiredPadding={1}
                      fontsize="14px"
                      allowClear={{ clearIcon: <IconAllowClear /> }}
                    />
                  ) : (
                    <p className="text-data">{lastName}</p>
                  )}
                </div>
              </div>
            </div>
            <CustomDivider />
            <div className="row-data">
              <div className="col-1">
                <p className="text-head">เบอร์โทรศัพท์</p>
                <p className={`text-data ${edit === true ? 'text-data-disabled' : ''}`}>
                  {phoneNumberAutoFormat(mobileNumber)}
                </p>
              </div>
              <div className="col-2">
                <CustomDivider type="vertical" />
              </div>
              <div className="col-3">
                <p className="text-head-right">กลุ่มผู้ใช้บริการ</p>
                <p className={`text-data-right ${edit === true ? 'text-data-right-disabled' : ''}`}>{groupUser}</p>
              </div>
            </div>
            <CustomDivider />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p className="info-title">ที่อยู่ปัจจุบันของคุณ</p>
            </div>
            <CustomDivider />
            <div className="row-data">
              <div className="col-full">
                <p className="text-head">รายละเอียดที่อยู่</p>
                {edit ? (
                  <InputEditProfile
                    name="address"
                    placeholder="กรอกที่อยู่ของคุณ"
                    type={windowWidth <= isSizeMobile ? 'textarea' : 'text'}
                    required={true}
                    errors={formik.errors}
                    touched={formik.touched}
                    value={formik?.values?.first_name}
                    form={formik}
                    status={formik.status}
                    maxLength={255}
                    requiredPadding={1}
                    fontsize="14px"
                  />
                ) : (
                  <p className="text-data">{address}</p>
                )}
              </div>
            </div>
            <CustomDivider />
            <div className="row-data">
              <div className="col-1">
                <p className="text-head" style={{ paddingBottom: edit ? '0px' : '12px' }}>
                  จังหวัด
                </p>
                {edit ? (
                  <StyleDropdown>
                    <DropdownEditProfile
                      title="จังหวัด"
                      placeholder="เลือกจังหวัด"
                      showSearch={false}
                      required={true}
                      onChange={value => {
                        formik.setFieldValue('province_id', value)
                        setProvinceId(value)
                        onChangeProvince()
                      }}
                      className="big-input"
                      // options={[{ value: 'นครราชสีมา', label: 'นครราชสีมา' }]}
                      options={optionsProvince}
                      value={formik?.values?.province_id}
                      // defaultValue={formik.values.province}
                      checkmobile={windowWidth <= isSizeMobile ? true : false}
                      onDrawerOptionClick={handleDrawerOptionClick}
                      fieldFormik="province_id"
                    />
                  </StyleDropdown>
                ) : (
                  <p className="text-data">{province}</p>
                )}
              </div>
              <div className="col-2">
                <CustomDivider type="vertical" />
              </div>
              <div className="col-3">
                <p className="text-head-right" style={{ paddingBottom: edit ? '0px' : '12px' }}>
                  อำเภอ
                </p>
                {edit ? (
                  <StyleDropdown>
                    <DropdownEditProfile
                      title="อำเภอ"
                      placeholder="เลือกอำเภอ"
                      showSearch={false}
                      required={true}
                      onChange={value => {
                        formik.setFieldValue('district_id', value)
                        setDistrictId(value)
                        onChangeDistrict(value)
                      }}
                      className="big-input"
                      options={optionsDistrict}
                      value={formik.values.district_id}
                      // defaultValue={formik.values.district_id}
                      checkmobile={windowWidth <= isSizeMobile ? true : false}
                      onDrawerOptionClick={handleDrawerOptionClick}
                      fieldFormik="district_id"
                    />
                  </StyleDropdown>
                ) : (
                  <p className="text-data-right">{district}</p>
                )}
              </div>
            </div>
            <CustomDivider />
            <div className="row-data">
              <div className="col-1">
                <p className="text-head" style={{ paddingBottom: edit ? '0px' : '12px' }}>
                  ตำบล
                </p>
                {edit ? (
                  <StyleDropdown>
                    <DropdownEditProfile
                      title="ตำบล"
                      placeholder="เลือกตำบล"
                      showSearch={false}
                      required={true}
                      onChange={value => {
                        formik.setFieldValue('sub_district_id', value)
                        setSubDistrictId(value)
                      }}
                      className="big-input"
                      options={optionsSubdistrict}
                      value={formik?.values?.sub_district_id}
                      disabled={formik?.values?.district_id === ''}
                      // defaultValue={formik?.values?.subdistrict}
                      checkmobile={windowWidth <= isSizeMobile ? true : false}
                      onDrawerOptionClick={handleDrawerOptionClick}
                      fieldFormik="sub_district_id"
                    />
                  </StyleDropdown>
                ) : (
                  <p className="text-data">{subDistrict}</p>
                )}
              </div>
              <div className="col-2">
                <CustomDivider type="vertical" />
              </div>
              <div className="col-3">
                <p className="text-head-right" style={{ paddingBottom: edit ? '0px' : '12px' }}>
                  รหัสไปรษณีย์
                </p>
                <p className={`text-data-right ${edit === true ? 'text-data-right-disabled' : ''}`}>
                  {edit ? formik.values.postcode : postCode}
                </p>
              </div>
            </div>
          </>
        )}
      </Loader>

      <ModalSuccessEditProfile open={isModalOpen} footer={false} closable={false} onCancel={handleCancel} zIndex={1050}>
        <Success />
        <div className="title">แก้ไขข้อมูลส่วนตัวสำเร็จ</div>
        <div className="description">แก้ไขข้อมูลส่วนตัวสำเร็จ กดตกลงเพื่อกลับไปที่หน้าแรก</div>
        <BtOk onClick={() => handleOk()}>ตกลง</BtOk>
      </ModalSuccessEditProfile>
    </>
  )
}

export default EditProfileDrawer
