
import styled, { createGlobalStyle } from 'styled-components'
import { Modal, Button } from 'antd'


export const BtSubmit = styled(Button)`
  background-color: #fc6521;
  /* background-color: ${props => (props.disabled ? 'transparent' : '#fc6521')}; */
  font-family: 'Prompt';
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  border-radius: 8px;
  width: 100%;
  height: 40px;
  justify-content: center;

  &:hover,
  &:active,
  &:focus {
    color: #ffffff;
    background-color: #fc6521;
    border-color: #fc6521;
  }

  &.ant-btn[disabled] {
    background: rgba(252, 101, 33, 1) !important;
    color: #ffffff;
    opacity: 0.2;
  }
`

export const Boxhead = styled.div`
font-family: "Prompt";
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 20px;
margin-bottom: 16px;
  
`
export const Boxsearchgoogle = styled.div`

  &.pac-container{
    background-color: red !important;

    .pac-item{
    background-color: red !important;
    .pac-item-query{
    background-color: red !important;
  }
  }
  }

 
  

 
`


export const BoxSelect = styled.div`

  /* .ant-select-single .ant-select-selector .ant-select-selection-search {
    top: 5 !important;
  } */
  .ant-input-affix-wrapper {
    width: 270px;
  }
`

export const Boxtextlocationarea = styled.div`
      display: flex;
      margin-top: 15px;
      width: 100%;
      margin: 16px 0px 16px 0px;

  .col-location-1{
    width: 10%;
    margin-top:-4px;
    /* padding-right: 5px; */
  }
  .col-location-2{
    font-family: "Prompt";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    width: 90%;

    .detaillocation{
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: 14.4px; 
      color: #ABA9A8;
    }
  }
`
export const Boxtextlocation = styled.div`
      display: flex;
      margin-top: 10px;
      width: 100%;
      cursor: pointer;
      padding: 16px 8px 16px 8px;
      border-radius: 8px;
      :hover{
        background: #FFF8F4;
      }


  .col-location-1{
    width: 15%;
    padding-right: 5px;
  }
  .col-location-2{
    font-family: "Prompt";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    width: 85%;

    .detaillocation{
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: 14.4px; 
      color: #ABA9A8;
    }
  }
`

export const BoxMaparea = styled.div`
 position: absolute;
  z-index: 299;
  left: 20px;
  top: 170px;
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: center; */
  padding: 24px 16px;
  width: 300px;
  height: 480px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(200, 211, 218, 0.3);
  border-radius: 16px;
  h4 {
    margin: 0;
  }
  h3 {
    margin: 0;
  }
  small {
    width: 100%;
  }
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    img {
      margin-right: 5px;
    }
    h3 {
      margin: 0;
    }
  }
`
export const BoxMapSearch = styled.div`
 position: absolute;
  z-index: 299;
  left: 20px;
  top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  padding: 16px;
  width: 300px;
  height: 140px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(200, 211, 218, 0.3);
  border-radius: 16px;
  h4 {
    margin: 0;
  }
  h3 {
    margin: 0;
  }
  small {
    width: 100%;
  }
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    img {
      margin-right: 5px;
    }
    h3 {
      margin: 0;
    }
  }
`

export const ModalMap = styled(Modal)`
   
    .ant-modal-content{
      border-radius: 36px !important;
      width: 1200px;
      height: 800px;
    }
    .ant-modal-header{
      padding: 30px 25px 3px 25px;
      border-bottom: none;
      border-radius: 36px !important;
      font-family: "Prompt";
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
    }

    .ant-modal-close-x {
        color:#F36B24 !important;
        width: 40px;
        height: 40px;
        line-height:40px;
        margin:10px;
        border-radius:50%;
        box-shadow: 0px 0px 2px 2px #F4F4F4;      
      }

      #option-button {
        padding: 10px;
        position: absolute;
        bottom: 8px;
        right: 0px;
        z-index: 999;
      }

      .btn-zoom-in,
      .btn-zoom-in:hover,
      .btn-zoom-in:focus {
        background: #ffffff;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
        border: none;
        width: 32px;
        height: 32px;
        border-bottom: 1px solid #e2e5f8;
      }
      .btn-get-location,
      .btn-get-location:hover,
      .btn-get-location:focus {
        background: #ffffff;
        border-radius: 8px;
        border: none;
        width: 32px;
        height: 32px;
        border-bottom: 1px solid #e2e5f8;
      }

      .btn-zoom-out,
      .btn-zoom-out:hover,
      .btn-zoom-out:focus {
        background: #ffffff;
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
        border-top-right-radius: 0px;
        border-top-left-radius: 0px;
        border: none;
        width: 32px;
        height: 32px;
        padding-top: 7px;
      }

  
`
export const Boxmapmaker = styled.div`
  font-family: "Prompt";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  background: white;
  padding: 16px;
  border-radius: 8px;
  width: 150px;
  margin-bottom:75px;

`

export const ButtonOpenMap = styled.div`
  cursor: ${props => (props.disable === 'true' ? 'unset' : 'pointer')};
  width: 100%;
  height: 80px;
  background: #ffffff;
  box-shadow: 0px 2px 10px 0px #F4F4F4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 16px;
  order: 1;
  flex-grow: 0;
  transition: 0.3s;
  padding: 16px 22px;

  /* &:hover {
    border: 1px solid #FFC4AA;
  } */
  h3 {
    margin: 0;
    margin-left: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .title {
    display: flex;
    align-items: center;
  }
  .col-location-1{
    width: 1%;
    padding-right: 5px;
  }
  .col-location-2{
    font-family: "Prompt";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    width: 80%;

    .detaillocation{
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: 14.4px; 
      color: #ABA9A8;
    }
  }
  .col-location-3{
    width: 5%;
  }

`



export const mapStyle = [

  {
    elementType: 'geometry',
    stylers: [
      {
        color: '#f2f2f2',
      },
    ],
  },
  {
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#7c88a6',
      },
    ],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#f5f5f5',
      },
    ],
  },
  {
    featureType: 'administrative.land_parcel',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#dde6e8',
      },
    ],
  },
  {
    featureType: 'administrative.neighborhood',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        color: '#dde6e8',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#7c88a6',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        color: '#f2f2f2',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#7c88a6',
      },
    ],
  },
  {
    featureType: 'poi.business',
    elementType: 'labels.icon',
    stylers: [
      {
        color: '#937f66',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#e6e6e6',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry.stroke',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#97a6cc',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [
      {
        color: '#dddfe1',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#dddfe1',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#97a6cc',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#97a6cc',
      },
    ],
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry',
    stylers: [
      {
        color: '#97a6cc',
      },
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#97a6cc',
      },
    ],
  },
  {
    featureType: 'transit.station',
    elementType: 'geometry',
    stylers: [
      {
        color: '#81c0fe',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ccd4e0',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#bac4d1',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#868ea2',
      },
    ],
  },
]


