import { Button, Checkbox } from 'antd'
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Prompt' !important;

  background-image: ${props => (props.url ? `url(${props.url})` : '#fcfcfc')};
  background-position: ${props => (props.mobile ? `0% 375%` : 'center center')};
  background-repeat: ${props => (props.mobile ? `none` : 'no-repeat')};
  background-size: ${props => (props.mobile ? `none` : 'cover')};

  /* .ant-tooltip-disabled-compatible-wrapper {
    width: 100%;
  } */
`

export const BoxContent = styled.div`
  width: 964px;
  height: 95%;
  background-color: #ffffff;
  border-radius: 24px;
  padding: 16px 16px;
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
  flex-direction: column;
`

export const WrapConsent = styled.div`
  width: 100%;
  height: 85%;
  overflow-y: auto;
  background-color: #f5f1f1;
  border-radius: 10px;
  padding: 16px 24px;

  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background-color: #eaeaeb !important; /* Set the scrollbar track color */
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #f36b24 !important; /* Set the scrollbar thumb color */
    color: red !important;
    border-radius: 8px;
  }
`

export const TitleHead = styled.h1`
  font-size: 20px;
  text-align: center;
`

export const Title = styled.h3`
  font-size: 14px;
`

export const PIndent = styled.p`
  text-indent: 3em;
  font-weight: 300;
`

export const PNomal = styled.p`
  font-weight: 300;
`

export const WrapNumber = styled.div`
  /* text-indent: 2em; */

  ol {
    counter-reset: section; /* เริ่มต้นการนับเลขข้อ */
  }
  li {
    list-style: none; /* ไม่แสดงเลขข้อ */
    font-weight: 300;
    overflow-wrap: break-word;
  }
  li:before {
    counter-increment: section; /* เพิ่มค่าของเลขข้อ */
    content: counter(section) '. '; /* แสดงเลขข้อ */
  }
`

export const CustomCheckbox = styled(Checkbox)`
  .ant-checkbox .ant-checkbox-inner {
    border-color: #f36b24 !important;
  }

  .ant-checkbox-inner {
    width: 24px !important;
    height: 24px !important;
    border-radius: 4px !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #f36b24;
    border-color: #f36b24;
  }

  .ant-checkbox-checked .ant-checkbox-inner:after {
    left: 7px !important;
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: #f36b24 !important;
  }

  .ant-checkbox-checked:after {
    border: 1px solid #f36b24 !important;
  }
`

export const BoxCheckBox = styled.div`
  margin: 1rem 0rem;
  display: flex;
  align-items: center;
`

export const BoxButton = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const BtSubmit = styled(Button)`
  background: #f36b24;
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: white;
  line-height: 6px;
  padding: 18px 8px;
  width: 366px;
  border: none;
  cursor: pointer;
  height: 56px;
  /* margin-bottom: 5.5rem; */

  ${props =>
    props.type === 'primary' &&
    `
      background: #F36B24;

       &:hover,
        :focus {
         background: #F36B24 !important;
         color: white;
        }
    `}

  &:disabled {
    background-color: #fee0d3;
    border-color: #fee0d3;
    color: white;
    cursor: not-allowed;

    &:hover,
    :focus {
      background: #fee0d3;
      color: white;
    }
  }

  &:hover,
  :focus {
    background: #f36b24;
    color: white;
  }

  &:active {
    box-shadow: none;
    border-color: #f36b24 !important;
  }

  @media only screen and (max-width: 768px) {
    height: 56px;
    font-size: 18px;
    width: 100%;
    margin-bottom: 3rem;
  }

  @media only screen and (max-width: 375px) {
    height: 56px;
    font-size: 18px;
    width: 100%;
    margin-bottom: 3rem;
  }
`
